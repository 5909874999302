import FuturaStdHeavyWoff from 'assets/fonts/FuturaStd/FuturaStdHeavy.woff';
import FuturaStdMediumWoff from 'assets/fonts/FuturaStd/FuturaStdMedium.woff';
import FuturaStdBookWoff from 'assets/fonts/FuturaStd/FuturaStdBook.woff';
import FuturaStdBoldWoff from 'assets/fonts/FuturaStd/FuturaStdBold.woff';
import RobotoMonoRegularTtf from 'assets/fonts/RobotoMono/RobotoMono-Regular.ttf';

export const FuturaStdHeavy = {
  fontFamily: 'FuturaStdHeavy',
  fontStyle: 'heavy',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Futura-Std'),
    local('Futura-Std-Heavy'),
    url(${FuturaStdHeavyWoff}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};
export const FuturaStdMedium = {
  fontFamily: 'FuturaStdMedium',
  fontStyle: 'medium',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
      local('Futura-Std'),
      local('Futura-Std-Medium'),
      url(${FuturaStdMediumWoff}) format('woff')
    `,
  unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};
export const FuturaStdBook = {
  fontFamily: 'FuturaStdBook',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
      local('Futura-Std'),
      local('Futura-Std-Book'),
      url(${FuturaStdBookWoff}) format('woff')
    `,
  unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};
export const FuturaStdBold = {
  fontFamily: 'FuturaStdBold',
  fontStyle: 'bold',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
      local('Futura-Std'),
      local('Futura-Std-Bold'),
      url(${FuturaStdBoldWoff}) format('woff')
    `,
  unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};
export const RobotoMono = {
  fontFamily: 'Roboto Mono',
  fontStyle: 'regular',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${RobotoMonoRegularTtf}) format('truetype')`
};
