import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import _, { isObject } from 'lodash';
import { register } from 'entities/users/users.api';
import { fetchAccount, fetchAccounts } from 'entities/accounts/accounts.api';
import { updateRole, deleteRole } from 'entities/roles/roles.api';
import { getServerError } from 'pages/pages.utils';
import OutlinedFieldSelect from './components/OutlinedFieldSelect';
import { rolesWithWeight, getHighestRole } from 'utils/roles';
import propTypes from './AddEditUser.props';
import makeStyles from './AddEditUser.styles';
const useStyles = makeStyles();

function reducer (state, action) {
  switch (action.type) {
    case 'updateUser':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

function AddEditUser (props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const { refreshTable, editMode = false, submitButtonRef, data, onSubmit, accountId } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [error, setError] = useState([]);
  const [errorFields, setErrorFields] = useState({});
  const [role, setRole] = useState([]);
  const roles = _.values(rolesWithWeight);

  const [user, dispatch] = useReducer(reducer, {
    id: data?.id,
    username: data?.traits.username,
    first_name: data?.traits.name.first,
    last_name: data?.traits.name.last,
    email: data?.traits.email,
    accountid: data?.account ?? accountId,
    roles: data?.roles
  });

  useEffect(() => {
    if (accountId) {
      fetchAccount(accountId).then(response => setAccounts(response?.data ? [response?.data] : []));
    } else {
      fetchAccounts().then(response => setAccounts(response?.data ?? []));
    };

    if (editMode) {
      setRole(_.get(getHighestRole(user.roles), 'roleName'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanupErrors = field => {
    setErrorFields(prevError => {
      const nextErrors = { ...prevError };
      switch (field) {
        default:
          delete nextErrors[field]; break;
      }

      return nextErrors;
    });
  };

  const updateUser = useCallback(e => {
    dispatch({
      type: 'updateUser',
      payload: {
        [e.target.name]: e.target.value
      }
    });
    cleanupErrors(e.target.name);
  }, [dispatch]);

  const updateUserRole = useCallback(e => {
    setRole(e.target.value);
    updateUser(e);
  }, [setRole, updateUser]);

  const onDeleteRole = (role, roles, id) => {
    const highestRole = getHighestRole(roles);
    if (highestRole.roleName !== 'none') {
      return deleteRole(highestRole.roleName, id).then(() => updateRole(role, id));
    };
    updateRole(role, id);
  };

  const editUser = useCallback(user => {
    if (user?.id && user?.role && user?.roles) {
      onDeleteRole(user?.role, user?.roles, user?.id)
        .then(() => refreshTable());
    };
  }, [refreshTable]);

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    if (!editMode) {
      register(user)
        .then(() => {
          onSubmit();
          refreshTable();
        })
        .catch(error => {
          const errorData = getServerError(error?.response?.data);
          if (isObject(errorData)) {
            setErrorFields(errorData);
            setError([]);
          } else {
            setError(errorData);
          };
        });
    } else {
      editUser(user);
    }
  }, [editMode, user, onSubmit, refreshTable, editUser]);

  return (
    <form className={classes.addUserForm} onSubmit={handleSubmit}>
      <FormControl
        variant="outlined"
      >
        {error && <p className={classes.inlineError}>{error}</p>}
        {<OutlinedFieldSelect
          label={t('select_the_owner_account')}
          required
          fullWidth
          labelWidth={75}
          margin="normal"
          name="accountid"
          id="accountid"
          value= {user.accountid || accountId}
          onChange={updateUser}
          disabled={editMode || accountId}
        >
          {
            accounts.length > 0
              ? accounts.map((account, index) => (
              <MenuItem value={account.id} key={index}>{account.name}</MenuItem>
              ))
              : <MenuItem value={0}>No Items</MenuItem>
          }
        </OutlinedFieldSelect>}
      </FormControl>
      <FormControl
        variant="outlined"
      >
        <OutlinedFieldSelect
          label={t('role')}
          required
          fullWidth
          labelWidth={75}
          margin="normal"
          name="role"
          id="role"
          value= {role}
          onChange={updateUserRole}
        >
          {
            roles.length > 0
              ? roles.map((role, index) => (
              <MenuItem value={role.roleName} key={index}>{role.label}</MenuItem>
              ))
              : <MenuItem value={0}>No Items</MenuItem>
          }
        </OutlinedFieldSelect>
      </FormControl>
      <FormControl
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-username">
          {t('username')}
        </InputLabel>

        <OutlinedInput
          margin="normal"
          required
          fullWidth
          name="username"
          labelWidth={70}
          type="text"
          autoFocus
          id="username"
          autoComplete="username"
          value={user.username}
          onChange={updateUser}
          disabled={editMode}
        />
      </FormControl>
      <FormControl
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-username">
          {t('first_name')}
        </InputLabel>

        <OutlinedInput
          margin="normal"
          required
          fullWidth
          name="first_name"
          labelWidth={70}
          type="text"
          autoFocus
          id="first_name"
          autoComplete="first_name"
          value={user.first_name}
          onChange={updateUser}
          disabled={editMode}
        />
      </FormControl>
      <FormControl
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-username">
          {t('last_name')}
        </InputLabel>

        <OutlinedInput
          margin="normal"
          required
          fullWidth
          name="last_name"
          labelWidth={70}
          type="text"
          autoFocus
          id="last_name"
          autoComplete="last_name"
          value={user.last_name}
          onChange={updateUser}
          disabled={editMode}
        />
      </FormControl>
      <FormControl
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-username">
          {t('email_address')}
        </InputLabel>

        <OutlinedInput
          margin="normal"
          required
          fullWidth
          name="email"
          labelWidth={100}
          type="email"
          autoFocus
          id="email"
          autoComplete="email"
          value={user.email}
          onChange={updateUser}
          disabled={editMode}
          error={errorFields?.email}
          />
          {errorFields?.email && <p className={classes.inlineError}>{errorFields?.email}</p>}
      </FormControl>
      { !editMode &&
        <FormControl
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-password">{t('password')}</InputLabel>
          <OutlinedInput
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('password')}
            labelWidth={70}
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            value={user.password}
            onChange={updateUser}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label={t('toggle_password_visibility')}
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility /> }
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      }
      <button ref={submitButtonRef} style={{ display: 'none' }} type='submit'></button>
    </form>
  );
}

AddEditUser.propTypes = propTypes;

export default AddEditUser;
