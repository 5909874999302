import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Warning } from 'common/Icons';
import makeStyles from './styles';
import { useDialog } from 'hooks';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles();

const AbortButton = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const { onClick } = props;
  const onConfirm = () => {
    onClick();
    onDisplayDialog({ isOpen: false });
  };
  const onCancel = () => {
    onDisplayDialog({ isOpen: false });
  };

  const onDisplayDialog = useDialog('ConfirmAbortScan',
    {
      onClose: () => {
        onDisplayDialog({ isOpen: false });
      },
      components: {
        Icon: <Warning />,
        Title: <Typography variant="h5" align="center">{t('confirm_cancel_scan_message')}</Typography>,
        ContentText: <Typography variant="body2" align="center">{t('if_you_cancel_scan')}</Typography>,
        Actions: (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={onConfirm}
              size="large"
            >
              {t('confirm_cancel_scan')}
            </Button>
            <Button
              variant="outlined"
              onClick={onCancel}
              size="large"
            >
            {t('confirm_continue_scan')}
            </Button>
          </>
        )
      }
    });

  return <Button
    variant="contained"
    fullWidth
    className={classes.scanButton}
    onClick={() => onDisplayDialog({ isOpen: true })}
    color="secondary"
  >
    {t('abort_scan')}
  </Button>;
};

AbortButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default AbortButton;
