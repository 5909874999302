import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  safe: {
    color: theme.palette.success.main
  },
  unsafe: {
    color: theme.palette.error.main
  },
  failed: {
    color: theme.palette.error.main
  },
  cancelled: {
    color: theme.palette.text.secondary
  },
  suspicious: {
    color: theme.palette.warning.main
  },
  undetermined: {
    color: '#167424'
  },
  clean: {
    color: '#167424'
  },
  unknown: {
    color: theme.palette.text.secondary
  },
  notAnalyzed: {
    color: theme.palette.text.secondary
  },
  warning: {
    color: theme.palette.warning.main
  },
  safeBackground: {
    backgroundColor: theme.palette.success.main
  },
  unsafeBackground: {
    backgroundColor: theme.palette.error.main
  },
  failedBackground: {
    backgroundColor: theme.palette.error.main
  },
  cancelledBackground: {
    backgroundColor: theme.palette.error.main
  },
  suspiciousBackground: {
    backgroundColor: theme.palette.warning.main
  },
  undeterminedBackground: {
    backgroundColor: '#167424'
  },
  cleanBackground: {
    backgroundColor: '#167424'
  },
  unknownBackground: {
    backgroundColor: theme.palette.text.secondary
  },
  notAnalyzedBackground: {
    backgroundColor: theme.palette.text.secondary
  },
  warningBackground: {
    backgroundColor: theme.palette.warning.main
  },
  runningBackground: {
    backgroundColor: theme.palette.primary.main
  }
}));
