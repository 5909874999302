/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Icon from './Icon';
import makeStyles from './styles';
import { DiGoogleAnalytics } from 'react-icons/di';
import { IoMdImages, IoIosLock, IoMdShare, IoMdCloseCircle, IoIosLogOut, IoIosCheckmarkCircle, IoIosCheckmarkCircleOutline, IoMdCopy, IoIosCopy, IoIosGlobe, IoMdCloseCircleOutline } from 'react-icons/io';
import { GrDocumentLocked, GrResources, GrDocumentOutlook, GrDocumentText } from 'react-icons/gr';
import { SiCodesandbox } from 'react-icons/si';
import { TiDocumentText, TiDeleteOutline, TiDocumentDelete } from 'react-icons/ti';
import { FiFileText, FiAlertTriangle, FiCodesandbox } from 'react-icons/fi';
import {
  AiOutlineDown,
  AiOutlineUp,
  AiOutlineRight,
  AiOutlineDownload,
  AiOutlineFileProtect,
  AiFillFileExclamation,
  AiOutlineFileDone,
  AiOutlineFileExcel,
  AiFillCheckCircle,
  AiOutlineUser,
  AiFillWarning,
  AiOutlineTags,
  AiOutlineHistory,
  AiFillCheckSquare,
  AiFillCloseSquare,
  AiOutlineCode,
  AiOutlineImport,
  AiOutlinePlus,
  AiOutlineMinus,
  AiFillQuestionCircle,
  AiOutlineFilePdf,
  AiOutlineSave
} from 'react-icons/ai';
import {
  RiDeleteBin6Line,
  RiDatabase2Line,
  RiErrorWarningFill,
  RiErrorWarningLine,
  RiUserAddLine,
  RiFileAddLine,
  RiCodepenFill,
  RiLock2Line,
  RiLockUnlockLine,
  RiFileLockLine,
  RiDeleteBin6Fill,
  RiCurrencyLine,
  RiUserLine,
  RiUserSearchLine,
  RiShieldUserLine,
  RiDonutChartFill,
  RiFileCopy2Line,
  RiMailSendLine,
  RiMailUnreadLine,
  RiDashboardLine,
  RiShieldFill,
  RiUserSettingsLine,
  RiFileDownloadLine,
  RiGooglePlayLine,
  RiFilter2Fill,
  RiFilterOffFill
} from 'react-icons/ri';
import { MdReplay, MdLocationSearching, MdYoutubeSearchedFor, MdEdit, MdCheck, MdClose, MdContentCopy, MdAssignmentInd, MdInfoOutline, MdRefresh, MdMoreVert } from 'react-icons/md';
import { BsTag, BsShield, BsSquareFill, BsFileText, BsFileBreak, BsFillInfoCircleFill, BsGear, BsBraces, BsLayoutWtf, BsFileEarmarkArrowUp } from 'react-icons/bs';
import { VscCode, VscTools, VscWarning, VscFileSymlinkDirectory, VscSymbolBoolean, VscSymbolMethod, VscLinkExternal } from 'react-icons/vsc';
import { ImFileZip, ImFilesEmpty } from 'react-icons/im';
import { HiOutlineUsers, HiOutlineFolderDownload } from 'react-icons/hi';

const useStyles = makeStyles();

export const RetryIcon = props => <Icon {...props}> <MdReplay title="RetryIcon"/></Icon>;
export const FileTextIcon = props => {
  const classes = useStyles();
  return <Icon {...props} className={clsx(classes.fileTextIcon, props.className)}><FiFileText title={props.title}/></Icon>;
};

FileTextIcon.propTypes = {
  className: PropTypes.string
};

export const PlusSimple = props => <Icon {...props}><AiOutlinePlus title={props.title}/></Icon>;
export const MinusSimple = props => <Icon {...props}><AiOutlineMinus title={props.title}/></Icon>;
export const Pdf = props => <Icon {...props}><AiOutlineFilePdf title={props.title}/></Icon>;
export const OfficeDoc = props => <Icon {...props}><GrDocumentOutlook title={props.title}/></Icon>;
export const File = props => <Icon {...props}><GrDocumentText title={props.title}/></Icon>;
export const DownloadFile = props => <Icon {...props}><RiFileDownloadLine title={props.title}/></Icon>;
export const DownloadFolder = props => <Icon {...props}><HiOutlineFolderDownload title={props.title}/></Icon>;

export const ArrowDown = props => <Icon {...props}><AiOutlineDown title={props.title}/></Icon>;
export const ArrowUp = props => <Icon {...props}><AiOutlineUp title={props.title}/></Icon>;
export const ArrowRight = props => <Icon {...props}><AiOutlineRight title={props.title}/></Icon>;

export const AlertIcon = props => <Icon {...props} ><FiAlertTriangle title={props.title}/></Icon>;
export const FillCheckSquare = props => <Icon {...props}><AiFillCheckSquare title={props.title}/></Icon>;
export const FillCloseSquare = props => <Icon {...props}><AiFillCloseSquare title={props.title}/></Icon>;
export const OutlineCode = props => <Icon {...props}><AiOutlineCode title={props.title}/></Icon>;
export const SymbolBoolean = props => <Icon {...props}><VscSymbolBoolean title={props.title}/></Icon>;
export const SymbolMethod = props => <Icon {...props}><VscSymbolMethod title={props.title}/></Icon>;
export const CodepenFill = props => <Icon {...props}><RiCodepenFill title={props.title}/></Icon>;

export const OutlineImport = props => <Icon {...props}><AiOutlineImport title={props.title}/></Icon>;
export const Braces = props => <Icon {...props}><BsBraces title={props.title}/></Icon>;
export const Codesandbox = props => <Icon {...props}><FiCodesandbox title={props.title}/></Icon>;
export const LayoutWtf = props => <Icon {...props}><BsLayoutWtf title={props.title}/></Icon>;
export const Resources = props => <Icon {...props}><GrResources title={props.title}/></Icon>;
export const AnalysisIcon = props => <Icon {...props} ><DiGoogleAnalytics title={props.title}/></Icon>;
export const UploadIcon = props => <Icon {...props} ><IoMdImages title={props.title}/></Icon>;
export const LockIcon = props => <Icon {...props}><IoIosLock title={props.title}/></Icon>;
export const TrashIcon = props => <Icon {...props}><RiDeleteBin6Line title={props.title}/></Icon>;
export const TrashFillIcon = props => <Icon {...props}><RiDeleteBin6Fill title={props.title}/></Icon>;
export const ArchiverIcon = props => <Icon {...props}> <GrDocumentLocked title={props.title}/></Icon>;
export const GoodScannedFile = props => <Icon {...props} ><AiOutlineFileProtect title={props.title}/></Icon>;
export const BadScannedFile = props => <Icon {...props} ><AiFillFileExclamation title={props.title}/></Icon>;
export const Share = props => <Icon {...props} ><IoMdShare title={props.title}/></Icon>;
export const FileText = props => <Icon {...props} ><BsFileText title={props.title}/></Icon>;
export const DocumentVerified = props => <Icon {...props} ><AiOutlineFileDone title={props.title}/></Icon>;
export const DocumentMissing = props => <Icon {...props} ><AiOutlineFileExcel title={props.title}/></Icon>;
export const RefreshIcon = props => <Icon {...props} ><MdYoutubeSearchedFor title={props.title}/></Icon>;
export const RescanIcon = props => <Icon {...props} ><MdLocationSearching title={props.title}/></Icon>;
export const DownloadRaw = props => <Icon {...props} ><AiOutlineDownload title={props.title}/></Icon>;
export const AddFile = props => <Icon {...props} ><BsFileEarmarkArrowUp title={props.title}/></Icon>;
export const Zip = props => <Icon {...props} ><ImFileZip title={props.title}/></Icon>;
export const Tags = props => <Icon {...props} ><BsTag title={props.title}/></Icon>;
export const Close = props => <Icon {...props} ><IoMdCloseCircle title={props.title}/></Icon>;
export const Details = props => <Icon {...props} ><TiDocumentText title={props.title}/></Icon>;
export const Metadata = props => <Icon {...props} ><VscCode title={props.title}/></Icon>;
export const Antivirus = props => <Icon {...props}><BsShield title={props.title}/></Icon>;
export const Sandbox = props => <Icon {...props} ><SiCodesandbox title={props.title}/></Icon>;
export const Database = props => <Icon {...props} ><RiDatabase2Line title={props.title}/></Icon>;
export const Tools = props => <Icon {...props} ><VscTools title={props.title}/></Icon>;
export const ProbeDefault = props => <Icon {...props} ><BsSquareFill title={props.title}/></Icon>;
export const FillCheckCircle = props => <Icon {...props}><AiFillCheckCircle title={props.title}/></Icon>;
export const ErrorWarningFill = props => <Icon {...props}> <RiErrorWarningFill title={props.title} /></Icon>;
export const ErrorWarningLine = props => <Icon {...props}> <RiErrorWarningLine title={props.title} /></Icon>;
export const EmptyFiles = props => <Icon {...props}><ImFilesEmpty title={props.title}/></Icon>;
export const ScanFile = props => <Icon {...props}><BsFileBreak title={props.title} /></Icon>;
export const Warning = props => <Icon {...props}><VscWarning title={props.title} /></Icon>;
export const InfoIcon = props => <Icon {...props}><BsFillInfoCircleFill title={props.title} /></Icon>;
export const LogoutIcon = props => <Icon {...props}><IoIosLogOut title={props.title}/></Icon>;
export const PersonIcon = props => <Icon {...props}><AiOutlineUser title={props.title}/></Icon>;
export const EditIcon = props => <Icon {...props}><MdEdit title={props.title} /></Icon>;
export const SaveIcon = props => <Icon {...props}><MdCheck title={props.title} /></Icon>;
export const CloseMdIcon = props => <Icon {...props}><MdClose title={props.title} /></Icon>;
export const WarningIcon = props => <Icon {...props}><AiFillWarning title={props.title} /></Icon>;
export const DeleteIcon = props => <Icon {...props}><TiDeleteOutline title={props.title} /></Icon>;
export const DeleteFileIcon = props => <Icon {...props}><TiDocumentDelete title={props.title} /></Icon>;
export const CheckIcon = props => <Icon {...props}><IoIosCheckmarkCircle title={props.title} /></Icon>;
export const CheckIconOutline = props => <Icon {...props}><IoIosCheckmarkCircleOutline title={props.title} /></Icon>;
export const TagsIcon = props => <Icon {...props}><AiOutlineTags title={props.title}/></Icon>;
export const GearIcon = props => <Icon {...props}><BsGear title={props.title}/></Icon>;
export const HistoryIcon = props => <Icon {...props}><AiOutlineHistory title={props.title}/></Icon>;
export const ArtifactsIcon = props => <Icon {...props}><VscFileSymlinkDirectory title={props.title}/></Icon>;
export const UsersIcon = props => <Icon {...props}><HiOutlineUsers title={props.title}/></Icon>;
export const Copy = props => <Icon {...props} ><IoMdCopy title={props.title}/></Icon>;
export const Copied = props => <Icon {...props} ><IoIosCopy title={props.title}/></Icon>;
export const UserAddOutline = props => <Icon {...props}><RiUserAddLine title={props.title}/></Icon>;
export const URLGlobe = props => <Icon {...props}><IoIosGlobe title={props.title}/></Icon>;
export const FileAddLine = props => <Icon {...props}><RiFileAddLine title={props.title}/></Icon>;
export const LinkExternal = props => <Icon {...props}><VscLinkExternal title={props.title}/></Icon>;
export const QuestionCircle = props => <Icon {...props}><AiFillQuestionCircle title={props.title}/></Icon>;
export const LockOutlinedIcon = props => <Icon {...props}><RiLock2Line title={props.title}/></Icon>;
export const UnLockOutlinedIcon = props => <Icon {...props}><RiLockUnlockLine title={props.title}/></Icon>;
export const LockedFileIcon = props => <Icon {...props}><RiFileLockLine title={props.title}/></Icon>;
export const MoneyIcon = props => <Icon {...props}><RiCurrencyLine title={props.title}/></Icon>;
export const UserLine = props => <Icon {...props}><RiUserLine title={props.title}/></Icon>;
export const UserSearchLine = props => <Icon {...props}><RiUserSearchLine title={props.title}/></Icon>;
export const ShieldUserLine = props => <Icon {...props}><RiShieldUserLine title={props.title}/></Icon>;
export const DonutChartFill = props => <Icon {...props}><RiDonutChartFill title={props.title}/></Icon>;
export const FileCopyLine = props => <Icon {...props}><RiFileCopy2Line title={props.title}/></Icon>;
export const EnvolopeLetter = props => <Icon {...props}><RiMailSendLine title={props.title}/></Icon>;
export const MailUnreadLine = props => <Icon {...props}><RiMailUnreadLine title={props.title}/></Icon>;
export const DashboardLine = props => <Icon {...props}><RiDashboardLine title={props.title}/></Icon>;
export const ShieldFill = props => <Icon {...props}><RiShieldFill title={props.title}/></Icon>;
export const ContentCopy = props => <Icon {...props}><MdContentCopy title={props.title}/></Icon>;
export const UserSettings = props => <Icon {...props}><RiUserSettingsLine title={props.title}/></Icon>;
export const CloseCircleOutline = props => <Icon {...props}><IoMdCloseCircleOutline title={props.title}/></Icon>;
export const OutlineSave = props => <Icon {...props}><AiOutlineSave title={props.title}/></Icon>;
export const AssignmentInd = props => <Icon {...props}><MdAssignmentInd title={props.title}/></Icon>;
export const GooglePlayLine = props => <Icon {...props}><RiGooglePlayLine title={props.title}/></Icon>;
export const InfoOutline = props => <Icon {...props}><MdInfoOutline title={props.title}/></Icon>;
export const Refresh = props => <Icon {...props}><MdRefresh title={props.title}/></Icon>;
export const MoreVert = props => <Icon {...props}><MdMoreVert title={props.title}/></Icon>;
export const Filter = props => <Icon {...props}><RiFilter2Fill title={props.title}/></Icon>;
export const FilterOff = props => <Icon {...props}><RiFilterOffFill title={props.title}/></Icon>;

FillCheckCircle.propTypes = {
  title: PropTypes.string
};

ErrorWarningFill.propTypes = {
  title: PropTypes.string
};
