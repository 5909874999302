import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, OutlinedInput, InputLabel } from '@material-ui/core';

export default function OutlinedFieldInput ({
  label = '',
  ...outlinedInputProps
}) {
  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor="outlined-adornment-tag">
        {label}
      </InputLabel>
      <OutlinedInput {...outlinedInputProps} />
    </FormControl>
  );
};

OutlinedFieldInput.propTypes = {
  label: PropTypes.string.isRequired
};
