import { makeStyles } from '@material-ui/core/styles';

export default (theme) =>
  makeStyles((theme) => ({
    fixedHeight: {
      height: 240
    },
    root: {
      flexGrow: 1,
      maxWidth: '100%',
      margin: '0 auto'
    },
    banner: {
      padding: theme.spacing(5),
      display: 'flex',
      overflow: 'hidden',
      flexDirection: 'row',
      marginBottom: 50,
      backgroundColor: theme.palette.grey[200],
      borderRadius: theme.shape.borderRadius
    },
    bannerTitle: { fontWeight: theme.typography.fontWeightBold },
    bannerSubtitle: {},
    bannerIcon: {
      height: '100px'
    },
    bannerText: {
      padding: theme.spacing(2, 0)
    },
    buttonContainer: {
      display: 'flex'
    },
    page: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'hidden',
      flexDirection: 'column',
      margin: '0 15%'
    },
    iconActions: {
      height: 20
    },
    filename: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    actions: {
      textAlign: 'right !important'
    },
    header: {
      fontWeight: 'normal',
      width: 200,
      textAlign: 'left'
    },
    table: {
      width: '100%'
    }
  }));
