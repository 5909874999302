import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import useAppConfigContext from 'contexts/AppConfigContext/useAppConfigContext';
import clsx from 'clsx';
import { ReactComponent as QFLogo } from 'assets/qflow_logo.svg';
import { ReactComponent as QFBackgroundImage } from 'assets/illustration.svg';
import {
  Dialog, DialogTitle, DialogContent, Button,
  Divider, Grid, FormControl, InputLabel,
  Typography, OutlinedInput, IconButton,
  InputAdornment
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { AlertIcon } from 'common/Icons';
import LanguageSwitcher from 'components/LanguageSwitcher';
import MfaDialog from './components/MfaDialog/MfaDialog';
import { useNotificationSetState } from 'contexts/NotificationContext';
import { useAuthUser } from 'hooks';
import { login } from 'entities/auth/auth.api';
import { isAdmin } from 'entities/auth/auth.utils';
import { ROUTE_PATHS } from 'utils/routes.constants';
import makeStyles from './styles';

const useStyles = makeStyles();

export default function SignIn (props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [showPassword, setPasswordVisibility] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { saveToken } = useAuthUser();
  const navigate = useNavigate();
  const location = useLocation();
  const setNotification = useNotificationSetState();
  const { isSaasModeEnabled, refreshUser } = useAppConfigContext();
  const successMessage = t('now_you_are_logged_in');
  const [isMfaDialogOpen, setMfaDialogOpen] = useState(false);

  const handleCloseMfaDialog = () => { setMfaDialogOpen(false); };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    try {
      setIsLoading(true);
      const response = await login({ username, password });
      if (response?.data?.mfa_required === true) {
        setMfaDialogOpen(true);
        saveToken(response?.data?.token);
        setIsLoading(false);
      } else {
        setNotification({
          message: successMessage,
          timeout: 3000
        });
        saveToken(response?.data?.token);
        refreshUser();

        const isAdminUser = isAdmin();

        if (!location.state) {
          if (isAdminUser) {
            navigate(ROUTE_PATHS.DASHBOARD);
          } else {
            navigate(ROUTE_PATHS.SCAN);
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setError(error?.response?.data?.errors?.description || t('something_went_wrong'));
    }
  };

  const handleSubmitGuest = (evt) => {
    evt.preventDefault();
    navigate(ROUTE_PATHS.SCAN);
  };

  const handleClickShowPassword = () => {
    setPasswordVisibility(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setError(false);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError(false);
  };
  return (
    <Grid container component="main" maxWidth="xs" className={classes.root}>
      <Grid item sm={12} md={5} lg={4} square>
        <Grid className={classes.toolbar}>
          <LanguageSwitcher />
        </Grid>
        <div className={classes.paper}>
          <div className={classes.logoContainer}>
            <div className={classes.logo}>
              <QFLogo />
            </div>
          </div>
          <div className={classes.productDescriptionWrapper}>
            <Typography
              className={classes.productDescription}
              component="h5"
              align="left"
              data-test="auth-layout-product-description"
            >
              {t('analyze_suspicious_files_to_detect_types_of_malware')}
            </Typography>
          </div>

          <Grid container>
            <form className={classes.form} onSubmit={handleSubmit}>
              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-username">
                  {t('username')}
                </InputLabel>

                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  name="username"
                  labelWidth={70}
                  type="text"
                  autoFocus
                  id="username"
                  autoComplete="username"
                  onChange={handleUsernameChange}
                  value={username}
                />
              </FormControl>
              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                {t('password')}
                </InputLabel>

                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t('password')}
                  labelWidth={70}
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  onChange={handlePasswordChange}
                  value={password}
                  endAdornment={password &&
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {error && (
                <div className={classes.alert}>
                  <Grid container alignItems='center'>
                    <Grid item xs={2}>
                      <AlertIcon size="75%" color="white" title={t('error')}/>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography
                        component="body2"
                        classes={{ colorError: classes.colorError }}
                        color="error"
                        data-test="signin-error-message"
                      >
                        {error}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              )}
              <div className={classes.forgotPassword}>
                <Typography component="h5" align="right">
                  <Link to={ROUTE_PATHS.ACCOUNT_PASSWORD_VERIFICATION} variant="body2" style={{ textDecoration: 'none' }}>
                   {t('forgot_password')}
                  </Link>
                </Typography>
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                data-test="sign-in-submit"
                disabled={isLoading}
              >
                {t('signin')}
              </Button>
            </form>
            </Grid>
            {!isSaasModeEnabled &&
           <Grid>
            <div className={classes.goToLinkWrapper}>
              <div className={classes.goToLinkContent}>
                <Typography variant="body2" color="textSecondary">
                  {t('dont_have_an_account')}
                </Typography>
                <Typography className={classes.signupLink} variant="body2" color="textSecondary">
                  <Link to={ROUTE_PATHS.SIGNUP} variant="body2">
                    {t('signup')}
                  </Link>
                </Typography>
              </div>
            </div>
            <div className={classes.dividerLine}>
              <Divider />
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleSubmitGuest}
              className={classes.submit}
              data-test="sign-in-as-guest"
              disabled={isLoading}
            >
              {t('sign_in_anonomously')}
            </Button>
            <div>
              <Typography variant="body2" color="textSecondary" align="center">
                <Trans i18nKey="by_continuing_anonymously_you_will_loose_access_warning">
                  By continuing anonymously you will lose access to <span className={classes.boldText}>search, notifications, scan timeline.</span>
                </Trans>
              </Typography>
            </div>
          </Grid> }
        </div>
      </Grid>
      <Grid item sm={12} md={7} lg={8} className={classes.QFBackground}>
        <QFBackgroundImage className={classes.image} />
        <Typography variant="h4" color="secondary" align="center">{t('malware_analysis_solution')}</Typography>
        <div className={classes.QFBackgroundText}>
          <Typography variant="body1" color="textSecondary" align="center">
          {t('qflow_is_an_auatomatic_file_analysis_solution_to_identify_malicious_files')}
          </Typography>
          <Typography variant="body1" color="textSecondary" align="center">
          {t('and_improve_threat_detection')}
          </Typography>
        </div>
      </Grid>
      <Dialog open={isMfaDialogOpen} onClose={handleCloseMfaDialog}>
        <DialogTitle>
          <Typography variant="h5" align="center"> <strong>{t('two_step_auth')}</strong>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <MfaDialog closeDialog={handleCloseMfaDialog} setError={setError}/>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
