import { makeStyles } from '@material-ui/core/styles';

export default (theme) =>
  makeStyles((theme) => ({
    banner: {
      padding: theme.spacing(5),
      display: 'flex',
      overflow: 'hidden',
      flexDirection: 'row',
      marginBottom: 50,
      backgroundColor: theme.palette.grey[200],
      borderRadius: theme.shape.borderRadius
    },
    bannerTitle: { fontWeight: theme.typography.fontWeightBold },
    bannerSubtitle: {},
    bannerIcon: {
      height: '100px',
      maxWidth: '100px'
    },
    bannerText: {
      padding: theme.spacing(2, 0),
      display: 'flex',
      alignItems: 'center'
    }
  }));
