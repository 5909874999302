import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { ReactComponent as MaintenanceView } from 'assets/maintenance.svg';
import makeStyles from './NoProbesAvailable.styles';
import { propTypes } from './NoProbesAvailable.props';

const useStyles = makeStyles();

const NoProbesAvailable = ({ onRefresh }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <MaintenanceView className={classes.errorImage}/>
      <Typography align='center' variant="h4" component="div" gutterBottom>No probes available in the catalog</Typography>
      <Typography classes={{ root: classes.tryRefreshText }} variant="body" component="div" gutterBottom>Try to refresh</Typography>
      <Button classes={{ root: classes.refreshBtn }} onClick={onRefresh} variant="outlined">Refresh</Button>
    </div>
  );
};

NoProbesAvailable.propTypes = propTypes;

export default NoProbesAvailable;
