import React from 'react';
import { useTranslation } from 'react-i18next';
import { propTypes } from './ActivePlanStatus.props';
import makeStyles from './ActivePlanStatus.styles';

const useStyles = makeStyles();

const ActivePlanStatus = ({ activePlan }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return <>
    {activePlan ? <p className={classes.activePlan}>· {t('active')}</p> : <p className={classes.inactivePlan}>· {t('inactive')}</p>}
  </>;
};

ActivePlanStatus.propTypes = propTypes;

export default ActivePlanStatus;
