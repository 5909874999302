import React, { useState, useEffect } from 'react';
import { Button, Grid, IconButton, Link, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import useDialog from 'hooks/useDialog';
import { fetchActiveMethods } from 'entities/auth/auth.api';
import { CheckIconOutline } from 'common/Icons';
import MfaConfigDialog from './components/MfaConfigDialog';
import MfaBkupCdDialog from './components/MfaBkupCdDialog';
import MfaDisableDialog from './components/MfaDisableDialog';
import MfaNewBkupCdDialog from './components/MfaNewBkupCdDialog';
import makeStyles, { CustomSwitch } from './MfaConfig.styles';

const useStyles = makeStyles();

function MfaConfig () {
  const classes = useStyles();
  const [t] = useTranslation();
  const [mfaAccess, setMfaAccess] = useState(false);
  const [backupCodesEnabled, setBackupCodesEnabled] = useState(false);

  const fetchMfaMethods = async () => {
    try {
      const response = await fetchActiveMethods();
      const activeMethods = response.data;
      setMfaAccess(activeMethods?.includes('totp'));
      setBackupCodesEnabled(activeMethods?.includes('lookup_secret'));
    } catch (error) {
      console.error('Error fetching MFA data:', error);
    }
  };

  useEffect(() => {
    fetchMfaMethods();
  }, []);

  const handleCloseDialog = () => {
    onDisplayMfaConfigDialog({ isOpen: false });
    disabledMfaDialog({ isOpen: false });
    backupCodesDialog({ isOpen: false });
    newBackupCodesDialog({ isOpen: false });
    fetchMfaMethods();
  };

  const generateNewBkupCodes = () => {
    newBackupCodesDialog({ isOpen: false });
    backupCodesDialog({ isOpen: true });
  };

  const onDisplayMfaConfigDialog = useDialog('mfa-dialog', {
    onClose: () => {
      onDisplayMfaConfigDialog({ isOpen: false });
    },
    components: {
      Content: <MfaConfigDialog closeDialog={handleCloseDialog}/>
    }
  });

  const backupCodesDialog = useDialog('mfa-bkupcd-dialog', {
    onClose: () => {
      backupCodesDialog({ isOpen: false });
    },
    components: {
      Content: <MfaBkupCdDialog />,
      Title: (
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
          <Close />
        </IconButton>
      )
    }
  });

  const newBackupCodesDialog = useDialog('new-mfa-bkupcd-dialog', {
    onClose: () => {
      backupCodesDialog({ isOpen: false });
    },
    components: {
      Content: <MfaNewBkupCdDialog closeDialog={handleCloseDialog} generateNewCodes={generateNewBkupCodes} />,
      Title: (
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
          <Close />
        </IconButton>
      )
    }
  });

  const disabledMfaDialog = useDialog('mfa-disable-dialog', {
    onClose: () => {
      disabledMfaDialog({ isOpen: false });
    },
    components: {
      Content: <MfaDisableDialog closeDialog={handleCloseDialog}/>
    }
  });

  const handleSwitchChange = async () => {
    if (mfaAccess) {
      disabledMfaDialog({ isOpen: true });
    } else {
      onDisplayMfaConfigDialog({ isOpen: true });
    }
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={11}>
        <Typography variant="h5">
          <strong>{t('mfa')}</strong>
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {t('mfa_description')}
        </Typography>
        {mfaAccess && backupCodesEnabled &&
          <>
            <Typography variant="body1" className={classes.backupCodesEnabledLabel}>
            <CheckIconOutline size={24} className={classes.successIcon} />
              {t('backup_codes_generated_successfully')}
              <Link component="button" variant="body1" underline="always" onClick={() => newBackupCodesDialog({ isOpen: true })} style={{ marginLeft: '5px' }}>{t('here')}.</Link>
            </Typography>
          </>
        }
        {mfaAccess && !backupCodesEnabled &&
          <Button
            variant="contained"
            color="primary"
            className={classes.backupCodesButton}
            onClick={() => backupCodesDialog({ isOpen: true })}>
              {t('generate_backup_codes')}
          </Button>
        }
      </Grid>
      <Grid item>
        <CustomSwitch checked={mfaAccess} onChange={handleSwitchChange} />
      </Grid>
    </Grid>
  );
}

export default MfaConfig;
