import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DialogBuilder } from 'components/Dialog';

const DialogSetStateContext = React.createContext();

const initialState = {
  dialogs: {}
};

const DialogProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <DialogSetStateContext.Provider value={setState}>
      {children}
      <DialogBuilder dialogsProps={state.dialogs} />
    </DialogSetStateContext.Provider>
  );
};

DialogProvider.propTypes = {
  children: PropTypes.any
};

export { DialogProvider, DialogSetStateContext };
