import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import propTypes from './OtpInput.props';
import makeStyles from './OtpInput.styles';

const useStyles = makeStyles();

const OtpInput = ({ length, onChange }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const inputRefs = useRef([]);
  const FIRST_HALF_LENGTH = length * 0.5;
  const LAST_HALF_LENGTH = length - FIRST_HALF_LENGTH;

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const focusNextInput = (index) => {
    if (index < FIRST_HALF_LENGTH + LAST_HALF_LENGTH - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const focusPrevInput = (index) => {
    if (index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleChange = (e, index) => {
    const inputValue = e.target.value;
    const numericalValue = inputValue.replace(/\D/g, '');

    if (numericalValue.length <= 1) {
      if (onChange) {
        onChange((prevPasscode) => {
          const updatedPasscode = [...prevPasscode];
          updatedPasscode[index] = numericalValue;
          return updatedPasscode.join('');
        });
      }

      if (inputValue.length === 0) {
        focusPrevInput(index);
      } else {
        focusNextInput(index);
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && inputRefs.current[index].value === '') {
      focusPrevInput(index);
    }
  };

  const createInputRef = (el, index) => {
    inputRefs.current[index] = el;
  };

  return (
      <div className={classes.container}>
          <Typography variant='body1'className={classes.instruction} >{t('mfa_enter_6_digit')}</Typography>
          <div className={classes.inputWrapper}>
              {Array.from({ length: FIRST_HALF_LENGTH + LAST_HALF_LENGTH }).map((_, index) => (
                  <>
                      {index === FIRST_HALF_LENGTH && <span style={{ margin: '0 6px' }}>-</span>}
                      <input
                          ref={(el) => createInputRef(el, index)}
                          type='text'
                          maxLength='1'
                          inputMode='numeric'
                          className={classes.input}
                          onChange={(e) => handleChange(e, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                      />
                  </>
              ))}
          </div>
      </div>
  );
};

OtpInput.propTypes = propTypes;
export default OtpInput;
