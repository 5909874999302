import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  listItemStatus: {
    paddingTop: 12,
    width: '40%'
  },
  listItemText: {
    width: '60%'
  },
  listItemIcon: {
    width: 50
  },
  iconSize: {
    width: '2.5rem',
    height: '2.5rem'
  },
  bigIcon: {
    width: 98,
    height: 100
  },
  scanButton: {
    margin: 12
  },
  body1WithItalic: {
    fontStyle: 'italic'
  },
  listTextPrimaryStatus: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));
