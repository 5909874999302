import { useContext } from 'react';
import { ScanContext } from './ScanContext';

export default function useScanContext () {
  const context = useContext(ScanContext);
  if (context === undefined) {
    throw new Error('ScanContext must be used within a ScanContext.Provider');
  }

  return context;
}
