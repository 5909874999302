import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Grid, TextField } from '@material-ui/core';
import { INITIAL_SEARCH_QUERY } from 'pages/MyFiles/MyFiles.constants';
import { propTypes } from './BasicSearch.props';
import makeStyles from './BasicSearch.styles';

const useStyles = makeStyles();

const initialQuery = INITIAL_SEARCH_QUERY.BASIC;

const BasicSearch = ({ onSearch, onReset }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [searchText, setSearchText] = useState();

  const handleOnClick = useCallback(() => {
    if (searchText) {
      onSearch({ ...initialQuery, name: searchText });
    }
  }, [onSearch, searchText]);

  const handleOnChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (!value) {
      onReset(initialQuery);
    }
  };

  const handleOnSubmit = useCallback(event => {
    event.preventDefault();
    handleOnClick();
  }, [handleOnClick]);

  return (
    <form onSubmit={handleOnSubmit}>
      <Grid container xs={8} spacing={2}>
        <Grid item xs={9} className={classes.toolbarItem}>
          <TextField
            label= {t('search')}
            variant="outlined"
            onChange={handleOnChange}
            size="medium"
            value={searchText}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            type='submit'
            className={classes.addButton}
            size="medium"
            color="primary"
            variant="contained">
            {t('search')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

BasicSearch.propTypes = propTypes;
export default BasicSearch;
