import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 480
  },
  bkupCodesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  bkupCodesBox: {
    width: '350px',
    borderRadius: '5px',
    border: '1px solid var(--light-grey, #79859F)',
    padding: '23px',
    margin: '28px auto 0 auto',
    backgroundColor: 'white',
    zIndex: '10'
  },
  labelCode: {
    margin: '7px',
    fontFamily: 'Roboto Mono',
    textTransform: 'uppercase'
  },
  description: {
    color: theme.palette.text.secondary,
    display: 'block',
    marginTop: '16px',
    textAlign: 'center'
  },
  btnGroup: {
    width: '350px',
    justifyContent: 'center',
    marginBottom: '30px'
  },
  btn: {
    backgroundColor: theme.palette.text.secondary + 26,
    borderRadius: '0px 0px 5px 5px',
    paddingTop: '20px',
    marginTop: '-10px'
  },
  btnIcon: {
    marginRight: '5px'
  },
  warningIcon: {
    fill: theme.palette.warning.main,
    marginBottom: 30
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 550,
    padding: 20
  },
  error: {
    color: '#FA4B54',
    display: 'block'
  }
}));
