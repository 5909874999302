import _ from 'lodash';

export const rolesWithWeight = {
  admin: {
    label: 'Administrator',
    roleName: 'admin',
    weight: 1
  },
  analyst: {
    label: 'Analyst',
    roleName: 'analyst',
    weight: 2
  },
  operator: {
    label: 'Operator',
    roleName: 'operator',
    weight: 3
  }
};

const HIGHEST_WEIGHT = _.size(rolesWithWeight) + 1;

export const getWeight = (role) => {
  const matchedRole = rolesWithWeight[role];

  if (!matchedRole) {
    return HIGHEST_WEIGHT;
  }

  return matchedRole.weight;
};

export const getHighestRole = (roles) => {
  const sortedRoles = roles?.sort(
    (roleA, roleB) => getWeight(roleA.name) - getWeight(roleB.name)
  );

  const highestRole = sortedRoles?.[0];

  if (highestRole && rolesWithWeight[highestRole.name]) {
    return rolesWithWeight[highestRole.name];
  }

  return {
    label: 'None',
    roleName: 'none',
    weight: HIGHEST_WEIGHT
  };
};
