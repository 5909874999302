export default function ({ value }) {
  const input = document.createElement('input');
  input.type = 'text';
  input.value = value;
  input.style.position = 'absolute';
  input.style.left = '-1000000px';
  document.body.appendChild(input);
  input.focus();
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);
};
