import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  textfield: {
    marginTop: '8px',
    '& input': {
      height: '19px'
    }
  }
}));
