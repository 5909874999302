import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, darken } from '@material-ui/core';
import { getBackgroundColor } from '../../AdvancedScan.utils';
import makeStyles from './NameChip.styles';
const useStyles = makeStyles();

export default function NameChip ({ name, category }) {
  const classes = useStyles();
  const backgroundColor = getBackgroundColor(category);
  const fillColor = darken(backgroundColor, 0.3);

  const divStyle = {
    backgroundColor: backgroundColor,
    border: `1.7px solid ${fillColor}`,
    color: fillColor
  };

  const firstLetter = name.charAt(0).toUpperCase();
  const secondLetter = name.charAt(1).toUpperCase();
  const chipText = `${firstLetter + secondLetter}`;

  return (
    <Grid className={classes.container}>
      <Grid className={classes.chip} style={divStyle}>
        <strong>{chipText}</strong>
      </Grid>
      <Typography>{name}</Typography>
    </Grid>
  );
}

NameChip.propTypes = {
  category: PropTypes.string,
  name: PropTypes.string
};
