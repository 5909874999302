import HTTP from 'common/http';

const ROLES = 'iam/roles';

export const updateRole = (role, userId) => {
  const url = `${ROLES}/${role}/${userId}`;
  return HTTP.put(url);
};

export const deleteRole = (role, userId) => {
  const url = `${ROLES}/${role}/${userId}`;
  return HTTP.delete(url);
};
