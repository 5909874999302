import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { propTypes } from './UserSummary.props';
import makeStyles from './UserSummary.styles';

ChartJS.register(ArcElement, Tooltip, Legend);

const useStyles = makeStyles();

export default function UserSummary ({ users }) {
  const [t] = useTranslation();
  const classes = useStyles();
  const [legends, setLegends] = useState({});
  const { operators, analysts, administrators } = users;
  const totalUsers = operators + analysts + administrators;

  const labels = [t('operators'), t('analysts'), t('administrators')];
  const data = [operators, analysts, administrators];
  const backgroundColor = ['#79859f', '#047fdc', '#12214d'];

  useEffect(() => {
    const legendLabels = (items, isLabel) => {
      return <ul className={classes.ulLegend}>
        {
          items.map((item, index) => {
            return <li key={index} className={classes.liLegend}>
              { isLabel && <span style={{ background: backgroundColor[index] }} className={classes.boxSpan}></span> }
              <p className={classes.labelLegend}>{item}</p>
            </li>;
          })
        }
      </ul>;
    };

    setLegends({
      labels: legendLabels(labels, true),
      values: legendLabels(data, false)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const graphData = {
    labels,
    datasets: [
      {
        data: data,
        borderColor: '#FFFFFF',
        borderWidth: 3,
        backgroundColor: backgroundColor
      }
    ]
  };

  const totalUsersNumberLabel = {
    id: 'totalUsersNumberLabel',
    beforeDatasetsDraw (chart, args, pluginOptions) {
      const { ctx } = chart;

      ctx.save();
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y - 5;
      ctx.font = 'bold 20px FuturaStdBook';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(totalUsers, xCoor, yCoor);
    }
  };

  const totalUsersTextLabel = {
    id: 'totalUsersTextLabel',
    beforeDatasetsDraw (chart, args, pluginOptions) {
      const { ctx } = chart;

      ctx.save();
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y + 15;
      ctx.font = '14px FuturaStdBook';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(t('total_users'), xCoor, yCoor);
    }
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: '75%'
  };

  return <>
    <div className={classes.container}>
      <Grid container justify='space-between' className={classes.header}>
        <Grid item>
          <Typography variant="h5" component="span"><strong>{t('users')}</strong></Typography>
        </Grid>
        <Grid item>
        </Grid>
      </Grid>
      <div className={classes.doughnutContainer}>
        <div className={classes.doughnut}>
          <Doughnut data={graphData} options={options} plugins={[totalUsersNumberLabel, totalUsersTextLabel]} >hola</Doughnut>
          <div id='d-labels'>{legends?.labels}</div>
          <div id='d-values'>{legends?.values}</div>
        </div>
      </div>
    </div>
  </>;
};

UserSummary.propTypes = propTypes;
