import React from 'react';
import { TableCell } from '@material-ui/core';
import { propTypes } from './CellFallbackView.props';

const CellFallbackView = ({ condition, children, ...cellProps }) => {
  return <TableCell {...cellProps}>
    {condition ? children : null}
  </TableCell>;
};

CellFallbackView.propTypes = propTypes;

export default CellFallbackView;
