import { bool, func, string, oneOf } from 'prop-types';

export const propTypes = {
  confirmLabel: string,
  cancelLabel: string,
  onConfirm: func.isRequired,
  onCancel: func.isRequired,
  setDisabled: bool,
  type: oneOf(['warning', 'error', 'info'])
};
