import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { propTypes } from './DeleteFileContent.props';
import makeStyles from './DeleteFileContent.style';

export default function DeleteFileContent ({ fileId }) {
  const [t] = useTranslation();
  const useStyles = makeStyles();
  const classes = useStyles();

  return (
    <>
      <Typography variant="h4" className={classes.title} >
        <Trans i18nKey={'are_you_sure_delete_file'}>Are you sure you want to <span className={classes.delete}>delete this file?</span></Trans>
      </Typography>
      <Typography variant="body2" className={classes.bodyText}>
        {t('this_action_is_permanent_once_file_is_deleted')}
      </Typography>
    </>
  );
};

DeleteFileContent.propTypes = propTypes;
