import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import propTypes from './InputText.props';
import makeStyles from './InputText.style';

const useStyles = makeStyles();

export default function InputText (props) {
  const [t] = useTranslation();
  const classes = useStyles();

  const { id, label, filter } = props;

  const handleChange = (event) => {
    filter(id, event.target.value);
  };

  return (
    <Grid item>
      <TextField
        id={`${id + '-text'}`}
        onChange={handleChange}
        label={t(label)}
        variant='outlined'
        type='search'
        className={classes.textfield}
      />
    </Grid>
  );
};

InputText.propTypes = propTypes;
