import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, MenuItem, Typography, Switch, Tooltip, IconButton } from '@material-ui/core';
import { InfoIcon } from 'common/Icons';
import OutlinedFieldInput from './components/OutlinedFieldInput';
import OutlinedFieldSelect from './components/OutlinedFieldSelect';
import { propTypes } from './AddPlanForm.props';
import { addPlanFormReducer, getInitialState, ADD_PLAN_FORM_ACTIONS } from './AddPlanForm.reducer';
import makesStyles from './AddPlanForm.style';

const useStyles = makesStyles();
export default function AddPlanForm ({ submitButtonRef, onSubmit, accountId, plans = [] }) {
  const [formState, dispatch] = React.useReducer(addPlanFormReducer, getInitialState(accountId));
  const [t] = useTranslation();
  const classes = useStyles();
  const disabled = !(plans.length > 0);
  const tooltipText = t('enforce_plan_limit_warning');

  const triggerFormSubmission = (e) => {
    if (e) {
      e.preventDefault();
    }
    onSubmit(formState);
  };

  return (
      <form className={classes.addPlanForm} onSubmit={triggerFormSubmission}>
        <OutlinedFieldSelect
          label= {t('select_a_plan')}
          required
          fullWidth
          labelWidth={75}
          margin="normal"
          name="select-plan"
          id="select-plan"
          disabled={disabled}
          value={formState.plan}
          onChange={(e) => dispatch({ type: ADD_PLAN_FORM_ACTIONS.UPDATE_PLAN, payload: e.target.value })}
        >
          {
            plans.length > 0
              ? plans.map((plan, index) => (
              <MenuItem value={plan.id} key={index}>{plan.name}</MenuItem>
              ))
              : <MenuItem value={0}>{t('no_items')}</MenuItem>
          }
        </OutlinedFieldSelect>
        <OutlinedFieldInput
          label={t('start_date')}
          inputLabelProps={{
            shrink: true
          }}
          notched="true"
          required
          fullWidth
          labelWidth={75}
          margin="normal"
          name="start-date"
          type="date"
          id="start-date"
          defaultValue=""
          value={formState.startDate}
          onChange={(e) => dispatch({ type: ADD_PLAN_FORM_ACTIONS.UPDATE_STARTDATE, payload: e.target.value })}
          disabled={disabled}
        />
        <OutlinedFieldInput
           label={t('end_date')}
          inputLabelProps={{
            shrink: true
          }}
          notched="true"
          required
          fullWidth
          labelWidth={75}
          margin="normal"
          name="end-date"
          type="date"
          id="end-date"
          value={formState.endDate}
          onChange={(e) => dispatch({ type: ADD_PLAN_FORM_ACTIONS.UPDATE_ENDDATE, payload: e.target.value })}
          disabled={disabled}
        />
        <div style={{ padding: 10, width: '100%' }}>
          <Grid container alignItems="center">
            <Grid item xs="6">
                <Typography variant="body2" align="left" className={classes.disabledText}>
                  {t('enforce_plan_limits')}
                  <Tooltip title={tooltipText} classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} arrow>
                    <IconButton>
                      <InfoIcon className={classes.infoIcon}/>
                    </IconButton>
                  </Tooltip>
                </Typography>
            </Grid>
            <Grid container item xs="6" justify="flex-end">
              <Switch checked={formState.enforcePlanLimit} onChange={(e) => dispatch({ type: ADD_PLAN_FORM_ACTIONS.UPDATE_ENFORCE_PLAN_LIMIT, payload: e.target.checked })}/>
            </Grid>
          </Grid>
        </div>
        <button ref={submitButtonRef} style={{ display: 'none' }} type='submit'></button>
      </form>
  );
}

AddPlanForm.propTypes = propTypes;
