import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export default theme => makeStyles(theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden'
  },
  page: {
    flexGrow: 1,
    margin: '100px 50px 0px 50px',
    overflow: 'auto'
  },
  fileDetailsPage: {
    flexGrow: 1,
    margin: '100px 0px',
    overflow: 'auto'
  },
  urlDetailsPage: {
    flexGrow: 1,
    margin: '100px 0px',
    overflow: 'auto'
  },
  probeDetailsPage: {
    flexGrow: 1,
    margin: '100px 50px',
    overflow: 'hidden'
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    borderBottom: '1px solid #e0e0e0',
    background: '#fff'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaper: {
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    minHeight: '100vh',
    background: theme.palette.text.secondary + '0D'
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    flexShrink: 0
  },
  drawer: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClosed: {
    width: theme.spacing(7),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  drawerContainer: {
    overflow: 'auto'
  },
  toggleDrawer: {
    position: 'absolute',
    bottom: 0,
    padding: '30px',
    color: theme.palette.text.secondary
  }
}));
