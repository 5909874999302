import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  infoIcon: { width: '1.5rem', height: '1.5rem' },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  endIcon: {
    color: '#057fdc99',
    marginLeft: 0
  }
}));
