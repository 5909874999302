import React from 'react';
import { Link } from 'react-router-dom';
import BaseLayout from 'layouts/BaseLayout';
import Typography from '@material-ui/core/Typography';
import { ROUTE_PATHS } from 'utils/routes.constants';
import makeStyles from './styles';
import { ReactComponent as Error403 } from 'assets/403_error.svg';
const useStyles = makeStyles();

export default function ErrorPage () {
  const classes = useStyles();
  return (
    <BaseLayout>
      <div className={classes.errorLayout}>
        <Error403 className={classes.errorImage} />
        <div>
          <Typography variant="h4" color="secondary" align="center">Access Denied</Typography>
          <Typography variant="body1" color="textSecondary" align="center">
             Please, go back to <Link to={ROUTE_PATHS.HOMEPAGE} reloadDocument>home page</Link>
          </Typography>
        </div>
      </div>
    </BaseLayout>
  );
}
