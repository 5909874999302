import React, { useState, useEffect } from 'react';
import { Switch } from '@material-ui/core';
import _ from 'lodash';
import { toggleUserStatus } from 'entities/users/users.api';
import useAuthUser from 'hooks/useAuthUser';
import { propTypes } from './ToggleUser.props';
import makeStyles from './ToggleUser.styles';
const useStyles = makeStyles();

export default function ToggleUser ({ enabled, userId, username, refreshTable }) {
  const classes = useStyles();
  const [localEnabled, setLocalEnabled] = useState(enabled);
  const { auth } = useAuthUser();

  useEffect(() => {
    setLocalEnabled(enabled);
  }, [enabled]);

  const currentUserId = _.get(auth, 'identity.id');

  // cannot enable/disable if this row is the currently logged-in user, anonymous, or admin user
  const disabled = userId === currentUserId || userId === 'anonymous' || username === 'admin';

  const toggle = async () => {
    await toggleUserStatus(userId, { enabled: !localEnabled });
    setLocalEnabled(!localEnabled);
    refreshTable();
  };

  return (
    <Switch
      checked={localEnabled}
      onChange={toggle}
      disabled={disabled}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track
      }}
      color='primary'
    />
  );
}

ToggleUser.propTypes = propTypes;
