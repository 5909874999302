import { fetchAnalysisRequest, startFileAnalysisRequest } from 'entities/analysisRequest/analysisRequest.api';
import { fetchAnalysis } from 'entities/analysis/analysis.api';

async function rescanRequest (file) {
  try {
    const analysisId = file?.current_analysis?.id;
    const analysisResponse = await fetchAnalysis(analysisId);
    const requestId = analysisResponse?.data?.request?.id;

    const analysisRequestResponse = await fetchAnalysisRequest(requestId);
    const workflow = analysisRequestResponse?.data?.options?.workflow;
    const workflowOptions = analysisRequestResponse?.data?.options?.workflow_options;

    await startFileAnalysisRequest([file.id], {
      force: false,
      priority: 'MEDIUM',
      workflow: workflow,
      keep_file: true,
      workflow_options: workflowOptions
    });

    return { success: true };
  } catch (error) {
    return { success: false };
  }
}

export { rescanRequest };
