import React from 'react';
import PropTypes from 'prop-types';
import BytesToSize from 'utils/BytesToSize';

const Size = ({ rowData }) => {
  if (rowData?.size) {
    return <span>{BytesToSize(rowData.size)}</span>;
  };
};

Size.propTypes = {
  rowData: PropTypes.object
};

export default Size;
