import React from 'react';
import { Navigate } from 'react-router-dom';
import { propTypes } from './BlockedInSaasMode.props';
import useAppConfigContext from 'contexts/AppConfigContext/useAppConfigContext';
import { ROUTE_PATHS } from 'utils/routes.constants';

const BlockedInSaasMode = ({ children, redirectLocation = ROUTE_PATHS.FORBIDDEN }) => {
  const { isSaasModeEnabled, isLoading } = useAppConfigContext();

  if (!isLoading && isSaasModeEnabled) {
    return <Navigate to={redirectLocation} />;
  }

  return children;
};

BlockedInSaasMode.propTypes = propTypes;

export default BlockedInSaasMode;
