import React from 'react';
import PropTypes from 'prop-types';
import { IconContext } from 'react-icons';
import { useTheme } from '@material-ui/core/styles';

const Icon = ({ color, size, children, ...iconProps }) => {
  const {
    palette,
    typography
  } = useTheme();
  const values = {
    ...iconProps,
    color: color || palette.primary.main,
    size: size || typography.iconSize
  };

  return <IconContext.Provider value={values}>
    <>
      {children}
    </>
  </IconContext.Provider>;
};

export default Icon;

Icon.propTypes = {
  children: PropTypes.element,
  color: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string
};
