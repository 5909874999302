import { InfoIcon, ErrorWarningFill } from 'common/Icons';
import defaulTheme from 'contexts/ThemeContext/defaultTheme';

const { palette } = defaulTheme();

export const TYPES = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
  SUCCESS: 'SUCCESS'
};

export const mapping = {
  [TYPES.ERROR]: {
    IconComponent: ErrorWarningFill,
    color: palette.error.main,
    textColor: palette.error.main
  },
  [TYPES.WARNING]: {
    IconComponent: ErrorWarningFill,
    color: palette.warning.main,
    textColor: palette.warning.main
  },
  [TYPES.INFO]: {
    IconComponent: InfoIcon,
    color: palette.primary.main,
    textColor: palette.primary.main
  },
  [TYPES.SUCCESS]: {
    IconComponent: InfoIcon,
    color: palette.success.main,
    textColor: palette.success.main
  }
};
