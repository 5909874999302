import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  container: {
    minWidth: '100%'
  },
  selectBox: {
    borderRadius: theme.spacing(1)
  },
  selectOption: {
    display: 'flex',
    flexDirection: 'column'
  },
  flowInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '6px',
    color: 'inherit'
  },
  checkbox: {
    fill: theme.palette.primary.main
  },
  selectValue: {
    padding: '10px',
    border: '2px solid',
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.primary.main
  },
  header: {
    fontSize: '1.25rem'
  },
  menuPaper: {
    height: '280px',
    border: '2px solid',
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(2),
    borderColor: theme.palette.primary.main,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: theme.spacing(2),
      backgroundColor: '#ddd'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: theme.spacing(2),
      backgroundColor: '#999'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#666'
    }
  },
  displayName: {
    marginLeft: '-15px',
    fontSize: '1rem',
    fontWeight: 'bold'
  },
  description: {
    marginTop: '-15px',
    marginLeft: '33px',
    fontSize: '1rem',
    fontStyle: 'italic'
  },
  selectedDisplayName: {
    marginLeft: '20px',
    fontSize: '1rem',
    fontWeight: 'bold'
  },
  selectedDescription: {
    marginLeft: '20px',
    fontSize: '1rem',
    fontStyle: 'italic'
  },
  menuItem: {
    width: '100%'
  }
}));
