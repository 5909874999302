import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, CircularProgress, Tooltip, IconButton } from '@material-ui/core';
import { InfoIcon } from 'common/Icons';
import makeStyles from './QualifierChip.styles';
import { propTypes } from './QualifierChip.props';
const useStyles = makeStyles();

function QualifierChip ({ label, tooltip, color, backgroundColor }) {
  const [t] = useTranslation();
  const classes = useStyles();

  if (label === 'scanning') {
    return (
      <div className={`${backgroundColor} ${classes.qualifierChip}`}>
        <div className={classes.scanningLabel}>
          <Typography className={classes.whiteText}>{t(`${label}`)}</Typography>
          <CircularProgress className={classes.circularProgress} size={12} />
        </div>
      </div>
    );
  }

  return (
    <div className={`${backgroundColor} ${classes.qualifierChip}`}>
      <div className={classes.labelContainer}>
        <Typography className={classes.whiteText}>
          {t(`${label}`)}
        </Typography>
        {t(tooltip) && (
          <Tooltip title={tooltip} classes={{ arrow: color, tooltip: classes.tooltip + ' ' + backgroundColor }} arrow placement='right'>
            <IconButton className={classes.infoIconContainer}>
              <InfoIcon className={classes.infoIcon} color='white' />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
QualifierChip.propTypes = propTypes;

export default QualifierChip;
