import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@material-ui/core';
import { downloadFileContent, fetchFileContent } from 'entities/files/files.api';
import Loading from 'common/Loading';
import { getServerError } from 'pages/pages.utils';
import { useSnackSetState } from 'contexts/SnackContext';
import { propTypes } from './ScreenshotViewer.props';
import makeStyles from './ScreenshotViewer.styles';

const useStyles = makeStyles();

const ScreenshotViewer = ({ preview }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const setSnack = useSnackSetState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(Object.keys(preview).length === 0);
  }, [preview]);

  const handleDownloadPreview = async () => {
    try {
      const response = await fetchFileContent(preview.id, {});
      downloadFileContent(response.data, { name: preview.name, type: 'application/pdf' });
    } catch (error) {
      setSnack({
        isOpen: true,
        title: t('something_went_wrong'),
        message: getServerError(error.data),
        severity: 'error'
      });
    }
  };

  return (
    <div className={classes.screenshotContainer} style={{ margin: 0 }}>
      { isLoading
        ? <Loading size={24} align={'start'} />
        : <>
            <div className={classes.imgBox}>
              <img alt={t('screenshot')} src={preview?.url} className={classes.filePreview} />
            </div>
            <Link href='#' onClick={handleDownloadPreview}>{preview?.name}</Link>
          </>
      }
    </div>

  );
};

ScreenshotViewer.propTypes = propTypes;

export default ScreenshotViewer;
