import React, { useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { propTypes } from './FormRadioGroup.props';

export default function FormRadioGroup ({ error, disabled = false, ...FormRadioGroup }) {
  const [t] = useTranslation();

  const [value, setValue] = useState(FormRadioGroup.value);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl
      component="fieldset"
      error={error}
      disabled={disabled}
    >
      <RadioGroup aria-label="gender" value={value} onChange={handleChange}>
        {FormRadioGroup.values.map((item) => {
          return <FormControlLabel value={item.key} control={<Radio />} key={item.key} label={t(item.label)} />;
        })}
      </RadioGroup>
    </FormControl>
  );
};

FormRadioGroup.propTypes = propTypes;
