import React from 'react';
import MainLayout from 'layouts/MainLayout';
import ErrorBoundary from 'common/ErrorBoundary';
import { SnackProvider } from 'contexts/SnackContext';
import { NotificationProvider } from 'contexts/NotificationContext';
import { BrowserRouter } from 'react-router-dom';
import { ThemeCustomProvider } from 'contexts/ThemeContext/ThemeContext';
import { DialogProvider } from 'contexts/DialogContext';
import { AuthProvider } from 'contexts/AuthContext';
import { AppConfigProvider } from 'contexts/AppConfigContext/AppConfigContext';
import ScanProvider from 'contexts/ScanContext/ScanContext';

function App () {
  return (
    <ErrorBoundary>
      <AppConfigProvider>
        <AuthProvider>
          <ScanProvider>
            <ThemeCustomProvider>
              <BrowserRouter>
                <SnackProvider>
                  <DialogProvider>
                    <NotificationProvider>
                      <MainLayout />
                    </NotificationProvider>
                  </DialogProvider>
                </SnackProvider>
              </BrowserRouter>
            </ThemeCustomProvider>
          </ScanProvider>
        </AuthProvider>
      </AppConfigProvider>
    </ErrorBoundary>
  );
}

export default App;
