import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  dialogIconSize: {
    width: '4rem',
    height: '4rem'
  },
  rootItemCard: {
    position: 'relative'
  },
  divider: {
    margin: '0 20px'
  },
  probesContainer: {
    overflow: 'hidden',
    marginBottom: '50px'
  },
  probecardsContainer: {
    marginTop: '20px'
  },
  categoryName: {
    margin: '20px 0',
    scrollMarginTop: '115px'
  },
  refreshIcon: {
    fill: theme.palette.text.primary
  },
  enablerContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));
