import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getSubscriptionCost } from 'entities/plans/plans.utils';
import ActionsMenu from './components/ActionsMenu';

const GetPlanNameColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('plan_name'),
    field: 'name',
    width: '15%',
    render: function PlanName (rowData) {
      return <Link to={{
        pathname: rowData.id,
        state: rowData
      }}>{rowData.name}</Link>;
    }
  };
};

const GetScanLimitColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('scan_limit'),
    width: '10%',
    render: rowData => {
      return rowData.scan_limit + ' ' + t('scans') + ' / ' + rowData.scan_limit_period;
    }
  };
};

const GetOperatorsColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('operators'),
    width: '5%',
    render: rowData => {
      return t('up_to') + ' ' + rowData.max_operator_accounts;
    }
  };
};

const GetAnalystsColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('analysts'),
    width: '5%',
    render: rowData => {
      return t('up_to') + ' ' + rowData.max_analyst_accounts;
    }
  };
};

const GetAdminsColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('admins'),
    width: '5%',
    render: rowData => {
      return t('up_to') + ' ' + rowData.max_admin_accounts;
    }
  };
};

const GetCostColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('cost'),
    width: '5%',
    render: rowData => {
      return getSubscriptionCost(rowData.subscription_cost, rowData.billing_period, 'EUR');
    }
  };
};

const GetMenuColumn = (refreshTableData) => {
  const [t] = useTranslation();
  return {
    title: t('actions'),
    width: '1%',
    sorting: false,
    // eslint-disable-next-line react/display-name
    render: function actionMenu (rowData) {
      return <ActionsMenu plan={rowData} refreshTable={refreshTableData}/>;
    }
  };
};

export const getColumns = (refreshTableData) => {
  return [
    GetPlanNameColumn(),
    GetScanLimitColumn(),
    GetOperatorsColumn(),
    GetAnalystsColumn(),
    GetAdminsColumn(),
    GetCostColumn(),
    GetMenuColumn(refreshTableData)
  ];
};
