import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  banner: {
    backgroundColor: '#E7E9ED',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
    paddingBottom: 20,
    marginTop: 30,
    marginBottom: 40,
    borderRadius: '5px',
    display: 'flex',
    width: '100%',
    height: 170,
    lineHeight: 1,
    textAlign: 'center'
  },
  iconContainer: {
    borderRadius: '5px',
    backgroundColor: '#12214D',
    padding: 5
  },
  icon: {
    width: '2.3em',
    height: '2rem'
  },
  number: {
    marginBottom: -6,
    marginTop: 10
  }
}));
