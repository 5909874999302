import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  roleChip: {
    '& Div': {
      height: 40,
      width: 115,
      borderRadius: 5,
      fontFamily: theme.typography.caption.fontFamily
    }
  },
  analyst: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  admin: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white'
  },
  operator: {
    backgroundColor: theme.palette.text.secondary,
    color: 'white'
  },
  none: {
    color: 'grey',
    backgroundColor: 'white',
    border: '2px solid grey'
  }
}));
