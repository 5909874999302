import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ANALYSIS_STATUS } from 'entities/analysis/analysis.constants';
import { SaveIcon, Warning } from 'common/Icons';
import { propTypes } from './AnalysisStatus.props';
import makeStyles from './AnalysisStatus.styles';

const useStyles = makeStyles();

const AnalysisStatus = ({ rowData }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const status = rowData.current_analysis ? rowData.current_analysis.status : null;

  const finished = status === ANALYSIS_STATUS.FINISHED;
  const failed = status === ANALYSIS_STATUS.FAILED;

  return (
    <>
      {finished &&
        <Tooltip title={t('all_analyses_completed_successfully')} arrow>
          <IconButton className={classes.noClickTooltip} disableRipple>
            <SaveIcon className={classes.statusIcon} />
          </IconButton>
        </Tooltip>
      }
      {failed &&
        <Tooltip title={t('at_least_one_analysis_resulted_in_a_warning_or_an_error')} arrow>
          <IconButton className={classes.noClickTooltip} disableRipple>
            <Warning className={classes.statusIcon} />
          </IconButton>
        </Tooltip>
      }
    </>
  );
};

AnalysisStatus.propTypes = propTypes;

export default AnalysisStatus;
