import HTTP from 'common/http';
import { API } from './sessions.constants';
import { getSession, getSessions } from './sessions.helpers';

export const fetchActiveSessions = async () => {
  const url = API.ACTIVE_SESSIONS;
  const response = await HTTP.get(url);
  return getSessions(response);
};

export const fetchCurrentSession = async () => {
  const url = API.CURRENT_SESSION;
  const response = await HTTP.get(url);
  return getSession(response);
};

export const fetchAdminSessions = async (userId) => {
  const url = API.ADMIN_SESSIONS;
  const response = await HTTP.get(url, { params: { userid: userId } });
  return getSessions(response);
};

export const deleteSession = (sessionId) => {
  const url = `${API.SESSIONS}/${sessionId}`;
  return HTTP.delete(url);
};

export const deleteActiveSessions = () => {
  const url = API.ACTIVE_SESSIONS;
  return HTTP.delete(url);
};
