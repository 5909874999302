import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  lockIconStyle: {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: 5
  },
  spanWithIcon: {
    height: '1.5rem',
    lineHeight: 2
  },
  boxIconWithScan: { display: 'flex', alignItems: 'center' }
}));
