import React from 'react';
import PropTypes from 'prop-types';
import { EmptyFiles, ScanFile, Antivirus, TagsIcon, UsersIcon, URLGlobe, MoneyIcon, DashboardLine, GearIcon } from 'common/Icons';
import { useTranslation } from 'react-i18next';

export default function SidebarbarIcon ({ sidebarId }) {
  const [t] = useTranslation();
  const sidebarIcon = [];
  const iconProps = {
    style: {
      width: '1.5rem',
      height: '1.5rem'
    }
  };

  switch (sidebarId) {
    case 'myFiles': sidebarIcon.push(<EmptyFiles {...iconProps} title={t('files')}/>); break;
    case 'scanner': sidebarIcon.push(<ScanFile {...iconProps} title={t('scan')} />); break;
    case 'probesStore': sidebarIcon.push(<Antivirus {...iconProps} title={t('probes')}/>); break;
    case 'tags': sidebarIcon.push(<TagsIcon {...iconProps} title={t('tags')}/>); break;
    case 'accounts': sidebarIcon.push(<UsersIcon {...iconProps} title={t('accounts')}/>); break;
    case 'accountAdminView': sidebarIcon.push(<UsersIcon {...iconProps} title={t('account')} />); break;
    case 'urlAnalysis': sidebarIcon.push(<URLGlobe {...iconProps} title={t('urls')} />); break;
    case 'plans': sidebarIcon.push(<MoneyIcon {...iconProps} title={t('plans')} />); break;
    case 'dashboard': sidebarIcon.push(<DashboardLine {...iconProps} title={t('dashboard')} />); break;
    case 'settings': sidebarIcon.push(<GearIcon {...iconProps} title={t('settings')} />); break;
    default: return sidebarIcon.push('palette');
  }
  return (
    <>
      { sidebarIcon }
    </>
  );
};

SidebarbarIcon.propTypes = {
  sidebarId: PropTypes.string.isRequired
};
