import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { useSnackSetState } from 'contexts/SnackContext';
import { isDefined } from 'utils/types';
import { fetchPlans } from 'entities/plans/plans.api';
import { createAccount, fetchAccountsDetail } from 'entities/accounts/accounts.api';
import { UserAddOutline } from 'common/Icons';
import { useDialog } from 'hooks';
import DialogButtons from 'components/DialogButtons';
import { getRange, getItemsCount, getServerError } from '../../pages.utils';
import { fetchSelfUser, fetchUsers } from 'entities/users/users.api';
import { combineAccountsAndUsersData } from './AccountTable.utils';
import { TableLocalization } from 'common/tableConfig';
import AccountSearch from './components/AccountSearch';
import AddAccountForm from './components/AddAccountForm';
import getTableProps from './AccountTable.tableProps';
import makesStyles from './AccountTable.style';

const useStyles = makesStyles();

const PLATFORM_ADMIN_NAME = 'quarksflow';

export default function AccountTable ({ plan }) {
  const classes = useStyles();
  const [t] = useTranslation();
  const localization = TableLocalization();
  const [plans, setPlans] = useState([]);
  const [searchTraits, setSearchTraits] = useState({});
  const [trait, setTrait] = useState('name');
  const [fragment, setFragment] = useState('');
  const [isPlatformAdmin, setIsPlatformAdmin] = useState();
  const setSnack = useSnackSetState();
  const submitButtonRef = useRef();
  const tableRef = useRef(null);
  const planMode = isDefined(plan);
  const refreshTableData = () => tableRef.current.onQueryChange();

  useEffect(() => {
    fetchSelfUser()
      .then(response => {
        setIsPlatformAdmin(response?.data?.account_name === PLATFORM_ADMIN_NAME);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchPlans().then(response => {
      setPlans(response?.data ?? []);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (account) => {
    createAccount(account)
      .then(() => {
        setSnack({
          isOpen: true,
          message: t('new_account_created'),
          title: t('account_creation_successful'),
          severity: 'success'
        });
        refreshTableData();
      })
      .catch(error => {
        setSnack({
          isOpen: true,
          message: getServerError(error.data),
          title: t('account_creation_failed'),
          severity: 'error'
        });
      });
  };

  const onSearch = (trait, fragment) => {
    setSearchTraits({
      trait: trait,
      fragment: fragment
    });
    refreshTableData();
  };

  const onDisplayDialog = useDialog('account-dialog', {
    onClose: () => {
      onDisplayDialog({ isOpen: false });
    },
    components: {
      Icon: <UserAddOutline className={classes.dialogIconSize} title={t('add_a_new_account')} />,
      Title: <Typography variant="h5" align="center">{t('add_a_new_account')} </Typography>,
      Content: <AddAccountForm submitButtonRef={submitButtonRef} onSubmit={onSubmit} />,
      Actions: (
        <div className={classes.dialogButtonsWrapper}>
          <DialogButtons
            confirmLabel= {t('add_account')}
            cancelLabel={t('cancel')}
            onConfirm={() => submitButtonRef.current.click()}
            onCancel={() => onDisplayDialog({ isOpen: false })}
          />
        </div>
      )
    }
  });

  const tableProps = getTableProps({
    plans: plans,
    plan: plan
  });

  const [isLoading, setIsLoading] = useState(true);
  const [resetPageIndex, setResetPageIndex] = useState(false);
  const [pageSize, setPageSize] = useState(tableProps.options.pageSize);

  const getTableData = async (tableQuery) => {
    setIsLoading(true);

    const { pageSize, page } = tableQuery;
    let pageIndex = page;

    if (resetPageIndex) {
      pageIndex = 0;
      setResetPageIndex(false);
    }

    const accountsParams = {
      range: getRange(pageIndex, pageSize),
      plan_id: planMode && plan.id ? plan.id : undefined,
      name: searchTraits?.trait === 'name' ? searchTraits.fragment : undefined,
      email: searchTraits?.trait === 'email' ? searchTraits.fragment : undefined
    };

    try {
      const [usersResponse, accountsResponse] = await Promise.all([
        fetchUsers({}),
        fetchAccountsDetail(accountsParams)
      ]);

      const combinedData = combineAccountsAndUsersData(accountsResponse.data, usersResponse.data);

      setIsLoading(false);

      return {
        data: combinedData,
        page: pageIndex,
        totalCount: getItemsCount(accountsResponse.headers['content-range'])
      };
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  return (
    <>
      {isPlatformAdmin && (<Grid container className={classes.toolbarContainer}>
          <Grid xs={10}>
            <AccountSearch
              stateSearch={{
                fragment,
                trait
              }}
              setTrait={setTrait}
              setFragment={(value) => {
                setFragment(value);
              }}
              onSearch={() => onSearch(trait, fragment)}
              traits={[
                'name',
                'email'
              ]}
            />
          </Grid>
          <Grid item container xs={2} justify="flex-end">
            <Button
              className={classes.addButton}
              size="medium"
              color="secondary"
              variant="contained"
              onClick={() => onDisplayDialog({ isOpen: true })}>
             {t('add_account')}
            </Button>
          </Grid>
        </Grid>)}
      <MaterialTable
        title=''
        {...tableProps}
        options={{ ...tableProps.options, pageSize }}
        components={{ ...tableProps.components }}
        onChangeRowsPerPage={setPageSize}
        data={getTableData}
        tableRef={tableRef}
        isLoading={isLoading}
        localization={localization}
      />
    </>
  );
};

AccountTable.propTypes = {
  plan: PropTypes.obj
};
