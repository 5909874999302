import tableConfig from 'common/tableConfig';
import { getColumns } from './UrlAnalysisTable.columns';

export default ({ refreshTableData }) => ({
  columns: getColumns(refreshTableData),
  components: {
    ...tableConfig.components
  },
  options: {
    ...tableConfig.options,
    toolbar: true,
    cellStyle: {
      borderBottom: '1px solid #e0e0e0',
      padding: '0px'
    }
  }
});
