export const TOOLTIP_STATUS = Object.freeze({
  running: 'scanning',
  failed: '',
  unknown: 'no_information',
  notAnalyzed: '',
  finished: '',
  safe: 'known_safe_file',
  undetermined: 'no_threat_detected',
  clean: 'no_threat_detected',
  suspicious: 'suspicious_behaviors_identified',
  unsafe: 'threat_found'
});
