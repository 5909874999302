import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  passwordDescription: {
    color: theme.palette.text.secondary,
    display: 'block',
    marginTop: '16px'
  },
  submitButton: {
    marginTop: '1em'
  },
  matchPassword: {
    color: theme.palette.success.main,
    fill: theme.palette.success.main,
    fontSize: '1em',
    margin: '5px'
  }
}));
