import React, { useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import BaseLayout from 'layouts/BaseLayout';
import { useSnackSetState } from 'contexts/SnackContext';
import { confirmChallenge } from 'entities/auth/auth.api';
import Loading from 'common/Loading';
import PasswordForm from 'common/PasswordForm';

const initState = {
  loading: false,
  data: null,
  error: null
};

const ACTIONS = {
  FETCH: 'fetch',
  SUCCESS: 'success',
  FAIL: 'fail'
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.FETCH: return { ...state, loading: true };
    case ACTIONS.SUCCESS: return { ...state, loading: false, data: action.payload };
    case ACTIONS.FAIL: return { ...state, loading: false, error: action.payload };
  }
};

export default function PasswordChallenge () {
  const setSnack = useSnackSetState();
  const [recovery, dispatch] = useReducer(reducer, initState);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const onsubmitPassword = async ({ password }) => {
    try {
      dispatch({ type: ACTIONS.FETCH });
      const response = await confirmChallenge({
        newPassword: password,
        token: params.get('token'),
        flowId: params.get('flow')
      });
      if (response.data) {
        dispatch({ type: ACTIONS.SUCCESS, payload: response.data });
        setSnack({
          isOpen: true,
          message: 'Your password has been reset successfuly',
          title: 'Great!',
          severity: 'success'
        });
      }
    } catch (error) {
      dispatch({ type: ACTIONS.FAIL, payload: error.data });
      setSnack({
        isOpen: true,
        message: 'something went wrong. Please, try later or ask support to your admin',
        title: 'Error!',
        severity: 'error'
      });
    }
  };

  const onsubmithandler = ({ password }) => {
    onsubmitPassword({ password });
  };

  return (
    <BaseLayout>
      <Grid container justify="center" alignItems="center">
        <Grid item md={5}>
        { !recovery.loading && <PasswordForm onsave={onsubmithandler}/> }
        {
          recovery.loading && <Loading size={100} />
        }
        </Grid>
      </Grid>
    </BaseLayout>
  );
}
