
import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  avatar: {
    width: 60,
    height: 60
  },
  container: {
    marginTop: 20,
    paddingLeft: 10,
    paddingRight: 10
  },
  backButton: {
    marginBottom: 15
  }
}));
