import HTTP from 'common/http';
import { getPlan } from './plans.helpers';

const PLANS = '/plans';

export const fetchPlans = params => {
  const url = PLANS;
  return HTTP.get(url, { params });
};

export const fetchPlan = id => {
  const url = PLANS;
  return HTTP.get(`${url}/${id}`)
    .then(getPlan);
};

export const createPlan = (plan) => {
  const url = PLANS;
  return HTTP.post(url, { data: plan });
};

export const editPlan = async (plan) => {
  const url = PLANS;
  return HTTP.put(`${url}/${plan.id}`, { data: plan });
};

export const deletePlan = id => {
  const url = PLANS;
  return HTTP.delete(`${url}/${id}`);
};
