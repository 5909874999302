import React from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import useScanContext from 'contexts/ScanContext/useScanContext';
import Upload from 'pages/Upload';
import Scan from 'pages/Scan';
import Alert from 'components/Alert/Alert';

const ScannerChildrenHelper = ({ isDisabled }) => {
  const [t] = useTranslation();
  const [scan] = useScanContext();

  return (
    <>
    {scan.status === 'idle' && <Upload disabled={isDisabled} />}
        {scan.status === 'scanning' && <Scan />}
        {isDisabled && (
          <Alert type={Alert.TYPES.WARNING} justify='center' style={{ marginBottom: '30px' }}>
            {t('scan_is_not_available_as_no_probes_are_enabled')}
          </Alert>
        )}
    </>
  );
};

ScannerChildrenHelper.propTypes = {
  isDisabled: bool
};

export default ScannerChildrenHelper;
