import { makeStyles } from '@material-ui/core/styles';

const booleanStyle = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  height: '30px',
  borderRadius: '200px',
  padding: '0 11px',
  maxWidth: '90px',
  justifyContent: 'center',

  '& p': {
    marginLeft: '7px'
  },

  '& svg': {
    borderRadius: '8px'
  }
};

export default theme => makeStyles(theme => ({
  truthy: {
    ...booleanStyle,
    backgroundColor: theme.palette.primary.main
  },
  falsy: {
    ...booleanStyle,
    backgroundColor: theme.palette.secondary.main
  }
}));
