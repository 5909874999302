import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles((theme) => ({
  link: {
    color: 'inherit',
    textDecoration: 'none'
  },
  fileIcon: {
    position: 'absolute',
    left: -20,
    width: 20,
    height: 20,
    marginRight: '10px'
  },
  loading: {
    position: 'absolute',
    left: -20,
    marginTop: '5px'
  },
  itemWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'inline',
    marginLeft: '-20px'
  },
  fileName: {
    paddingLeft: '30px',
    paddingRight: '20px',
    marginRight: '30px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  fileNameLoading: {
    paddingLeft: '30px',
    paddingRight: '20px',
    marginRight: '30px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tooltip: {
    color: '#FFFFFF',
    fontFamily: theme.typography.caption.fontFamily,
    fontSize: 15,
    padding: 8
  },
  safeBackground: {
    backgroundColor: theme.palette.success.main
  },
  unsafeBackground: {
    backgroundColor: theme.palette.error.main
  },
  failedBackground: {
    backgroundColor: theme.palette.error.main
  },
  cancelledBackground: {
    backgroundColor: theme.palette.error.main
  },
  suspiciousBackground: {
    backgroundColor: theme.palette.warning.main
  },
  undeterminedBackground: {
    backgroundColor: '#167424'
  },
  cleanBackground: {
    backgroundColor: '#167424'
  },
  unknownBackground: {
    backgroundColor: theme.palette.text.secondary
  },
  notAnalyzedBackground: {
    backgroundColor: theme.palette.text.secondary
  },
  warningBackground: {
    backgroundColor: theme.palette.warning.main
  }
}));
