export const TOOLTIP_STATUS = Object.freeze({
  running: '',
  failed: '',
  unknown: 'no_information',
  notAnalyzed: '',
  finished: '',
  respectful: 'File makes effort to avoid invading privacy',
  undetermined: 'Nothing invasive was detected',
  undetected: 'Nothing invasive was detected',
  pervasive: 'File asks for a certain number of permissions',
  invasive: 'File asks for too many permissions'
});
