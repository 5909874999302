import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, InputAdornment, Link, TextField } from '@material-ui/core';
import { GooglePlayLine, TrashIcon, URLGlobe, WarningIcon } from 'common/Icons';
import { DialogTitle, DialogButtons, DialogContent } from 'common/probes/ProbeDialog';
import useDialog from 'hooks/useDialog';
import { propTypes } from './UrlField.props';
import makeStyles from '../../UrlScanner.styles';
import getDefaultTheme from 'contexts/ThemeContext/defaultTheme';

const useStyles = makeStyles();
const defaultTheme = getDefaultTheme();

const GOOGLE_PLAY_STORE_DOMAIN = 'https://play.google.com/store/apps/details?id=';

const UrlField = ({ index, url, onChange, onRemove, canDelete, isDisabled, isGuestUser, onScanApkUrl }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const [googlePlayURL, setGooglePlayURL] = useState(null);

  const onRemoveDialog = useDialog(`remove-url-${index}`, {
    onClose: () => {
      onRemoveDialog({ isOpen: false });
    },
    components: {
      Icon: <WarningIcon color={defaultTheme.palette.error.main} size='4rem' />,
      Title: <DialogTitle title= {t('delete_url_confirmation')} />,
      ContentText: <DialogContent content= {t('url_wont_be_scanned', { url: `${url}` })} />,
      Actions: <DialogButtons type='warning'
        confirmLabel={t('delete')} cancelLabel={t('cancel')}
        onConfirm={() => onRemove(index)}
        onCancel={() => onRemoveDialog({ isOpen: false })} />
    }
  });

  const handleOnDeleteLink = useCallback(event => {
    event.preventDefault();
    // Only confirm non empty URLs.
    if (url !== '') {
      onRemoveDialog({ isOpen: true });
    } else {
      onRemove(index);
    }
  }, [onRemoveDialog, onRemove, index, url]);

  const handleUrlField = (event) => {
    const url = event.target.value;

    if ((url).startsWith(GOOGLE_PLAY_STORE_DOMAIN) && !isGuestUser) {
      setGooglePlayURL(url);
    } else {
      setGooglePlayURL(null);
    };
    onChange(event, index);
  };

  const handleApkScan = () => {
    onScanApkUrl(url);
  };

  return (
    <div key={index} className={classes.urlInputWrapper}>
      <TextField
        id="urloutlined-basic"
        placeholder={t('paste_url_here')}
        variant="outlined"
        value={url}
        onChange={handleUrlField}
        disabled={isDisabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              { googlePlayURL ? <GooglePlayLine size={20}/> : <URLGlobe size={20}/> }
            </InputAdornment>
          ),
          endAdornment: (
            <>{ googlePlayURL ? <Button color='primary' onClick={handleApkScan} className={classes.scanApkBtn} variant='contained'>{t('scan_apk')}</Button> : null }</>
          )
        }}
      />
      {canDelete &&
          <Link data-test-id='deleteInputLink' className={classes.deleteLink} href='#'
            onClick={handleOnDeleteLink}>
          <TrashIcon color='gray' size={16}/>
        </Link>}
    </div>
  );
};

UrlField.propTypes = propTypes;

export default UrlField;
