import React from 'react';
import tableConfig from 'common/tableConfig';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import UpdateButton from '../UpdateButton/UpdateButton';

const GetProbeName = () => {
  const [t] = useTranslation();
  return {
    title: t('probe_name'),
    width: '15%',
    // eslint-disable-next-line react/display-name
    render: rowData => <Typography>{rowData?.info?.display_name}</Typography>
  };
};
const GetProbeVersion = () => {
  const [t] = useTranslation();
  return {
    title: t('version'),
    width: '20%',
    // eslint-disable-next-line react/display-name
    render: rowData => <Typography>{rowData?.enabled_versions[0]?.version?.software}</Typography>
  };
};
const GetProbeNewVersion = () => {
  const [t] = useTranslation();
  return {
    title: t('new_version'),
    width: '20%',
    // eslint-disable-next-line react/display-name
    render: rowData => <Typography>{rowData?.last_version?.version?.software}</Typography>
  };
};
const GetProbeDatabaseVersion = () => {
  const [t] = useTranslation();
  return {
    title: t('database_version'),
    width: '20%',
    // eslint-disable-next-line react/display-name
    render: rowData => <Typography>{rowData?.version?.database || 'N/A'}</Typography>
  };
};
const GetProbeNewDatabaseVersion = () => {
  const [t] = useTranslation();
  return {
    title: t('new_db_version'),
    width: '20%',
    // eslint-disable-next-line react/display-name
    render: rowData => <Typography>{rowData?.last_version?.version?.database || 'N/A'}</Typography>
  };
};
const GetUpdateButton = (setProbesToUpdate, probesToUpdate) => {
  const [t] = useTranslation();

  return {
    title: t('action'),
    // eslint-disable-next-line react/display-name
    render: rowData => (
      <UpdateButton
        probeToUpdate={rowData}
        allProbesToUpdate={probesToUpdate}
        setProbesToUpdate={setProbesToUpdate}
      />
    )
  };
};
const getColumns = (setProbesToUpdate, probesToUpdate) => {
  return [
    GetProbeName(),
    GetProbeVersion(),
    GetProbeNewVersion(),
    GetProbeDatabaseVersion(),
    GetProbeNewDatabaseVersion(),
    GetUpdateButton(setProbesToUpdate, probesToUpdate)
  ];
};

export default (setProbesToUpdate, probesToUpdate) => ({
  columns: getColumns(setProbesToUpdate, probesToUpdate),
  components: {
    ...tableConfig.components
  },
  options: {
    ...tableConfig.options,
    tableLayout: 'fixed',
    paging: false,
    cellStyle: {
      paddingLeft: '0',
      border: 'none'
    },
    headerStyle: {
      paddingLeft: '0',
      border: 'none',
      color: '#79859F'
    }
  }
});
