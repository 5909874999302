import { STATUSES } from './ScanResults.constants';

export const defaultAnalysis = (urls, status = STATUSES.UPLOADING) => ({
  id: null,
  analysis: urls.map(url => ({
    id: null,
    status,
    target: {
      id: null,
      target_type: 'url',
      url
    }
  }))
});
