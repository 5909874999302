import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@material-ui/core';
import getDefaultTheme from 'contexts/ThemeContext/defaultTheme';
import { useSnackSetState } from 'contexts/SnackContext';
import { useDialog } from 'hooks';
import { TrashIcon } from 'common/Icons';
import DialogExtensions from 'components/DialogExtensions';
import DeletionStatus from './components/DeletionStatus';
import { propTypes } from './DeleteFilesAction.props';
import makeStyles from './DeleteFilesAction.styles';

const useStyles = makeStyles();
const defaultTheme = getDefaultTheme();

const DeleteFilesAction = ({ files, refreshData, onCancelSelection }) => {
  const [t] = useTranslation();
  const setSnack = useSnackSetState();
  const classes = useStyles();
  const showAction = useMemo(() => files?.length > 0, [files]);

  const handleOnClick = useCallback(() => {
    onDisplayConfirmationDialog({ isOpen: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnConfirm = useCallback(() => {
    onDisplayConfirmationDialog({ isOpen: false });
    onDisplayDeletionStatusDialog({ isOpen: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnFinishDeletion = useCallback(() => {
    onDisplayDeletionStatusDialog({ isOpen: false });
    onCancelSelection();
    // Requesting the files list quickly, (sometimes) return some deleted files.
    setTimeout(() => {
      refreshData();
    }, (0));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCancelSelection, refreshData]);

  const handleOnSuccessDeletion = useCallback(() => {
    handleOnFinishDeletion();
    setSnack({
      isOpen: true,
      title: t('files_deleted_successfully', { count: files.length }),
      severity: 'success'
    });
  }, [files.length, handleOnFinishDeletion, setSnack, t]);

  // First dialog to confirm or cancel deletion.
  const onDisplayConfirmationDialog = useDialog('delete-files-warning-dialog', {
    onClose: () => {
      onDisplayConfirmationDialog({ isOpen: false });
    },
    resetOnUpdate: false,
    components: {
      Icon: <TrashIcon color={defaultTheme.palette.error.main} size='4rem' />,
      Title: <DialogExtensions.Title title={t('delete_multiple_files_warning_title', { count: files.length })} />,
      ContentText: <DialogExtensions.Content content={t('delete_multiple_files_warning_description', { count: files.length })} />,
      Actions: <DialogExtensions.Buttons
        type='warning' confirmLabel={t('yes_delete')}
        cancelLabel={t('no')} onConfirm={handleOnConfirm}
        onCancel={() => onDisplayConfirmationDialog({ isOpen: false })}/>
    }
  });

  // If deletion confirmed, start deletion and show status.
  const onDisplayDeletionStatusDialog = useDialog('delete-files-status-dialog', {
    onClose: () => {
      onDisplayConfirmationDialog({ isOpen: false });
    },
    resetOnUpdate: false,
    components: {
      Icon: <TrashIcon color={defaultTheme.palette.error.main} size='4rem' />,
      Title: <DialogExtensions.Title title={t('deleting_files', { count: files.length })} />,
      Content: <DeletionStatus files={files} onClose={handleOnFinishDeletion} onSuccess={handleOnSuccessDeletion}/>
    }
  });

  return (showAction && <div className={classes.action}>
    <p className={classes.info} data-test-id='selectedText'>{t('items_selected', { count: files.length })}</p>
    <Link className={classes.deleteLink} onClick={handleOnClick} data-test-id='deleteLink'>{t('delete')}</Link>
    <p className={classes.divider} data-test-id='devider'>|</p>
    <Link className={classes.cancelLink} onClick={onCancelSelection} data-test-id='cancelLink'>{t('cancel')}</Link>
  </div>);
};

DeleteFilesAction.propTypes = propTypes;

export default DeleteFilesAction;
