import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  planRow: {
    display: 'flex',
    alignItems: 'center'
  },
  infoicon: {
    width: '1rem',
    height: '1rem',
    fill: theme.palette.text.primary
  },
  boxHeader: {
    paddingBottom: '10px',
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  boxInfo: {
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  popper: {
    zIndex: 200,
    border: 'none',
    borderRadius: '5px',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    width: '525px',
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.secondary.main} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.secondary.main}`
      }
    }
  },
  arrow: {
    position: 'absolute',
    fontSize: 10,
    width: '3em',
    height: '3em',
    marginTop: '6px',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '0em',
      height: '0em',
      borderStyle: 'solid',
      borderColor: theme.palette.secondary.main
    }
  },
  featureList: {
    display: 'flex',
    justifyContent: 'space-between',
    WebkitPaddingStart: '15px',
    '& li': {
      width: '100%'
    }
  }
}));
