import React, { useState, useEffect, useContext } from 'react';
import { SnackStateContext } from 'contexts/SnackContext';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';

export default function SimpleSnackbar () {
  const SnackState = useContext(SnackStateContext);
  const [snack, setLocalSnack] = useState(SnackState);

  const { isOpen, message, title, severity } = snack;

  const handleClose = () =>
    setLocalSnack({
      isOpen: false,
      message: undefined,
      title: undefined,
      severity: undefined
    });

  useEffect(() => {
    setLocalSnack(SnackState);
  }, [SnackState]);

  return (
    <Snackbar
      className="snack"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={isOpen}
      autoHideDuration={4000}
      onClose={handleClose}
      message={message}
    >
      <Alert className="snack-alert" severity={severity}>
        <AlertTitle className="snack-alert-title">{title}</AlertTitle>
        <span className="snack-alert-message">{message}</span>
      </Alert>
    </Snackbar>
  );
}
