import i18n from 'i18n';
import { DEFAULT_ERROR_MSG } from './pages.constants';

/**
 * We represent items per page but backend needs a range string.
 * @param Number page
 * @param Number itemsPerPage
 * @returns String range e.g '0-9'
 */
export const getRange = (page, itemsPerPage) => {
  // Assumes paging starts at 0;
  const from = page * itemsPerPage;
  const to = from + itemsPerPage - 1;
  return `${from}-${to}`;
};

/**
 * Extract the total number of items from "content-range" header.
 * e.g '0-9/100' where 100 is the total items.
 * @param String The content-range header
 * @returns Number total count.
 */
export const getItemsCount = contentRange => Number(contentRange?.split?.('/')?.[1]);

/**
 * Extracts error message from response or returns the default error message.
 * @param Object the error object as returned from the server.
 * @returns String error message.
 */
export const getServerError = (error, defaultErrorMsg = DEFAULT_ERROR_MSG) => {
  if (error?.msg) {
    return error.msg;
  }
  if (error?.errors?.description) {
    return error.errors.description;
  }
  return i18n.t(defaultErrorMsg);
};
