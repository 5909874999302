import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import makeStyles from './styles';
import en from 'assets/flags/en.svg';
import fr from 'assets/flags/fr.svg';

const useStyles = makeStyles();

export default function LanguageSwitcher () {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (lang) => {
    setLang(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', `${lang}`);
    setAnchorEl(null);
  };
  return (
    <div className={classes.languageSwitcher}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
        data-test="language-switcher-button"
        endIcon={<KeyboardArrowDownIcon />}
      >
        <img alt="flag" className={classes.mainFlag} src={lang === 'en' ? en : fr} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        value={lang}
        open={Boolean(anchorEl)}
        className="language-switcher-menu"
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleClose('en')} data-test="language-switcher-button-EN">
          <img alt="flag" className={classes.flag} src={en} />
          <span>English</span>
        </MenuItem>
        <MenuItem disabled onClick={() => handleClose('fr')} data-test="language-switcher-button-FR">
          <img alt="flag" className={classes.flag} src={fr} />
          <span>French</span>
          <span className={classes.comingSoon}>COMING SOON</span>
        </MenuItem>
      </Menu>
    </div>
  );
}
