import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  filename: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '45%'
  },
  status: {
    paddingRight: 30
  },
  header: {
    fontWeight: 'normal'
  },
  table: {
    width: '100%'
  },
  dataRow: {
    height: 100
  },
  actions: {
    textAlign: 'right'
  }
}));
