import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Button, FormControl, InputLabel, OutlinedInput, Divider } from '@material-ui/core';
import useAppConfigContext from 'contexts/AppConfigContext/useAppConfigContext';
import { useSnackSetState } from 'contexts/SnackContext';
import Loading from 'common/Loading';
import { getServerError } from 'pages/pages.utils';
import { updateIdentity } from 'entities/auth/auth.api';
import { CheckIcon, CloseCircleOutline, EditIcon } from 'common/Icons';
import makeStyles from './Profile.style';

const useStyles = makeStyles();

export default function Profile (props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const { user, refreshUser } = useAppConfigContext();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastname, setLastname] = useState('');
  const [username, setUsername] = useState('');
  const setSnack = useSnackSetState();
  const [loading, setLoading] = useState(true);
  const [editUserDetails, setEditUserDetails] = useState(false);

  useEffect(() => {
    if (user) {
      const traits = user?.traits ?? {};
      setEmail(traits.email);
      setFirstName(traits.name?.first);
      setLastname(traits.name?.last);
      setUsername(traits.username);
      setLoading(false);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onsubmithandler = async (e) => {
    if (e) {
      e.preventDefault();
    }

    const identity = {
      email,
      firstName,
      lastname,
      username
    };

    setLoading(true);
    try {
      await updateIdentity({
        username: identity.username,
        first_name: identity.firstName,
        last_name: identity.lastname,
        email: identity.email
      });

      setLoading(false);
      refreshUser();
      setEditUserDetails(false);
      setSnack({
        isOpen: true,
        message: t('your_profile_has_been_changed_successfully'),
        title: t('great!'),
        severity: 'success'
      });
    } catch (error) {
      setLoading(false);
      setSnack({
        isOpen: true,
        message: getServerError(error.data, t('profile_could_not_be_changed')),
        title: t('error!'),
        severity: 'error'
      });
    }
  };

  return (
    <div style={{ marginBottom: '50px' }}>
      <Grid container justify='space-between'>
        <Grid item>
          <Typography variant="h5" component="span" gutterBottom><strong>{t('user_details')}</strong></Typography>
        </Grid>
        <Grid item>
          { editUserDetails
            ? <Grid container>
                <Button className={classes.cancelButton} onClick={() => setEditUserDetails(false)}><CloseCircleOutline size={16} style={{ margin: '5px' }} color={classes.cancelButton} />{t('cancel')}</Button>
                <Divider classes={{ root: classes.divider }} orientation="vertical" flexItem />
                <Button color="primary" onClick={() => onsubmithandler()}><CheckIcon size={16} style={{ margin: '5px' }} />{t('save')}</Button>
              </Grid>
            : <Button color="primary" onClick={() => setEditUserDetails(true)}><EditIcon size={16} style={{ margin: '5px' }}/>{t('edit')}</Button>
          }
        </Grid>
      </Grid>
      { loading && <Loading size={50} />}
      { !loading && <form>
          <Grid container justify='space-between' spacing={5}>
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-firstname">
                  {t('first_name')}
                </InputLabel>

                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  name="firstName"
                  labelWidth={70}
                  type="text"
                  id="firstName"
                  autoComplete="firstName"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  disabled={!editUserDetails}
                />
              </FormControl>
              <FormControl
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-email">
                  {t('email')}
                </InputLabel>

                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  name="email"
                  labelWidth={70}
                  type="email"
                  autoFocus
                  id="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  disabled={!editUserDetails}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-lastname">
                  {t('last_name')}
                </InputLabel>

                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  name="lastname"
                  labelWidth={70}
                  type="text"
                  id="lastname"
                  autoComplete="lastname"
                  onChange={(e) => setLastname(e.target.value)}
                  value={lastname}
                  disabled={!editUserDetails}
                />
              </FormControl>
              <FormControl
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-username">
                  {t('username')}
                </InputLabel>

                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  name="username"
                  labelWidth={70}
                  type="username"
                  autoFocus
                  id="username"
                  autoComplete="username"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  disabled={!editUserDetails}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>}
    </div>
  );
}
