import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getFlatList } from 'pages/Details/Details.utils';
import { propTypes } from './Communication.props';
import HttpProtocol from './components/HttpProtocol';
import DnsProtocol from './components/DnsProtocol';
import UdpProtocol from './components/UdpProtocol';
import TcpProtocol from './components/TcpProtocol';
import makeStyles from '../../styles';

const useStyles = makeStyles();

const Communication = ({ data = [] }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const rowsPerProbeType = getFlatList(data);

  const httpData = rowsPerProbeType.filter(result => {
    return result.network_activities.find(net => net.protocols && net.protocols.includes('http'));
  });
  const http = httpData.map((item, index) => {
    return <HttpProtocol data={item} key={index} />;
  });

  const tcpData = rowsPerProbeType.filter(result => {
    return result.network_activities.find(net => net.type === 'TCP_SESSION');
  });
  const tcp = tcpData.map((item, index) => {
    return <TcpProtocol data={item} key={index} />;
  });

  const udpData = rowsPerProbeType.filter(result => {
    return result.network_activities.find(net => net.type === 'UDP_STREAM');
  });
  const udp = udpData.map((item, index) => {
    return <UdpProtocol data={item} key={index} />;
  });

  const dnsData = rowsPerProbeType.filter(result => {
    return result.network_activities.find(net => net.type === 'DNS_QUERY' || net.type === 'DNS_ANSWER');
  });
  const dns = dnsData.map((item, index) => {
    return <DnsProtocol data={item} key={index} />;
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div className={classes.fileInfoHeader}>
          <Typography variant="h4" className={classes.panelTitle}>
           {t('communication')}
          </Typography>
        </div>
        <div>
          {http}
          {tcp}
          {udp}
          {dns}
        </div>
      </Grid>
    </Grid>
  );
};

Communication.propTypes = propTypes;

export default Communication;
