import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3
    }
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${theme.palette.text.secondary + 50}`
  },
  label: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  content: {
    backgroundColor: 'transparent',
    paddingBottom: '15px'
  },
  selected: {
    backgroundColor: 'transparent'
  }
}));
