import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import ProbeConfiguration from '../components/ProbeConfiguration';
import TabPanel from '../components/ProbeConfiguration/components/TabPanel';
import { fetchProbe } from 'entities/probes/probes.api';
// import StoreProbeButton from './StoreProbeButton';
// import LocalProbeButton from './LocalProbeButton';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import ProbeDetail from '../components/ProbeDetail';
import { ROUTE_PATHS } from 'utils/routes.constants';
import makeStyles from './styles';
const useStyles = makeStyles();

export default function ProbeManagement () {
  const [t] = useTranslation();
  const [probe, setProbe] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  // const [activeTab, setActiveTab] = useState(0);
  const activeTab = 0;
  const classes = useStyles();

  useEffect(() => {
    fetchProbe(id)
      .then(response => {
        setIsLoading(false);
        setProbe(response?.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backToSummary = () => navigate(ROUTE_PATHS.PROBES);

  // const handleChange = (event, newValue) => {
  //   setActiveTab(newValue);
  // };

  return (
    <>
      <Grid container xs={12} spacing={2} className={classes.backButton}>
        <Button
          onClick={backToSummary}
          startIcon={<ArrowBackIcon />}
        >
          {t('back_to_probes')}
        </Button>
      </Grid>
      {/* hidding config tab until feature is ready */}
      {/* <Tabs
        value={activeTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label={t('probe_details')} />
        <Tab label={t('probe_config')}/>
      </Tabs> */}
      <TabPanel value={activeTab} index={0}>
        <ProbeDetail probe={probe} loading={isLoading} />
      </TabPanel>
      {/* <TabPanel value={activeTab} index={1}>
        <ProbeConfiguration probe={probe} />
      </TabPanel> */}
    </>
  );
}
