import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { fetchSyslog } from 'entities/syslog/syslog.api';
import FormField from '../FormField';
import FormRadioGroup from '../FormRadioGroup';
import { SEVERITY_LABELS } from './Syslog.constants';
import makeStyles from './Syslog.styles';

const useStyles = makeStyles();

export default function Syslog () {
  const [t] = useTranslation();
  const classes = useStyles();
  const [syslogData, setSyslogData] = useState({});

  useEffect(() => {
    fetchSyslog().then(response => {
      setSyslogData(response?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    <Grid container justify='space-between'>
      <Grid item>
        <Typography variant="h5" component="span" gutterBottom><strong>{t('syslog_connection')}</strong></Typography>
      </Grid>
    </Grid>
    <dl className={classes.tableDisplay}>
      <div>
        <dt>{t('dns_name_ip_address')}</dt>
        <dd>
          <FormField id={t('dns_name_ip_address')} label={t('dns_name_ip_address')} variant="outlined" className={classes.formField} value={syslogData?.hostname || ''} disabled />
        </dd>
      </div>
      <div>
        <dt>{t('transport')}</dt>
        <dd>
          <FormField id={t('transport')} label={t('transport')} variant="outlined" className={classes.formField} value={syslogData?.transport || ''} disabled />
        </dd>
      </div>
      <div>
        <dt>{t('network_port')}</dt>
        <dd>
          <FormField id={t('network_port')} label={t('network_port')} variant="outlined" className={classes.formField} value={syslogData?.port || ''} disabled />
        </dd>
      </div>
      <div>
        <dt>{t('event_severity')}</dt>
        <dd>
          <FormRadioGroup values={SEVERITY_LABELS} value={syslogData?.severity || 'all'} disabled />
        </dd>
      </div>
    </dl>
  </>;
}
