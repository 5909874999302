import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  container: {
    width: 400,
    margin: 30
  },
  divider: {
    display: 'flex',
    alignItems: 'center',
    margin: '30px 0'
  },
  instruction: {
    marginTop: 30,
    marginBottom: 15
  },
  or: {
    fontSize: '12px',
    color: 'grey',
    margin: '0px 8px'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 40
  }
}));
