import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import useAppConfigContext from 'contexts/AppConfigContext/useAppConfigContext';
import useScanContext from 'contexts/ScanContext/useScanContext';
import { SCAN_ACTIONS } from 'contexts/ScanContext/ScanContext';
import { fetchCatalogProbes } from 'entities/catalogs/catalogs.api';
import { isAdmin } from 'entities/auth/auth.utils';
import ProbeModel from 'common/probes/models/ProbeModel';
import TabPanel from './components/TabPanel';
import AppBar from './components/AppBar';
import ScannerChildrenHelper from './components/ScannerChildrenHelper';
import UrlScanner from './components/UrlScanner';

const DEFAULT_CATALOG = 'catalog.1';

export default function Scanner () {
  const { search } = useLocation();
  const defaultTab = search.includes('tab=url') ? 1 : 0;
  const urlParams = new URLSearchParams(search).get('URL');
  const isWarningEnabled = isAdmin();
  const { isUrlAnalysisEnabled } = useAppConfigContext();
  const [{ isAdvancedScan }, setScanContext] = useScanContext();
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [isFileScanDisabled, setIsFileScanDisabled] = useState(false);
  const [isURLScanDisabled, setIsURLScanDisabled] = useState(false);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    setScanContext({ type: SCAN_ACTIONS.CLEAN_SCAN });
  }, [setScanContext]);

  useEffect(() => {
    if (isWarningEnabled) {
      fetchCatalogProbes(DEFAULT_CATALOG)
        .then(response => {
          const list = response?.data?.map?.(d => new ProbeModel(d));
          setIsFileScanDisabled(!list.some(prob => prob.isFileProbeEnabled()));
          setIsURLScanDisabled(!list.some(prob => prob.isURLProbeEnabled()));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      {
        (isUrlAnalysisEnabled && !isAdvancedScan) &&
        <AppBar
          activeTab={activeTab}
          handleChange={handleChange}
        >
        </AppBar>
      }
      <TabPanel value={activeTab} index={0}>
          <ScannerChildrenHelper isDisabled={isFileScanDisabled} />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <UrlScanner isDisabled={isURLScanDisabled} value={urlParams}/>
      </TabPanel>
    </Grid>
  );
}
