import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 480
  },
  description: {
    color: theme.palette.text.secondary,
    display: 'block',
    marginTop: '16px'
  },
  key: {
    color: 'black',
    display: 'inline'
  },
  error: {
    color: '#FA4B54',
    display: 'block'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 550,
    padding: 20
  },
  successIcon: {
    fill: theme.palette.success.main,
    marginBottom: 30
  },
  warningIcon: {
    fill: theme.palette.warning.main,
    marginBottom: 30
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0
  }
}));
