import React from 'react';
import useAppConfigContext from 'contexts/AppConfigContext/useAppConfigContext';
import PropTypes from 'prop-types';
import { useLocation, NavLink } from 'react-router-dom';
import SidebarText from './SidebarText';
import SidebarIcon from './SidebarIcon';
import {
  List,
  ListElement,
  ListElementIcon
} from 'components/List';
import routes from 'utils/getRoutes';
import { getHighestRole } from 'utils/roles';
import makeStyles from './styles';

const useStyles = makeStyles();

export default function NavigationSidebar ({ collapse = true }) {
  const classes = useStyles();
  const appConfig = useAppConfigContext();
  const location = useLocation();

  const highestRole = getHighestRole(appConfig?.user?.roles);
  const sidebar = routes.filter(route => {
    return route.sidebar(appConfig) && (route.roles && route.roles.includes(highestRole.roleName));
  });

  return (
    <List>
      {
        sidebar.map(({ path, text, id }) => (
          <NavLink key={path} to={path} className={classes.routeLink}>
            <ListElement button selected={path === location.pathname}>
              <ListElementIcon style={{ minWidth: 35 }}>
                <SidebarIcon sidebarId={id} />
              </ListElementIcon>
              {collapse ? null : <SidebarText text={text} />}
            </ListElement>
          </NavLink>
        ))
      }
    </List>
  );
}

NavigationSidebar.propTypes = {
  collapse: PropTypes.bool
};
