import React, { useState, useEffect } from 'react';
import QualifierPill from '../QualifierPill/QualifierPill';
import { useTheme } from '@material-ui/core';
import { SECURITY_LABELS } from 'entities/analysis/analysis.constants';
import { propTypes } from './SecurityPill.props';

function SecurityPill ({ security, isScanning }) {
  const securityKey = security ? security.toLowerCase() : 'unknown';
  const [securityLabel, setSecurityLabel] = useState(SECURITY_LABELS[securityKey] || SECURITY_LABELS.unknown);
  const theme = useTheme();
  const [color, setColor] = useState('');

  useEffect(() => {
    if (isScanning) {
      setSecurityLabel('scanning');
    } else {
      let newColor = '';
      switch (securityKey) {
        case 'secure':
          newColor = theme.palette.success.main;
          break;
        case 'insecure':
        case 'failed':
        case 'cancelled':
          newColor = theme.palette.error.main;
          break;
        case 'weak':
          newColor = theme.palette.warning.main;
          break;
        case 'undetermined':
        case 'undetected':
          newColor = '#167424';
          break;
        case 'unknown':
        case 'notAnalyzed':
          newColor = theme.palette.text.secondary;
          break;
        default:
          newColor = theme.palette.text.secondary;
      }
      setColor(newColor);
    }
  }, [isScanning, securityKey, theme.palette]);

  return (
    <div>
      <QualifierPill qualifier='Security' label={securityLabel} color={color} isScanning={isScanning}/>
    </div>
  );
}

SecurityPill.propTypes = propTypes;
export default SecurityPill;
