import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { TrashIcon } from 'common/Icons';
import { useDialog } from 'hooks';
import { useSnackSetState } from 'contexts/SnackContext';
import { deleteSession } from 'entities/sessions/sessions.api';
import { getServerError } from 'pages/pages.utils';
import DialogButtons from 'components/DialogButtons/DialogButtons';
import propTypes from './RevokeSession.props';
import makeStyles from '../ActiveSessions.styles';

const useStyles = makeStyles();

function RevokeSession ({ id, device, refreshTableData }) {
  const [t] = useTranslation();
  const setSnack = useSnackSetState();
  const classes = useStyles();

  const handleRevokeSession = async () => {
    try {
      await deleteSession(id);
      setSnack({
        isOpen: true,
        title: t('session_revoked'),
        severity: 'success'
      });
      await refreshTableData();
      closeDialog();
    } catch (error) {
      setSnack({
        isOpen: true,
        title: t('something_went_wrong'),
        message: getServerError(error.data),
        severity: 'warning'
      });
      closeDialog();
    }
  };

  const onRevokeSessionDialog = useDialog(`revoke-session-dialog-${id}`, {
    onClose: () => {
      closeDialog();
    },
    components: {
      Icon: <TrashIcon className={classes.dialogIconSize} />,
      Title: <Typography variant="h5" align="center">{t('are_you_sure_you_want_to_revoke')}</Typography>,
      ContentText: (<>
        <Typography variant="body2" align="center">
          <Trans i18nKey="active_session_on_device_will_be_logget_out">
            Active Session on <strong>{{ device: device }}</strong> will be logged out after this.
          </Trans>
        </Typography>
      </>),
      Actions: (
        <DialogButtons
          confirmLabel={t('yes_revoke_access')}
          cancelLabel={t('no')}
          onConfirm={() => handleRevokeSession()}
          onCancel={() => closeDialog()}
          type='warning'
        />
      )
    }
  });

  const closeDialog = () => {
    onRevokeSessionDialog({ isOpen: false });
  };

  return (<div onClick={() => onRevokeSessionDialog({ isOpen: true })}>
      <TrashIcon color="#000" className={classes.iconStyle} title={t('delete')}/>
    </div>);
};

RevokeSession.propTypes = propTypes;
export default RevokeSession;
