import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  chipResult: {
    textTransform: 'uppercase',
    width: 100
  },
  secure: {
    color: theme.palette.success.main
  },
  insecure: {
    color: theme.palette.error.main
  },
  failed: {
    color: theme.palette.error.main
  },
  cancelled: {
    color: theme.palette.text.secondary
  },
  weak: {
    color: theme.palette.warning.main
  },
  undetermined: {
    color: '#167424'
  },
  undetected: {
    color: '#167424'
  },
  unknown: {
    color: theme.palette.text.secondary
  },
  notAnalyzed: {
    color: theme.palette.text.secondary
  },
  warning: {
    color: theme.palette.warning.main
  },
  secureBackground: {
    backgroundColor: theme.palette.success.main
  },
  insecureBackground: {
    backgroundColor: theme.palette.error.main
  },
  failedBackground: {
    backgroundColor: theme.palette.error.main
  },
  cancelledBackground: {
    backgroundColor: theme.palette.error.main
  },
  weakBackground: {
    backgroundColor: theme.palette.warning.main
  },
  undeterminedBackground: {
    backgroundColor: '#167424'
  },
  undetectedBackground: {
    backgroundColor: '#167424'
  },
  unknownBackground: {
    backgroundColor: theme.palette.text.secondary
  },
  notAnalyzedBackground: {
    backgroundColor: theme.palette.text.secondary
  },
  warningBackground: {
    backgroundColor: theme.palette.warning.main
  }
}));
