import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Typography, Button } from '@material-ui/core';
import { useSnackSetState } from 'contexts/SnackContext';
import { unlinkMfaDevice } from 'entities/auth/auth.api';
import propTypes from './MfaDisableDialog.props';
import makeStyles from './MfaDisableDialog.styles';

const useStyles = makeStyles();

function MfaDisableDialog ({ closeDialog }) {
  const [t] = useTranslation();
  const setSnack = useSnackSetState();
  const classes = useStyles();

  const onCancel = () => {
    closeDialog();
  };
  const onDisable = async () => {
    try {
      await unlinkMfaDevice();
      setSnack({
        isOpen: true,
        title: t('mfa_disabled_success'),
        severity: 'success'
      });
      closeDialog();
    } catch (error) {
      let errorMessage = t('mfa_disabled_failure');
      if (error?.response?.status === 403) {
        errorMessage = t('session_too_old');
      }
      setSnack({
        isOpen: true,
        title: t('something_went_wrong'),
        message: errorMessage,
        severity: 'warning'
      });
      closeDialog();
    }
  };

  return (
    <>
      <div className={classes.container}>
        <Typography variant="h4" align='center' className={classes.disabledTitle} >
          <Trans i18nKey={'are_you_sure_disabled_mfa'}>
            Are you sure you want to
            <div className={classes.warning}>disable mfa?</div>
          </Trans>
        </Typography>
        <Typography variant="body2" className={classes.bodyText}>
          {t('mfa_adds_protection_warning')}
        </Typography>
      </div>
      <div className={classes.buttonContainer}>
        <Button variant="outlined" onClick={onCancel} size="large">
          {t('cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onDisable} size="large">
          {t('disable')}
        </Button>
      </div>
    </>
  );
};

MfaDisableDialog.propTypes = propTypes;
export default MfaDisableDialog;
