import React from 'react';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { URLGlobe, FileText } from 'common/Icons';
import { propTypes } from './AppBar.props';
import makeStyles from './AppBar.styles';

const useStyles = makeStyles();

const tabs = [{
  label: 'file',
  iconComponent: FileText
},
{
  label: 'url',
  iconComponent: URLGlobe
}];

const iconProps = { size: '15' };

export default function AppBarContainer (props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const { activeTab, handleChange } = props;
  const scanTabs = tabs.map((tab, index) => {
    return (
      <Tab
        key= {tab.label}
        label={
          <span className={classes.tabLabelAndIcon}>{<tab.iconComponent className={classes.iconTab} {...iconProps}/>}{t(`${tab.label}`)}</span>
        }
      />
    );
  });

  return (
    <AppBar
        position="static"
        color="default"
        className={classes.appBar}
    >
      <Tabs
        value={activeTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        >
        {scanTabs}
      </Tabs>
    </AppBar>
  );
}

AppBarContainer.propTypes = propTypes;
