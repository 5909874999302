import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  scanButton: {
    margin: 12,
    marginTop: 28,
    height: 50,
    width: 150
  },
  loadingContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  probeContainerHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  advancedHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerText: {
    marginTop: 15,
    marginBottom: 0
  },
  headerSubText: {
    marginTop: 0
  },
  backIcon: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: 5
    }
  }
}));
