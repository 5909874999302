import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  pillContainer: {
    display: 'flex',
    marginRight: 15
  },
  pill: {
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50px',
    padding: '4px 2px',
    alignItems: 'center',
    maxWidth: '100%',
    height: '16px'
  },
  qualifier: {
    marginRight: '5px',
    marginLeft: '8px',
    color: 'white'
  },
  rightSide: {
    backgroundColor: 'white',
    flex: 1,
    borderRadius: '0 50px 50px 0',
    padding: '5px 7px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '10px'
  }
}));
