import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import makeStyles from '../../styles';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import CellFallbackView from '../CellFallbackView/CellFallbackView';
const useStyles = makeStyles();

export default function CommonTable ({
  columns,
  data,
  title
}) {
  const [t] = useTranslation();
  const classes = useStyles();
  const cellProps = {
    className: classes.cell,
    component: 'th',
    scope: 'row'
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <div className={classes.fileInfoHeader}>
          <Typography variant="h4" className={classes.panelTitle}>
            {title}
          </Typography>
        </div>
        <Table className={classes.table} size="medium" aria-label={t('table_name', { title })}>
          <TableHead>
            <TableRow>
              {
                columns.map(
                  (column, index) => (
                    <TableCell key={index} className={classes.headerCell}
                      align={column.align || 'left'}
                    >
                      {t(column.name)}
                    </TableCell>
                  )
                )
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((d) => (
              <TableRow key={d.name}>
                {
                  columns.map(
                    (column, index) => (
                      <CellFallbackView key={index} {...column?.columnProps}
                        {...cellProps} condition={d[column.id]}>
                        {d[column.id]}
                      </CellFallbackView>
                    )
                  )
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

CommonTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired
};
