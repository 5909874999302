import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  dialogContent: {
    width: 550
  },
  textCenter: {
    textAlign: 'center'
  },
  statusMessage: {
    marginTop: 25,
    alignItems: 'center'
  },
  underline: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  iconContainer: {
    alignSelf: 'center',
    display: 'flex'
  },
  smallIcon: {
    width: 25,
    height: 25
  },
  marginRight: {
    marginRight: 15
  },
  componentItem: {
    padding: 10
  },
  statusContainer: {
    marginTop: 30
  },
  successColor: {
    fill: theme.palette.success.main
  },
  warningColor: {
    fill: theme.palette.warning.main
  },
  greyText: {
    color: theme.palette.grey[800]
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));
