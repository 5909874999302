import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { Link, generatePath } from 'react-router-dom';
import { ROUTE_PATHS } from 'utils/routes.constants';

const NameColumn = ({ rowData }) => {
  return (
    <Tooltip
      title={rowData.url}
      className="tooltip"
      arrow
    >
      <Link to={generatePath(ROUTE_PATHS.DETAILS_URL, { id: rowData.id })}>{rowData.url}</Link>
    </Tooltip>
  );
};
NameColumn.propTypes = {
  rowData: PropTypes.object
};

export default NameColumn;
