import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    borderRadius: '4px',
    width: '35px',
    height: '35px',
    marginRight: 15,
    color: 'grey',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    fontSize: '16px',
    fontWeight: 'bold'
  }
}));
