import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  activePlan: {
    color: theme.palette.success.main
  },
  inactivePlan: {
    color: theme.palette.text.secondary
  }
}));
