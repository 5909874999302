import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFlatList } from 'pages/Details/Details.utils';
import { propTypes } from './GenericComponent.props';
import GenericTable from '../GenericTable';

const GenericComponent = ({ data, columns, title }) => {
  const [t] = useTranslation();
  const rows = getFlatList(data);
  return (
    <GenericTable rows={rows} columns={columns} title={t(title)}/>
  );
};

GenericComponent.propTypes = propTypes;

export default GenericComponent;
