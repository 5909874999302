import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

//
export default function DialogContent ({ content }) {
  return (
    <Typography variant="body2" align="center">{content}</Typography>
  );
}

DialogContent.propTypes = {
  content: PropTypes.string
};
