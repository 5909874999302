import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ListElementText } from 'components/List';
import makeStyles from './styles';

const useStyles = makeStyles();

export default function SidebarText (props) {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <ListElementText primary={t(`${props.text}`)} classes={{ primary: classes.primary }} />
  );
};

SidebarText.propTypes = {
  text: PropTypes.string
};
