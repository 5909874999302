export const getDuration = data => {
  const start = new Date(data.start);
  const end = new Date(data.end);
  return (end - start) / 1000;
};

export const getRows = (data) => {
  const rowData = [];

  data.forEach(result => {
    const mergedResults = result.data?.filter(item => ['ThreatLabel', 'SafetyQualifier', 'SecurityQualifier', 'PrivacyQualifier'].includes(item.type));

    let safetyQualifier = null;
    let securityQualifier = null;
    let privacyQualifier = null;
    let threatLabel = null;
    const duration = getDuration(result);

    mergedResults.forEach(item => {
      if (item.type === 'SafetyQualifier') {
        safetyQualifier = item.level;
      } else if (item.type === 'SecurityQualifier') {
        securityQualifier = item.level;
      } else if (item.type === 'PrivacyQualifier') {
        privacyQualifier = item.level;
      } else if (item.type === 'ThreatLabel') {
        threatLabel = item.name;
      }
    });

    rowData.push({
      probeName: result.probe.display_name,
      probeVersion: result.probe.version,
      id: `${result.id}`,
      probeCategory: result.probe.category,
      status: result.status,
      error: result.errors[0] || null,
      duration: duration,
      SafetyQualifier: safetyQualifier,
      SecurityQualifier: securityQualifier,
      PrivacyQualifier: privacyQualifier,
      ThreatLabel: threatLabel
    });
  });

  return rowData;
};
