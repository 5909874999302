export const initialState = {
  startDate: '',
  endDate: '',
  plan: '',
  accountId: '',
  enforcePlanLimit: false
};

export const ADD_PLAN_FORM_ACTIONS = Object.freeze({
  UPDATE_STARTDATE: 'addPlanForm/startDate',
  UPDATE_ENDDATE: 'addPlanForm/endDate',
  UPDATE_PLAN: 'addPlanForm/plan',
  UPDATE_ACCOUNT_ID: 'addPlanForm/accountId',
  UPDATE_ENFORCE_PLAN_LIMIT: 'addPlanForm/enforcePlanLimit'
});

export const getInitialState = accountId => ({ ...initialState, accountid: accountId });

export const addPlanFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PLAN_FORM_ACTIONS.UPDATE_STARTDATE:
      return { ...state, startDate: action.payload };
    case ADD_PLAN_FORM_ACTIONS.UPDATE_ENDDATE:
      return { ...state, endDate: action.payload };
    case ADD_PLAN_FORM_ACTIONS.UPDATE_PLAN:
      return { ...state, plan: action.payload };
    case ADD_PLAN_FORM_ACTIONS.UPDATE_ACCOUNT_ID:
      return { ...state, accountId: action.payload };
    case ADD_PLAN_FORM_ACTIONS.UPDATE_ENFORCE_PLAN_LIMIT:
      return { ...state, enforcePlanLimit: action.payload };
    default:
      return initialState;
  }
};
