import React, { useContext, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import { CheckIcon, WarningIcon } from 'common/Icons';
import { fetchHealth } from 'entities/health/health.api';
import Loading from 'common/Loading';
import { AuthContext } from 'contexts/AuthContext';
import { componentsHashMap, serviceStatusMessageMap, getStatusLabel } from './HealthStatusQF.utils';
import makeStyles from './HealthStatusQF.styles';

const useStyles = makeStyles();

export default function HealthStatusQF () {
  const [t] = useTranslation();
  const { auth } = useContext(AuthContext);
  const [healthInfo, setHealthInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const { status, components } = healthInfo || {};

  useEffect(() => {
    setLoading(true);
    fetchHealth({})
      .then(response => setHealthInfo(response.data))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid className={classes.dialogContent}>
      {loading && <Loading />}
      {!loading && <>
      <h1 className={classes.textCenter}>
        {
          status === 'UP'
            ? <CheckIcon className={classes.successColor} />
            : <WarningIcon className={classes.warningColor} />
        }
      </h1>
      <h1 className={classes.textCenter}>{t('qflow_status', { status: t(`${serviceStatusMessageMap[status]}`) || t(`${serviceStatusMessageMap.DEGRADED}`) })}</h1>
      { auth.session_token
        ? (
            <>
              <p className={classes.textCenter}><Trans i18nKey="having_trouble_email_us">Having trouble? Email us at <a href='mailto:qflow-support@quarkslab.com'>qflow-support@quarkslab.com</a></Trans></p>
              <Grid container>
                {
                  _.keys(components)
                    .map((component, index) =>
                      <Grid
                        className={classes.componentItem}
                        key={index}
                        item
                        container
                        justify='space-between'
                        xs={6}
                      >
                        <Grid item>
                          <strong>{ t(`${componentsHashMap[component]}`) }</strong>
                          <br />
                          <span className={classes.greyText}>{t('service_is', { status: getStatusLabel(components[component].status) })}</span>
                        </Grid>
                        <Grid item className={classes.iconContainer}>
                          {
                            status === 'UP'
                              ? <CheckIcon className={`${classes.smallIcon} ${classes.successColor}`} />
                              : <WarningIcon className={`${classes.smallIcon} ${classes.warningColor}`} />
                          }
                        </Grid>
                      </Grid>
                    )
                }
              </Grid>
              <Grid container className={classes.statusContainer}>
                <Grid container item justify='center' alignItems='center' xs={6}>
                  <WarningIcon className={`${classes.smallIcon} ${classes.warningColor} ${classes.marginRight}`} /> <span>{t('service_is_degraded')}</span>
                </Grid>
                <Grid container item justify='center' alignItems='center' xs={6}>
                  <CheckIcon className={`${classes.smallIcon} ${classes.successColor} ${classes.marginRight}`} /> <span>{t('service_is_up')}</span>
                </Grid>
              </Grid>
            </>
          )
        : null
      }
      </>
      }
    </Grid>
  );
};
