import React, { useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Typography } from '@material-ui/core';
import { FileAddLine, TrashFillIcon } from 'common/Icons';
import { useAuthUser, useDialog } from 'hooks';
import { getHighestRole } from 'utils/roles';
import { useSnackSetState } from 'contexts/SnackContext';
import { associatePlan, cancelAccountPlan, fetchActivePlan } from 'entities/accounts/accounts.api';
import DialogButtons from 'components/DialogButtons';
import MenuActions from 'components/MenuActions';
import AddPlanForm from '../AddPlanForm/AddPlanForm';
import { MENU_KEYS } from './constants';
import makesStyles from './ActionsMenu.style';
import { getServerError } from 'pages/pages.utils';
import { isDefined } from 'utils/types';
const useStyles = makesStyles();

export default function ActionsMenu ({ plans, accountId, linkedPlan, accountName, activePlan }) {
  const classes = useStyles();
  const [t, i18n] = useTranslation();
  const { auth } = useAuthUser();
  const setSnack = useSnackSetState();
  const submitButtonRef = useRef();
  const disabled = !(plans.length > 0);
  const planMode = isDefined(linkedPlan);

  const options = React.useMemo(() => {
    const roles = auth.roles.map((role) => ({ name: role }));
    const highestRole = getHighestRole(roles);
    return [
      {
        key: MENU_KEYS.ASSIGN_PLAN,
        placeholder: t('assign_plan'),
        roles: ['analyst', 'admin'],
        visible: true,
        planMode: !planMode,
        onClick: () => {
          onDisplayDialog({ isOpen: true });
        }
      },
      {
        key: MENU_KEYS.CANCEL_PLAN,
        placeholder: t('cancel_plan'),
        roles: ['analyst', 'admin'],
        visible: true,
        planMode: planMode && activePlan,
        onClick: () => {
          cancelPlanDialog({ isOpen: true });
        }
      }
    ].filter(
      (option) => option.roles.includes(highestRole.roleName) && option.visible && option.planMode
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, i18n.language]);

  const associateNewPlan = (formState) => {
    const payload = {
      start_date: formState.startDate,
      end_date: formState.endDate,
      plan: formState.plan,
      overage_allowed: !formState.enforcePlanLimit
    };
    return associatePlan(formState.accountid, payload);
  };

  const onSubmit = (plan) => {
    associateNewPlan(plan)
      .then(() => {
        setSnack({
          isOpen: true,
          message: t('plan_has_been_associated_to_account'),
          title: t('plan_association_successful'),
          severity: 'success'
        });
        onDisplayDialog({ isOpen: false });
      })
      .catch(error => {
        const errors = _.get(error, 'response.data.errors');
        const errorMsg = _.values(errors);
        setSnack({
          isOpen: true,
          message: errorMsg[0] ? errorMsg[0] : t('if_error_persists_contact_administrator'),
          title: t('plan_association_has_failed'),
          severity: 'error'
        });
      });
  };

  const onDisplayDialog = useDialog(`addplan-dialog-${accountId}`, {
    onClose: () => {
      onDisplayDialog({ isOpen: false });
    },
    components: {
      Icon: <FileAddLine className={classes.dialogIconSize} />,
      Title: (
        <Typography variant="h5" align="center">
          {t('assign_a_plan')}
        </Typography>
      ),
      ContentText: (<>
          <Typography variant="body2" align="center">
            {t('select_an_existing_plan_to_assign_to_this_account')}
          </Typography>
          <Typography variant="body2" align="center">
            {t('you_can_assign_multiple_plans_to_same_account_no_overlap')}
        </Typography>
        </>
      ),
      Content: (
        <AddPlanForm
          submitButtonRef={submitButtonRef}
          onSubmit={onSubmit}
          plans={plans}
          accountId={accountId}
        />
      ),
      Actions: (
        <div className={classes.dialogButtonsWrapper}>
          <DialogButtons
            confirmLabel={t('assign_plan')}
            cancelLabel={t('cancel')}
            onConfirm={() => submitButtonRef.current.click()}
            onCancel={() => onDisplayDialog({ isOpen: false })}
            setDisabled={disabled}
          />
        </div>
      )
    }
  });

  const onCancelPlan = () => {
    fetchActivePlan(accountId)
      .then(plan => {
        cancelAccountPlan(accountId, plan.id)
          .then(() => {
            setSnack({
              isOpen: true,
              title: t('plan_succesfully_canceled'),
              severity: 'success'
            });
            // refreshTable();
          })
          .catch(err => {
            setSnack({
              isOpen: true,
              title: getServerError(err?.response?.data),
              severity: 'error'
            });
          });
      });
    cancelPlanDialog({ isOpen: false });
  };

  const cancelPlanDialog = useDialog(`cancelplan-dialog-${accountId}`, {
    onClose: () => {
      cancelPlanDialog({ isOpen: false });
    },
    components: {
      Icon: <TrashFillIcon className={`${classes.dialogIconSize} ${classes.dialogIconError}`} />,
      Title: (
        <Typography variant="h5" align="center">
          <Trans i18nKey="are_you_sure_you_want_to_cancel_plan">Are you sure you want to cancel plan
           <strong>{{ linkedPlan: linkedPlan?.name }}</strong>
           from <strong>{{ account_name: accountName }}</strong> ?
          </Trans>
        </Typography>
      ),
      ContentText: (<>
          <Typography variant="body2" align="center">
            {t('the_account_will_be_without_a_plan')}
          </Typography>
        </>
      ),
      Actions: (
        <div className={classes.dialogButtonsWrapper}>
          <DialogButtons type='warning'
            confirmLabel={t('yes_cancel_plan')}
            cancelLabel={t('no')}
            onConfirm={onCancelPlan}
            onCancel={() => cancelPlanDialog({ isOpen: false })}
          />
        </div>
      )
    }
  });

  return options.length > 0 ? <MenuActions options={options} /> : null;
}

ActionsMenu.propTypes = {
  plans: PropTypes.array,
  accountId: PropTypes.string,
  linkedPlan: PropTypes.obj,
  accountName: PropTypes.string,
  activePlan: PropTypes.bool
};
