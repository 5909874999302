import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import LinkedPlan from './components/LinkedPlan';
import ScansColumn from './components/ScansColumn';
import UsersColumn from './components/UsersColumn';
import ActionsMenu from './components/ActionsMenu';
import ActivePlanStatus from './components/ActivePlanStatus';
import { ROUTE_PATHS } from 'utils/routes.constants';
import tableConfig from 'common/tableConfig';

const GetAccountNameColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('name'),
    field: 'name',
    width: '5%',
    cellStyle: {
      ...tableConfig.options.cellStyle,
      maxWidth: 300,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      border: 'none'
    },
    render: function AccountName (rowData) {
      // avoiding fetch account general info from API
      return <Link to={{
        pathname: generatePath(ROUTE_PATHS.ACCOUNT_INFO, { id: rowData.id }),
        state: rowData
      }}>{rowData.name}</Link>;
    }
  };
};

const GetAccountIdColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('id'),
    field: 'id',
    width: '2.5%'
  };
};

const GetAccountEmailAddressColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('email_address'),
    field: 'email',
    width: '5%'
  };
};

const GetAccountPlanColumn = (plan) => {
  const [t] = useTranslation();
  return {
    title: t('plan'),
    field: 'plan',
    width: '5%',
    // eslint-disable-next-line react/display-name
    render: rowData => {
      if (plan) {
        const activePlan = plan.id === rowData.current_plan;
        return <ActivePlanStatus activePlan={activePlan} />;
      } else {
        return <LinkedPlan accountId={rowData.id} currency={rowData.currency} />;
      };
    }
  };
};

const GetUsersColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('users'),
    field: 'users',
    width: '5%',
    render: rowData => {
      return <UsersColumn accountId={rowData.id} users={rowData.matchedUsers}/>;
    }
  };
};

const GetScansColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('scans'),
    field: 'scans',
    width: '5%',
    render: rowData => {
      return <ScansColumn accountId={rowData.id} />;
    }
  };
};

const GetAccountCurrencyColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('currency'),
    field: 'currency',
    width: '5%'
  };
};

const GetMenuColumn = (plans, plan) => {
  const [t] = useTranslation();
  return {
    title: t('actions'),
    width: '1%',
    sorting: false,
    // eslint-disable-next-line react/display-name
    render: function actionMenu (rowData) {
      const activePlan = plan?.id === rowData.current_plan;
      return <ActionsMenu plans={plans} accountId={rowData.id} linkedPlan={plan} accountName={rowData.name} activePlan={activePlan} />;
    }
  };
};

export const getColumns = (plans, plan) => {
  return [
    GetAccountNameColumn(),
    GetAccountIdColumn(),
    GetAccountEmailAddressColumn(),
    GetAccountPlanColumn(plan),
    GetUsersColumn(),
    GetScansColumn(),
    GetAccountCurrencyColumn(),
    GetMenuColumn(plans, plan)
  ];
};
