import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Loading from 'common/Loading';
import useAppConfigContext from 'contexts/AppConfigContext/useAppConfigContext';
import { fetchAccount } from 'entities/accounts/accounts.api';
import { fetchSelfUser } from 'entities/users/users.api';
import UsersTable from './components/Users';
import AccountDetails from './components/Details';
import Tabs from './components/Tabs';
import AccountPlan from './components/AccountPlan';
import { ROUTE_PATHS } from 'utils/routes.constants';
import makeStyles from './AccountInfo.style';

const useStyle = makeStyles();
const myTabs = [
  'users',
  'account_details',
  'plan'
];

export default function AccountInfo () {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const classes = useStyle({});
  const { user = {} } = useAppConfigContext();
  const location = useLocation();
  const locationArray = location.pathname.split('/');
  const accountIdFromPath = useMemo(() => locationArray.length > 2 ? locationArray[locationArray.length - 1] : null, [locationArray]);
  const accountId = useMemo(() => accountIdFromPath ?? user.account, [accountIdFromPath, user.account]);
  const [pageHeaderName, setPageHeaderName] = useState(() => location.state ? location.state.name : '');
  const [currency, setCurrency] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState();

  const getAccount = async (id) => {
    fetchAccount(id)
      .then(response => {
        setAccount(response.data);
        setPageHeaderName(response?.data?.name);
        setCurrency(response?.data?.currency);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!location.state) {
      if (accountId) {
        getAccount(accountId);
      } else {
        fetchSelfUser()
          .then(response => {
            getAccount(response.data?.account);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
  <Grid item xs='12'>
    {isLoading && <Loading size={50}/>}
    {!isLoading && <>
      {accountIdFromPath && <Button
        onClick={() => navigate(ROUTE_PATHS.ACCOUNTS)}
        className={classes.navigationButton}
        startIcon={<ArrowBackIcon />}
      >
        {t('back_to_accounts')}
      </Button>}
      <div className={classes.headerContainer}>
        <Typography variant="h4" component="span" gutterBottom>{t('account_details_for', { account_name: `${pageHeaderName}` })}</Typography>
        <Typography>{t('see_below_account_details')} </Typography>
      </div>
      <Tabs centered tabs={myTabs}>
        {activeIndex => <>
          {activeIndex === 0 && <UsersTable accountId={account?.id}/>}
          {activeIndex === 1 && <AccountDetails account={account}/>}
          {activeIndex === 2 && <AccountPlan accountId={account?.id} currency={currency} />}
        </>}
      </Tabs>
    </>}
  </Grid>);
}
