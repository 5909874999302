export const STATUS_CODE = {
  GET: 'GET',
  200: '200 OK',
  201: '201 CREATED',
  204: '204 NO CONTENT',
  304: '304 NOT MODIFIED',
  400: '400 BAD REQUEST',
  401: '401 UNAUTHORIZED',
  403: '403 FORBIDDEN',
  404: '404 NOT FOUND',
  409: '409 CONFLICT',
  500: '500 INTERNAL SERVER ERROR',
  503: '503 SERVICE UNAVAILABLE',
  504: '504 GATEWAY TIMEOUT'
};

export const COLOR_RESPONSES = {
  POSITIVE: '#30B043',
  NEUTRAL: 'grey',
  NEGATIVE: '#FA4B54'
};
