import { useContext } from 'react';
import { AppConfigContext } from './AppConfigContext';

export default function useAppConfigContext () {
  const context = useContext(AppConfigContext);
  if (context === undefined) {
    throw new Error('AppConfigContext must be used within a AppConfigContext.Provider');
  }

  return context;
}
