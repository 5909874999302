import { deleteTag } from 'entities/tags/tags.api';

export const deleteUnusedTags = async (files, urls, tags) => {
  const allFileTags = files.flatMap((file) => file.tags.map((tag) => tag.id));
  const allUrlTags = urls.flatMap((url) => url.tags.map((tag) => tag.id));

  const tagsToDelete = tags.filter(
    (tag) => !allFileTags.includes(tag.id) && !allUrlTags.includes(tag.id)
  );

  if (tagsToDelete.length > 0) {
    const deleteTagPromises = tagsToDelete.map(async (tag) => {
      await deleteTag(tag.id);
    });

    await Promise.resolve(Promise.all(deleteTagPromises));
  }
};
