import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import CommonTable from '../CommonTable';
import { COLUMNS, DATE_FORMAT } from './History.constants';
import { iterateLikeStack } from './History.utils';
import { propTypes } from './History.props';
import { getAnalysisStatus } from 'entities/analysis/analysis.utils';

const AnalysisTable = ({ rows = [] }) => {
  const [t] = useTranslation();
  const data = [];

  iterateLikeStack(rows, (row) => {
    data.push(row);
  });
  return (
    <CommonTable
      title={t('history')}
      columns={COLUMNS}
      data={data.map(row => ({
        ...row,
        created_at: dayjs(row.created_at).format(DATE_FORMAT),
        safety: getAnalysisStatus(row.safety, row.status)
      }))}
    />
  );
};

AnalysisTable.propTypes = propTypes;

export default AnalysisTable;
