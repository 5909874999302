import React from 'react';
import { Grid, Tabs, Tab } from '@material-ui/core';
import TabPanel from './components/TabPanel';
import { propTypes } from './ProbeTabs.props';
import makeStyles from './ProbeTabs.styles';

const useStyles = makeStyles();

export default function ProbeTabs ({ tabs, children }) {
  const classes = useStyles();
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const handleChange = (event, newValue) => {
    setActiveTabIndex(newValue);
  };

  return (
    <Grid container>
      <Grid xs={2}>
        <Tabs
          orientation='vertical'
          value={activeTabIndex}
          onChange={handleChange}
          className={classes.tabs}>
          {
            tabs.map(tab => <Tab key={`tab-${tab}`} label={tab} classes={{
              root: classes.tab,
              selected: classes.tabSelected
            }} />)
          }
        </Tabs>
      </Grid>
      <Grid item xs={10}>
        <TabPanel value={tabs[activeTabIndex]} key={`label-${tabs[activeTabIndex]}`} index={tabs[activeTabIndex]}>
          { children(activeTabIndex) }
        </TabPanel>
      </Grid>
    </Grid>
  );
};

ProbeTabs.propTypes = propTypes;
