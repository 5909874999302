import React from 'react';
import { useTranslation } from 'react-i18next';
import { URLGlobe } from 'common/Icons';
import { ListElement, ListElementIcon, ListElementText } from 'components/List';
import ScanStatus from 'common/scan/ScanStatus';
import { propTypes } from './Row.props';
import makeStyles from '../styles';

const useStyles = makeStyles();

const Row = ({ analysis, statusError }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <ListElement divider>
      <ListElementIcon className={classes.listItemIcon}>
        <URLGlobe className={classes.iconSize} viewBox="0 0 24 20" title={t('url_analysis')} />
      </ListElementIcon>
      <ListElementText
        primary={analysis.target.url}
        className={classes.listItemText} />
      <ListElementText
        className={classes.listItemStatus}
        classes={{
          primary: classes.listTextPrimaryStatus
        }}
        primary={<ScanStatus status={statusError ?? analysis.status.toLocaleLowerCase()} />}
      />
    </ListElement>
  );
};

Row.propTypes = propTypes;

export default Row;
