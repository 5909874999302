import React, { useState, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import BaseLayout from 'layouts/BaseLayout';
import { useSnackSetState } from 'contexts/SnackContext';
import { verifyEmail } from 'entities/auth/auth.api';
import Loading from 'common/Loading';

const initState = {
  loading: false,
  data: null,
  error: null
};

const ACTIONS = {
  FETCH: 'fetch',
  SUCCESS: 'success',
  FAIL: 'fail'
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.FETCH: return { ...state, loading: true };
    case ACTIONS.SUCCESS: return { ...state, loading: false, data: action.payload };
    case ACTIONS.FAIL: return { ...state, loading: false, error: action.payload };
  }
};

export default function RecoveryPassword (props) {
  const [t] = useTranslation();
  const setSnack = useSnackSetState();
  const [email, setEmail] = useState('');
  const [recovery, dispatch] = useReducer(reducer, initState);
  const emailLabel = t('email');
  const sendEmail = async () => {
    try {
      dispatch({ type: ACTIONS.FETCH });
      const response = await verifyEmail(email);
      if (response.data) {
        dispatch({ type: ACTIONS.SUCCESS, payload: response.data });
        setSnack({
          isOpen: true,
          message: t('if_account_exists_email_sent'),
          title: t('great!'),
          severity: 'success'
        });
      }
    } catch (error) {
      dispatch({ type: ACTIONS.FAIL, payload: error.data });
      setSnack({
        isOpen: true,
        message: t('something_went_wrong.'),
        title: t('error!'),
        severity: 'error'
      });
    }
  };

  const onsubmithandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    sendEmail();
  };

  return (
    <BaseLayout>
      <Grid container justify="center" alignItems="center">
        <Grid item md={5}>
        { !recovery.loading && <form onSubmit={onsubmithandler}>
          <Typography>{t('recover_your_account')}</Typography>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-email">
              {emailLabel}
            </InputLabel>

            <OutlinedInput
              margin="normal"
              required
              fullWidth
              name="email"
              labelWidth={70}
              type="text"
              autoFocus
              id="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            data-test="recovery-submit"
          >
            {t('send_recovery_link')}
          </Button>
        </form>}
        {
          recovery.loading && <Loading size={100} />
        }
        </Grid>
      </Grid>

    </BaseLayout>
  );
}
