import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Grid, FormHelperText } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Loading from 'common/Loading';
import { CheckIcon } from 'common/Icons';
import { updatePassword } from 'entities/auth/auth.api';
import { useSnackSetState } from 'contexts/SnackContext';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'utils/routes.constants';
import makeStyles from './PasswordForm.styles';

const useStyles = makeStyles();

export default function PasswordForm () {
  const [t] = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setPasswordVisibility] = useState(false);
  const [error, setError] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const setSnack = useSnackSetState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPasswordMatch(password === confirmPassword && confirmPassword !== '');
    setError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, confirmPassword]);

  const handleClickShowPassword = () => {
    setPasswordVisibility(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onsubmithandler = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (passwordMatch) {
      setLoading({ idle: true });
      try {
        const response = await updatePassword({
          new_password: password
        });
        if (response) {
          setLoading(false);
        }
        setSnack({
          isOpen: true,
          message: t('your_password_has_been_changed_successfully'),
          title: t('great!'),
          severity: 'success'
        });
        navigate(ROUTE_PATHS.SIGNIN);
      } catch (error) {
        setLoading(false);
        setSnack({
          isOpen: true,
          message: t('password_could_not_be_changed'),
          title: t('error!'),
          severity: 'error'
        });
      }
    } else {
      setError(true);
    }
  };

  return (
    <div style={{ marginBottom: '50px' }}>
      <Typography variant="h5" component="span" gutterBottom><strong>{t('password')}</strong></Typography>
      <Grid container justify='space-between' spacing={5}>
        <Grid item xs={6} >
          <Typography variant="body1" component="span" gutterBottom className={classes.passwordDescription}>{t('you_can_change_your_password')}</Typography>
        </Grid>
        <Grid item xs={6} >
          { loading && <Loading size={50} /> }
          { !loading && <form onSubmit={onsubmithandler}>
            <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">{t('new_password')}</InputLabel>
                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t('new_password')}
                  labelWidth={70}
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-confirm-password">{t('confirm_password')}</InputLabel>
                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  name="confirm-password"
                  label={t('confirm_password')}
                  labelWidth={70}
                  type={showPassword ? 'text' : 'password'}
                  id="confirm-password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                  error={error}
                  helperText={error ? 'passwords_dont_match' : ''}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                { error ? <FormHelperText error>{t('passwords_dont_match')}</FormHelperText> : null }
                { passwordMatch ? <FormHelperText className={classes.matchPassword} ><CheckIcon size={12} color={classes.matchPassword} style={{ marginRight: '5px' }} />{t('password_match')}</FormHelperText> : null }
              </FormControl>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                data-test="password-in-submit"
                disabled={false}
                className={classes.submitButton}
              >
                {t('change_password')}
              </Button>
            </form>
          }
        </Grid>
      </Grid>
    </div>
  );
};
