import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  container: {
    marginBottom: '60px'
  },
  doughnutContainer: {
    marginBottom: '60px',
    border: '1px solid',
    borderColor: theme.palette.text.secondary + 40,
    borderRadius: '5px',
    padding: '30px',
    paddingBottom: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '23px'
  },
  header: {
    marginBottom: '30px'
  },
  doughnut: {
    height: '140px',
    width: '200px',
    display: 'flex',
    justifyContent: 'center'
  },
  ulLegend: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    height: '100%'
  },
  liLegend: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    margin: '7px'
  },
  labelLegend: {
    margin: 0,
    padding: 0
  },
  boxSpan: {
    display: 'inline-block',
    height: '12px',
    marginRight: '10px',
    width: '12px',
    borderRadius: '2px'
  }
}));
