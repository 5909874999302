import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { URLGlobe } from 'common/Icons';
import makeStyles from './Panel.styles';

const useStyles = makeStyles();

const Panel = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const title = t('scan_finished');

  return (
    <Grid container className={classes.banner} direction="row">
      <Grid item xs={2} className={classes.bannerIcon}>
        <URLGlobe />
      </Grid>
      <Grid item className={classes.bannerText} xs={10}>
        <Typography
          variant="h5"
          color="primary"
          className={classes.bannerTitle}
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Panel;
