import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { getFlatList } from 'pages/Details/Details.utils';
import CellFallbackView from '../CellFallbackView/CellFallbackView';
import { COLUMNS } from './FormatIdentificationTable.constants';
import { propTypes } from './FormatIdentificationTable.props';
import makeStyles from '../../styles';

const useStyles = makeStyles();

const FormatIdentificationTable = ({ data = [] }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const cellProps = {
    className: classes.cell,
    component: 'th',
    scope: 'row'
  };

  const rows = useMemo(() => getFlatList(data), [data]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={10}>
        <div className={classes.fileInfoHeader}>
          <Typography variant="h4" className={classes.panelTitle}>
            {t('format_identification')}
          </Typography>
        </div>
        <Table className={classes.table} size="medium" aria-label={t('format_identification')}>
          <TableHead>
            <TableRow>
              {COLUMNS.map(col => <TableCell className={classes.headerCell} key={col.field} align="left">{t(col.headerName)}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(({ id, probeName, format, confidence, description }) => (
              <TableRow key={id}>
                <CellFallbackView {...cellProps} condition={probeName}>
                  {probeName}
                </CellFallbackView>
                <CellFallbackView {...cellProps} condition={format}>
                  {format}
                </CellFallbackView>
                <CellFallbackView {...cellProps} condition={confidence}>
                  {Math.round(confidence * 1000) / 10}%
                </CellFallbackView>
                <CellFallbackView {...cellProps} condition={description}>
                  {description}
                </CellFallbackView>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

FormatIdentificationTable.propTypes = propTypes;

export default FormatIdentificationTable;
