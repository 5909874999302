import React from 'react';
import { Button } from '@material-ui/core';
import { propTypes } from './ButtonFilter.props';

export default function ButtonFilter ({ children, id, period, setPeriod, ...props }) {
  const handleClick = () => {
    setPeriod(id);
  };
  const active = id === period;
  return <Button {...props} variant={active ? 'contained' : props.variant} color={active ? 'secondary' : props.color} onClick={handleClick}>{children}</Button>;
}

ButtonFilter.propTypes = propTypes;
