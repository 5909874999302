import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@material-ui/core';
import { CloseMdIcon } from 'common/Icons';
import Row from './components/Row';
import { propTypes } from './DeletionStatus.props';
import makeStyles from './DeletionStatus.style';

const useStyles = makeStyles();

const DialogContent = ({ files, onClose, onSuccess }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [removedCount, setRemovedCount] = useState(0);

  const handleOnDelete = useCallback(() => {
    setRemovedCount(prev => prev + 1);
  }, []);

  const handleOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (files.length > 0 && files.length === removedCount) {
      onSuccess(removedCount);
    }
  }, [removedCount, files, onSuccess]);

  return (
    <div className={classes.container}>
      {files.length > 1 && <p className={classes.subTitle}>{t('files')}</p>}
      {files.map(file => (
        <Row key={file.id} file={file} onDelete={handleOnDelete} />
      ))}
      <Link className={classes.closeButton} onClick={handleOnClose} data-test-id='closeLink'>
        <CloseMdIcon color="#000" size={18} />
      </Link>
    </div>
  );
};

DialogContent.propTypes = propTypes;

export default DialogContent;
