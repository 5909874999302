import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, IconButton, Tooltip, Tabs, Tab } from '@material-ui/core';
import { getServerError } from 'pages/pages.utils';
import { useSnackSetState } from 'contexts/SnackContext';
import { updateProbe as fetchUpdate } from 'entities/probes/probes.api';
import Alert from 'components/Alert/Alert';
import Loading from 'common/Loading';
import ProbeModel from 'common/probes/models/ProbeModel';
import StoreCard from 'common/probes/StoreCard';
import BackToTopButton from 'components/BackToTopButton';
import { fetchCatalogProbes, refreshCatalog, DEFAULT_CATALOG } from 'entities/catalogs/catalogs.api';
import { fetchSelfUser } from 'entities/users/users.api';
import { isPlatformAdmin } from 'entities/users/users.utils';
import PageHeader from 'pages/components/PageHeader';
import NoProbesAvailable from './components/NoProbesAvailable';
import { ROUTE_PATHS } from 'utils/routes.constants';
import { capitalizeFirstLetter } from 'utils/string';
import { Filter, FilterOff, Refresh } from 'common/Icons';
import { getTabsPositions, handleTabsScroll } from './StoreView.utils';
import ActionsMenu from './components/ActionsMenu';
import FilterBox from './components/FilterBox';
import makeStyles from './styles';

const useStyles = makeStyles();

export default function StoreView () {
  const [t] = useTranslation();
  const setSnack = useSnackSetState();
  const [filterLocals, setFilterLocals] = useState(false);
  const [isFetching, setIsfetching] = useState(true);
  const [data, setData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate();
  const [probesList, setProbesList] = useState([]);
  const [unfilteredProbesList, setUnfilteredProbesList] = useState([]);
  const classes = useStyles();
  const [isRefreshing, setisRefreshing] = useState(false);
  const [tabsPositions, setTabsPositions] = useState([]);
  const [enabledFilter, setEnabledFilter] = useState(false);

  const initialFilterQuery = {
    status: '',
    access_mode: '',
    name: '',
    type: '',
    category: ''
  };

  const [filterQuery, setFilterQuery] = useState(initialFilterQuery);
  const [categoriesToFilter, setCategoriesToFilter] = useState([]);

  useEffect(() => {
    fetchSelfUser()
      .then(response => {
        const user = response.data;
        if (!isPlatformAdmin(user)) {
          navigate(ROUTE_PATHS.FORBIDDEN);
        }
      });
    // To be removed after implementing new filters with dedicated area
    setFilterLocals(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCatalogProbes(DEFAULT_CATALOG)
      .then(res => {
        setIsfetching(false);
        if (res.data?.length > 0) {
          setData(res.data.sort((a, b) => a.info.display_name.localeCompare(b.info.display_name)));
          setProbesList(res.data.sort((a, b) => a.info.display_name.localeCompare(b.info.display_name)).map(d => new ProbeModel(d)));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tabsPositions.length === 0) {
      setTabsPositions(getTabsPositions);
    };
  }, [tabsPositions]);

  useEffect(() => {
    const handleScroll = () => handleTabsScroll(tabsPositions, selectedTab, setSelectedTab);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [tabsPositions, selectedTab]);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleOnRefresh = () => {
    setisRefreshing(true);
    refreshCatalog(DEFAULT_CATALOG)
      .then(() => fetchCatalogProbes(DEFAULT_CATALOG)).then(() => setisRefreshing(false));
  };

  const toggleFilter = () => {
    if (categoriesToFilter.length === 0) {
      setCategoriesToFilter(Object.keys(probesListByCategory).sort());
    };
    if (!enabledFilter && unfilteredProbesList.length === 0) {
      setUnfilteredProbesList(probesList);
    };
    if (enabledFilter) {
      setProbesList(unfilteredProbesList);
      setFilterQuery(initialFilterQuery);
    };
    setEnabledFilter(!enabledFilter);
  };

  const areAllDisabled = () => {
    return probesList.every(prob => !prob.isEnabled()) &&
    unfilteredProbesList.every(prob => !prob.isEnabled()) &&
    !isFetching;
  };

  const onClickCard = (probe) => {
    navigate(`/probes/store/${probe.id}`);
  };

  const probesListByCategory = probesList.reduce((result, probe) => {
    const category = probe.info.category;

    if (!result[category]) {
      result[category] = [];
    }

    result[category].push(probe);

    return result;
  }, {});

  const updateProbe = (action, probe) => {
    setProbesList(oldProbesList => {
      switch (action) {
        case 'enable': return oldProbesList.map(oldProbe => {
          if (probe.id === oldProbe.id) {
            const probeFromResponse = data.find(d => d.info.display_name === probe.info.display_name);
            const updatedProbe = new ProbeModel(probeFromResponse);
            updatedProbe.toEnable();
            return updatedProbe;
          }

          return oldProbe;
        });
        case 'disable': return oldProbesList.map(oldProbe => {
          if (probe.id === oldProbe.id) {
            const probeFromResponse = data.find(d => d.info.display_name === probe.info.display_name);
            const updatedProbe = new ProbeModel(probeFromResponse);
            updatedProbe.toDisable();
            return updatedProbe;
          }

          return oldProbe;
        });
        case 'restrictAccess': return oldProbesList.map(oldProbe => {
          if (probe.id === oldProbe.id) {
            const probeFromResponse = data.find(d => d.info.display_name === probe.info.display_name);
            const updatedProbe = new ProbeModel(probeFromResponse);
            updatedProbe.enabledVersion = { ...probe.enabledVersion };
            updatedProbe.restrictAccess();
            return updatedProbe;
          }

          return oldProbe;
        });
        case 'fullAccess': return oldProbesList.map(oldProbe => {
          if (probe.id === oldProbe.id) {
            const probeFromResponse = data.find(d => d.info.display_name === probe.info.display_name);
            const updatedProbe = new ProbeModel(probeFromResponse);
            updatedProbe.enabledVersion = { ...probe.enabledVersion };
            updatedProbe.fullAccess();
            return updatedProbe;
          }

          return oldProbe;
        });
        case 'update': return oldProbesList.map(oldProbe => {
          // becareful with this version
          if (probe.id === oldProbe.latest.id) {
            const probeFromResponse = data.find(d => d.info.display_name === oldProbe.info.display_name);
            const updatedProbe = new ProbeModel(probeFromResponse);
            updatedProbe.toUpdate();
            return updatedProbe;
          }

          return oldProbe;
        });
      }
    });
  };

  const updateProbes = (action) => {
    const enableProbe = action === 'enable_all_probes';

    probesList.filter(probe => {
      return enableProbe ? !probe.isEnabled() : probe.isEnabled();
    }).forEach(probe => {
      updateProbeStatus(probe, enableProbe);
    });
  };

  const updateProbeStatus = async (probe, enableProbe) => {
    try {
      const response = await fetchUpdate(probe.id, { enabled: enableProbe });
      if (response.status === 200) {
        setSnack({
          isOpen: true,
          title: !enableProbe ? t('disable_a_probe') : t('enable_a_probe'),
          message: !enableProbe ? t('probe_was_disabled_successfully', { name: probe.info.name }) : t('probe_was_enabled_successfully', { name: probe.info.name }),
          severity: 'success'
        });
        updateProbe(enableProbe ? 'enable' : 'disable', probe);
      };
    } catch (error) {
      const errorMsg = probe.isEnabled() ? t('could_not_disable_probe', { name: probe.info.name }) : t('could_not_enable_probe', { name: probe.info.name });
      setSnack({
        isOpen: true,
        title: t('something_went_wrong'),
        message: getServerError(error.data, errorMsg),
        severity: 'error'
      });
    }
  };

  const handleFilter = (type, value) => {
    const updatedFilterQuery = { ...filterQuery };
    updatedFilterQuery[type] = value;
    setFilterQuery(updatedFilterQuery);

    const filterFunctions = {
      name: probe => probe.info.display_name.toLowerCase().startsWith(updatedFilterQuery.name.toLowerCase()),
      status: probe => (updatedFilterQuery.status === 'enabled' ? probe.isEnabled() : !probe.isEnabled()),
      target_type: probe => updatedFilterQuery.target_type === 'url' ? probe.info.target_type === 'URL' : probe.info.target_type === 'FILE',
      access_mode: probe => probe.enabledVersion ? (updatedFilterQuery.access_mode === 'restricted') === !probe.enabledVersion.selected : false,
      category: probe => probe.info.category === updatedFilterQuery.category
    };

    setProbesList(unfilteredProbesList.filter(probe => {
      return Object.keys(updatedFilterQuery).every(key => {
        return updatedFilterQuery[key] !== '' ? filterFunctions[key](probe) : true;
      });
    }));
  };

  const classesItem = {
    root: classes.rootItemCard
  };

  return (
    <Grid classes={{ root: classes.probesContainer }}>
      <Grid container justify='space-between'>
        <Grid item xs={11}>
          <PageHeader title={t('probes')}>
            {areAllDisabled() && (
              <Alert type={Alert.TYPES.WARNING} style={{ marginRight: '10px' }}>
                {t('scan_is_not_available_as_no_probes_are_enabled')}
              </Alert>
            )}
            {isRefreshing
              ? <Loading size={20} />
              : <Tooltip title={t('check_for_updates')} arrow>
                  <IconButton size={'small'} color='secondary' classes={{ root: classes.refreshBtn }} onClick={handleOnRefresh} >
                    <Refresh className={classes.refreshIcon} size={24}/>
                  </IconButton>
                </Tooltip>
            }
            {
              <Tooltip title={t('filter')} arrow>
                <IconButton size={'small'} color='secondary' classes={{ root: classes.refreshBtn }} onClick={toggleFilter} >
                  { enabledFilter ? <FilterOff className={classes.refreshIcon} size={24}/> : <Filter className={classes.refreshIcon} size={24}/> }
                </IconButton>
              </Tooltip>
            }
          </PageHeader>
        </Grid>
        <Grid item xs={1}>
          <ActionsMenu action={updateProbes} />
        </Grid>
        { enabledFilter &&
          <Grid item>
            <FilterBox filter={handleFilter} categories={categoriesToFilter}/>
          </Grid>
        }
        <Grid item align="right" alignItems="center">
          <BackToTopButton/>
        </Grid>
      </Grid>
      { isFetching && <Loading align="center" size={75} /> }
      { (!isFetching && probesList.length > 0) && (
        <>
          <Tabs
            id='category-tabs'
            value={selectedTab}
            onChange={handleChangeTab}
            indicatorColor='primary'
            textColor='primary'
            style={{ position: 'fixed', width: '100%', zIndex: 1000, backgroundColor: '#FFFFFF' }}
            variant='scrollable'
            scrollButtons='auto'>
            {
              Object.keys(probesListByCategory).sort()
                .map(category => (
                  <Tab label={category} key={category} href={`#${category}`}/>
                ))
            }
          </Tabs>
          <div className={classes.probecardsContainer}>
            { // display locals/enabled probes
              filterLocals && Object.entries(probesListByCategory).sort()
                .filter(([category, probes]) => probes.some(probe => probe.isEnabled())
                ).map(([category, probes]) => (
                <>
                  <Typography variant="h5" component="p" className={classes.categoryName}>
                    <strong>{capitalizeFirstLetter(t(category))}</strong>
                  </Typography>
                  <Grid container justify="flex-start" spacing={2}>
                    { probes.filter(probe => {
                      return probe.isEnabled();
                    }).map(probe => (
                      <Grid key={probe.id} item classes={classesItem}>
                        <StoreCard
                          probe={probe}
                          action={updateProbe}
                          onCardAction={onClickCard}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
                ))
            }
            { // display all probes
              !filterLocals && Object.entries(probesListByCategory).sort().map(([category, probes]) => (
                <>
                  <Typography variant="h5" component="p" id={category} className={`${classes.categoryName} category-tab`}>
                    <strong>{capitalizeFirstLetter(t(category))}</strong>
                  </Typography>
                  <Grid container justify="flex-start" spacing={2}>
                    { probes.map(probe => (
                      <Grid key={probe.id} item classes={classesItem}>
                        <StoreCard
                          probe={probe}
                          action={updateProbe}
                          onCardAction={onClickCard}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              ))
            }
          </div>
        </>
      )}
      { (!isFetching && probesList.length === 0) && <NoProbesAvailable onRefresh={handleOnRefresh}/> }
    </Grid>
  );
};
