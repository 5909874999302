const URL = 'URL';
const FILE = 'FILE';

export default class ProbeModel {
  constructor (payloadResponse) {
    this.enabledVersion = payloadResponse.enabled_versions[0] || null;
    this.info = {
      ...payloadResponse.info
    };
    this.licenseInfo = {
      // TODO: temporary work around until backend delete is updated, uncomment info[0]
      ...payloadResponse.info.info[payloadResponse.info.info.length - 1],
      // ...payloadResponse.info.info[0],
      ...payloadResponse.info.readiness
    };
    this.version = this.enabledVersion?.version ?? payloadResponse?.last_version?.version; // use case: a probe is disabled
    this.latest = payloadResponse.last_version;
    this.id = this.enabledVersion?.id ?? payloadResponse?.last_version?.id; // use case: a probe is disabled
  }

  isURLProbeEnabled () {
    return this.info.target_type === URL && this.isEnabled();
  }

  isFileProbeEnabled () {
    return this.info.target_type === FILE && this.isEnabled();
  }

  isEnabled () {
    return !!this.enabledVersion;
  }

  isFullAccess () {
    return this.enabledVersion?.selected;
  }

  isReadyForUpdate () {
    return this.enabledVersion?.id !== this.latest.id;
  }

  toEnable () {
    if (!this.enabledVersion) {
      this.enabledVersion = { ...this.latest, selected: true };
    }
  }

  toDisable () {
    if (this.enabledVersion) {
      this.enabledVersion = null;
    }
  }

  toUpdate () {
    if (this.isReadyForUpdate()) {
      this.enabledVersion = { ...this.latest, selected: this.enabledVersion?.selected };
      this.id = this.latest.id;
      this.version = { ...this.latest.version };
    }
  }

  restrictAccess () {
    if (this.enabledVersion) {
      this.enabledVersion.selected = false;
    }
  }

  fullAccess () {
    if (this.enabledVersion) {
      this.enabledVersion.selected = true;
    }
  }
}
