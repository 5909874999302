import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  appBar: {
    backgroundColor: '#f5f5f500',
    boxShadow: 'none'
  },
  tabLabelAndIcon: {
    display: 'flex',
    flexDirection: 'row',
    '& > div ': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      lineHeight: '18px'
    },
    '& svg': {
      marginRight: 10
    }
  },
  iconTab: {
    alignSelf: 'center'
  }
}));
