import React from 'react';
import { useTranslation } from 'react-i18next';
import { propTypes } from './SubmittedBy.props';

const SubmittedBy = ({ owner }) => {
  const [t] = useTranslation();
  const urlOwner = owner?.traits?.username || t('unknown');

  return (<p>{urlOwner}</p>);
};

SubmittedBy.propTypes = propTypes;

export default SubmittedBy;
