import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { getPercentage } from 'utils/math';
import { propTypes } from './UserLimit.props';
import makeStyles from './UserLimit.styles';

const useStyles = makeStyles();

const UserLimit = ({ usedScans, maxScans, timeUntilReset }) => {
  const progress = getPercentage(usedScans, maxScans);
  const remainingScans = Math.max(maxScans - usedScans, 0);
  const [t] = useTranslation();
  const classes = useStyles({ progress });
  return (
    <Grid container className={classes.container}>
      <div className={classes.progressWrapper}>
        <div className='progressDetails'>
          <p data-test-id='pourcentage' className='percentage'>
            {progress}%
          </p>
          <span data-test-id='description' className='description'>
            {t('scans_used_fraction', { scansFraction: `${usedScans}/${maxScans}` })}
          </span>
        </div>
        <svg className={classes.progress}>
          <circle className='baseCircle' />
          <circle className='progressCircle' />
        </svg>
      </div>
      <div data-test-id='resetInfo' className={classes.resetInfo}>
        {timeUntilReset.measurement === 'hours' ? t('plan_reset_in_hours', { count: timeUntilReset.value }) : t('plan_reset_in_days', { days: timeUntilReset.value })}
      </div>
      <div className={classes.scanInfo}>
        <div data-test-id='usedScans' className={classes.scanCount}>
          <div className='label labelBlue'>{t('used_scans')}</div>
          <div className='count'>{usedScans}</div>
        </div>
        <div data-test-id='remainingScans' className={classes.scanCount}>
          <div className='label labelGray'>{t('remaining_scans')}</div>
          <div className='count'>{remainingScans}</div>
        </div>
      </div>
    </Grid>
  );
};

UserLimit.propTypes = propTypes;

export default UserLimit;
