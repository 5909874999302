import { MIME_TYPES } from '../pages/MyFiles/components/CellMenu/CellMenu.constants';

export const createLinkAndDownload = (href, filename, downloadType) => {
  const link = document.createElement('a');
  link.href = href;
  if (downloadType === MIME_TYPES.APPLICATION.zip) {
    filename = filename.replace(/\.[^/.]+$/, '.zip');
  }
  link.download = filename;
  link.rel = 'noreferrer';
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const createBlobToURL = ({ stream }) => {
  const blob = new Blob([stream]);
  return window.URL.createObjectURL(blob);
};

export const createPDFBlobToURL = ({ stream }) => {
  const blob = new Blob([stream], { type: 'application/pdf' });
  return window.URL.createObjectURL(blob);
};
