import PropTypes from 'prop-types';

export const propTypes = {
  switchInfo: PropTypes.shape({
    text: PropTypes.string,
    tooltip: PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.object
    }),
    onClick: PropTypes.func,
    enabled: PropTypes.bool,
    checked: PropTypes.bool
  })
};
