import React, { useRef, useState } from 'react';
import MaterialTable from 'material-table';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TableLocalization } from 'common/tableConfig';
import { useDialog } from 'hooks';
import { deleteSession, fetchAdminSessions, fetchCurrentSession } from 'entities/sessions/sessions.api';
import { useSnackSetState } from 'contexts/SnackContext';
import { TrashIcon } from 'common/Icons';
import DialogButtons from 'components/DialogButtons';
import { getServerError } from 'pages/pages.utils';
import getTableProps from './ActiveSessionsTable.tableprops';
import { propTypes } from './ActiveSessionsTable.props';
import makeStyles from './ActiveSessionsTable.styles';

const useStyles = makeStyles();

function ActiveSessionsTable ({ userId, onClose }) {
  const [t] = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [activeSessions, setActiveSessions] = useState([]);
  const [currentSession, setCurrentSession] = useState({});
  const [hasActiveSessions, setHasActiveSessions] = useState(false);
  const tableRef = useRef(null);
  const localization = TableLocalization();
  const setSnack = useSnackSetState();
  const refreshTableData = () => tableRef.current.onQueryChange();

  const getTableData = async () => {
    setIsLoading(true);
    try {
      const currSession = await fetchCurrentSession();
      const actSessions = await fetchAdminSessions(userId);
      setCurrentSession(currSession);
      setActiveSessions(actSessions);
      let userIsCurrent = false;
      const updatedSessions = actSessions.map(session => {
        if (session.id === currSession.id) {
          session.currentSession = true;
          userIsCurrent = true;
        };
        return session;
      });

      if (userIsCurrent && updatedSessions.length === 1) {
        setHasActiveSessions(false);
      } else {
        setHasActiveSessions(actSessions.length > 0);
      };

      setIsLoading(false);
      return { data: updatedSessions };
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const { components, columns, options } = getTableProps(refreshTableData);

  const handleRevokeActiveSessions = async () => {
    try {
      activeSessions.map(session => {
        if (session.id !== currentSession.id) {
          deleteSession(session.id);
        }
      });
      await refreshTableData();
      setSnack({
        isOpen: true,
        title: t('active_sessions_revoked'),
        severity: 'success'
      });
    } catch (error) {
      setSnack({
        isOpen: true,
        title: t('something_went_wrong'),
        message: getServerError(error.data),
        severity: 'warning'
      });
    } finally {
      revokeSessionsDialog({ isOpen: false });
    }
  };

  const revokeSessionsDialog = useDialog('revoke-sessions-dialog', {
    onClose: () => {
      revokeSessionsDialog({ isOpen: false });
    },
    components: {
      Icon: <TrashIcon className={classes.dialogIconSize} />,
      Title: <Typography variant="h5" align="center">{t('are_you_sure_you_want_to_revoke_all_sessions')}</Typography>,
      ContentText: <Typography variant="body2" align="center">{t('active_sessions_msg_revoke_warning')}</Typography>,
      Actions: (
        <DialogButtons
          confirmLabel={t('yes_revoke_access')}
          cancelLabel={t('no')}
          onConfirm={() => handleRevokeActiveSessions()}
          onCancel={() => revokeSessionsDialog({ isOpen: false })}
          type='warning'
        />
      )
    }
  });

  return (<>
    <MaterialTable
      title=''
      columns={columns}
      data={getTableData}
      options={options}
      components={components}
      localization={localization}
      isLoading={isLoading}
      tableRef={tableRef}
    />
    <div className={classes.btnGroup}>
      <Button variant='contained' color="default" onClick={() => revokeSessionsDialog({ isOpen: true })} disabled={!hasActiveSessions} className={classes.revokeBtn}>
        {t('revoke_active_sessions')}
      </Button>
      <Button variant='outlined' onClick={() => onClose()} className={classes.btn}>
        {t('cancel')}
      </Button>
    </div>
  </>);
};
ActiveSessionsTable.propTypes = propTypes;

export default ActiveSessionsTable;
