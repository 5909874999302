import React from 'react';
import { Link, generatePath } from 'react-router-dom';
import { Typography, Tooltip } from '@material-ui/core';
import { useAuthUser } from 'hooks';
import { propTypes } from './NameColumn.props';
import { ROUTE_PATHS } from 'utils/routes.constants';

const Filename = ({ rowData }) => {
  const { auth } = useAuthUser();
  return auth.roles && auth.roles.length > 0
    ? (
        <Tooltip
          title='Go to results'
          className="tooltip"
          arrow
        >
          <Link to={generatePath(ROUTE_PATHS.DETAILS_URL, { id: rowData.id })}>{rowData.url}</Link>
        </Tooltip>
      )
    : (
        <Typography>
          {rowData.url}
        </Typography>
      );
};

Filename.propTypes = propTypes;

export default Filename;
