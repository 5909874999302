import { SAFETY_LABELS } from 'entities/analysis/analysis.constants';

export const ANTIVRUS = 'antivirus';
export const UNSAFE = 'unsafe';
export const SAFETY_QUALIFIER = 'SafetyQualifier';
export const UNKNOWN = 'Unknown';

export const STATUS_MSG = {
  [SAFETY_LABELS.unsafe]: 'threat_found',
  [SAFETY_LABELS.suspicious]: 'suspicious_behaviors_identified',
  [SAFETY_LABELS.undetermined]: 'no_threat_detected',
  [SAFETY_LABELS.unknown]: 'no_information',
  [SAFETY_LABELS.running]: 'no_information',
  [SAFETY_LABELS.safe]: 'known_safe_file'
};

export const STATUS_PROGRESS_BAR = {
  [SAFETY_LABELS.unsafe]: 100,
  [SAFETY_LABELS.suspicious]: 75,
  [SAFETY_LABELS.undetermined]: 50,
  [SAFETY_LABELS.safe]: 25,
  [SAFETY_LABELS.running]: 15,
  [SAFETY_LABELS.unknown]: 0
};
