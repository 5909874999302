import React from 'react';
import Box from '@material-ui/core/Box';
import { propTypes } from './TabPanel.props';
import makeStyles from './TabPanel.styles';

const useStyles = makeStyles();

export default function TabPanel (props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      className={classes.tabPanel}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = propTypes;
