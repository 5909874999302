import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  addAccountForm: {
    display: 'flex',
    margin: '0 auto',
    flexWrap: 'wrap',
    width: 390
  },
  infoAccountOwnerBox: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%'
  },
  infoIconStyle: {
    width: '1rem',
    height: '1rem'
  }
}));
