import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  tagSearch: {
    width: 250,
    margin: 0
  },
  tagAction: {
    width: 400,
    margin: 0
  },
  inputTextTags: {
    margin: 0
  }
}));
