import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import makeStyles from '../../Users.styles';

const useStyles = makeStyles();

const Search = ({
  setFragment,
  setTrait,
  stateSearch,
  traits,
  onSearch
}) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const handleSubmit = event => {
    event.preventDefault();
    onSearch();
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <Grid container xs>
        <Grid item xs={2} className={[classes.toolbarItem, classes.traitSelector]}>
          <FormControl
            variant="outlined"
          >
            <InputLabel htmlFor="trait-selector">{t('trait')}</InputLabel>
            <Select
              labelId="trait-selector"
              id="trait-selector"
              value={stateSearch.trait}
              onChange={(e) => setTrait(e.target.value)}
              variant="outlined"
              name="trait"
              label={t('trait')}
              className={classes.uppercase}
            >
              {
                traits.map(
                  (trait, index) => (
                    <MenuItem
                      key={index}
                      value={trait}
                      className={classes.uppercase}>
                      {trait}
                    </MenuItem>
                  )
                )
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} className={classes.toolbarItem}>
          <TextField
            label={t('search')}
            variant="outlined"
            onChange={(e) => setFragment(e.target.value)}
            className={classes.searchText}
            size="medium"
            value={stateSearch.fragment ? stateSearch.fragment : null}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            type='submit'
            className={classes.addButton}
            size="medium"
            color="primary"
            variant="contained">
            {t('search')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

Search.propTypes = {
  setFragment: PropTypes.func.isRequired,
  setTrait: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  stateSearch: PropTypes.shape({
    fragment: PropTypes.string.isRequired,
    trait: PropTypes.string.isRequired
  }).isRequired,
  traits: PropTypes.array.isRequired
};

export default Search;
