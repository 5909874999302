import { isPlatformAdmin } from 'entities/users/users.utils';
import Scanner from 'pages/Scanner';
import Results from 'pages/Results';
import MyFiles from 'pages/MyFiles';
import Details from 'pages/Details';
import AccountListPage from 'pages/Accounts';
import PlansListPage from 'pages/Plans';
import PlanInfo from 'pages/PlanInfo';
import UrlAnalysisListPage from 'pages/UrlAnalysisList';
import Dashboard from 'pages/Dashboard';
import AccountInfo from 'pages/AccountInfo';
import Settings from 'pages/Settings';
import { BrowsingProbePage, ProbeManagement } from 'pages/ProbesStore';
import { ROUTE_PATHS } from './routes.constants';

export default [
  {
    id: 'dashboard',
    type: 'page',
    path: ROUTE_PATHS.DASHBOARD,
    icon: 'palette',
    text: 'dashboard',
    component: Dashboard,
    sidebar: (appConfig) => true,
    roles: ['admin']
  },
  {
    id: 'scanner',
    type: 'page',
    path: ROUTE_PATHS.SCAN,
    icon: 'palette',
    text: 'scan',
    component: Scanner,
    sidebar: (appConfig) => true,
    roles: ['admin', 'analyst', 'operator']
  },
  {
    id: 'myFiles',
    path: ROUTE_PATHS.FILES,
    text: 'files',
    component: MyFiles,
    sidebar: (appConfig) => true,
    roles: ['admin', 'analyst', 'operator']
  },
  {
    id: 'results',
    path: ROUTE_PATHS.RESULTS_FILE,
    icon: 'palette',
    text: 'results',
    component: Results,
    sidebar: (appConfig) => false
  },
  {
    id: 'results',
    path: ROUTE_PATHS.RESULTS_URL,
    icon: 'palette',
    text: 'results',
    component: Results,
    sidebar: (appConfig) => false
  },
  {
    id: 'urlAnalysis',
    path: ROUTE_PATHS.URL_ANALYSIS,
    text: 'urls',
    component: UrlAnalysisListPage,
    sidebar: (appConfig) => appConfig.isUrlAnalysisEnabled,
    roles: ['admin', 'analyst', 'operator']
  },
  {
    id: 'details',
    path: ROUTE_PATHS.DETAILS_FILE,
    icon: 'palette',
    component: Details,
    sidebar: (appConfig) => false
  },
  {
    id: 'details',
    path: ROUTE_PATHS.DETAILS_URL,
    icon: 'palette',
    component: Details,
    sidebar: (appConfig) => false
  },
  {
    id: 'probesStore',
    path: ROUTE_PATHS.PROBES,
    icon: 'palette',
    component: BrowsingProbePage,
    text: 'probes',
    sidebar: ({ user }) => user && isPlatformAdmin(user),
    roles: ['admin']
  },
  {
    id: 'probeManagament',
    path: ROUTE_PATHS.PROBE_MANAGEMENT,
    icon: 'palette',
    component: ProbeManagement,
    text: '',
    sidebar: (appConfig) => false
  },
  {
    id: 'accounts',
    type: 'page',
    path: ROUTE_PATHS.ACCOUNTS,
    icon: 'palette',
    text: 'accounts',
    component: AccountListPage,
    sidebar: ({ user }) => user && isPlatformAdmin(user),
    roles: ['admin']
  },
  {
    id: 'accountInfo',
    type: 'page',
    path: ROUTE_PATHS.ACCOUNT_INFO,
    icon: 'palette',
    text: '',
    component: AccountInfo,
    sidebar: (appConfig) => false,
    roles: ['admin']
  },
  {
    id: 'accountAdminView',
    type: 'page',
    path: ROUTE_PATHS.ACCOUNT_ADMIN_VIEW,
    icon: 'palette',
    text: 'account',
    component: AccountInfo,
    sidebar: ({ user }) => user && !isPlatformAdmin(user),
    roles: ['admin']
  },
  {
    id: 'plans',
    type: 'page',
    path: ROUTE_PATHS.PLANS,
    icon: 'palette',
    text: 'plans',
    component: PlansListPage,
    sidebar: ({ user }) => user && isPlatformAdmin(user),
    roles: ['admin']
  },
  {
    id: 'planInfo',
    type: 'page',
    path: ROUTE_PATHS.PLAN_INFO,
    icon: 'palette',
    text: '',
    component: PlanInfo,
    sidebar: (appConfig) => false,
    roles: ['admin']
  },
  {
    id: 'settings',
    type: 'page',
    path: ROUTE_PATHS.SETTINGS,
    icon: 'palette',
    text: 'settings',
    component: Settings,
    sidebar: ({ user }) => user && isPlatformAdmin(user),
    roles: ['admin']
  }
];
