import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  dialogIconSize: {
    width: '4rem',
    height: '4rem'
  },
  infoIcon: {
    width: '0.8rem',
    height: '0.8rem',
    fill: theme.palette.text.primary
  },
  cardActions: {
    padding: '20px 30px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.text.secondary + 26
  },
  cardActionsEnabled: {
    borderColor: '#047fdc',
    backgroundColor: theme.palette.primary.main + 27
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  ribbon: {
    position: 'absolute',
    width: '100%',
    margin: 0,
    zIndex: 999
  },
  ribbonText: {
    width: 200,
    backgroundColor: '#047fdc61',
    margin: '0 auto',
    borderRadius: '0 0 5% 5%',
    display: 'block'
  }
}));
