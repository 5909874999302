import React from 'react';
import { getFlatList } from 'pages/Details/Details.utils';
import GenericTable from '../GenericTable';
import { propTypes } from './Dependency.props';
import { useTranslation } from 'react-i18next';

const Dependency = ({ data, columns }) => {
  const [t] = useTranslation();
  const rows = getFlatList(data);
  return (
    <GenericTable rows={rows} columns={columns} title={t('dependency')}/>
  );
};

Dependency.propTypes = propTypes;

export default Dependency;
