import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles((theme) => ({
  treeView: {
    flexGrow: 1,
    padding: '20px'
  },
  treeItemLabel: {
    color: theme.palette.text.secondary,
    paddingRight: 10
  },
  headerLabel: {
    backgroundColor: '#e5f2fb',
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  headers: {
    color: theme.palette.text.secondary,
    fontSize: '12px'
  },
  positive: {
    backgroundColor: theme.palette.success.main
  },
  negative: {
    backgroundColor: theme.palette.error.main
  },
  unknown: {
    backgroundColor: theme.palette.grey[700]
  },
  overflowHidden: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));
