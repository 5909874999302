import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useDialog from 'hooks/useDialog';
import { stringify } from 'pages/Details/Details.utils';
import GenericTable from '../GenericTable';
import GenericTabs from './components/GenericTabs/GenericTabs';
import { getTabs } from './GenericDialog.utils';
import { propTypes } from './GenericDialog.props';
import makeStyles from './GenericDialog.style';

const useStyles = makeStyles();

const GenericDialog = ({ id, data, label }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const mapContentToComponent = {
    tabs: <GenericTabs tabs={getTabs(data.tabs)}/>,
    table: <GenericTable columns={data.columns} rows={data.rows} title={t(data.title ?? data.key)}/>,
    default: <pre className={classes.code}>{stringify(data)}</pre>
  };
  const Content = mapContentToComponent[data.type || 'default'];

  const onDisplayDialog = useDialog('generic-table-dialog' + id, {
    onClose: () => {
      onDisplayDialog({ isOpen: false });
    },
    resetOnUpdate: false,
    components: {
      Icon: null,
      Content: Content,
      Title: (
        <IconButton
          aria-label={t('close')}
          className={classes.closeButton}
          onClick={() => onDisplayDialog({ isOpen: false })}>
          <CloseIcon />
        </IconButton>
      )
    }
  });

  const handleOnClick = useCallback(e => {
    e.preventDefault();
    onDisplayDialog({ isOpen: true });
  }, [onDisplayDialog]);

  return (
    <Link href='#' onClick={handleOnClick}>{label ?? t('view_details')}</Link>);
};

GenericDialog.propTypes = propTypes;

export default GenericDialog;
