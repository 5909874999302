import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  container: {
    marginTop: '20px'
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer'
  },
  subTitle: {
    marginLeft: '10px',
    fontSize: '16px'
  }
}));
