import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import InputSelect from './components/InputSelect';
import propTypes from './Filter.props';
import InputText from './components/InputText';

export default function FilterBox ({ filter, categories }) {
  const [t] = useTranslation();

  return (
    <Grid container direction='row' spacing={3}>
      <InputText
        id={'name'}
        label={t('name')}
        filter={filter}
      />
      <InputSelect
        id={'status'}
        label={t('status')}
        filter={filter}
        items={['enabled', 'disabled']}
      />
      <InputSelect
        id={'access_mode'}
        label={t('access_mode')}
        filter={filter}
        items={['restricted', 'full_access']}
      />
      <InputSelect
        id={'target_type'}
        label={t('target_type')}
        filter={filter}
        items={['file', 'url']}
      />
      <InputSelect
        id={'category'}
        label={t('category')}
        filter={filter}
        items={categories}
      />
    </Grid>
  );
};

FilterBox.propTypes = propTypes;
