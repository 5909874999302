import { makeStyles } from '@material-ui/core/styles';
import { TYPES } from '../../DialogExtensions.constants';

export default (theme) => makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',

    '& button': {
      width: '100%',
      maxWidth: '280px',
      margin: '5px'
    }
  },
  confirmBtn: {
    background: props => props.type === TYPES.WARNING && theme.palette.error.main,

    '&:hover': {
      background: props => props.type === TYPES.WARNING && theme.palette.error.dark
    }
  }
}));
