export const getLayoutStyle = (location) => {
  const isFileDetails = location.pathname.includes('details/file/');
  const isUrlDetails = location.pathname.includes('details/url/');
  const isProbeDetails = location.pathname.includes('probes/store');

  let layoutStyle;
  switch (true) {
    case isFileDetails:
      layoutStyle = 'fileDetailsPage';
      break;
    case isUrlDetails:
      layoutStyle = 'urlDetailsPage';
      break;
    case isProbeDetails:
      layoutStyle = 'probeDetailsPage';
      break;
    default:
      layoutStyle = 'page';
  }
  return layoutStyle;
};
