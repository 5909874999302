import { number, string, shape } from 'prop-types';

export const propTypes = {
  plan: shape({
    name: string.isRequired,
    maxFileSize: number.isRequired,
    scanLimit: number.isRequired,
    subscriptionCost: shape({
      EUR: number,
      USD: number
    }).isRequired
  })
};
