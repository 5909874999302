export const API = {
  LOGIN: 'auth/login',
  REGISTER: 'auth/register',
  LOGOUT: 'auth/logout',
  ACTIVE_METHODS: 'auth/active_methods',
  RECOVERY: {
    INIT: 'auth/recovery/init',
    CHALLENGE: 'auth/recovery/challenge'
  },
  UPDATE: {
    IDENTITY: 'auth/update/identity',
    PASSWORD: 'auth/update/password'
  },
  MFA: {
    REGISTER: '/auth/mfa/otp/registration',
    LOGIN: '/auth/login/totp',
    BACKUP: '/auth/mfa/backup/registration',
    LOGIN_WITH_BACKUP: '/auth/login/lookup_secret',
    UNLINK: '/auth/mfa/unlink'
  }
};
