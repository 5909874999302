import HTTP from 'common/http';

const USERS = 'iam/users';
const SELF = 'iam/users/self';
const REGISTER = 'auth/register';

export const fetchUsers = ({ trait, fragment, allOwnedUsers = true }) => {
  const url = USERS;
  return HTTP.get(url, { params: { trait, fragment, all_owned_users: allOwnedUsers } });
};

export const fetchUser = id => {
  const url = `${USERS}/${id}`;
  return HTTP.get(url);
};

export const fetchSelfUser = () => {
  const url = SELF;
  return HTTP.get(url);
};

export const toggleUserStatus = (id, params) => {
  const url = `${USERS}/${id}`;
  return HTTP.put(url, { params });
};

export const register = data => {
  const url = USERS;
  return HTTP.post(url, { data });
};

export const selfRegister = data => {
  const url = REGISTER;
  return HTTP.post(url, { data });
};

export const deleteUser = id => {
  const url = `${USERS}/${id}`;
  return HTTP.delete(url);
};
