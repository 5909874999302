import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Typography, Grid, CircularProgress } from '@material-ui/core';
import { propTypes } from './QualifierPill.props';
import makeStyles from './QualifierPill.styles';

const useStyles = makeStyles();

const QualifierPill = ({ qualifier, label, color, isScanning }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const dynamicStyle = {
    backgroundColor: color
  };

  return (
    <>
      <Grid className={classes.pillContainer}>
        <Paper className={classes.pill} elevation={0} style={dynamicStyle}>
          <Typography variant="body2" className={classes.qualifier}>
            {t(`${qualifier}`)}
          </Typography>
          <div className={classes.rightSide}>
            <Typography variant="body2">
            {t(`${label}`)}{isScanning && <><CircularProgress size={8} style={{ marginLeft: 5 }}/></>}
            </Typography>
          </div>
        </Paper>
      </Grid>
    </>
  );
};
QualifierPill.propTypes = propTypes;

export default QualifierPill;
