import React from 'react';
import { FormControl, Select, InputLabel } from '@material-ui/core';
import propTypes from './OutlinedFieldSelect.props';

export default function OutlinedFieldSelect ({
  label = '',
  id = '',
  inputLabelProps = {},
  children,
  ...outlinedInputProps
}) {
  const SelectHeight = {
    height: '51px'
  };

  return (
    <FormControl variant="outlined">
      <InputLabel id={id} {...inputLabelProps}>
        {label}
      </InputLabel>
      <Select
        labelId={id}
        label={label}
        variant='outlined'
        style={SelectHeight}
        {...outlinedInputProps}>
        {children}
      </Select>
    </FormControl>
  );
};

OutlinedFieldSelect.propTypes = propTypes;
