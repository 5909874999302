import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { propTypes } from './ObjectUsage.props';
import makeStyles from './ObjectUsage.styles';

const useStyles = makeStyles();

export default function ObjectUsage ({ icon, type, total }) {
  const [t] = useTranslation();
  const classes = useStyles();

  return <>
    <div className={classes.container}>
      {icon}
      <Typography variant="h5" component="span" gutterBottom className={classes.total}><strong>{total}</strong></Typography>
      <span>
        {type}
      </span>
      <span>
        {t('submitted')}
      </span>
    </div>
  </>;
};

ObjectUsage.propTypes = propTypes;
