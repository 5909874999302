import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@material-ui/core';
import { downloadFileContent, fetchFileContent } from 'entities/files/files.api';
import { getServerError } from 'pages/pages.utils';
import { useSnackSetState } from 'contexts/SnackContext';
import { propTypes } from './PdfViewer.props';
import makeStyles from './PdfViewer.styles';

const useStyles = makeStyles();

const PdfViewer = ({ preview }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const setSnack = useSnackSetState();

  const handleDownloadPreview = async () => {
    try {
      const response = await fetchFileContent(preview.id, {});
      downloadFileContent(response.data, { name: preview.name, type: 'application/pdf' });
    } catch (error) {
      setSnack({
        isOpen: true,
        title: t('something_went_wrong'),
        message: getServerError(error.data),
        severity: 'error'
      });
    }
  };

  return (
    <div className={classes.previewContainer}>
      <iframe src={preview?.url} type="application/pdf" width="100%" height="245px" className={classes.filePreview}>
        <p>{t('browser_doesnt_support_pdfs')}</p>
      </iframe>
      <Link href='#' onClick={handleDownloadPreview}>{preview?.name}</Link>
    </div>

  );
};

PdfViewer.propTypes = propTypes;

export default PdfViewer;
