import React from 'react';
import PropTypes from 'prop-types';

const NotificationStateContext = React.createContext();
const NotificationSetStateContext = React.createContext();
const initialState = {
  message: undefined,
  action: undefined,
  icon: undefined,
  timeout: undefined,
  status: 100
};

function NotificationProvider ({ children }) {
  const [state, setState] = React.useState(initialState);

  const clearNotification = () => {
    setState(initialState);
  };

  React.useEffect(() => {
    if (state.timeout) {
      const timer = setTimeout(clearNotification, state.timeout);
      return () => clearTimeout(timer);
    }
  }, [state.timeout]);

  return (
    <NotificationSetStateContext.Provider value={setState}>
      <NotificationStateContext.Provider value={state}>
        {children}
      </NotificationStateContext.Provider>
    </NotificationSetStateContext.Provider>
  );
}

function useNotificationSetState () {
  const context = React.useContext(NotificationSetStateContext);
  if (context === undefined) {
    throw new Error(
      'NotificationSetStateContext must be used within a NotificationStateContext.Provider'
    );
  }
  return context;
}

NotificationProvider.propTypes = {
  children: PropTypes.any
};

export {
  NotificationProvider,
  NotificationStateContext,
  NotificationSetStateContext,
  useNotificationSetState
};
