import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { TYPES } from '../../DialogExtensions.constants';
import { propTypes } from './DialogButtons.props';
import makeStyles from './DialogButtons.style';

const useStyles = makeStyles();

export default function DialogButtons ({
  confirmLabel = 'confirm',
  cancelLabel = 'cancel',
  onConfirm,
  onCancel,
  type = TYPES.INFO
}) {
  const [t] = useTranslation();
  const classes = useStyles({ type });
  return (
    <div className={classes.container}>
      <Button
       className={classes.confirmBtn}
        variant="contained"
        color="primary"
        onClick={onConfirm}
        size="large"
      >
        {t(confirmLabel)}
      </Button>
      <Button
        variant="outlined"
        onClick={onCancel}
        size="large"
      >
        {t(cancelLabel)}
      </Button>
    </div>
  );
}

DialogButtons.propTypes = propTypes;
