import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Grid, Typography } from '@material-ui/core';
import { getDockerImageTag } from 'utils';

const Child = ({ subtitle, label, ...rest }) => {
  const [t] = useTranslation();
  return (
    <Grid item>
    <Typography gutterBottom variant="caption">
      {subtitle}
    </Typography>
    <Typography variant="subtitle1" color="textSecondary" component="p">
      {label || t('unknown')}
    </Typography>
  </Grid>
  );
};

Child.propTypes = {
  subtitle: PropTypes.string,
  label: PropTypes.string
};

export default function ManifestSection ({ probe }) {
  const [t] = useTranslation();
  const {
    version: {
      database, software
    },
    platform,
    author
  } = probe;

  const operatingSystem = {
    unix: 'Linux',
    windows: 'Windows'
  };

  return (
    <Grid item>
      <Grid container justify="space-between">
        <Child subtitle={t('version')} label={software} />
        <Child subtitle={t('database_version')} label={database} />
        <Child subtitle={t('latest_build')} label={dayjs(probe.build_date).format('MM/DD/YYYY')} />
        <Child subtitle={t('platform')} label={operatingSystem[platform]} />
        <Child subtitle={t('docker_image')} label={getDockerImageTag(probe)} />
        <Child subtitle={t('author')} label={author} />
      </Grid>
    </Grid>
  );
};

ManifestSection.propTypes = {
  probe: PropTypes.object
};
