import React from 'react';
import useAppConfigContext from 'contexts/AppConfigContext/useAppConfigContext';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import ObjectUsage from './components/ObjectUsage';
import { propTypes } from './Usage.props';
import { FileCopyLine, TagsIcon, URLGlobe } from 'common/Icons';
import makeStyles from './Usage.styles';

const useStyles = makeStyles();

export default function Usage ({ files, urls, tags }) {
  const { isUrlAnalysisEnabled } = useAppConfigContext();
  const [t] = useTranslation();
  const classes = useStyles();

  return <>
    <div className={classes.container}>
      <Grid container justify='space-between' className={classes.header}>
        <Grid item>
          <Typography variant="h5" component="span"><strong>{t('usage')}</strong></Typography>
        </Grid>
        <Grid item>
        </Grid>
      </Grid>
      <div className={classes.objects}>
        <ObjectUsage
          icon={<FileCopyLine className={`${classes.icon} ${classes.files}`}/>}
          type={t('files')}
          total={files.length}
        />
        {isUrlAnalysisEnabled && <ObjectUsage
          icon={<URLGlobe className={`${classes.icon} ${classes.urls}`}/>}
          type={t('urls')}
          total={urls.length}
        />}
        <ObjectUsage
          icon={<TagsIcon className={`${classes.icon} ${classes.tags}`}/>}
          type={t('tags')}
          total={tags.length}
        />
      </div>
    </div>
  </>;
};

Usage.propTypes = propTypes;
