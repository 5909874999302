import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default function PasswordForm (props) {
  const [t] = useTranslation();
  const [password, setPassword] = useState('');
  const [showPassword, setPasswordVisibility] = useState(false);

  const handleClickShowPassword = () => {
    setPasswordVisibility(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    props.onsave({
      password
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">{t('password')}</InputLabel>

        <OutlinedInput
          margin="normal"
          required
          fullWidth
          name="password"
          label={t('password')}
          labelWidth={70}
          type={showPassword ? 'text' : 'password'}
          id="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        data-test="password-in-submit"
      >
        {t('save')}
      </Button>
    </form>
  );
};

PasswordForm.propTypes = {
  onsave: PropTypes.func.isRequired
};
