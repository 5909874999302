import React from 'react';
import { useTranslation } from 'react-i18next';
import QualifierChip from '../QualifierChip';
import { ANALYSIS_STATUS, SECURITY_LABELS } from 'entities/analysis/analysis.constants';
import { TOOLTIP_STATUS } from './Security.constants';
import { propTypes } from './Security.props';
import makeStyles from './Security.styles';

const useStyles = makeStyles();

const Security = ({ rowData }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  let qualifierProps = {
    label: '',
    color: '',
    backgroundColor: '',
    tooltip: ''
  };

  if (!rowData.current_analysis) {
    qualifierProps = {
      label: SECURITY_LABELS.notAnalyzed,
      color: classes.notAnalyzed,
      backgroundColor: classes.notAnalyzedBackground,
      tooltip: t(TOOLTIP_STATUS.notAnalyzed)
    };
  } else {
    const status = rowData.current_analysis.status || null;
    const security = rowData.current_analysis.security || null;

    const securityKey = security ? security.toLowerCase() : 'unknown';
    let securityLabel = SECURITY_LABELS[securityKey] || SECURITY_LABELS.unknown;
    let securityClass = classes[securityKey] || classes[securityLabel];
    let statusBackground = classes[securityKey + 'Background'] || classes[securityLabel + 'Background'];
    let tooltip = t(TOOLTIP_STATUS[securityKey]) || t(TOOLTIP_STATUS.unknown);

    if (status === ANALYSIS_STATUS.RUNNING) {
      securityLabel = SECURITY_LABELS.running;
      securityClass = classes.running;
      statusBackground = classes.runningBackground;
      tooltip = t(TOOLTIP_STATUS.running);
    }

    qualifierProps = {
      label: securityLabel,
      color: securityClass,
      backgroundColor: statusBackground,
      tooltip
    };
  }

  return <QualifierChip {...qualifierProps} />;
};

Security.propTypes = propTypes;

export default Security;
