export default theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    minHeight: '100%',
    alignItems: 'left',
    alignContent: 'flex-start',
    backgroundColor: '#133280',
    color: '#fff',
    fontFamily: 'monospace',
    fontSize: '2rem',
    margin: '0 10px',
    '& p': {
      marginBlockStart: '0.5em',
      marginBlockEnd: '0.5em'
    },
    '& a': {
      color: 'white',
      textDecoration: 'none'
    }
  }
});
