import React from 'react';
import { useTranslation } from 'react-i18next';
import OutlinedFieldInput from './components/OutlinedFieldInput';
import { propTypes } from './AddAccountForm.props';
import { initialState, addAccountFormReducer, ADD_ACCOUNT_FORM_ACTIONS } from './AddAccountForm.reducer';
import makesStyles from './AddAccountForm.style';

const useStyles = makesStyles();
export default function AddAccountForm ({ submitButtonRef, onSubmit }) {
  const [formState, dispatch] = React.useReducer(addAccountFormReducer, initialState);
  const [t] = useTranslation();
  const classes = useStyles();

  const triggerFormSubmission = (e) => {
    if (e) {
      e.preventDefault();
    }
    onSubmit(formState);
  };

  return (
    <form className={classes.addAccountForm} onSubmit={triggerFormSubmission}>
      <OutlinedFieldInput
        label={t('account_name')}
        required
        fullWidth
        labelWidth={77}
        margin="normal"
        name="name"
        type="text"
        id="name"
        autoComplete="name"
        value={formState.name}
        onChange={(e) => dispatch({ type: ADD_ACCOUNT_FORM_ACTIONS.NAME, payload: e.target.value })}
      />
      <OutlinedFieldInput
        label={t('email_address')}
        required
        fullWidth
        labelWidth={100}
        margin="normal"
        name="email"
        type="email"
        id="email-address"
        autoComplete="email"
        value={formState.email}
        onChange={(e) => dispatch({ type: ADD_ACCOUNT_FORM_ACTIONS.UPDATE_EMAIL, payload: e.target.value })}
      />
      <button ref={submitButtonRef} style={{ display: 'none' }} type='submit'></button>
    </form>
  );
}

AddAccountForm.propTypes = propTypes;
