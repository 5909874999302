import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { Link, generatePath } from 'react-router-dom';
import StatusIcon from 'pages/components/StatusIcon';
import { ROUTE_PATHS } from 'utils/routes.constants';

const Filename = ({ rowData, searchQuery }) =>
  <>
    <StatusIcon currentAnalysis={rowData?.current_analysis}/>
    <Tooltip
      title={rowData.name}
      className="tooltip"
      arrow
    >
      <Link to={generatePath(ROUTE_PATHS.DETAILS_FILE, { id: rowData.id })} state={{ prevSearchQuery: searchQuery }}>{rowData.name}</Link>
    </Tooltip>
  </>;

Filename.propTypes = {
  rowData: PropTypes.object,
  searchQuery: PropTypes.object
};

export default Filename;
