import HTTP from 'common/http';
import { getAccountPayload, getPlans, getPlan, getRootAccount, setAccountData } from './accounts.helpers';
import { getActivePlan } from './accounts.utils';

const ACCOUNTS = 'accounts';
const ACCOUNT_DETAILS = 'accounts/details';
const PLANS = 'plans';

export const fetchAccount = (id, params) => {
  const url = ACCOUNTS;
  return HTTP.get(`${url}/${id}`, { params });
};

export const fetchAccounts = params => {
  const url = ACCOUNTS;
  return HTTP.get(url, { params });
};

export const fetchAccountsDetail = params => {
  const url = ACCOUNT_DETAILS;
  return HTTP.get(url, { params });
};

export const createAccount = async account => {
  const accounts = await fetchAccountsDetail();
  const rootAccount = getRootAccount(accounts?.data ?? []);
  return HTTP.post(ACCOUNTS, { data: getAccountPayload(account, rootAccount) });
};

export const updateAccount = async (id, payload) => {
  const url = ACCOUNTS;
  return HTTP.put(`${url}/${id}`, { data: setAccountData(payload) });
};

// PLANS

export const fetchAccountPlans = accountId => {
  const url = `${ACCOUNTS}/${accountId}/plans`;
  return HTTP.get(url)
    .then(getPlans);
};

export const fetchActivePlan = async accountId => {
  const accountPlans = await fetchAccountPlans(accountId);
  return getActivePlan(accountPlans);
};

export const fetchAccountPlan = (accountId, accountPlanId) => {
  const url = `${ACCOUNTS}/${accountId}/${PLANS}/${accountPlanId}`;
  return HTTP.get(url)
    .then(getPlan);
};

export const associatePlan = (accountId, data) => {
  const url = `${ACCOUNTS}/${accountId}/${PLANS}`;
  return HTTP.post(url, { data });
};

export const cancelAccountPlan = (accountId, accountPlanId) => {
  const url = `${ACCOUNTS}/${accountId}/${PLANS}/${accountPlanId}`;
  return HTTP.delete(url);
};
