import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { TableContainer } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { propTypes } from './DetailsPanel.props';
import { getPreview } from './DetailsPanel.utils';
import SummaryTable from '../SummaryTable';
import PdfViewer from './components/PdfViewer';
import ScreenshotViewer from './components/ScreenshotViewer';
import { isDefined } from 'utils/types';
import makeStyles from '../../styles';

const useStyles = makeStyles();

const DetailsPanel = props => {
  const [t] = useTranslation();
  const classes = useStyles();

  const { threats, extracted, workflow } = props;

  const [preview, setPreview] = useState(null);
  const [screenshot, setScreenshot] = useState(null);

  useEffect(() => {
    const previewIDList = extracted.filter(res => res.artifacts[0]?.type === 'RAW_OUTPUT').filter(res => res.artifacts[0]?.name.startsWith('clean-'));

    const previewId = previewIDList[0]?.artifacts[0]?.id;
    const previewName = previewIDList[0]?.artifacts[0]?.name;

    if (isDefined(previewId)) {
      getPreview(previewId).then(url => {
        setPreview({
          url: url,
          id: previewId,
          name: previewName
        });
      });
    }

    const screenshotIDList = extracted.filter(res => res.artifacts[0]?.type === 'SCREENSHOT');

    const screenshotId = screenshotIDList[0]?.artifacts[0]?.id;
    const screenshotName = screenshotIDList[0]?.artifacts[0]?.name;

    if (isDefined(screenshotId)) {
      setScreenshot({});
      getPreview(screenshotId).then(url => {
        setScreenshot({
          url: url,
          id: screenshotId,
          name: screenshotName
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={1} >
      <Grid item xs={12}>
        <TableContainer className={`${classes.summaryContainer} ${classes.tableContainer}`}>
            <SummaryTable data={threats} workflow={workflow} />
        </TableContainer>
        { preview && <>
            <div className={classes.fileInfoHeader}>
              <Typography variant="h4" className={classes.panelTitle}>
              {t('document_preview')}
              </Typography>
            </div>
            <div>
              <PdfViewer preview={preview} />
            </div>
          </>
        }
        { screenshot && <>
            <div className={classes.fileInfoHeader}>
              <Typography variant="h4" className={classes.panelTitle}>
              {t('screenshot')}
              </Typography>
            </div>
            <div>
              {<ScreenshotViewer preview={screenshot} />}
            </div>
          </>
        }
      </Grid>
    </Grid>
  );
};

DetailsPanel.propTypes = propTypes;

export default DetailsPanel;
