import { any, bool, object } from 'prop-types';

const propTypes = {
  userRef: any,
  submitButtonRef: any,
  data: object,
  editMode: bool
};

export default propTypes;
