import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  dialogIcon: {
    padding: 10,
    display: 'flex',
    justifyContent: 'center'
  },
  chipVirusFound: {
    backgroundColor: theme.palette.error.main
  },
  dialogTitleRoot: {
    padding: '5px 24px'
  },
  paperRoot: {
    padding: '30px',
    minWidth: '500px',
    maxWidth: '750px'
  }
}));
