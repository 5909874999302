import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Chip } from '@material-ui/core';
import tableConfig from 'common/tableConfig';
import RevokeSession from 'pages/UserSettings/components/ActiveSessions/components/RevokeSession';

const dateFormatDay = 'MMM DD, YYYY';
const dateFormatHour = 'H:mm A';

export default (refreshTableData) => ({
  components: {
    ...tableConfig.components
  },
  columns: getColumns(refreshTableData),
  options: {
    ...tableConfig.options,
    paging: false,
    cellStyle: {
      paddingLeft: '0',
      border: '0',
      verticalAlign: 'top'
    },
    headerStyle: {
      paddingLeft: '0',
      border: '0',
      color: '#79859F'
    }
  }
});

const GetIpAddress = () => {
  const [t] = useTranslation();
  return {
    title: t('ip_address'),
    cellStyle: {
      ...tableConfig.options.cellStyle,
      width: 180,
      paddingLeft: 0
    },
    // eslint-disable-next-line react/display-name
    render: rowData => rowData.ipAddress
  };
};

const GetLastAccess = () => {
  const [t] = useTranslation();
  return {
    title: t('last_access_on'),
    cellStyle: {
      ...tableConfig.options.cellStyle,
      width: 120,
      paddingLeft: 0
    },
    // eslint-disable-next-line react/display-name
    render: rowData => <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{ rowData.lastAccessOn ? moment(rowData.lastAccessOn).format(dateFormatDay) : null }</span>
      <span>{ rowData.lastAccessOn ? moment(rowData.lastAccessOn).format(dateFormatHour) : null }</span>
      </div>
  };
};

const GetDevice = () => {
  const [t] = useTranslation();
  return {
    title: t('device'),
    cellStyle: {
      ...tableConfig.options.cellStyle,
      width: 150,
      paddingLeft: 0
    },
    // eslint-disable-next-line react/display-name
    render: rowData => rowData.device
  };
};

const GetExpiresAt = () => {
  const [t] = useTranslation();
  return {
    title: t('expiration_date'),
    cellStyle: {
      ...tableConfig.options.cellStyle,
      width: 120,
      paddingLeft: 0
    },
    // eslint-disable-next-line react/display-name
    render: rowData => <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>{ rowData.expiresAt ? moment(rowData.expiresAt).format(dateFormatDay) : null }</span>
        <span>{ rowData.expiresAt ? moment(rowData.expiresAt).format(dateFormatHour) : null }</span>
      </div>
  };
};

const GetActions = (refreshTableData) => {
  const [t] = useTranslation();

  return {
    title: t('actions'),
    cellStyle: {
      ...tableConfig.options.cellStyle,
      width: 120,
      paddingLeft: 0
    },
    // eslint-disable-next-line react/display-name
    render: (rowData) => {
      return (
        (!rowData.currentSession
          ? <RevokeSession id={rowData.id} device={rowData.device} refreshTableData={refreshTableData}/>
          : <Chip label={t('current_session')} size="small" aria-label="session label" />)
      );
    }
  };
};

const getColumns = (refreshTableData) => {
  return [
    GetIpAddress(),
    GetLastAccess(),
    GetDevice(),
    GetExpiresAt(),
    GetActions(refreshTableData)
  ];
};
