import React from 'react';
import { Tooltip } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';

const LabelChip = ({ text, onDelete, color, showDelete, tooltipText }) => {
  const chipStyle = {
    border: `2px solid #${color}`,
    borderRadius: '5px',
    fontWeight: '400',
    margin: '5px',
    display: 'flex',
    backgroundColor: 'transparent',
    color: `#${color}`
  };

  const getDeleteFunc = () => (showDelete ? onDelete : null);

  return (
    <Chip
      label={text}
      onDelete={getDeleteFunc()}
      color={'primary'}
      size={'small'}
      deleteIcon={
        tooltipText
          ? <Tooltip title={tooltipText} arrow placement='top'>
            <CancelIcon style={{ color: `#${color}` }} />
          </Tooltip>
          : <CancelIcon style={{ color: `#${color}` }} />
      }
      style={{ ...chipStyle }}
    />
  );
};

LabelChip.defaultProps = {
  showDelete: true
};

LabelChip.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string,
  file: PropTypes.object,
  onDelete: PropTypes.func,
  color: PropTypes.string,
  showDelete: PropTypes.bool,
  tooltipText: PropTypes.string
};

export default LabelChip;
