import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({

  addButton: {
    marginTop: theme.spacing(2),
    height: 51
  },
  toolbarItem: {
    paddingRight: 10,
    marginBottom: '-2px'
  }
}));
