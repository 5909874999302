import axios from 'axios';
import { HTTP_METHODS } from './http.constants';

class HTTP {
  constructor () {
    throw Error('Static class can not be instantiated.');
  }

  static getBaseURL () {
    return process.env.REACT_APP_QUARKSFLOW_CORE_API_ENDPOINT;
  };

  // TODO: move to the user module?
  static getToken () {
    return localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : undefined;
  };

  static getHeaders (additonalHeaders = {}) {
    const token = this.getToken();
    const headers = {
      'Content-Type': 'application/json',
      ...additonalHeaders,
      ...(token ? { Authorization: token } : {})
    };
    return headers;
  };

  /**
   * The base method for sending requests
   * and the only method that wraps the external dependency.
   * All the other methods should be dependency agnostic.
   * @param {*} method ['get', 'post', 'put', 'delete'];
   * @param {*} url endpoint url
   * @param {*} options { headers, params, data...}
   * @returns promise
   */
  static _sendRequest (method, url, options = {}) {
    const headers = this.getHeaders(options.headers);
    return axios({ baseURL: this.getBaseURL(), url, method, ...options, headers });
  }

  /**
   * This method will be temporaryily and only used by the API Bridge to
   * ease the transition to this new implementation.
   */
  static sendRequestDontUseOutsideBridgeApi (method, url, options) {
    return this._sendRequest(method, url, options);
  };

  static get (url, options = {}) {
    return this._sendRequest(HTTP_METHODS.GET, url, options);
  }

  static post (url, options = {}) {
    return this._sendRequest(HTTP_METHODS.POST, url, options);
  }

  static put (url, options = {}) {
    return this._sendRequest(HTTP_METHODS.PUT, url, options);
  }

  static delete (url, options = {}) {
    return this._sendRequest(HTTP_METHODS.DELETE, url, options);
  }
}

export default HTTP;
