import React from 'react';
import { Grid } from '@material-ui/core';
import { propTypes } from './Alert.props';
import { mapping, TYPES } from './Alert.constants';

const Alert = ({ type, children, justify = 'initial', style = {} }) => {
  const { IconComponent, color, textColor } = mapping[type];

  return (
    <Grid wrap='nowrap' alignItems='center' justify={justify} style={style} container>
      <Grid item><IconComponent color={color} size='25px' style={{ margin: '5px 5px 0 0' }}/></Grid>
      <Grid item><span style={{ color: textColor }}>{children}</span></Grid>
    </Grid>
  );
};

Alert.propTypes = propTypes;
Alert.TYPES = TYPES;

export default Alert;
