import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  container: {
    marginBottom: '60px'
  },
  objects: {
    display: 'flex',
    direction: 'row'
  },
  header: {
    marginBottom: '30px'
  },
  icon: {
    width: '48px',
    height: '48px',
    padding: '10px',
    borderRadius: '10px',
    verticalAlign: 'middle',
    marginBottom: '30px',
    fill: '#FFF'
  },
  files: {
    backgroundColor: '#047FDC'
  },
  urls: {
    backgroundColor: '#12214D'
  },
  tags: {
    backgroundColor: '#79859F'
  }
}));
