import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: '#fff',
    color: theme.palette.text.primary
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  mainBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center'
  },
  statusBar: {
    display: 'flex',
    flexDirection: 'row'
  },
  root: {
    color: '#fff',
    display: 'flex',
    flexDirection: 'row-reverse',
    '& .MuiChip-icon': {
      color: '#fff',
      marginLeft: '-6px',
      marginRight: 5
    }
  },
  deletable: {
    color: '#fff'
  },
  logo: {
    marginLeft: 0,
    fontSize: '0px',
    width: 120
  },
  infoicon: {
    width: '1rem',
    height: '1rem'
  },
  boxIcon: {
    display: 'inline-block',
    position: 'relative',
    top: 2.5
  },
  logoutIcon: { width: '1.5rem', height: '1.5rem' },
  logoutButton: { padding: 2.5 }
}));
