import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { DialogContent } from 'common/probes/ProbeDialog';
import { propTypes } from './SetPasswordForm.props';
import makeStyles from './SetPasswordForm.styles';

const useStyles = makeStyles();

const SetPasswordForm = ({ submitButtonRef, onSubmit, file }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState(file.password);

  const handleClickShowPassword = (event) => {
    setIsPasswordVisible(!isPasswordVisible);
    event.preventDefault();
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const triggerFormSubmission = useCallback((e) => {
    if (e) {
      e.preventDefault();
    }
    onSubmit(password);
  }, [onSubmit, password]);

  return (
    <form onSubmit={triggerFormSubmission}>
      <Grid className={classes.dialogContent}>
        <DialogContent content={t('the_file_appears_to_be_password_protected')} />
      </Grid>
      <FormControl variant="outlined">
        <InputLabel htmlFor="password">{t('password')}</InputLabel>
        <OutlinedInput
          autoComplete='one-time-code'
          className={classes.formInput}
          label={t('password')}
          name="password"
          type={isPasswordVisible ? 'text' : 'password'}
          id="passwordFile"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          endAdornment={
            password && (
              <InputAdornment position="end">
                <IconButton
                  aria-label={t('toggle_password_visibility')}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </FormControl>
      <button ref={submitButtonRef} style={{ display: 'none' }} type='submit'></button>
    </form>
  );
};

SetPasswordForm.propTypes = propTypes;

export default SetPasswordForm;
