import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  container: {
    border: '1px solid',
    borderColor: theme.palette.text.secondary + 40,
    borderRadius: '5px',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    width: '160px',
    marginRight: '23px'
  },
  total: {
    marginBottom: 0
  }
}));
