import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
  DialogTitle as MuiDialogTitle
} from '@material-ui/core';

import makeStyles from './styles';
const useStyles = makeStyles();

const DialogIcon = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.dialogIcon}>
      {children}
    </div>
  );
};

DialogIcon.propTypes = {
  children: PropTypes.node
};

const Dialog = ({
  keyName, // should be unique
  isOpen,
  onClose,
  components
}) => {
  const {
    Icon,
    Title,
    ContentText,
    Content,
    Actions,
    Footer
  } = components;
  const classes = useStyles();
  const dialogTitleStyle = {
    root: classes.dialogTitleRoot
  };

  const icon = Icon && <DialogIcon> { Icon } </DialogIcon>;
  const title = Title && <MuiDialogTitle id={keyName} classes={dialogTitleStyle}> { Title } </MuiDialogTitle>;
  const contentText = ContentText && <MuiDialogContentText> { ContentText } </MuiDialogContentText>;
  const actions = Actions && <MuiDialogActions> { Actions }</MuiDialogActions>;
  const footer = Footer;

  return (
    <MuiDialog classes={{ paper: classes.paperRoot }} maxWidth={false} open={isOpen} onClose={onClose} aria-labelledby={keyName}>
      { icon }
      { title }
      <MuiDialogContent>
        { contentText }
        { Content }
      </MuiDialogContent>
      { actions }
      { footer }
    </MuiDialog>
  );
};

Dialog.propTypes = {
  keyName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  components: PropTypes.objectOf(PropTypes.shape({
    Icon: PropTypes.node,
    Title: PropTypes.node,
    ContentText: PropTypes.node,
    Content: PropTypes.node,
    Actions: PropTypes.node,
    Footer: PropTypes.node
  }))
};

export default Dialog;
