import React, { useState, useEffect } from 'react';
import QualifierPill from '../QualifierPill/QualifierPill';
import { useTheme } from '@material-ui/core';
import { PRIVACY_LABELS } from 'entities/analysis/analysis.constants';
import { propTypes } from './PrivacyPill.props';

function PrivacyPill ({ privacy, isScanning }) {
  const privacyKey = privacy ? privacy.toLowerCase() : 'unknown';
  const [privacyLabel, setPrivacyLabel] = useState(PRIVACY_LABELS[privacyKey] || PRIVACY_LABELS.unknown);
  const theme = useTheme();
  const [color, setColor] = useState('');

  useEffect(() => {
    if (isScanning) {
      setPrivacyLabel('scanning');
    } else {
      let newColor = '';
      switch (privacyKey) {
        case 'respectful':
          newColor = theme.palette.success.main;
          break;
        case 'invasive':
        case 'failed':
        case 'cancelled':
          newColor = theme.palette.error.main;
          break;
        case 'pervasive':
          newColor = theme.palette.warning.main;
          break;
        case 'undetermined':
        case 'clean':
          newColor = '#167424';
          break;
        case 'unknown':
        case 'notAnalyzed':
          newColor = theme.palette.text.secondary;
          break;
        default:
          newColor = '';
      }
      setColor(newColor);
    }
  }, [isScanning, privacyKey, theme.palette]);

  return (
    <div>
      <QualifierPill qualifier='Privacy' label={privacyLabel} color={color} isScanning={isScanning}/>
    </div>
  );
}

PrivacyPill.propTypes = propTypes;
export default PrivacyPill;
