import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List } from 'components/List';
import {
  Grid,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography
} from '@material-ui/core';
import { RadioButtonCheckedOutlined, RadioButtonUncheckedOutlined } from '@material-ui/icons';
import { propTypes } from './WorkflowList.props';
import makeStyles from './WorkflowList.styles';

const useStyles = makeStyles();

export default function WorkflowList ({ selectedFlow, handleFlowSelect, flowsList }) {
  const classes = useStyles();
  const [t] = useTranslation();
  const [open, setopen] = useState(false);

  const handleOpen = () => {
    setopen(true);
    const bottom = document.documentElement.scrollHeight;
    window.scrollTo({
      top: bottom,
      scroll: 'smooth'
    });
  };

  const handleClose = () => {
    setopen(false);
  };

  return (
    <Grid item xs={8} className={classes.container}>
        <Typography variant="h4" gutterBottom className={classes.header}>{t('analysis_method')}</Typography>
      <List aria-label="flow list" className={classes.selectOption}>
        <Select
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          labelId="flows-demo-select"
          id="flows-select"
          variant ='outlined'
          className={classes.selectBox}
          value={selectedFlow[0]}
          onChange={handleFlowSelect}
          renderValue={(selected) => (
            <>
              <Typography className={classes.selectedDisplayName}>{selected.display_name}</Typography>
              <Typography className={classes.selectedDescription}>{selected.description}</Typography>
            </>
          )}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            classes: {
              paper: classes.menuPaper
            },
            getContentAnchorEl: null
          }}
        >
          {flowsList.map((flow) => (
            <MenuItem key={flow.name} value={flow} className={classes.menuItem}>
              <div className={classes.selectOption}>
                <div className={classes.flowInfo}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.checkbox}
                        checked={selectedFlow.includes(flow)}
                        icon={<RadioButtonUncheckedOutlined className={classes.checkbox}/>}
                        checkedIcon={<RadioButtonCheckedOutlined className={classes.checkbox} />}
                      />
                    }
                  />
                  <Typography className={classes.displayName}>{flow.display_name}</Typography>
                </div>
                <Typography className={classes.description}>{flow.description}</Typography>
              </div>
            </MenuItem>
          ))}
        </Select>
      </List>
    </Grid>
  );
}

WorkflowList.propTypes = propTypes;
