import { func, oneOf, string } from 'prop-types';
import { TYPES } from '../../DialogExtensions.constants';

export const propTypes = {
  confirmLabel: string,
  cancelLabel: string,
  onConfirm: func.isRequired,
  onCancel: func.isRequired,
  type: oneOf(Object.values(TYPES))
};
