import React from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { useDefaultValues } from 'hooks';
import { NavLink } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import makeStyles from './styles';

const useStyles = makeStyles();

// const KRATOS_PATH = process.env.REACT_APP_QUARKSFLOW_KRATOS_ENDPOINT;

// const goToKratos = path => { window.location.href = `${KRATOS_PATH}/${path}`; };

const defaultvalue = {
  path: '#',
  text: 'empty text',
  icon: 'no icon'
};

const AuthLink = (props) => {
  const [t] = useTranslation();
  const paths = {
    login: '/signin',
    register: '/signup'
  };

  const classes = useStyles();
  const { path, text } = useDefaultValues(defaultvalue, props);
  return <NavLink key={path} to={paths[path]} className={classes.routeLink} >
    <Typography display="inline">{t(`${text}`)}</Typography>
  </NavLink>;
};

AuthLink.propTypes = {
  path: propTypes.string,
  icon: propTypes.string,
  text: propTypes.string
};
export default AuthLink;
