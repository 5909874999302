import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  block: {
    padding: '15px 30px',
    margin: '14px 0',
    borderRadius: '10px',
    display: 'flex',
    width: '100%',
    justifyContent: 'start',
    lineHeight: 1,
    textAlign: 'start',
    '&:first-of-type': {
      marginTop: '29px'
    }
  },
  icon: {
    width: '48px',
    height: '48px',
    padding: '10px',
    borderRadius: '10px',
    verticalAlign: 'middle',
    marginRight: '30px',
    fill: '#FFF'
  },
  fileDescription: {
    fontSize: '14px',
    fontWeight: '400'
  },
  UNSAFE: {
    backgroundColor: '#fa4b5430'
  },
  SUSPICIOUS: {
    backgroundColor: '#ffa14330'
  },
  CLEAN: {
    backgroundColor: '#16742430'
  },
  SAFE: {
    backgroundColor: '#30b04330'
  },
  UNSAFEicon: {
    backgroundColor: '#fa4b54'
  },
  SUSPICIOUSicon: {
    backgroundColor: '#ffa143'
  },
  CLEANicon: {
    backgroundColor: '#167424'
  },
  SAFEicon: {
    backgroundColor: '#30b043'
  }

}));
