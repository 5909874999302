import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Chip } from '@material-ui/core';
import tableConfig from 'common/tableConfig';
import RevokeSession from './components/RevokeSession';

const dateFormat = 'MMM DD, YYYY, H:mm A';

export default (refreshTableData) => ({
  components: {
    ...tableConfig.components
  },
  columns: getColumns(refreshTableData),
  options: {
    ...tableConfig.options,
    paging: false,
    cellStyle: {
      paddingLeft: '0',
      border: '0'
    },
    headerStyle: {
      paddingLeft: '0',
      border: '0',
      color: '#79859F'
    }
  }
});

const GetIpAddress = () => {
  const [t] = useTranslation();
  return {
    title: t('ip_address'),
    width: '30%',
    // eslint-disable-next-line react/display-name
    render: rowData => rowData.ipAddress
  };
};

const GetLastAccess = () => {
  const [t] = useTranslation();
  return {
    title: t('last_access_on'),
    width: '40%',
    // eslint-disable-next-line react/display-name
    render: rowData => <span>{ rowData.lastAccessOn ? moment(rowData.lastAccessOn).format(dateFormat) : null }</span>
  };
};

const GetDevice = () => {
  const [t] = useTranslation();
  return {
    title: t('device'),
    width: '30%',
    // eslint-disable-next-line react/display-name
    render: rowData => rowData.device
  };
};

const GetExpiresAt = () => {
  const [t] = useTranslation();
  return {
    title: t('expiration_date'),
    width: '40%',
    // eslint-disable-next-line react/display-name
    render: rowData => <span>{ rowData.expiresAt ? moment(rowData.expiresAt).format(dateFormat) : null }</span>
  };
};

const GetActions = (refreshTableData) => {
  const [t] = useTranslation();

  return {
    title: t('actions'),
    width: '10%',
    // eslint-disable-next-line react/display-name
    render: (rowData) => {
      return (
        (!rowData.currentSession
          ? <RevokeSession id={rowData.id} device={rowData.device} refreshTableData={refreshTableData}/>
          : <Chip label={t('current_session')} size="small" aria-label="session label" />)
      );
    }
  };
};

const getColumns = (refreshTableData) => {
  return [
    GetIpAddress(),
    GetLastAccess(),
    GetDevice(),
    GetExpiresAt(),
    GetActions(refreshTableData)
  ];
};
