export const ROUTE_PATHS = {
  HOMEPAGE: '/',
  DASHBOARD: '/dashboard',
  SCAN: '/scan',
  FILES: '/files',
  RESULTS_FILE: '/results/file',
  RESULTS_URL: '/results/url',
  URL_ANALYSIS: '/url-analysis',
  PROBES: '/probes',
  PROBE_MANAGEMENT: '/probes/:store/:id',
  PLANS: '/plans',
  DETAILS_FILE: '/details/file/:id',
  DETAILS_URL: '/details/url/:id',
  ACCOUNTS: '/accounts',
  ACCOUNT_INFO: '/accounts/:id',
  ACCOUNT_ADMIN_VIEW: '/account',
  ACCOUNT_PASSWORD_VERIFICATION: '/account/password-verification',
  ACCOUNT_PASSWORD_RECOVERY: '/account/password-recovery',
  PLAN_INFO: '/plans/:id',
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  USER_SETTINGS: '/user-settings',
  URL_SCAN: '/scan?tab=url',
  TAGS: '/tags',
  FORBIDDEN: '/forbidden',
  SETTINGS: '/settings'
};
