import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { fetchPlan } from 'entities/plans/plans.api';
import { fetchActivePlan } from 'entities/accounts/accounts.api';
import PlanHeader from './components/PlanHeader';
import Usage from './components/Usage';
import UserLimit from './components/UserLimit';
import { getTimeBeforeRenewal } from 'entities/plans/plans.utils';
import { propTypes } from './AccountPlan.props';
import makeStyles from './AccountPlan.styles';

const useStyles = makeStyles({});

const AccountPlan = ({ accountId, currency }) => {
  const classes = useStyles({});
  const [t] = useTranslation();
  const [activePlan, setActivePlan] = useState({ usage: {} });
  const [isLoading, setIsLoading] = useState(true);
  const [hasNoActivePlan, setHasNoActivePlan] = useState(false);

  const timeUntilReset = getTimeBeforeRenewal(activePlan.endDate);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const activePlan = await fetchActivePlan(accountId);
      if (activePlan?.plan) {
        const planData = await fetchPlan(activePlan.plan);
        setActivePlan({ ...planData, ...activePlan });
      } else {
        setHasNoActivePlan(true);
      }
      setIsLoading(false);
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container className={classes.container}>
      {isLoading && <p>{t('loading_three_dots')}</p>}
      {hasNoActivePlan && <p>{t('no_active_plan_for_this_account')}</p>}
      {!isLoading && !hasNoActivePlan &&
        <>
          <PlanHeader plan={activePlan} accountCurrency={currency}/>
          <Grid className={classes.usageContainer} container spacing={2}>
            <Grid item xs='8'>
            <Typography className={classes.hiddenTitle} variant="h6" gutterBottom>--</Typography>
              <Usage type='operators' count={activePlan.usage.operatorCount} max={activePlan.maxOperatorAccounts} />
              <Usage type='analysts' count={activePlan.usage.analystCount} max={activePlan.maxAnalystAccounts}/>
              <Usage type='administrators' count={activePlan.usage.adminCount} max={activePlan.maxAdminAccounts}/>
            </Grid>

            <Grid item xs='4'>
              <Typography className={classes.title} variant="h6" gutterBottom>{t('usage')}</Typography>
              <UserLimit usedScans={activePlan.usage.analysisCount} maxScans={activePlan.scanLimit} timeUntilReset={timeUntilReset} />
            </Grid>
          </Grid>
        </>
      }
    </Grid>
  );
};

AccountPlan.propTypes = propTypes;

export default AccountPlan;
