import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from 'pages/components/PageHeader';
import Syslog from './components/Syslog';

export default function Dashboard () {
  const [t] = useTranslation();

  return <>
    <PageHeader title={t('settings')}/>
    <Syslog />
  </>;
}
