import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { ArrowDown, ArrowRight } from 'common/Icons';
import { TreeView } from 'common/TreeView';
import NoResult from '../../../NoResult';
import { NETWORK_ACTIVITY } from './DnsProtocol.constants';
import makeStyles from '../../Communications.style';

const useStyles = makeStyles();

const DnsProtocol = props => {
  const [t] = useTranslation();
  const { data } = props;

  const networkActivities = data.network_activities;

  const classes = useStyles();
  const noResult = <NoResult message={t('not_defined_abbrev')}/>;

  const gridDns = itemData => {
    return (
      <Grid container>
        <Grid item xs={1}>
          {itemData.type === NETWORK_ACTIVITY.ANSWER
            ? t('answer')
            : itemData.type === NETWORK_ACTIVITY.QUERY
              ? t('query')
              : null
          }
        </Grid>
        <Grid item xs={1}>
          {itemData.rrtype ?? noResult}
        </Grid>
        <Grid item xs={3}>
          {itemData.timestamp ?? noResult}
        </Grid>
        <Grid item xs={2} className={classes.overflowHidden}>
          {itemData.rdata ?? noResult}
        </Grid>
        <Grid item xs={2} className={classes.overflowHidden}>
          {itemData.rrname ?? noResult}
        </Grid>
        <Grid item xs={1}>
          {itemData.response_code ?? noResult}
        </Grid>
        <Grid item xs={2}>
          {itemData.return_message ?? noResult}
        </Grid>
      </Grid>
    );
  };

  const dnsHeader = () => {
    return (
      <Grid container className={classes.headers}>
        <Grid item xs={1}>
          {t('network_activity')}
        </Grid>
        <Grid item xs={1}>
          {t('rrtype')}
        </Grid>
        <Grid item xs={3}>
          {t('timestamp')}
        </Grid>
        <Grid item xs={2}>
          {t('rrdata')}
        </Grid>
        <Grid item xs={2}>
          {t('rrname')}
        </Grid>
        <Grid item xs={1}>
          {t('response_code')}
        </Grid>
        <Grid item xs={2}>
          {t('return_message')}
        </Grid>
      </Grid>
    );
  };

  function groupByName (objectArray) {
    const newObject = objectArray.reduce((prev, curr) => {
      (prev[curr.name] = prev[curr.name] || []).push(curr);
      return prev;
    }, []);
    return newObject;
  };

  const dnsData = data => {
    const filteredData = groupByName(data.map(item => {
      return {
        ...item,
        name: item.name ?? item.rrname
      };
    }));

    return ([{
      id: '1',
      content: <div>
        <span className={classes.treeItemLabel}>{t('protocol') + ': '}</span><span>{t('dns')}</span>
      </div>,
      child: Object.entries(filteredData).map(itemGroup => {
        const mainData = itemGroup[1].map(item => {
          return { content: gridDns(item) };
        });
        mainData.unshift({ content: dnsHeader() });
        return {
          id: itemGroup[0],
          content: <div><span className={classes.treeItemLabel}>{t('name') + ' '}</span><span>{itemGroup[0]}</span></div>,
          child: mainData
        };
      })
    }]);
  };

  return (
    <>
      <div className={classes.headerLabel}>
        <div>
          <span className={classes.treeItemLabel}>{t('source') + ': '}</span><span>{data.source}</span>
        </div>
        <div>
          <span className={classes.treeItemLabel}>{t('first_seen') + ': '}</span><span>{data.first_seen}</span>
        </div>
        <div>
          <span className={classes.treeItemLabel}>{t('last_seen') + ': '}</span><span>{data.last_seen}</span>
        </div>
        <div>
          <span className={classes.treeItemLabel}>{t('destination') + ': '}</span><span>{data.destination}</span>
        </div>
      </div>
      <TreeView
        className={classes.treeView}
        defaultExpanded={['1', '2', '3']}
        defaultCollapseIcon={<ArrowDown size={20}/>}
        defaultExpandIcon={<ArrowRight size={20} />}
        data={dnsData(networkActivities)}
      />
    </>
  );
};

DnsProtocol.propTypes = {
  data: PropTypes.object
};

export default DnsProtocol;
