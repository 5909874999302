import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const DropzoneTypography = () => {
  return (
    <Typography variant="h6" gutterBottom>
      Drag &apos;n&apos; drop some files here, or click to select files
    </Typography>
  );
};

const DragTypography = () => {
  return (
    <Typography variant="h6" gutterBottom>
      Drop your files here
    </Typography>
  );
};

const DragDropDefaultTypography = ({ isDragActive }) => {
  return isDragActive ? <DragTypography /> : <DropzoneTypography />;
};

DragDropDefaultTypography.propTypes = {
  isDragActive: PropTypes.bool.isRequired
};

export default DragDropDefaultTypography;
