import { array, func, string } from 'prop-types';

const propTypes = {
  id: string,
  label: string,
  items: array,
  filter: func
};

export default propTypes;
