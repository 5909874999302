import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContent, DialogTitle } from 'common/probes/ProbeDialog';
import DialogButtons from 'components/DialogButtons';
import { Typography } from '@material-ui/core';
import { AssignmentInd, TrashFillIcon, UserAddOutline } from 'common/Icons';
import { useTheme } from '@material-ui/core/styles';
import { getLoggedUserId } from 'entities/auth/auth.utils';
import { deleteUser } from 'entities/users/users.api';
import { useSnackSetState } from 'contexts/SnackContext';
import { getServerError } from 'pages/pages.utils';
import useDialog from 'hooks/useDialog';
import MenuActions from 'components/MenuActions';
import AddEditUser from '../AddEditUser';
import { propTypes } from './ActionsMenu.props';
import ToggleUser from '../ToggleUser';
import ActiveSessionsTable from './ActiveSessionsTable';
import makeStyles from '../../Users.styles';

const useStyles = makeStyles();

const ActionsMenu = ({ data, refreshTable, selfUser }) => {
  const [t] = useTranslation();
  const setSnack = useSnackSetState();
  const classes = useStyles();
  const { palette } = useTheme();
  const submitButtonRef = useRef();
  const userRef = useRef({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    id_account: ''
  });

  const onConfirm = () => {
    submitButtonRef.current.click();
  };

  const onDelete = () => {
    if (data?.id) {
      deleteUser(data?.id)
        .then(() => {
          setSnack({
            isOpen: true,
            title: t('user_succesfully_deleted'),
            severity: 'success'
          });
          refreshTable();
        })
        .catch(err => {
          setSnack({
            isOpen: true,
            message: getServerError(err?.response?.data),
            title: t('user_delete_has_failed'),
            severity: 'error'
          });
          deleteUserDialog({ isOpen: false });
        });
    };
  };

  const enableUserToggle = (data) => {
    return <span className={classes.iconStyle}>
      {t('user_enabled')} <ToggleUser
        userId={data.id}
        username={data.traits.username}
        enabled={data.enabled}
        refreshTable={refreshTable}
      />
    </span>;
  };

  const onDisableEditRole = (rowData) => {
    return getLoggedUserId === rowData.id || rowData.id === 'anonymous' || rowData.traits.username === 'admin';
  };

  const editableContent = <AddEditUser
    submitButtonRef={submitButtonRef}
    userRef={userRef}
    data={data}
    editMode={true}
    refreshTable={refreshTable}
  />;

  const optionsActions =
  [
    {
      placeholder: <span className={classes.iconStyle}>{t('edit_user')}</span>,
      onClick: () => {
        editUserDialog({ isOpen: true });
      },
      disabled: onDisableEditRole(data)
    },
    {
      placeholder: <span className={classes.iconStyle}>{t('delete_user')}</span>,
      onClick: () => {
        deleteUserDialog({ isOpen: true });
      },
      disabled: onDisableEditRole(data)
    },
    {
      placeholder: <span className={classes.iconStyle}>{t('active_sessions')}</span>,
      onClick: () => {
        activeSessionsDialog({ isOpen: true });
      },
      disabled: data.id === 'anonymous',
      visible: selfUser === data.account
    },
    {
      divider: true
    },
    {
      placeholder: enableUserToggle(data),
      onClick: () => {},
      disabled: onDisableEditRole(data)
    }
  ].filter(
    (option) => option.visible !== false
  );

  const editUserDialog = useDialog(`edit-user-dialog-${data?.id}`, {
    onClose: () => {
      editUserDialog({ isOpen: false });
    },
    resetOnUpdate: false,
    components: {
      Icon: <UserAddOutline className={classes.dialogIconSize} title={t('edit_user')} />,
      Title: <Typography variant="h5" align="center">{t('edit_user')}</Typography>,
      Content: editableContent,
      Actions: (
        <DialogButtons
          confirmLabel={t('save_changes')}
          cancelLabel={t('cancel')}
          onConfirm={onConfirm}
          onCancel={() => editUserDialog({ isOpen: false })}
        />
      )
    }
  });

  const deleteUserDialog = useDialog(`deleteuser-dialog-${data?.id}`, {
    onClose: () => {
      deleteUserDialog({ isOpen: false });
    },
    components: {
      Icon: <TrashFillIcon color={palette.error.main} size='4rem' />,
      Title: <DialogTitle title={t('do_you_want_to_delete_this_user')} />,
      ContentText: <DialogContent content={`${!data.traits.name
        ? data.traits.username
        : `${data.traits.name.first} ${data.traits.name.last}`}  (${data.traits.username})`}/>,
      Actions: <DialogButtons type='secondary'
        confirmLabel={t('yes_delete_the_user')} cancelLabel={t('no')}
        onConfirm={onDelete}
        onCancel={() => deleteUserDialog({ isOpen: false })} />
    }
  });

  const activeSessionsDialog = useDialog(`active-sessions-dialog-${data?.id}`, {
    onClose: () => {
      activeSessionsDialog({ isOpen: false });
    },
    components: {
      Icon: <AssignmentInd color={palette.primary.main} size='4rem' />,
      Title: <DialogTitle title={t('active_sessions')} />,
      Content: <ActiveSessionsTable userId={data?.id} onClose={() => activeSessionsDialog({ isOpen: false })}/>
    }
  });

  return <MenuActions options={optionsActions}/>;
};

ActionsMenu.propTypes = propTypes;
export default ActionsMenu;
