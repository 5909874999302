import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  formInput: {
    height: '50px',
    marginBottom: '5px'
  },
  dialogContent: {
    color: theme.palette.text.secondary,
    marginBottom: '10px',
    width: 450
  }
}));
