import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import defaulTheme from './defaultTheme';
const ThemeSetContext = createContext();

function ThemeCustomProvider ({ children }) {
  const [theme, setTheme] = useState(defaulTheme);

  const updateTheme = theme => newValues => {
    const newTheme = merge({}, theme, newValues);
    setTheme(newTheme);
  };

  return (
    <ThemeSetContext.Provider value={updateTheme(theme)}>
      <ThemeProvider theme={createMuiTheme(theme)}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeSetContext.Provider>
  );
}

function useThemeSetContext () {
  const context = useContext(ThemeSetContext);
  if (context === undefined) {
    throw new Error('ThemeSetContext must be used within a ThemeContext.Provider');
  }
  return context;
}

ThemeCustomProvider.propTypes = {
  children: PropTypes.any
};

export { ThemeCustomProvider, ThemeSetContext, useThemeSetContext };
