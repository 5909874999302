import React from 'react';
import Item from './components/Item';
import { propTypes } from './Row.props';

const Row = ({ analysis }) => {
  return (
    <Item data={analysis}/>
  );
};

Row.propTypes = propTypes;

export default Row;
