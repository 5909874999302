import React from 'react';
import { Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getHighestRole } from 'utils/roles';
import _ from 'lodash';
import { propTypes } from './RoleColumn.props';
import makeStyles from './RoleColumn.styles';
const useStyles = makeStyles();

export default function RoleColumn ({ roles }) {
  const [t] = useTranslation();
  const role = _.get(getHighestRole(roles), 'roleName');
  const classes = useStyles();
  const appliedStyle = classes[role];
  return (
    <div className={classes.roleChip}><Chip className={appliedStyle} label={t(`${role}`)} /></div>
  );
}

RoleColumn.propTypes = propTypes;
