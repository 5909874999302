import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { getSubscriptionCost, getRenewalDateFormatted } from 'entities/plans/plans.utils';
import { propTypes } from './PlanHeader.props';
import makeStyles from './PlanHeader.styles';

const useStyles = makeStyles();

const PlanHeader = ({ plan = {}, accountCurrency }) => {
  const [t] = useTranslation();
  const billingInfo = useMemo(() => {
    return getSubscriptionCost(plan.subscriptionCost, plan.billingPeriod, accountCurrency);
  }, [accountCurrency, plan.subscriptionCost, plan.billingPeriod]);

  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs='12'>
        <Typography variant='h6' className={classes.title}>{plan.name}</Typography>
      </Grid>
      <Grid item xs='12' className={classes.infoPanel}>
        <div data-test-id='info' className={classes.info}>
          <div className={classes.infoTitle}>{t('price')}</div>
          <div className={classes.infoBody}>{billingInfo}</div>
        </div>

        <div data-test-id='info' className={classes.info}>
          <div className={classes.infoTitle}>{t('renewal_date')}</div>
          <div className={classes.infoBody}>{getRenewalDateFormatted(plan.endDate)}</div>
        </div>

        <div data-test-id='info' className={classes.info}>
          <div className={classes.infoTitle}>{t('max_files_per_scan')}</div>
          <div className={classes.infoBody}>{plan.scanLimit}</div>
        </div>
      </Grid>
    </Grid>
  );
};

PlanHeader.propTypes = propTypes;

export default PlanHeader;
