export const STATUS = {
  ERROR: 'error',
  WARNING: 'warning',
  ACTIVE: 'active'
};
export const MSG = {
  EXPIRED: 'expired_license',
  NOT_SET: 'no_license_set',
  FREE: 'free_license'
};
