import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isArray, isBoolean, isDefined, isNumber, isObject, isString } from 'utils/types';
import BooleanDisplay from '../BooleanDisplay';
import GenericDialog from '../GenericDialog';
import NoResult from '../NoResult';
import { propTypes } from './GenericPanel.props';
import makeStyles from './GenericPanel.styles';

const useStyles = makeStyles();

const GenericPanel = ({ data, keys, columnWidth, title }) => {
  const [t] = useTranslation();
  const classes = useStyles({ columnWidth });
  return (
    <Grid container>
      <Grid item xs>
        {title && (<div className={classes.fileInfoHeader}>
          <Typography variant="h4" className={classes.panelTitle}>
            {title}
          </Typography>
        </div>)}
        <dl className={classes.genericPanel}>
          {keys.map(({ key, label }, index) => {
            const value = data[key];
            return (
              <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <dt>{t(label ?? key)}</dt>
                <dd>
                  {(isString(value) || isNumber(value)) && value}
                  {isArray(value) && <GenericDialog id={key} data={{ [key]: value }}></GenericDialog>}
                  {isObject(value) && <GenericDialog id={key} data={value}></GenericDialog>}
                  {isBoolean(value) && <BooleanDisplay value={value}/>}
                  {!isDefined(value) && <NoResult/>}
                </dd>
              </div>
            );
          })}
        </dl>
      </Grid>
    </Grid>
  );
};

GenericPanel.propTypes = propTypes;

export default GenericPanel;
