import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { ShieldFill } from 'common/Icons';
import ProbeUpdateTable from './components/ProbeUpdateTable/ProbeUpdateTable';
import makeStyles from './ProbeSummary.styles';
import { propTypes } from './ProbeSummary.props';

const useStyles = makeStyles();

function ProbeSummary ({ probesList }) {
  const [probesToUpdate, setProbesToUpdate] = useState(probesList?.filter((probe) =>
    probe.enabled_versions.length > 0 && probe.enabled_versions?.[0]?.id !== probe.last_version.id));
  const [t] = useTranslation();
  const classes = useStyles();
  const numberOfProbes = probesList.length;

  const numberOfEnabledProbes = probesList.filter((probe) => probe.enabled_versions.length > 0).length;
  const numberOfProbesToUpdate = probesToUpdate.length;

  const showTable = probesToUpdate.length > 0;

  return (
    <Grid>
      <Grid className={classes.title}>
        <Typography variant="h5" component="span" gutterBottom><strong>{t('probes')}</strong></Typography>
      </Grid>
      <Grid className={classes.chipContainer}>
        <Grid item className={classes.chip} style={{ marginRight: 30 }} xs={6}>
          <ShieldFill className={classes.icon}/>
          <div className={classes.info}>
            <Typography variant="h4" className={classes.number}>{numberOfEnabledProbes}/{numberOfProbes}</Typography>
            <Typography>{t('enabled_over_total')}</Typography>
          </div>
        </Grid>
        <Grid item className={classes.chip} style={{ marginLeft: 30 }} xs={6}>
        <ShieldFill className={classes.icon}/>
          <div className={classes.info}>
            <Typography variant="h4" className={classes.number}>{numberOfProbesToUpdate}/{numberOfProbes}</Typography>
            <Typography>{t('updatable_over_total')}</Typography>
          </div>
        </Grid>
      </Grid>
      {showTable &&
        <Grid>
          <Grid className={classes.title}>
            <Typography><strong>{t('updates_available')}</strong></Typography>
          </Grid>
          <Grid className={classes.table} xs={12}>
            <ProbeUpdateTable probesToUpdate={probesToUpdate} setProbesToUpdate={setProbesToUpdate}/>
          </Grid>
        </Grid>
      }
    </Grid>
  );
};

ProbeSummary.propTypes = propTypes;

export default ProbeSummary;
