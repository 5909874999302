import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  disabledText: {
    color: theme.palette.text.secondary + 80,
    '& svg': {
      fill: theme.palette.text.secondary + 80
    }
  },
  arrow: {
    color: theme.palette.secondary.main
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
    fontFamily: theme.typography.caption.fontFamily,
    padding: 15,
    lineHeight: 1.5,
    letterSpacing: 0.3,
    width: '215px'
  }
}));
