import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@material-ui/core';
import { disableProbe } from 'entities/probes/probes.api';
import { isExpiringSoon, daysUntilExpiry } from './ProbeCard.utils';
import { STATUS, MSG } from './ProbeCard.constants';
import makeStyles from './styles';

const useStyles = makeStyles();
export default function ProbeCardManifest ({
  version, licenseInfo, id, setEnablePossible, probe
}) {
  const [t] = useTranslation();
  const classes = useStyles();
  const isEnabled = probe.enabledVersion !== null;

  const getDisplayStatus = (licenseInfo = []) => {
    const { invalid, expires, errors } = licenseInfo;
    const daysRemaining = daysUntilExpiry(expires);
    const translationKey = daysRemaining > 1 ? 'expires_in_other' : 'expires_in_one';

    switch (true) {
      case errors && errors.length > 0:
        return { status: STATUS.ERROR, tooltip: MSG.NOT_SET };
      case invalid:
        return { status: STATUS.ERROR, tooltip: MSG.EXPIRED };
      case expires && isExpiringSoon(expires):
        return { status: STATUS.WARNING, tooltip: t(translationKey, { days: daysRemaining }) };
      case 'expires' in licenseInfo:
        return { status: STATUS.ACTIVE };
      default:
        return { status: STATUS.ACTIVE };
    }
  };

  const { status, tooltip } = getDisplayStatus(licenseInfo);

  if (status === STATUS.ERROR) {
    setEnablePossible(false);
    if (isEnabled) {
      disableProbe(id);
    }
  }

  const typographyClasses = {
    overline: status === STATUS.WARNING ? classes.warning : status === STATUS.ERROR ? classes.error : classes.active
  };

  const typographyClassesWithEllipsis = {
    root: classes.typographyWithEllipsis,
    overline: classes.overline
  };

  return (
    <div className={classes.cardItemsWrapper}>
      <div className={classes.cardItems}>
        <Typography variant="caption" display="block" color="textSecondary" gutterBottom>
          {t('version')}
        </Typography>
        <Typography variant="overline" display="block" gutterBottom classes={{ overline: classes.overline }}>
          {version.software}
        </Typography>
      </div>
      <div className={classes.cardItems}>
        <Typography variant="caption" display="block" color="textSecondary" className={classes.captionTitle} gutterBottom>
          {t('database_version')}
        </Typography>
        <Typography
          variant="overline"
          display="block"
          gutterBottom
          classes={typographyClassesWithEllipsis}
          title={version.database}>
          {version.database || 'N/A'}
        </Typography>
      </div>
      <div>
        <Typography variant="caption" display="block" color="textSecondary" className={classes.captionTitle} gutterBottom>
          {t('status')}
        </Typography>
        <Tooltip title={t(tooltip)} arrow>
          <Typography className={typographyClasses.overline}>
          {t(`${status}`)}
          </Typography>
        </Tooltip>
      </div>
    </div>
  );
};

ProbeCardManifest.propTypes = {
  version: PropTypes.object,
  licenseInfo: PropTypes.object,
  id: PropTypes.string,
  probe: PropTypes.object,
  setEnablePossible: PropTypes.func
};
