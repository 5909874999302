import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import makeStyles from './styles';
import { FileTextIcon } from 'common/Icons';
import { fetchFile, uploadFile } from 'entities/files/files.api';
import { startFileAnalysisRequest } from 'entities/analysisRequest/analysisRequest.api';
import {
  ListElement,
  ListElementIcon,
  ListElementText
} from 'components/List';
import { ANALYSIS_STATUS } from 'entities/analysis/analysis.constants';
import { bytesToSize } from 'utils';
import ScanStatus from 'common/scan/ScanStatus';
/* context */
import useScanContext from 'contexts/ScanContext/useScanContext';

const useStyles = makeStyles();
const FILE_TOO_LARGE = 413;
const UNSUPPORTED_FORMAT = 415;

const Item = (props) => {
  const [scanContext] = useScanContext();
  const [scanStatus, setScanStatus] = useState({ status: 'uploading' });
  const [file, setFile] = useState(props.file.instance);
  const [uploadedFile, setUploadedFile] = useState({});

  const [scannedFile, setScannedFile] = useState();
  const [scannedFileError, setScannedFileError] = useState(false);

  const scanIntervalRef = useRef();
  const classes = useStyles();

  const startAnalysisRequest = async data => {
    const fileId = data.id;
    startFileAnalysisRequest([fileId], {
      force: false,
      priority: 'MEDIUM',
      workflow: scanContext.flows,
      workflow_options: (scanContext.probes.length > 0 ? { probelist: scanContext.probes } : {}),
      keep_file: scanContext.keepFile
    })
      .then(analysisRes => {
        if (analysisRes.data) {
          setScanStatus({ status: 'pending' });
          startScanInterval(fileId);
        }
      })
      .catch(() => {
        setScanStatus({ status: 'errorScanning' });
        props.addFinished(props.file.temporal_Id, props.file.temporal_Id);
      });
  };

  useEffect(() => {
    setScanStatus({ status: 'uploading' });
    const uploadAndScan = async () => {
      if (props.file) {
        try {
          const response = await uploadFile(props.file);
          if (response.data) {
            setUploadedFile(response.data);
            setFile(prevFile => ({ ...prevFile, ...response.data }));
            startAnalysisRequest(response.data);
          }
        } catch (error) {
          if (error?.response?.status === FILE_TOO_LARGE) {
            setScanStatus({ status: 'errorUploadingFileTooLarge' });
          } else if (error?.response?.status === UNSUPPORTED_FORMAT) {
            setScanStatus({ status: 'errorUnsupportedFormat' });
          } else {
            setScanStatus({ status: 'errorUploading' });
          }

          props.addFinished(props.file.temporal_Id, props.file.temporal_Id);
        }
      };
    };

    // Upload file & Create request.
    uploadAndScan();

    return () => {
      clearScanInterval(); // finish interval once component is unmounted
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* perform scanning a file */
  useEffect(() => {
    const finishStatusSet = new Set([ANALYSIS_STATUS.FINISHED, ANALYSIS_STATUS.FAILED, ANALYSIS_STATUS.CANCELED]);
    const hasScanResponseDataAndFinishes = scannedFile &&
    finishStatusSet.has(scannedFile.current_analysis.status);

    if (hasScanResponseDataAndFinishes) {
      props.addFinished(props.file.tmpId, scannedFile.id);
      clearScanInterval();
      setScanStatus({ status: scannedFile.current_analysis.status.toLowerCase() });
    } else if (scannedFileError) {
      props.addFinished(props.file.tmpId, uploadedFile.id);
      clearScanInterval();
      setScanStatus({ status: 'errorScanning' });
    } else if (scannedFile && scannedFile.current_analysis.status === ANALYSIS_STATUS.PENDING) {
      setScanStatus({ status: scannedFile.current_analysis.status.toLowerCase() });
    } else if (scannedFile && scannedFile.current_analysis.status === ANALYSIS_STATUS.RUNNING) {
      setScanStatus({ status: scannedFile.current_analysis.status.toLowerCase() });
    }

    return () => {
      if (hasScanResponseDataAndFinishes || scannedFileError) {
        clearScanInterval(); // finish interval once component is unmounted
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannedFile, scannedFileError, props.stopScanning]);

  useEffect(() => {
    if (props.stopScanning) {
      clearScanInterval();
    }
  }, [props.stopScanning]);

  const clearScanInterval = () => {
    clearInterval(scanIntervalRef.current);
  };

  const startScanInterval = (id) => {
    scanIntervalRef.current = setInterval(() => {
      fetchFile(id)
        .then(fileRes => {
          setScannedFile(fileRes.data);
        })
        .catch(() => {
          setScannedFileError(true);
        });
    }, 1000);
  };

  const { name, size } = file;
  return <ListElement key={name} divider>
    <ListElementIcon className={classes.listItemIcon}>
      <FileTextIcon className={classes.iconSize} viewBox="0 0 24 20" title='File'/>
    </ListElementIcon>
    <ListElementText
      primary={name}
      secondary={bytesToSize(size)}
      className={classes.listItemText} />
    <ListElementText
      className={classes.listItemStatus}
      classes={{
        primary: classes.listTextPrimaryStatus
      }}
      primary={<ScanStatus status={scanStatus.status} />}
    />
  </ListElement>;
};

Item.propTypes = {
  file: PropTypes.object,
  addFinished: PropTypes.func,
  stopScanning: PropTypes.bool
};

export default Item;
