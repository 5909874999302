import { makeStyles } from '@material-ui/core/styles';

// changing values here may require recalculation of hard coded values in Header.jsx
const radius = 90;
const stroke = 12;
// formula for calculating the r value in Header.jsx > circle
const normalizedRadius = radius - stroke * 2;
// formula for calculating cx and cy values Header.jsx > circle
const circumreference = normalizedRadius * 2 * Math.PI;
const difference = 185;
const fillColor = (theme, progress) => ({
  0: '#777777',
  15: theme.palette.primary.main,
  25: theme.palette.success.main,
  50: theme.palette.success.dark,
  75: theme.palette.warning.main,
  100: theme.palette.error.main
})[progress];

export default theme => makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    height: 'auto',
    boxSizing: 'content-box',
    background: theme.palette.text.secondary + 20,
    border: `1px solid ${theme.palette.text.secondary + 80}`,
    borderRadius: '5px',
    alignItems: 'center',
    margin: '20px 0',
    padding: '20px'
  },
  head: {
    fontSize: '28px',
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginBottom: '10px',
    '& svg.failed': {
      color: theme.palette.warning.main
    }
  },
  targetTitle: {
    fontSize: '28px',
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginBottom: '10px'
  },
  indicator: {
    width: '100px',
    height: 'inherit',
    background: 'red',
    marginRight: '5px'
  },
  information: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    marginLeft: '20px',
    overflow: 'hidden'
  },
  columns: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minWidth: '700px'
  },
  column: {
    marginRight: '50px',

    '& p': {
      margin: '0',
      marginTop: '7px'
    },

    '&:first-child': {
      marginLeft: '0'
    },

    '& .title': {
      color: 'gray'
    },

    '& .content': {
      color: 'black',
      fontSize: '1.2em'
    }
  },

  progressWrapper: {
    height: radius * 2,
    width: radius * 2,
    position: 'relative',

    '& .progressDetails': {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      marginTop: '8px',

      '& .percentage': {
        fontSize: '20px',
        fontWeight: 'bold',
        margin: 0,
        color: ({ progress }) => fillColor(theme, progress)
      },

      '& .description': {
        fontSize: '16PX',
        height: '25px'
      },

      '& .info': {
        margin: '0',
        width: 'max-content',
        maxWidth: '140px',
        textAlign: 'center'
      }
    }
  },
  progress: {
    height: radius * 2 + 'px',
    width: radius * 2 + 'px',
    '& .baseCircle': {
      stroke: '#e1e4e8',
      fill: 'transparent',
      strokeWidth: stroke,
      strokeLinecap: 'round',
      strokeDasharray: circumreference - difference,
      strokeDashoffset: (circumreference - 1 * circumreference) - difference,
      transform: 'rotate(9.7deg)',
      transformOrigin: '50% 50%'
    },

    '& .progressCircle': {
      stroke: ({ progress }) => fillColor(theme, progress),
      fill: 'transparent',
      strokeWidth: stroke,
      strokeLinecap: 'round',
      strokeDasharray: circumreference,
      strokeDashoffset: 180,
      transform: 'rotate(170.3deg)',
      transformOrigin: '50% 50%'
    }
  },
  hashLinks: {
    padding: '10px'
  },
  icon: {
    width: '0.75em',
    height: '0.75em'
  },
  deletedFileInfo: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center'
  },
  fileIcon: {
    width: '1em',
    height: '1em'
  },
  folderIcon: {
    width: '.8em',
    height: '.8em'
  },
  targetName: {
    marginRight: 10,
    maxWidth: '2000px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  targetContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: '1.10em',
    maxWidth: '400px',
    margin: '0px 20px 0px 20px'
  },
  dialogIconSize: {
    width: '4rem',
    height: '4rem'
  },
  delete: {
    color: ' #fa4b54'
  },
  dialogButtonsWrapper: {
    display: 'flex',
    maxWidth: '500px',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    margin: '15px auto',
    padding: '0px 20px 0px 20px',
    '& Button': {
      minWidth: '210px',
      fontSize: '0.8em',
      height: '38px'
    }
  },
  qualifierContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20
  },
  urlTagsSection: {
    display: 'inline-block',
    marginLeft: '15px'
  }
}));
