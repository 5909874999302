import React from 'react';
import { Button } from '@material-ui/core';
import { propTypes } from './DialogButtons.props';
import makeStyles from './DialogButton.styles';

const useStyles = makeStyles();

export default function DialogButtons ({
  confirmLabel = 'confirm',
  cancelLabel = 'cancel',
  onConfirm,
  onCancel,
  setDisabled,
  type = 'info'
}) {
  const classes = useStyles({ type });
  return (
    <>
      <Button
        className={classes.confirmBtn}
        variant="contained"
        color="primary"
        onClick={onConfirm}
        size="large"
        disabled={setDisabled}
      >
        {confirmLabel}
      </Button>
      <Button
        variant="outlined"
        onClick={onCancel}
        size="large"
      >
        {cancelLabel}
      </Button>
    </>
  );
}

DialogButtons.propTypes = propTypes;
