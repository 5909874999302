import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid, Paper } from '@material-ui/core';
import MaterialTable from 'material-table';
import Loading from 'common/Loading';
import { fetchAllProbes } from 'entities/probes/probes.api';
import SelectedProbes from './components/SelectedProbes';
/* context */
import useScanContext from 'contexts/ScanContext/useScanContext';
import { SCAN_ACTIONS } from 'contexts/ScanContext/ScanContext';
import getTableProps from './tableProps';

import makeStyles from './styles';

const useStyles = makeStyles();

export default function AdvancedScan ({ type, setScanAvailable }) {
  const [scan, setScanContext] = useScanContext();
  const [selectedProbes, setSelectedProbes] = useState(() => [...scan.probes]);
  const [selectedProbeNames, setSelectedProbesNames] = useState();
  const [probes, setProbes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalItemsInPage, setTotalItemsInPage] = useState(0);
  const [filterValue, setFilterValue] = useState('');
  const { columns, options } = getTableProps();
  const [filteredProbes, setFilteredProbes] = useState([]);
  const [pageSize, setPageSize] = useState(options.pageSize);
  const classes = useStyles();
  const [tableData, setTableData] = useState({
    data: [],
    page: 0,
    totalCount: 0
  });

  const probesType = type;

  useEffect(() => {
    setScanContext({ type: SCAN_ACTIONS.SELECT_PROBES, payload: [...selectedProbes] }); // update context state after select all the probes
  }, [selectedProbes, setScanContext]);

  useEffect(() => {
    const fetchProbes = async () => {
      const allProbes = await fetchAllProbes();
      const probesByType = allProbes?.data.filter(probe => probe.target_type === probesType);
      const alphabeticalProbes = probesByType.sort((a, b) => a.display_name.localeCompare(b.display_name));
      setProbes(alphabeticalProbes);
      setIsLoading(false);
    };

    fetchProbes();
  }, [probesType]);

  useEffect(() => {
    const filterProbes = () => {
      const filteredProbes = probes.filter(probe =>
        probe.display_name.toLowerCase().includes(filterValue.toLowerCase())
      );
      setFilteredProbes(filteredProbes);
    };

    filterProbes();
  }, [filterValue, probes]);

  useEffect(() => {
    const getSelectedProbesData = (probeIds) => {
      return probeIds.map((probeId) => {
        const probe = probes.find((probe) => probe.id === probeId);
        return probe ? { id: probe.id, displayName: probe.display_name, category: probe.category } : null;
      });
    };

    const selectedProbeNames = getSelectedProbesData(selectedProbes);
    setSelectedProbesNames(selectedProbeNames);
  }, [selectedProbes, probes]);

  const fetchData = useCallback(async () => {
    const getTableData = (tableQuery) => {
      const { page } = tableQuery;
      const data = filteredProbes;
      const totalCount = filteredProbes.length;
      setTotalItemsInPage(data.length);
      return {
        data,
        page: page,
        totalCount
      };
    };

    const initialTableQuery = {
      pageSize,
      page: 0
    };

    const updatedTableData = await getTableData(initialTableQuery);
    setTableData(updatedTableData);
  }, [filteredProbes, pageSize]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onChange = probe => event => {
    const isChecked = event.target.checked;
    setSelectedProbes(prevProbes => {
      if (isChecked) {
        return [...prevProbes, probe.id];
      } else {
        return prevProbes.filter(probeId => probeId !== probe.id);
      }
    });
  };

  const handleOnSelectAll = useCallback(rows => setSelectedProbes(rows.map(probe => probe.id)), []);

  const handleDeleteProbe = (probeId) => {
    setSelectedProbes(prevProbes => prevProbes.filter(probe => probe !== probeId));
  };

  const isHeaderChecked = useMemo(() =>
    selectedProbes.length === 0 ? false : selectedProbes.length === totalItemsInPage,
  [selectedProbes.length, totalItemsInPage]
  );

  const showSelectedProbes = selectedProbes.length > 0;

  const handleSearch = (e) => {
    setFilterValue(e.target.value);
  };

  useEffect(() => {
    setScanAvailable(selectedProbes.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProbes]);

  return (
    <Grid xs={12}>
      <Grid xs={5} className={classes.input}>
        <TextField
            label= 'Search'
            variant="outlined"
            size="medium"
            value={filterValue}
            onChange={(e) => handleSearch(e)}
          />
        </Grid>
        {(!isLoading && showSelectedProbes) && <SelectedProbes selectedProbeNames={selectedProbeNames} onDeleteProbe={handleDeleteProbe}/>}
      <Paper className={classes.paper} elevation={0}>
        {isLoading
          ? <Grid className={classes.loading}><Loading /></Grid>
          : <MaterialTable
            data={tableData.data}
            columns={columns}
            onSelectionChange={handleOnSelectAll}
            onChangeRowsPerPage={setPageSize}
            options={{
              ...options,
              pageSize,
              selection: true,
              selectionProps: rowData => ({
                checked: selectedProbes.some(probeId => probeId === rowData.id),
                onChange: onChange(rowData)
              }),
              headerSelectionProps: {
                checked: isHeaderChecked
              }
            }}
          />}
      </Paper>
    </Grid>
  );
};

AdvancedScan.propTypes = {
  type: PropTypes.string,
  setScanAvailable: PropTypes.func
};
