import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  paper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    '& .MuiTableCell-body': {
      border: 'none',
      boxShadow: 'none'
    },
    '& .MuiCheckbox-root': {
      padding: 0
    }
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 500
  },
  input: {
    marginBottom: 20
  }
}));
