import DialogButtons from './components/DialogButtons';
import DialogContent from './components/DialogContent';
import DialogTitle from './components/DialogTitle';

const DialogExtensions = {
  Title: DialogTitle,
  Content: DialogContent,
  Buttons: DialogButtons
};

export default DialogExtensions;
