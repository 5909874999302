import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  container: {
    marginTop: '50px'
  },
  userSettingsDescription: {
    color: theme.palette.text.secondary,
    display: 'block',
    marginBottom: '2em'
  },
  backIcon: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2em',
    '& > svg': {
      marginRight: 5
    }
  },
  iconStyle: {
    height: 25,
    width: 25,
    cursor: 'pointer'
  },
  dialogIconSize: {
    width: '4rem',
    height: '4rem',
    fill: theme.palette.error.main
  },
  revokeSessionsButton: {
    marginTop: '30px',
    background: theme.palette.text.secondary,
    '&:hover': {
      background: theme.palette.error.dark
    }
  }
}));
