import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    cursor: 'pointer'
  },
  plusContainer: {
    cursor: 'pointer'
  },
  chipAvatar: {
    width: 10,
    height: 10
  },
  tooltipContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: 8
  },
  remainingTags: {
    display: 'flex',
    alignItems: 'center'
  },
  tagAvatar: {
    width: 10,
    height: 10
  },
  tagText: {
    marginLeft: '5px',
    padding: '3px'
  },
  plusIcon: {
    margin: 5,
    maxWidth: '25px',
    minWidth: '25px',
    fontSize: '0.8125rem',
    color: theme.palette.text.secondary,
    padding: 0,
    borderRadius: '25px'
  },
  startIcon: {
    margin: 0
  },
  tagInputContainer: {
    marginBottom: 40
  },
  iconStyle: {
    height: 25,
    width: 25,
    cursor: 'pointer'
  },
  deleteButtonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    margin: '0 auto',
    padding: '0px 10px 10px 10px',
    '& Button': {
      minWidth: '200px'
    }
  }
}));
