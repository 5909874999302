import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles((theme) => ({
  filePreview: {
    height: '60vh',
    borderRadius: '10px',
    margin: 0
  },
  screenshotContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  imgBox: {
    border: 'solid 1px ' + theme.palette.text.secondary,
    padding: '10px',
    borderRadius: '10px'
  }
}));
