import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import useAuthUser from 'hooks/useAuthUser';
import { LockIcon } from 'common/Icons';
import { propTypes } from './UserNameCell.props';
import makeStyles from './UserNameCell.styles';

const useStyles = makeStyles();

export default function UsernameCell ({ fullname, userid, username }) {
  const [t] = useTranslation();
  const classes = useStyles();
  const { auth } = useAuthUser();
  const loggedUserId = _.get(auth, 'identity.id');
  let tooltipText = t('this_is_a_system_account_and_cannot_be_edited');
  const displayLockIcon = loggedUserId === userid || userid === 'anonymous' || username === 'admin';

  if (loggedUserId === userid) {
    tooltipText = t('cannot_edit_a_currently_logged_in_account');
  }

  const displayFullname = displayLockIcon
    ? (
        <Tooltip title={tooltipText}>
          <div className={classes.boxIconWithScan}>
            <LockIcon className={classes.lockIconStyle} title={t('locked')}/>
            <span className={classes.spanWithIcon}>{ fullname }</span>
          </div>
        </Tooltip>
      )
    : <span>{ fullname }</span>;

  return displayFullname;
}

UsernameCell.propTypes = propTypes;
