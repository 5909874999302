import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  root: {
    height: '100vh',
    backgroundColor: '#fff'
  },
  alert: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.error.main,
    borderRadius: theme.shape.borderRadius,
    marginTop: 16,
    marginBottom: 8
  },
  toolbar: {
    textAlign: 'right',
    marginRight: '10%',
    [theme.breakpoints.down('lg')]: {
      marginRight: '5%'
    },
    marginTop: 30,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold
  },
  colorError: { color: theme.palette.error.contrastText },
  dividerLine: {
    padding: theme.spacing(2, 0),
    width: '100%'
  },
  image: {
    width: '55%',
    margin: '20px auto',
    display: 'block'
  },
  productDescriptionWrapper: {
    alignSelf: 'flex-start'
  },
  goToLinkWrapper: {
    width: '100%',
    paddingBottom: 15
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(8, 4),
    marginLeft: '20%',
    marginRight: '20%',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '10%',
      marginRight: '10%'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '15%',
      marginRight: '15%'
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: '20%',
      marginRight: '20%'
    }
  },
  logo: {
    margin: theme.spacing(3),
    marginLeft: 0,
    width: 120
    // backgroundColor: theme.palette.secondary.main
  },
  logoContainer: {
    width: '100%'
    // backgroundColor: theme.palette.secondary.main
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  QFBackgroundText: {
    marginTop: theme.spacing(3)
  },
  QFBackground: {
    backgroundColor: theme.palette.text.secondary + '1A'
  },
  goToLinkContent: {
    display: 'flex',
    justifyContent: 'center'
  },
  signupLink: {
    marginLeft: '10px',
    fontWeight: 'bold'
  }
}));
