import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { ArrowDown, ArrowRight } from 'common/Icons';
import { TreeView } from 'common/TreeView';
import NoResult from '../../../NoResult';
import { statusChip } from './HttpProtocols.utils';
import makeStyles from '../../Communications.style';

const useStyles = makeStyles();

const HttpProtocol = props => {
  const { data } = props;
  const [t] = useTranslation();
  const networkActivities = data.network_activities;

  const classes = useStyles();
  const noResult = <NoResult message={t('not_defined_abbrev')}/>;

  const gridHttp = itemData => {
    return <Grid container>
      <Grid item xs={1}>
        { statusChip(itemData.method ?? itemData.status_code) }
      </Grid>
      <Grid item xs={1}>
        {t('request')}
      </Grid>
      <Grid item xs={2} className={classes.overflowHidden}>
        {itemData.host ?? noResult}
      </Grid>
      <Grid item xs={3}>
        {itemData.timestamp ?? noResult}
      </Grid>
      <Grid item xs={3} className={classes.overflowHidden}>
        {itemData.content ?? noResult}
      </Grid>
      <Grid item xs={1}>
        {itemData.version ?? noResult}
      </Grid>
      <Grid item xs={1}>
        {itemData.size ?? noResult}
      </Grid>
    </Grid>;
  };

  const httpHeader = () => {
    return <Grid container className={classes.headers}>
      <Grid item xs={1}>
        {t('method')}
      </Grid>
      <Grid item xs={1}>
        {t('network_activity')}
      </Grid>
      <Grid item xs={2}>
        {t('host')}
      </Grid>
      <Grid item xs={3}>
        {t('process_ended')}
      </Grid>
      <Grid item xs={3}>
        {t('content')}
      </Grid>
      <Grid item xs={1}>
        {t('version')}
      </Grid>
      <Grid item xs={1}>
        {t('size')}
      </Grid>
    </Grid>;
  };

  const httpData = data => {
    const mainData = data.map(item => {
      return { content: gridHttp(item) };
    });
    mainData.unshift({ content: httpHeader() });

    return [{
      id: '1',
      content: <div>
        <span className={classes.treeItemLabel}>{t('protocol') + ': '}</span><span>{t('http')}</span>
      </div>,
      child: mainData
    }];
  };

  return (
    <>
      <div className={classes.headerLabel}>
        <div>
          <span className={classes.treeItemLabel}>{t('source') + ': '}</span><span>{data.source}</span>
        </div>
        <div>
          <span className={classes.treeItemLabel}>{t('first_seen') + ': '}</span><span>{data.first_seen}</span>
        </div>
        <div>
          <span className={classes.treeItemLabel}>{t('last_seen') + ': '}</span><span>{data.last_seen}</span>
        </div>
        <div>
          <span className={classes.treeItemLabel}>{t('destination') + ': '}</span><span>{data.destination}</span>
        </div>
      </div>
      <TreeView
        className={classes.treeView}
        defaultExpanded={['1', '2', '3']}
        defaultCollapseIcon={<ArrowDown size={20}/>}
        defaultExpandIcon={<ArrowRight size={20} />}
        data={httpData(networkActivities)}
      />
    </>
  );
};

HttpProtocol.propTypes = {
  data: PropTypes.object
};

export default HttpProtocol;
