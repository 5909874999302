import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  dialogIconSize: {
    width: '4rem',
    height: '4rem',
    fill: theme.palette.error.main
  },
  dialogButtonsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '90%',
    margin: '0 auto'
  },
  alertFooter: {
    margin: '24px -40px -24px',
    backgroundColor: theme.palette.primary.main + '26',
    color: theme.palette.primary.main
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold
  }
}));
