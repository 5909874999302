import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    border: 'none',
    cursor: 'pointer',
    marginLeft: -5
  },
  icon: {
    width: '4em',
    height: '4em'
  },
  description: {
    textAlign: 'center'
  }
}));
