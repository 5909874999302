import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Chip } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { fetchTags, createTag } from 'entities/tags/tags.api';
import { jsonToURI } from './AddTagInput.utils';
import PropTypes from 'prop-types';
import makeStyles from './styles';

const useStyles = makeStyles();
const filter = createFilterOptions();

const AddTagInput = ({ appliedTags, setAppliedTags }) => {
  const [t] = useTranslation();
  const [availableTags, setAvailableTags] = useState([]);
  const classes = useStyles();

  const createNewTag = async (valueItem) => {
    if (!valueItem.id) {
      const matchResult = valueItem.text.match(/"([^"]+)"/);
      const newTagText = matchResult ? matchResult[1] : valueItem.text;

      const newTag = {
        text: newTagText,
        color: '047FDC',
        description: `${newTagText} default description`
      };

      try {
        const res = await createTag(jsonToURI(newTag));
        const createdTag = {
          text: res?.data?.text,
          color: res?.data?.color,
          id: res?.data?.id
        };

        setAppliedTags([...appliedTags, createdTag]);
      } catch (error) {
        console.error('Error creating tag:', error);
      }
    } else {
      setAppliedTags([...appliedTags, valueItem]);
    }
  };

  useEffect(() => {
    const fetchTagsData = async () => {
      try {
        const response = await fetchTags();
        setAvailableTags(response.data);
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };
    fetchTagsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedTags]);

  const tagOptions = availableTags;
  const filteredTags = tagOptions?.filter(tag => !appliedTags?.find(selectedTag => selectedTag.id === tag.id));

  const handleTagChange = (_, newValue, reason) => {
    if (reason === 'select-option') {
      newValue?.forEach(createNewTag);
    } else {
      setAppliedTags(newValue);
    }
  };

  return (
    <div>
      <Autocomplete
        multiple
        id="tags-standard"
        filterSelectedOptions
        value={appliedTags}
        className={classes.tagAction}
        onChange={handleTagChange}
        options={filteredTags}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some(option => inputValue === option.text);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              text: `Create new tag "${inputValue}"`
            });
          }
          return filtered;
        }}
        getOptionLabel={({ text }) => text || ''}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((tag, index) => <Chip key={index} label={tag.text} {...getTagProps({ index })} />)
        }
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={t('tags')}
            placeholder={t('add_tags')}
            className={classes.inputTextTags}
          />
        )}
      />
    </div>
  );
};

AddTagInput.propTypes = {
  appliedTags: PropTypes.array,
  setAppliedTags: PropTypes.func.isRequired,
  tags: PropTypes.array
};

export default AddTagInput;
