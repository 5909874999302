import { shape, string } from 'prop-types';

export const propTypes = {
  analysis: shape({
    id: string.isRequired,
    target: shape({
      url: string,
      id: string
    }).isRequired
  }).isRequired
};
