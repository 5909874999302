import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AdvancedScan from 'common/scan/AdvancedScan'; // - TODO: FINISH PROBES STORE IN UI
import PageHeader from 'pages/components/PageHeader/PageHeader';
/* context */
import useScanContext from 'contexts/ScanContext/useScanContext';
import { SCAN_ACTIONS } from 'contexts/ScanContext/ScanContext';

import makeStyles from './styles';

const useStyles = makeStyles();

export default function AdvancedScanDrawer (props) {
  const [, setScanContext] = useScanContext();
  const [scanAvailable, setScanAvailable] = useState(false);
  const [t] = useTranslation();
  const scanFileTranslation = t('scan');
  const classes = useStyles();

  const onScan = () => {
    if (props.type === 'FILE') {
      setScanContext({ type: SCAN_ACTIONS.START_SCAN });
    } else if (props.type === 'URL') {
      setScanContext({ type: SCAN_ACTIONS.START_SCAN_URLS });
    }
  };

  return (
    <Grid
      container
      justify="flex-start"
      spacing={2}
    >
      <Grid className={classes.probeContainerHeader}>
        <div className={classes.backIcon} onClick={() => props.onClose()}> <ArrowBackIcon /> {t('back')}</div>
        <div className={classes.advancedHeader}>
          <div>
          <PageHeader className={classes.headerText} title= {t('advanced_scan')} />
            <p className={classes.headerSubText}>{t('select_below_what_probes_you_would_like')}</p>
          </div>
            <Button
              variant="contained"
              className={classes.scanButton}
              onClick={onScan}
              color="primary"
              disabled={!scanAvailable}
            >
              {scanFileTranslation}
            </Button>
        </div>
      </Grid>
      <AdvancedScan type={props.type} setScanAvailable={setScanAvailable}/>
    </Grid>
  );
};

AdvancedScanDrawer.propTypes = {
  // open: PropTypes.bool.isRequired,
  // anchor: PropTypes.string.isRequired, // needs to be a set
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired
};
