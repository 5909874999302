import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { fetchAnalysisRequest } from 'entities/analysisRequest/analysisRequest.api';
import { isGuestUser } from 'entities/auth/auth.utils';
import { List, ListElement, ListElementText } from 'components/List';
import Panel from './components/Panel';
import Row from './components/Row';
import { COLUMNS } from './URLResults.constants';
import { ROUTE_PATHS } from 'utils/routes.constants';
import { propTypes } from './URLResults.props';
import makeStyles from '../../styles';

const useStyles = makeStyles();

const Results = ({ urlId }) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const isGuest = isGuestUser();
  const classes = useStyles();
  const [analysisList, setAnalysisList] = useState([]);

  const handleOnClick = () => {
    navigate(ROUTE_PATHS.URL_SCAN);
  };

  const getData = async () => {
    const analysisRequest = await fetchAnalysisRequest(urlId);
    return analysisRequest.data?.analysis;
  };

  useEffect(() => {
    getData()
      .then(res => setAnalysisList(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColumns = () => isGuest ? COLUMNS.filter((column) => { return column.key !== 'actions'; }) : COLUMNS;

  const headerRow = getColumns().map(({ primaryText, key, width }) => {
    return <ListElementText
      key={key}
      primary={t(`${primaryText}`)}
      className={classes[key]}
      style={{ width: width }}
    />;
  }
  );

  return (
    <>
      <Grid item lg={6} className={classes.root}>
        <div className={classes.page}>
          <Panel/>
          <div className={classes.table}>
            <div>
              <ListElement divider>
                {headerRow}
              </ListElement>
            </div>
            <List aria-label="file preview list">
              {analysisList.map(analysis => <Row key={analysis.id} analysis={analysis}/>)}
            </List>
          </div>
        </div>
      </Grid>
      <Grid item container justify="center" lg={12}>
        <Grid item xs={2}>
          <Button
            variant="contained"
            fullWidth
            style={{ margin: 12 }}
            onClick={handleOnClick}
            color="primary"
          >
            { t('new_scan') }
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

Results.propTypes = propTypes;

export default Results;
