import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 475
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    '& Button:nth-child(2)': {
      backgroundColor: '#fa4b54',
      '&:hover': {
        backgroundColor: '#f90612'
      }
    }
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 550,
    padding: 20
  },
  bodyText: {
    color: 'grey',
    margin: '0px 0px 20px 0px',
    padding: '10px 10px 10px 10px',
    fontSize: '1em',
    textAlign: 'center'
  },
  disabledTitle: {
    fontSize: '2em',
    margin: '0px 20px 10px 20px',
    textAlign: 'center'
  },
  warning: {
    color: ' #fa4b54'
  }
}));
