import React from 'react';
import { FormControl, Select, InputLabel } from '@material-ui/core';
import propTypes from './OutlinedFieldSelect.props';

export default function OutlinedFieldSelect ({
  label = '',
  inputLabelProps = {},
  children,
  ...outlinedInputProps
}) {
  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor="outlined-adornment-tag" {...inputLabelProps}>
        {label}
      </InputLabel>
      <Select {...outlinedInputProps}>
        {children}
      </Select>
    </FormControl>
  );
};

OutlinedFieldSelect.propTypes = propTypes;
