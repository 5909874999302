import React, { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { deleteURL } from 'entities/urls/urls.api';
import { propTypes } from './DeleteUrlContent.props';
import makeStyles from './DeleteUrlContent.styles';

export default function DeleteUrlContent ({ urlId }) {
  const [t] = useTranslation();
  const useStyles = makeStyles();
  const classes = useStyles();

  useEffect(() => { deleteURL(urlId, { dry: true }); }, [urlId]);

  return (
    <>
      <Typography variant="h4" className={classes.title} >
        <Trans i18nKey={'are_you_sure_delete_url'}>Are you sure you want to <span className={classes.delete}>delete this url?</span></Trans>
      </Typography>
      <Typography variant="body2" className={classes.bodyText}>
        {t('this_action_is_permanent_once_url_is_deleted')}
      </Typography>
    </>
  );
};

DeleteUrlContent.propTypes = propTypes;
