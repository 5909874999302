import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  header: {
    marginBottom: '30px'
  },
  LineChartBlock: {
    marginBottom: '50px'
  }
}));
