import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFlatList } from '../../Details.utils';
import { propTypes } from './Metadata.props';
import GenericTable from '../GenericTable';

const Metadata = ({ metadata, documentMetadata }) => {
  const [t] = useTranslation();
  const metadataRows = getFlatList(metadata.data);
  const documentMetadataRows = getFlatList(documentMetadata.data);
  const isMetadataVisible = metadataRows.length > 0;
  const isDocumentMetadataVisible = documentMetadataRows.length > 0;

  return (
    <>
      {isMetadataVisible && (
        <>
          <GenericTable title={t(metadata.title)}
            columns={metadata.columns}
            rows={metadataRows}/>
          <br/><br/>
        </>
      )}

      {isDocumentMetadataVisible && (
        <GenericTable title={t(documentMetadata.title)}
          columns={documentMetadata.columns}
          rows={documentMetadataRows}/>
      )}
    </>
  );
};

Metadata.propTypes = propTypes;

export default Metadata;
