import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  tabs: {
    height: 'fit-content',
    marginTop: 24,
    width: '180px',
    marginRight: '10px',
    borderLeft: `1px solid ${theme.palette.divider}`,

    '& .MuiTabs-indicator': {
      display: 'none'
    }
  },
  tab: {
    minHeight: '40px',
    margin: '5px 10px',
    color: theme.palette.text.primary,
    fontSize: '16px',
    textTransform: 'none'
  },
  tabSelected: {
    backgroundColor: theme.palette.text.secondary + 25,
    borderRadius: '5px'
  }
}));
