import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Loading from 'common/Loading';
import { fetchPlan } from 'entities/plans/plans.api';
import useDialog from 'hooks/useDialog';
import PlanDetails from './components/PlanDetails';
import PlanAccounts from './components/PlanAccounts';
import Tabs from './components/Tabs';
import makeStyles from './PlanInfo.style';
import AddEditPlan from 'pages/Plans/components/AddEditPlan';
import { FileCopyLine } from 'common/Icons';
import { DialogButtons } from 'common/probes/ProbeDialog';
import { ROUTE_PATHS } from 'utils/routes.constants';

const useStyle = makeStyles();
const myTabs = [
  'plan_details',
  'associated_accounts'
];

export default function PlanInfo () {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const classes = useStyle({});
  const location = useLocation();
  const locationArray = location.pathname.split('/');
  const planId = useMemo(() => locationArray.length > 2 ? locationArray[locationArray.length - 1] : null, [locationArray]);
  const submitButtonRef = useRef();
  const [pageHeaderName, setPageHeaderName] = useState(() => location.state ? location.state.name : '');
  const [isLoading, setIsLoading] = useState(true);
  const [plan, setPlan] = useState();

  const getPlan = async (id) => {
    fetchPlan(id)
      .then(response => {
        setPlan(response);
        setPageHeaderName(response?.name);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const editableContent = <AddEditPlan
    submitButtonRef={submitButtonRef}
    data={plan}
    onSubmit={() => editPlanDialog({ isOpen: false })}
    editMode={true}
    refreshTable={() => getPlan(planId)}
  />;

  const editPlanDialog = useDialog(`edit-plan-dialog-${planId}`, {
    onClose: () => {
      editPlanDialog({ isOpen: false });
    },
    resetOnUpdate: false,
    components: {
      Icon: <FileCopyLine className={classes.dialogIconSize} title={t('add_a_new_plan')}/>,
      Title: <Typography variant="h5" align="center">{t('edit_plan')}</Typography>,
      Content: editableContent,
      Actions: (
        <DialogButtons
          confirmLabel={t('save_changes')}
          cancelLabel={t('cancel')}
          onConfirm={() => submitButtonRef.current.click()}
          onCancel={() => editPlanDialog({ isOpen: false })}
        />
      )
    }
  });

  useEffect(() => {
    if (!location.state) {
      if (planId) {
        getPlan(planId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
  <Grid item xs='12'>
    {isLoading && <Loading size={50}/>}
    {!isLoading && <>
      {planId && <Button
        onClick={() => navigate(ROUTE_PATHS.PLANS)}
        className={classes.navigationButton}
        startIcon={<ArrowBackIcon />}
      >
        {t('back_to_plans')}
      </Button>}
      <div className={classes.headerContainer}>
        <Grid container justify='space-between'>
          <Grid item>
            <Typography variant="h4" component="span" gutterBottom>{t('plan_details_for', { plan_name: `${pageHeaderName}` })}</Typography>
            <Typography>{t('see_below_plan_details')} </Typography>
          </Grid>
          <Grid item>
            <Button
                className={classes.addButton}
                size="medium"
                color="primary"
                variant="contained"
                onClick={() => editPlanDialog({ isOpen: true })}>
              {t('edit_plan')}
            </Button>
          </Grid>
        </Grid>
      </div>
      <Tabs centered tabs={myTabs}>
        {activeIndex => <>
          {activeIndex === 0 && <PlanDetails plan={plan}/>}
          {activeIndex === 1 && <PlanAccounts plan={plan}/>}
        </>}
      </Tabs>
    </>}
  </Grid>);
}
