import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  users: {
    color: ({ isLimitReached }) => isLimitReached ? theme.palette.error.main : 'default',
    display: 'flex',
    alignItems: 'center'
  },
  noUsers: {
    color: theme.palette.grey[500],
    fontStyle: 'italic'
  },
  warningIcon: {
    marginRight: '5px',

    '& svg': {
      fill: theme.palette.error.main,
      stroke: '#FFF'
    }
  }
}));
