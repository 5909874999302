import React from 'react';
import { Chip } from '@material-ui/core';
import { STATUS_CODE, COLOR_RESPONSES } from './HttpProtocol.constants';

const styledChip = data => {
  return <Chip
    label={data.name}
    color="primary"
    variant="outlined"
    style={{ borderColor: data.color, color: data.color }}
  />;
};

export const statusChip = data => {
  if (data) {
    switch (data) {
      case 'GET':
      case 200:
      case 201:
      case 204:
        return styledChip({ name: STATUS_CODE[data], color: COLOR_RESPONSES.POSITIVE });
      case 304:
      case 500:
      case 503:
      case 504:
        return styledChip({ name: STATUS_CODE[data], color: COLOR_RESPONSES.NEUTRAL });
      case 400:
      case 401:
      case 403:
      case 404:
      case 409:
        return styledChip({ name: STATUS_CODE[data], color: COLOR_RESPONSES.NEGATIVE });
      default:
        return styledChip({ name: data, color: 'grey' });
    }
  } else {
    return null;
  }
};
