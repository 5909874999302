import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import makeStyles from './styles';

const useStyles = makeStyles();

// Do you want to install the probe?
export default function DialogButtons ({
  confirmLabel = 'confirm',
  cancelLabel = 'cancel',
  onConfirm,
  onCancel,
  type = 'info'
}) {
  const classes = useStyles({ type });
  return (
    <>
      <Button
       className={classes.confirmBtn}
        variant="contained"
        color="primary"
        onClick={onConfirm}
        size="large"
      >
        {confirmLabel}
      </Button>
      <Button
        variant="outlined"
        onClick={onCancel}
        size="large"
      >
        {cancelLabel}
      </Button>
    </>
  );
}

DialogButtons.propTypes = {
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['warning', 'error', 'info'])
};
