import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertIcon } from 'common/Icons';
import { fetchActivePlan } from 'entities/accounts/accounts.api';
import { getTotalUsersLimit, getTotalUsersUsage } from 'entities/accounts/accounts.utils';
import { fetchPlan } from 'entities/plans/plans.api';
import { propTypes } from './UsersColumn.props';
import makeStles from './UsersColumn.styles';

const useStyles = makeStles();

const UsersColumn = ({ accountId, users }) => {
  const [t] = useTranslation();
  const [activePlan, setActivePlan] = useState({ usage: {} });
  const [isLoading, setIsLoading] = useState(true);
  const [hasNoActivePlan, setHasNoActivePlan] = useState(false);
  const usersLimit = getTotalUsersLimit(activePlan);
  const usersUsage = getTotalUsersUsage(activePlan.usage);
  const isLimitReached = usersUsage / usersLimit === 1;
  const classes = useStyles({ isLimitReached });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const activePlan = await fetchActivePlan(accountId);
      if (activePlan?.plan) {
        const planData = await fetchPlan(activePlan.plan);
        setActivePlan({ ...planData, ...activePlan });
      } else {
        setHasNoActivePlan(true);
      }
      setIsLoading(false);
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    {isLoading && <p>...</p>}
    {!isLoading && hasNoActivePlan && <p className={classes.noUsers}>{users.length}</p>}
    {!isLoading && !hasNoActivePlan && (
      <p className={classes.users}>
        {isLimitReached && <span className={classes.warningIcon}> <AlertIcon size={22} title={t('users_limit_reached')}/> </span>}
        <span>{`${usersUsage}/${usersLimit}`}</span>
      </p>
    )}
  </>;
};

UsersColumn.propTypes = propTypes;

export default UsersColumn;
