let tmpfiles = [];

export const createFile = (
  fileInstance = new File([''], 'filename.txt', {
    type: 'text/plain',
    lastModified: new Date(),
    id: Date.now()
  })
) => {
  return {
    instance: fileInstance,
    password: '',
    status: 'none',
    temporal_Id: Date.now()
  };
};

export const saveTmpFiles = (files) => {
  tmpfiles = [...files];
};

export const getTmpFiles = () => tmpfiles;

export const cleanStoreFiles = () => {
  tmpfiles = [];
};

export default {
  saveTmpFiles,
  getTmpFiles,
  createFile,
  cleanStoreFiles
};
