import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import NoResult from 'pages/Details/components/NoResult';
import GenericTable from 'pages/Details/components/GenericTable';
import { propTypes } from './GenericTabs.props';
import makeStyles from './GenericTabs.style';

const useStyles = makeStyles();

const GenericTabs = ({ tabs }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange} aria-label={t('generic_tabs')} variant="fullWidth">
            {tabs.map((tab, index) =>
              <Tab key={index} disabled={!tab.body}
                label={<span className={classes.tabLabelAndIcon}>{<tab.iconComponent size={15}/>}{t(tab.label ?? tab.key)}</span>}
                value={index}/>
            )}
          </TabList>
        </Box>
        {tabs.map((tab, index) => {
          return <TabPanel key={index} value={index}>
            { tab.isEmpty && <NoResult/>}
            { !tab.isEmpty && tab.type === 'json' && <pre className={classes.code}>{tab.body}</pre>}
            { !tab.isEmpty && tab.type === 'table' && <GenericTable rows={tab.data} columns={tab.columns}/> }
          </TabPanel>;
        })}
      </TabContext>
    </Box>
  );
};

GenericTabs.propTypes = propTypes;

export default GenericTabs;
