import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  code: {
    background: theme.palette.text.secondary + 10,
    padding: '24px',
    overflow: 'auto',
    color: theme.palette.text.primary
  }
}));
