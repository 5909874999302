import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  container: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  description: {
    color: theme.palette.text.secondary,
    display: 'block',
    marginTop: '16px'
  },
  key: {
    color: 'black',
    display: 'inline'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    width: '40px',
    height: '40px',
    textAlign: 'center',
    fontSize: '18px',
    margin: '4px',
    borderRadius: '4px'
  },
  instruction: {
    marginBottom: 8
  }
}));
