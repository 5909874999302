export const getSessions = response => {
  let sessions = [];
  sessions = (response.data ?? []).map((session = {}) => ({
    id: session.id,
    ipAddress: session.devices[0].ip_address,
    lastAccessOn: session.authenticated_at,
    device: '-',
    active: session.active,
    expiresAt: session.expires_at
  }));
  return sessions;
};

export const getSession = response => {
  const session = response.data ?? {};
  return {
    id: session.id,
    ipAddress: session.devices[0].ip_address,
    lastAccessOn: session.authenticated_at,
    device: '-',
    active: session.active,
    expiresAt: session.expires_at,
    currentSession: true
  };
};
