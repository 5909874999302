import tableConfig from 'common/tableConfig';
import { getColumns } from './Users.columns';

const getRowStyle = rowData => (
  rowData.enabled === false ? { color: '#7E8AA3', fontStyle: 'italic', opacity: 0.5 } : {}
);

export default (fetchData, { selfUser }) => ({
  columns: getColumns(fetchData, { selfUser }),
  components: {
    ...tableConfig.components
  },
  options: {
    ...tableConfig.options,
    tableLayout: 'fixed',
    rowStyle: getRowStyle
  }
});
