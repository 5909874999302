import React, { useRef, useState } from 'react';
import MaterialTable from 'material-table';
import { fetchPlans } from 'entities/plans/plans.api';
import getTableProps from './Plans.tableProps';
import { TableLocalization } from 'common/tableConfig';
import { getRange, getItemsCount } from '../pages.utils';

export default function PlansListPage () {
  const localization = TableLocalization();
  const tableRef = useRef(null);
  const submitButtonRef = useRef();
  const refreshTableData = () => tableRef.current.onQueryChange();
  const tableProps = getTableProps({
    submitButtonRef,
    refreshTableData
  });

  const [isLoading, setIsLoading] = useState(true);
  const [resetPageIndex, setResetPageIndex] = useState(false);
  const [pageSize, setPageSize] = useState(tableProps.options.pageSize);

  // fetching data
  const getTableData = tableQuery => {
    setIsLoading(true);
    return new Promise((resolve) => {
      const { pageSize, page } = tableQuery;
      let pageIndex = page;
      // To prevent out of bounds errors, pageIndex
      // should be reset whenever fetching a new query.
      if (resetPageIndex) {
        pageIndex = 0;
        setResetPageIndex(false);
      }
      fetchPlans({ range: getRange(pageIndex, pageSize) })
        .then(json => {
          resolve({
            data: json.data,
            page: pageIndex,
            totalCount: getItemsCount(json.headers['content-range'])
          });
          setIsLoading(false);
        });
    });
  };

  return <>
    <MaterialTable
      title=''
      {...tableProps}
      options={{ ...tableProps.options, pageSize }}
      components={{ ...tableProps.components }}
      onChangeRowsPerPage={setPageSize}
      data={getTableData}
      tableRef={tableRef}
      isLoading={isLoading}
      localization={localization}
    />
  </>;
}
