import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Typography, OutlinedInput, Grid, Button } from '@material-ui/core';
import { useNavigate, useLocation } from 'react-router-dom';
import OtpInput from 'pages/UserSettings/components/MfaConfig/components/OtpInput/OtpInput';
import useAppConfigContext from 'contexts/AppConfigContext/useAppConfigContext';
import { useNotificationSetState } from 'contexts/NotificationContext';
import { useAuthUser } from 'hooks';
import { mfaLogin, mfaBackupLogin } from 'entities/auth/auth.api';
import { isAdmin } from 'entities/auth/auth.utils';
import { ROUTE_PATHS } from 'utils/routes.constants';
import propTypes from './MfaDialog.props';
import makeStyles from './MfaDialog.styles';

const useStyles = makeStyles();

function MfaDialog ({ closeDialog, setError }) {
  const classes = useStyles();
  const [t] = useTranslation();
  const { saveToken } = useAuthUser();
  const setNotification = useNotificationSetState();
  const [passcode, setPasscode] = useState('');
  const [backupCode, setBackupCode] = useState('');
  const { refreshUser } = useAppConfigContext();
  const navigate = useNavigate();
  const location = useLocation();

  const onCancel = () => {
    closeDialog();
  };
  const onConfirm = async () => {
    try {
      let response;
      if (passcode.length > 0) {
        response = await mfaLogin(passcode);
      } else if (backupCode.length > 0) {
        response = await mfaBackupLogin(backupCode.toLowerCase());
      }
      const token = response?.data;
      saveToken(token);
      refreshUser();
      setNotification({
        message: t('now_you_are_logged_in'),
        timeout: 3000
      });
      const isAdminUser = isAdmin();
      if (!location.state) {
        if (isAdminUser) {
          navigate(ROUTE_PATHS.DASHBOARD);
        } else {
          navigate(ROUTE_PATHS.SCAN);
        }
      }
    } catch (error) {
      closeDialog();
      setError(error?.response?.data?.errors?.description || t('something_went_wrong'));
      navigate(ROUTE_PATHS.SIGNIN);
    }
  };

  const handlePasscodeChange = (value) => {
    setPasscode(value);
    if (value.length > 0) {
      setBackupCode('');
    }
  };
  const handleBackupCodeChange = (event) => {
    setBackupCode(event.target.value);
    if (event.target.value.length > 0) {
      setPasscode('');
    }
  };
  const isPasscodeValid = passcode.length === 6;
  const isBackupCodeValid = backupCode.length >= 8;

  return (
      <>
      <Grid className={classes.container}>
          <OtpInput length={6} onChange={handlePasscodeChange} />
          <div className={classes.divider}>
              <Divider style={{ flexGrow: 1 }} />
              <Typography variant="body1" className={classes.or}>
                {t('or').toUpperCase()}
              </Typography>
              <Divider style={{ flexGrow: 1 }} />
          </div>
          <Grid style={{ width: '100%' }}>
              <Typography variant='body1' className={classes.instruction} >{t('if_you_no_longer_have')}</Typography>
              <OutlinedInput
                  fullWidth
                  placeholder={t('enter_backup_code')}
                  value={backupCode}
                  onChange={handleBackupCodeChange}
              />
          </Grid>
      </Grid>
          <div className={classes.buttonContainer}>
              <Button variant="outlined" onClick={onCancel} size="large">
                  {t('cancel')}
              </Button>
              <Button variant="contained" color="primary" onClick={onConfirm} size="large" disabled={!isPasscodeValid && !isBackupCodeValid}>
                  {t('verify')}
              </Button>
          </div>
      </>
  );
}
MfaDialog.propTypes = propTypes;
export default MfaDialog;
