import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuActions from 'components/MenuActions';
import propTypes from './ActionsMenu.props';

export default function ActionsMenu ({ action }) {
  const [t] = useTranslation();

  const options = [
    {
      key: 'enable_all_probes',
      placeholder: t('enable_all'),
      visible: true,
      onClick: () => {
        action('enable_all_probes');
      }
    },
    {
      key: 'disable_all_probes',
      placeholder: t('disable_all'),
      visible: true,
      onClick: () => {
        action('disable_all_probes');
      }
    }
  ];

  return <MenuActions options={options} type={'vertical'} />;
};

ActionsMenu.propTypes = propTypes;
