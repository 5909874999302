import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFlatList } from '../../Details.utils';
import GenericTable from '../GenericTable';
import { propTypes } from './ExecutableInfo.props.';

const ExecutableInfo = ({ executableInfo, executableAdvanced }) => {
  const [t] = useTranslation();
  const executableInfoRows = getFlatList(executableInfo.data);
  const executableAdvancedRows = getFlatList(executableAdvanced.data);
  const isExecutableInfoVisible = executableInfoRows.length > 0;
  const isExecutableAdvancedVisible = executableAdvancedRows.length > 0;

  return (
    <>
      {isExecutableInfoVisible && (
        <>
          <GenericTable title={t(executableInfo.title)}
            columns={executableInfo.columns}
            rows={executableInfoRows}/>
          <br/><br/>
        </>
      )}

      {isExecutableAdvancedVisible && (
        <GenericTable title={t(executableAdvanced.title)}
          columns={executableAdvanced.columns}
          rows={executableAdvancedRows}/>
      )}
    </>
  );
};

ExecutableInfo.propTypes = propTypes;

export default ExecutableInfo;
