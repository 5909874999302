import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { ArrowDown, ArrowRight } from 'common/Icons';
import { TreeView } from 'common/TreeView';
import { getFlatList, filterListToObjectByAttribute } from 'pages/Details/Details.utils';
import GenericTable from '../GenericTable';
import ProbeTabes from '../ProbeTabs';
import { propTypes } from './Behavior.props';
import { BEHAVIOR_COLUMNS } from './Behavior.constants';
import MitreLink from './components/MitreLink';
import makeStyles from '../../styles';

const useStyles = makeStyles();

const Behavior = ({ data }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const { behavior = [], mitreAttackTechnique = [] } = data;
  const behaviorRows = getFlatList(behavior);
  const mitreAttackTechniqueRows = getFlatList(mitreAttackTechnique);
  const isBehaviorVisible = behaviorRows.length > 0;
  const isMitreAttackTechniqueVisible = mitreAttackTechniqueRows.length > 0;

  const rowsPerProbeType = useMemo(() => {
    return filterListToObjectByAttribute(getFlatList(behavior), 'probeName');
  }, [behavior]);
  const probes = useMemo(() => Object.keys(rowsPerProbeType), [rowsPerProbeType]);

  const rowsPerNameType = useMemo(() => {
    return filterListToObjectByAttribute(getFlatList(mitreAttackTechnique), 'name');
  }, [mitreAttackTechnique]);

  const cellProps = {
    className: classes.cell,
    component: 'th',
    scope: 'row'
  };

  const mitreData = data => {
    const mainData = Object.entries(data).map(item => {
      return {
        id: item[0],
        content: <div>
          <span className={classes.treeItemLabel}>{t('technique')}</span><span>{item[0]}</span>
        </div>,
        child: [{
          id: item[0] + 'sub',
          content: <Table className={classes.table} size="small" aria-label={t('technique_table')}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell} align="left">{t('tactics')}</TableCell>
                <TableCell className={classes.headerCell} align="left">{t('technique_id')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {item[1].map((item) => (
                <TableRow key={item.technique_id} hover={true}>
                  <TableCell {...cellProps}>{item.description}</TableCell>
                  <TableCell {...cellProps}>{<MitreLink id={item.technique_id} />}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }]
      };
    });
    return <TreeView
      className={classes.treeView}
      defaultExpanded={['1', '2', '3']}
      defaultCollapseIcon={<ArrowDown size={20}/>}
      defaultExpandIcon={<ArrowRight size={20} />}
      data={mainData}
    />;
  };

  return (
    <>
      {isBehaviorVisible &&
        <Grid container spacing={1}>
          <ProbeTabes tabs={probes}>
            {activeTabIndex => (
              <GenericTable title={t('behavior')}
                columns={BEHAVIOR_COLUMNS}
                rows={rowsPerProbeType[probes[activeTabIndex]]}/>
            )}
          </ProbeTabes>
        </Grid>
      }

      {isMitreAttackTechniqueVisible &&
        <>
          <div className={classes.fileInfoHeader}>
            <Typography variant="h4" className={classes.panelTitle}>
            {t('mitre_attack_technique')}
            </Typography>
          </div>
          {mitreData(rowsPerNameType)}
        </>
      }
    </>
  );
};

Behavior.propTypes = propTypes;

export default Behavior;
