import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackSetState } from 'contexts/SnackContext';
import { Typography, OutlinedInput, InputAdornment, IconButton, Grid, InputLabel, FormControl } from '@material-ui/core';
import { InfoIcon, LockIcon } from 'common/Icons';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { fetchFileContentAsZip, downloadFileContent } from 'entities/files/files.api';
import { MIME_TYPES } from 'entities/files/files.constants';
import { propTypes } from './ZipDownloadForm.props';
import makeStyles, { CustomSwitch } from './ZipDownloadForm.styles';

const useStyles = makeStyles();

export const ZipDownloadForm = ({ submitButtonRef, file, handleDialogClose }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const setSnack = useSnackSetState();
  const [showPassword, setPasswordVisibility] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordEnabled, setPasswordEnabled] = useState(false);

  const DEFAULT_PASSWORD = 'infected';

  const handleClickShowPassword = (event) => {
    setPasswordVisibility(!showPassword);
    event.preventDefault();
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const toggleEnablePassword = () => {
    if (passwordEnabled === false) {
      setPasswordEnabled(true);
      setPassword(DEFAULT_PASSWORD);
    }
    if (passwordEnabled === true) {
      setPasswordEnabled(false);
      setPassword('');
    }
  };

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const onZipDownloadSubmit = async (password) => {
      try {
        const response = await fetchFileContentAsZip(file.id, {
          password: password
        });
        downloadFileContent(response.data, file, MIME_TYPES.APPLICATION.zip);
      } catch (error) {
        setSnack({
          isOpen: true,
          title: t('something_went_wrong'),
          severity: 'error'
        });
      } finally {
        handleDialogClose();
      }
    };

    onZipDownloadSubmit(password);
  }, [password, file, handleDialogClose, setSnack, t]);

  return (
        <form onSubmit={handleSubmit} className={classes.formLayout} >
            <Typography variant='body2' align='center' className={classes.bodyText}>{t('in_order_to_prevent_accidental_file_deletion_or_opening')}</Typography>
            <Grid className={classes.inputLayout}>
            <Typography className={classes.label}><LockIcon color='black' className={classes.lockIcon}/>{t('password_protection')}</Typography>
            <CustomSwitch onChange={toggleEnablePassword}/>
            </Grid>
            {passwordEnabled && <FormControl variant='outlined'>
            <InputLabel htmlFor='password' >{t('password')}</InputLabel>
            <OutlinedInput
                className={classes.formInput}
                label='password'
                name='password'
                type={showPassword ? 'text' : 'password'}
                id='password'
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                endAdornment={password &&
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>}
            />
            <Typography variant='body2' className={classes.infotext} ><InfoIcon className={classes.infoIconSize}/>{t('the_default_password_can_be_changed')} </Typography></FormControl> }
            <button ref={submitButtonRef} style={{ display: 'none' }} type='submit'></button>
            </form>
  );
};
ZipDownloadForm.propTypes = propTypes;

export default ZipDownloadForm;
