import HTTP from 'common/http';

const READINESS = '/readiness';

export const fetchReadiness = () => {
  const url = READINESS;
  return HTTP.get(url);
};

export const fetchUrlAnalysisEnabled = () => {
  return fetchReadiness().then(response => {
    return response?.data?.url_analysis_enabled;
  });
};
