import React from 'react';
import { TreeView } from '@material-ui/lab';
import { propTypes } from './TreeView.props';
import { StyledTreeItem } from './components/StyledTreeItem';

const TreeViewComponent = ({ className, defaultExpanded, defaultCollapseIcon, defaultExpandIcon, defaultEndIcon, data }) => {
  const renderItem = (itemList) => {
    return itemList.map((item) => (
      <StyledTreeItem key={item.id} nodeId={item.id} content={item.content}>
        {item.child && renderItem(item.child)}
      </StyledTreeItem>
    ));
  };

  return <TreeView
    className={className}
    defaultExpanded={defaultExpanded}
    defaultCollapseIcon={defaultCollapseIcon}
    defaultExpandIcon={defaultExpandIcon}
    defaultEndIcon={defaultEndIcon}
  >
    {renderItem(data)}
  </TreeView>;
};

TreeViewComponent.propTypes = propTypes;

export default TreeViewComponent;
