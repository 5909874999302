export const DATE_FORMAT = 'MMMM D, YYYY h:mm A';

export const COLUMNS = [
  {
    name: 'scanned_at',
    id: 'created_at'
  },
  {
    name: 'safety',
    id: 'safety'
  },
  {
    name: 'status',
    id: 'status'
  }
];
