import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { InfoOutline } from 'common/Icons';
import { useDialog } from 'hooks';
import HealthStatusQF from 'pages/Scanner/components/HealthStatusQF';
import makeStyles from './InfoButton.styles';

const useStyles = makeStyles();

export default function InfoButton () {
  const [t] = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onDisplayDialog = useDialog('health-dialog', {
    onClose: () => {
      onDisplayDialog({ isOpen: false });
    },
    components: {
      Icon: null,
      Title: (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ),
      Content: <HealthStatusQF />
    }
  });

  return (<>
      <Button
        onClick={openMenu}
        endIcon={<KeyboardArrowDownIcon />}
        classes={{ endIcon: classes.endIcon }}
      >
        <InfoOutline className={classes.infoIcon} title={t('info')} />
      </Button>
      <Menu
        id="info-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={onClose}
      >
        <MenuItem onClick={() => onDisplayDialog({ isOpen: true })}>
          {t('qflow_stat')}
        </MenuItem>
      </Menu>
    </>
  );
}
