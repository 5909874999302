import { makeStyles } from '@material-ui/core/styles';

const WARNING_LEVEL = 100;
const barHeight = '12px';

export default theme => makeStyles((theme) => ({
  container: {
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '130px',
    width: '100%',
    minWidth: '200px',
    border: `1px solid ${theme.palette.text.secondary + 50}`,
    padding: '20px'
  },
  addedSoFar: {
    display: 'flex',
    justifyContent: 'space-between',
    color: ({ progress }) =>
      progress === WARNING_LEVEL ? theme.palette.warning.main : '#000'
  },
  accountType: {
    fontWeight: 'bold'
  },
  limit: {
    color: theme.palette.text.secondary
  },
  accountUsage: {
    width: '300px'
  },
  bar: {
    background: theme.palette.text.secondary + 40,
    width: '100%',
    maxWidth: '100%',
    height: barHeight,
    borderRadius: '20px',
    zIndex: 99,

    '&::after': {
      content: '""',
      display: 'block',
      height: barHeight,
      width: '100%',
      backgroundColor: ({ progress }) =>
        progress === WARNING_LEVEL ? theme.palette.warning.main : theme.palette.primary.main,
      position: 'relative',
      borderRadius: '20px',
      maxWidth: ({ progress }) => `${progress}%`,
      top: 0,
      left: 0,
      right: 0
    }
  }
}));
