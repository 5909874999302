export const componentsHashMap = {
  argo: 'argo',
  control: 'control',
  database: 'database',
  permission: 'permission',
  provider: 'provider',
  standard_roles: 'standard_roles',
  storage: 'storage',
  workflows: 'workflows'
};

export const serviceStatusMessageMap = {
  UP: 'up_and_running',
  DEGRADED: 'down'
};

export const serviceHashMap = {
  UP: 'up',
  DEGRADED: 'degraded'
};

export const getStatusLabel = (status) => {
  const label = serviceHashMap[status];
  return label || serviceHashMap.DEGRADED;
};
