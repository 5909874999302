import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '90%',
    margin: '0 auto',
    '& Button': {
      width: '250px',
      height: '50px',
      padding: '15px'
    }
  },
  bodyText: {
    color: 'grey',
    margin: '0px 0px 20px 0px',
    padding: '10px 10px 10px 10px',
    fontSize: '1em',
    textAlign: 'center'
  },
  disabledTitle: {
    fontSize: '2em',
    margin: '0px 20px 10px 20px',
    textAlign: 'center'
  },
  warningIcon: {
    fill: theme.palette.warning.main,
    marginBottom: 30
  }
}));
