import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Chip, Typography } from '@material-ui/core';
import makeStyles from './SelectedProbes.styles';
import { getBackgroundColor } from '../../AdvancedScan.utils';
import { propTypes } from './SelectedProbes.props';
const useStyles = makeStyles();

export default function SelectedProbes ({ selectedProbeNames, onDeleteProbe }) {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <Grid className={classes.container}>
      <Typography className={classes.label}>{t('selected_probes')}:</Typography>
      {selectedProbeNames?.map((probe) => {
        const backgroundColor = getBackgroundColor(probe?.category);
        return (
          <Chip
            className={classes.selectedChip}
            key={probe?.id}
            label={probe?.displayName}
            onDelete={() => onDeleteProbe(probe?.id)}
            style={{ backgroundColor: backgroundColor }}
          />
        );
      })}
    </Grid>
  );
}

SelectedProbes.propTypes = propTypes;
