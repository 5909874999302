import i18next from 'i18next';
import dayjs from 'dayjs';
import { DOLLAR, EURO, NO_PRICE } from './plans.constants';

/**
 * Get subscription cost string E.g $10/mo
 * @param {*} costObject cost object
 * @param {*} period subscription period
 * @param {*} currency account currency (account.currency)
 * @returns a string representation of the subscription cost.
 */
export const getSubscriptionCost = (costObject = {}, period = '', currency = '') => {
  const dollarPrice = costObject[DOLLAR.KEY] && `${DOLLAR.SYMBOL}${costObject[DOLLAR.KEY]}/${period}`;
  const euroPrice = costObject[EURO.KEY] && `${EURO.SYMBOL}${costObject[EURO.KEY]}/${period}`;
  // Use account currency
  if (currency.toUpperCase() === DOLLAR.KEY && dollarPrice) {
    return dollarPrice;
  } else if (currency.toUpperCase() === EURO.KEY && euroPrice) {
    return euroPrice;
  }
  // Fallback
  return dollarPrice || euroPrice || `${NO_PRICE}/${period}`;
};

export const getRenewalDateFormatted = (date = '') => {
  const locale = i18next.language;
  return new Date(date).toLocaleString(locale, { day: 'numeric', month: 'short' });
};

export const getTimeBeforeRenewal = (date) => {
  const today = dayjs();
  const endDate = dayjs(date);
  const hoursBeforeRenewal = endDate.diff(today, 'hour');
  const daysBeforeRenewal = hoursBeforeRenewal / 24;
  // TODO: if we go past the renewal date expired X days ago etc.
  if (daysBeforeRenewal < 0) {
    return {
      measurement: 'days',
      value: 0
    };
  }
  const hoursBeforeRenewalRounded = Math.floor(hoursBeforeRenewal);
  const daysBeforeRenewalRounded = Math.floor(daysBeforeRenewal);
  if (daysBeforeRenewalRounded <= 1) {
    return {
      measurement: 'hours',
      value: hoursBeforeRenewalRounded
    };
  } else {
    return {
      measurement: 'days',
      value: daysBeforeRenewalRounded
    };
  }
};
