export const isExpiringSoon = (expiryDate) => {
  const todaysDate = new Date();
  const sevenDaysFromNow = todaysDate.setDate(todaysDate.getDate() + 7);
  const expiryAsDate = new Date(expiryDate);
  return expiryAsDate < sevenDaysFromNow;
};

export const formatExpiryDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
};

export const daysUntilExpiry = (dateString) => {
  const today = new Date();
  const expiry = new Date(dateString);
  const timeDiff = expiry.getTime() - today.getTime();
  const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  return dayDiff;
};
