import React from 'react';
import { useTranslation } from 'react-i18next';
import AccountTable from './AccountTable';
import PageHeader from 'pages/components/PageHeader';
import PropTypes from 'prop-types';

export default function AccountsListPage ({ plan }) {
  const [t] = useTranslation();
  return <>
    <PageHeader title={t('accounts')} />
    <AccountTable plan={plan} />
  </>;
};

AccountsListPage.propTypes = {
  plan: PropTypes.obj
};
