import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Bar from './components/Bar';
import makeStyles from './styles';
const useStyles = makeStyles();

export default function BaseLayout (props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={classes.appBar}
      >
        <Bar open={false} />
      </AppBar>
      <div className={classes.page}>
        {props.children}
      </div>
    </div>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.element
};
