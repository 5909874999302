import { makeStyles } from '@material-ui/core/styles';
export default theme => makeStyles(theme => ({
  errorLayout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  errorImage: {
    width: '35%',
    display: 'block',
    margin: '0 auto'
  }
}));
