import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  qualifierChip: {
    display: 'flex',
    height: '25px',
    width: '100px',
    borderRadius: '20px',
    padding: 10
  },
  scanningLabel: {
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    justifyContent: 'space-between'
  },
  whiteText: {
    color: 'white',
    fontSize: '0.8rem'
  },
  circularProgress: {
    marginLeft: '5px',
    color: 'white'
  },
  infoIcon: {
    width: '13px',
    height: '13px',
    padding: '0px'
  },
  tooltip: {
    color: '#FFFFFF',
    fontFamily: theme.typography.caption.fontFamily,
    fontSize: 15
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between'
  },
  infoIconContainer: {
    padding: '0px',
    cursor: 'auto'
  }
}));
