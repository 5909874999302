export const INITIAL_SEARCH_QUERY = {
  BASIC: {
    sort: 'updated:desc'
  },
  ADVANCED: {
    main: 'name',
    searchText: '',
    status: '',
    sort: 'updated:desc',
    safety: '',
    tags: []
  }
};
