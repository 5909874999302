import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  constructor (props) {
    super(props);
    this.state = {
      hasError: false,
      error: undefined,
      errorInfo: undefined
    };
  }

  componentDidCatch (error, info) {
    console.log('Inside componentDidCatch in ErrorBoudanry component');
    this.setState({
      hasError: true,
      error: error.error,
      errorInfo: info
    });
    console.error(error, info);
  }

  render () {
    const { classes } = this.props;
    if (this.state.hasError) {
      return (
        <div className={classes.container}>
          <p>A problem has been detected and Win...no, wait.</p>
          <p>It was not your operating system. It&#39;s our code.</p>
          <p>And that is weird. Click <a href="/">here</a> to refresh the page and start again.</p>
          <p>Error: {this.state.error && this.state.error.toString()}</p>
          <details open style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.element
};

export default ErrorBoundary;
