import React from 'react';
import { useTranslation } from 'react-i18next';
import CategoryChip from './components/CategoryChip/CategoryChip';
import NameChip from './components/NameChip/NameChip';

const GetProbeNameColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('probe_name'),
    width: '15%',
    sorting: false,
    cellStyle: {
      maxWidth: 300,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      border: 'none'
    },
    render: rowData => <NameChip name = {rowData.display_name} category = {rowData.category}/>
  };
};

const GetProbeTypeColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('probe_type'),
    width: '15%',
    sorting: false,
    render: rowData => <CategoryChip category = {rowData.category}/>
  };
};

const GetSummaryColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('summary'),
    width: '35%',
    sorting: false,
    render: rowData =>
    <p>{rowData.summary}</p>
  };
};

const GetVersionColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('version'),
    width: '15%',
    sorting: false,
    render: rowData => <p>{rowData.version.software || 'N/A'}</p>
  };
};

const GetDatabaseVersionColumn = () => {
  const [t] = useTranslation();
  return {
    title: t('database_version'),
    field: 'date',
    width: '15%',
    sorting: false,
    render: rowData => <p>{rowData.version.database || 'N/A'}</p>
  };
};

export const getColumns = () => {
  return [
    GetProbeNameColumn(),
    GetProbeTypeColumn(),
    GetSummaryColumn(),
    GetVersionColumn(),
    GetDatabaseVersionColumn()
  ];
};
