import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  container: {
    overflow: 'hidden'
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 500
  },
  sideBySide: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center'
  },
  itemOne: {
    marginRight: 30
  },
  itemTwo: {
    marginLeft: 30
  }
}));
