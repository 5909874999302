import {
  FuturaStdHeavy,
  FuturaStdMedium,
  FuturaStdBook,
  FuturaStdBold,
  RobotoMono
} from './fonts.js';

export default () => ({
  palette: {
    primary: {
      light: '#3698e3',
      main: '#047FDC',
      dark: '#02589a'
    },
    secondary: {
      light: '#414d70',
      main: '#12214D',
      dark: '#0c1735'
    },
    error: {
      light: '#fb6f76',
      main: '#FA4B54',
      dark: '#af343a'
    },
    success: {
      light: '#59bf68',
      main: '#30B043',
      dark: '#217b2e'
    },
    warning: {
      light: '#ffb368',
      main: '#FFA143',
      dark: '#b2702e'
    },
    probeCategory: {
      tools: '#D24E75',
      metadata: '#45A59E',
      external: '#ECB93F',
      antivirus: '#1659a5'
    },
    text: {
      primary: '#080523',
      secondary: '#79859F',
      disabled: '#939db2'
    }
  },
  typography: {
    iconSize: '6rem',
    h1: { fontFamily: 'FuturaStdBold' },
    h2: { fontFamily: 'FuturaStdBold' },
    h3: { fontFamily: 'FuturaStdHeavy' },
    h4: { fontFamily: 'FuturaStdHeavy' },
    h5: { fontFamily: 'FuturaStdBook' },
    h6: { fontFamily: 'FuturaStdBook' },
    subtitle1: { fontFamily: 'FuturaStdMedium' },
    subtitle2: { fontFamily: 'FuturaStdMedium' },
    body1: { fontFamily: 'FuturaStdBook' },
    body2: { fontFamily: 'FuturaStdBook' },
    caption: { fontFamily: 'FuturaStdBook' },
    button: { fontFamily: 'FuturaStdBook' },
    overline: { fontFamily: 'FuturaStdMedium' }
  },
  overrides: {
    MuiButtonGroup: {
      grouped: {
        minWidth: 51
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#9e9e9e'
      }
    },
    MuiButton: {
      contained: {
        padding: '15px 40px', // medium is default size
        fontSize: '14px',
        lineHeight: '14px'
      },
      containedSizeLarge: {
        padding: '15px 40px',
        minWidth: 175,
        maxWidth: 250
      },
      outlinedSizeLarge: {
        padding: '7px 35px',
        minWidth: 175,
        maxWidth: 250
      },
      outlined: {
        padding: '7px 35px'
      },
      root: {
        padding: 0,
        textTransform: 'none',
        fontSize: '16px'
      }
    },
    MuiFormControl: {
      root: {
        width: '100%',
        marginTop: 16,
        marginBottom: 8
      }
    },
    MuiInputBase: {
      input: {
        height: '14px'
      }
    },
    MuiTableRow: {
      footer: {
        margin: '10px auto'
      }
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [FuturaStdHeavy, FuturaStdMedium, FuturaStdBook, FuturaStdBold, RobotoMono]
      }
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: 700,
        minWidth: 500,
        padding: '30px 40px 25px'
      }
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'space-between'
      }
    }
  }
});
