import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  headerContainer: {
    marginBottom: 20
  },
  subheading: {
    fontSize: 24,
    fontFamily: 'FuturaStdHeavy',
    marginTop: 20,
    '&:first-child': {
      marginTop: 12
    }
  },
  navigationButton: {
    marginBottom: '20px'
  }
}));
