import HTTP from 'common/http';
import { API } from './auth.constants';

export const login = data => {
  const url = API.LOGIN;
  return HTTP.post(url, { data });
};

export const mfaLogin = (otp) => {
  const url = API.MFA.LOGIN;
  return HTTP.post(url, { params: { totp_code: otp } });
};
export const mfaBackupLogin = (backupCode) => {
  const url = API.MFA.LOGIN_WITH_BACKUP;
  return HTTP.post(url, { params: { lookup_secret: backupCode } });
};
export const register = data => {
  const url = API.REGISTER;
  return HTTP.post(url, { data });
};

export const logout = () => {
  const url = API.LOGOUT;
  return HTTP.post(url);
};

export const verifyEmail = email => {
  const url = API.RECOVERY.INIT;
  return HTTP.post(url, {
    data: { email }
  });
};

export const confirmChallenge = ({ flowId, token, newPassword }) => {
  const url = API.RECOVERY.CHALLENGE;
  return HTTP.post(url, {
    data: { token, flowid: flowId, new_password: newPassword }
  });
};

export const updateIdentity = data => {
  const url = API.UPDATE.IDENTITY;
  return HTTP.post(url, { data });
};

export const updatePassword = data => {
  const url = API.UPDATE.PASSWORD;
  return HTTP.post(url, { data });
};

export const getMfaDetails = data => {
  const url = API.MFA.REGISTER;
  return HTTP.get(url);
};

export const registerMfaDevice = (flowId, otp) => {
  const url = API.MFA.REGISTER;
  return HTTP.post(url, {
    data: { flowid: flowId, totp_code: otp }
  });
};

export const unlinkMfaDevice = () => {
  const url = API.MFA.UNLINK;
  return HTTP.get(url);
};

export const getMfaBackupCodes = () => {
  const url = API.MFA.BACKUP;
  return HTTP.get(url);
};

export const registerMfaBackupCodes = (flowId) => {
  const url = API.MFA.BACKUP;
  return HTTP.post(url, {
    data: { flowid: flowId }
  });
};

export const validateMfaBackupCodes = (flowId) => {
  const url = API.MFA.BACKUP;
  return HTTP.post(url, {
    data: { flowid: flowId }
  });
};

export const fetchActiveMethods = () => {
  const url = API.ACTIVE_METHODS;
  return HTTP.get(url);
};
