import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  addUserForm: {
    maxWidth: '600px'
  },
  inlineError: {
    color: theme.palette.error.main
  }
}));
