import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@material-ui/core';
import CommonTable from '../CommonTable';
import DownloadContent from './components/DownloadContent';
import { propTypes } from './Artifacts.props';
import { LinkExternal } from '../../../../common/Icons/index';
import makeStyles from './Artifacts.style';

const useStyles = makeStyles();

export default function Artifacts ({ data = [] }) {
  const [t] = useTranslation();

  const trimUrl = (url, length) => {
    return (url.length > length) ? url.slice(0, length - 1) + '...' : url;
  };

  const classes = useStyles();
  const artifactMapper = {};
  const linksMapper = {};

  data.fileLatestResults.forEach((result) => {
    if (result.artifacts.length > 0) {
      result.artifacts.slice(0, 100).forEach(artifact => {
        artifactMapper[artifact.type] = [...(artifactMapper[artifact.type] ?? []),
          {
            ...artifact,
            probeName: result.probe.display_name,
            action: artifact.mimetype ? <DownloadContent content={artifact} /> : <Typography variant="p1" className={classes.deletedText}>{t('file_deleted')}</Typography>
          }
        ];
      });
    }
  });

  data.ExternalLink.forEach((result) => {
    const probeName = result.probe.display_name;
    result.data.forEach(info => {
      linksMapper[info.type] = [...(linksMapper[info.type] ?? []),
        {
          link: <a className={classes.link} rel='noreferrer' target='_blank' href={info.value}>{trimUrl(info.value, 40)}<LinkExternal className={classes.icon} title={t('open_link')}/></a>,
          description: info.description || t('artifact_analysis_details_link', { probe_name: probeName })
        }
      ];
    });
  });

  const artifactsColumns = [
    { id: 'name', name: 'artifact_name', width: '20%' },
    { id: 'mimetype', name: 'mime_type', width: '30%' },
    { id: 'probeName', name: 'probe', width: '15%' },
    { id: 'action', name: 'action', width: '10%' }
  ];
  const linksColumns = [
    { id: 'link', name: 'link' },
    { id: 'description', name: 'description' }
  ];

  return (
    <Grid spacing={1} >
      <Grid item xs={12}>
        {
          Object.keys(artifactMapper).map(artifactType => {
            const isExtracted = artifactType === 'EXTRACTED';
            const tableStyling = isExtracted ? classes.extracted : '';
            return (
              <Grid key={artifactType} className={tableStyling} >
                <CommonTable
                  title={artifactType}
                  columns={artifactsColumns}
                  data={artifactMapper[artifactType]}
                />
              </Grid>
            );
          })
        }
        {
          Object.keys(linksMapper).map(link => {
            return (
              <CommonTable
                key={linksMapper[link]}
                title={t('external_links')}
                columns={linksColumns}
                data={linksMapper[link]}
              />
            );
          })
        }
      </Grid>
    </Grid>
  );
};

Artifacts.propTypes = propTypes;
