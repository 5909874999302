import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import clsx from 'clsx';
import { ReactComponent as Logo } from 'assets/qflow_logo.svg';
import { ReactComponent as QFBackgroundImage } from 'assets/illustration.svg';
import { FormControl, Button, Divider, Grid, InputLabel, Typography, OutlinedInput, IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import LanguageSwitcher from 'components/LanguageSwitcher';
import { useNotificationSetState } from 'contexts/NotificationContext';
import useAppConfigContext from 'contexts/AppConfigContext/useAppConfigContext';
import { AlertIcon } from 'common/Icons';
import { useAuthUser } from 'hooks';
import { getServerError } from 'pages/pages.utils';
import { ROUTE_PATHS } from 'utils/routes.constants';
import { selfRegister } from 'entities/users/users.api';
import makeStyles from './styles';

const useStyles = makeStyles();

export default function SignUp (props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  // fields
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastname, setLastname] = useState('');
  // fields
  const [showPassword, setPasswordVisibility] = useState(false);
  const [error, setError] = useState(false);
  const { saveToken } = useAuthUser();
  const navigate = useNavigate();
  const { refreshUser } = useAppConfigContext();
  const setNotification = useNotificationSetState();

  const successMessage = t('you_are_now_registered');
  const defaultErrorMessage = t('something_went_wrong');

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setIsLoading(true);
    selfRegister({ username, password, last_name: lastname, email, first_name: firstName })
      .then(response => {
        setIsLoading(false);
        setNotification({
          message: successMessage,
          timeout: 3000
        });
        saveToken(response.data);
        refreshUser();
        navigate(ROUTE_PATHS.SCAN);
      })
      .catch(error => {
        setIsLoading(false);
        setError(getServerError(error, defaultErrorMessage));
      });
  };

  const goGuestUser = () => {
    navigate(ROUTE_PATHS.SCAN);
  };

  const handleClickShowPassword = () => {
    setPasswordVisibility(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid container component="main" maxWidth="xs" className={classes.root}>
      <Grid item sm={12} md={5} lg={4} square>
        <Grid className={classes.toolbar}>
          <LanguageSwitcher />
        </Grid>
        <div className={classes.paper}>
          <div className={classes.logoContainer}>
            <div className={classes.logo}>
              <Logo />
            </div>
          </div>
          <div className={classes.productDescriptionWrapper}>
            <Typography
              className={classes.productDescription}
              component="h5"
              align="left"
              data-test="auth-layout-product-description"
            >
              {t('analyze_suspicious_files_to_detect_types_of_malware')}
            </Typography>
          </div>

          <Grid container>
            <form className={classes.form} onSubmit={handleSubmit} /* method="post" submit={formValues.action} */>
              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-username">
                  {t('email')}
                </InputLabel>

                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  name="email"
                  labelWidth={70}
                  type="email"
                  autoFocus
                  id="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </FormControl>
              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-username">
                  {t('first_name')}
                </InputLabel>

                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  name="firstName"
                  labelWidth={70}
                  type="text"
                  autoFocus
                  id="firstName"
                  autoComplete="firstName"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
              </FormControl>
              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-username">
                  {t('last_name')}
                </InputLabel>

                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  name="lastname"
                  labelWidth={70}
                  type="text"
                  autoFocus
                  id="lastname"
                  autoComplete="lastname"
                  onChange={(e) => setLastname(e.target.value)}
                  value={lastname}
                />
              </FormControl>
              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-username">
                  {t('username')}
                </InputLabel>

                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  name="username"
                  labelWidth={70}
                  type="text"
                  autoFocus
                  id="username"
                  autoComplete="username"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                />
              </FormControl>
              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                {t('password')}
                </InputLabel>

                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t('password')}
                  labelWidth={70}
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {error && (
                <div className={classes.alert}>
                  <Grid container alignItems='center'>
                    <Grid item xs={2}>
                      <AlertIcon size="75%" color="white" title={t('error')}/>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography
                        component="body2"
                        classes={{ colorError: classes.colorError }}
                        color="error"
                        data-test="signin-error-message"
                      >
                        {error}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              )}
              {/* <input type="hidden" value={formValues.csrfToken} username="csrf_token"/> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                data-test="sign-in-submit"
                disabled={isLoading}
              >
                {t('signup')}
              </Button>
            </form>
            <div className={classes.goToLinkWrapper}>
              <div className={classes.goToLinkContent}>
                <Typography variant="body2" color="textSecondary">
                  {t('already_have_an_account')}
                </Typography>
                <Typography className={classes.signupLink} variant="body2" color="textSecondary">
                  <Link to={ROUTE_PATHS.SIGNIN} variant="body2">
                  {t('signin')}
                  </Link>
                </Typography>
              </div>
            </div>
            <div className={classes.dividerLine}>
              <Divider />
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              onClick={goGuestUser}
              className={classes.submit}
              data-test="sign-in-as-guest"
              disabled={isLoading}
            >
             {t('sign_in_anonomously')}
            </Button>
          </Grid>

          <Divider />
        </div>
      </Grid>
      <Grid item sm={false} md={7} lg={8} className={classes.QFBackground}>
        <QFBackgroundImage className={classes.image} />
        <Typography variant="h4" color="secondary" align="center"> {t('malware_analysis_solution')}</Typography>
        <div className={classes.QFBackgroundText}>
          <Typography variant="body1" color="textSecondary" align="center">
          {t('qflow_is_an_auatomatic_file_analysis_solution_to_identify_malicious_files')}
          </Typography>
          <Typography variant="body1" color="textSecondary" align="center">
          {t('and_improve_threat_detection')}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}
