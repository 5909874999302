import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  urlName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  status: {
    width: '180px'
  }
}));
