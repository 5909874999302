import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { MoreActions } from 'common/Icons';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const MoreActions = props => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const actionItem = (fn) => () => {
    handleClose();
    fn(props.rowData);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          props.actions.map(action => <MenuItem key={action.text} onClick={actionItem(action.onClick)}>
            <ListItemIcon>
              {action.icon}
            </ListItemIcon>
            <ListItemText primary={action.text} />
          </MenuItem>
          )
        }
      </StyledMenu>
    </div>
  );
};

MoreActions.propTypes = {
  rowData: PropTypes.object,
  actions: PropTypes.object
};

export default MoreActions;
