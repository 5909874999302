export const TOOLTIP_STATUS = Object.freeze({
  running: '',
  failed: '',
  unknown: 'no_information',
  notAnalyzed: '',
  finished: '',
  secure: 'File is known to be secure',
  undetermined: 'No security flaws were detected',
  undetected: 'No security flaws were detected',
  weak: 'File uses known weak or outdated libraries',
  insecure: 'File has significant security flaws and may be dangerous to use'
});
