import React from 'react';
import PropTypes from 'prop-types';
import {
  List as MuiList,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  ListItemIcon as MuiListItemIcon,
  ListItemSecondaryAction as MuiListItemSecondaryAction
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import makeStyles from './styles';
const useStyles = makeStyles();

export const List = ({ children, ...props }) => {
  return (
    <MuiList aria-label={props['aria-label']} {...props}>
      { children }
    </MuiList>
  );
};

List.propTypes = {
  children: PropTypes.any,
  'aria-label': PropTypes.any
};

export const ListElement = ({ divider, className, children, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <MuiListItem className={`${className} ${classes.MuiListItem}`} {...props}>
        { children }
      </MuiListItem>
      { divider && <Divider /> }
    </>
  );
};

ListElement.propTypes = {
  divider: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string
};

export const ListElementIcon = ({ children, ...props }) => {
  return (
    <MuiListItemIcon {...props}>
      { children }
    </MuiListItemIcon>
  );
};

ListElementIcon.propTypes = {
  children: PropTypes.object
};

export const ListElementText = (props) => <MuiListItemText {...props} />;

export const ListElementActions = ({ children }) => {
  return (
    <>
      <MuiListItemSecondaryAction>
        { children }
      </MuiListItemSecondaryAction>
    </>
  );
};

ListElementActions.propTypes = {
  children: PropTypes.any
};
