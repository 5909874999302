import React from 'react';
import { useNavigate } from 'react-router-dom';
import useAppConfigContext from 'contexts/AppConfigContext/useAppConfigContext';
import { isGuestUser, isAnalyst, isAdmin } from 'entities/auth/auth.utils';
import Loading from 'common/Loading';
import { ROUTE_PATHS } from '../../utils/routes.constants.js';
import makeStyles from './styles';
const useStyles = makeStyles();

export default function HomePage () {
  const { isSaasModeEnabled, isLoading } = useAppConfigContext();
  const navigate = useNavigate();
  const isAdminUser = isAdmin();
  const isAnalystUser = isAnalyst();
  const isGuest = isGuestUser();
  const classes = useStyles();

  if (isLoading) {
    return <div className={classes.loaderContainer} ><Loading size={80} /></div>;
  } else if (isSaasModeEnabled && isGuest) {
    return navigate(ROUTE_PATHS.SIGNIN);
  }
  if (isGuest) {
    return navigate(ROUTE_PATHS.SCAN);
  } else {
    return (isAdminUser || isAnalystUser) ? navigate(ROUTE_PATHS.DASHBOARD) : navigate(ROUTE_PATHS.SCAN);
  }
}
