import { array, arrayOf, bool, node, shape, string } from 'prop-types';

export const propTypes = {
  tabs: arrayOf(shape({
    label: string.isRequired,
    body: string.isRequired,
    iconComponent: node.isRequired,
    isEmpty: bool.isRequired,
    type: string,
    columns: array,
    data: array
  }))
};
