import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  btnScanUrl: {
    width: '200px',
    alignSelf: 'center'
  },
  addLinkWrapper: {
    display: 'flex',
    justifyContent: 'space-between',

    '& svg': {
      marginRight: '5px',
      color: theme.palette.text.secondary
    }
  },
  addLinkExplanation: {
    color: theme.palette.text.secondary
  },
  deleteLink: {
    marginLeft: '20px'
  },
  urlInputWrapper: {
    display: 'flex',
    alignItems: 'baseline',

    '& svg': {
      color: `${theme.palette.text.secondary} !important`
    }
  },
  noProbeWarningWrapper: {
    margin: '30px 0px 30px 0px'
  },
  assignText: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2em'
  },
  scanApkBtn: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    },
    margin: '10px 0px 10px 8px',
    width: '120px',
    padding: '15px 0px'
  },
  scanDescription: {
    width: '490px',
    margin: '0 auto'
  }
}));
