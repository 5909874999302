import PropTypes from 'prop-types';
import React from 'react';

const SnackStateContext = React.createContext();
const SnackSetStateContext = React.createContext();
const initialState = {
  isOpen: false,
  message: undefined,
  title: undefined,
  severity: undefined
};

function SnackProvider ({ children }) {
  const [state, setState] = React.useState(initialState);
  return (
    <SnackSetStateContext.Provider value={setState}>
      <SnackStateContext.Provider value={state}>
        {children}
      </SnackStateContext.Provider>
    </SnackSetStateContext.Provider>
  );
}

function useSnackSetState () {
  const context = React.useContext(SnackSetStateContext);
  if (context === undefined) {
    throw new Error('SnackSetStateContext must be used within a SnackStateContext.Provider');
  }
  return context;
}

SnackProvider.propTypes = {
  children: PropTypes.any
};

export { SnackProvider, SnackStateContext, SnackSetStateContext, useSnackSetState };
