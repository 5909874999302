import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { TrashFillIcon } from 'common/Icons';
import { useDialog } from 'hooks';
import { useSnackSetState } from 'contexts/SnackContext';
import { deletePlan } from 'entities/plans/plans.api';
import { DialogButtons } from 'common/probes/ProbeDialog';
import MenuActions from 'components/MenuActions';
import { MENU_KEYS } from './ActionsMenu.constants';
import { getServerError } from 'pages/pages.utils';
import propTypes from './ActionsMenu.props';
import makesStyles from './ActionsMenu.style';

const useStyles = makesStyles();

export default function ActionsMenu ({ plan, refreshTable }) {
  const classes = useStyles();
  const [t] = useTranslation();
  const setSnack = useSnackSetState();

  const options = React.useMemo(() => {
    return [
      {
        key: MENU_KEYS.DELETE_PLAN,
        placeholder: t('delete_plan'),
        visible: true,
        onClick: () => {
          deletePlanDialog({ isOpen: true });
        }
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  const onDeletePlan = () => {
    if (plan?.id) {
      deletePlan(plan?.id)
        .then(() => {
          setSnack({
            isOpen: true,
            title: t('plan_succesfully_deleted'),
            severity: 'success'
          });
          refreshTable();
        })
        .catch(err => {
          setSnack({
            isOpen: true,
            title: getServerError(err?.response?.data),
            severity: 'error'
          });
        });
      deletePlanDialog({ isOpen: false });
    };
  };

  const deletePlanDialog = useDialog(`deleteplan-dialog-${plan.id}`, {
    onClose: () => {
      deletePlanDialog({ isOpen: false });
    },
    components: {
      Icon: <TrashFillIcon className={classes.dialogIconSize} />,
      Title: (
        <Typography variant="h5" align="center">
          {t('are_you_sure_you_want_to_delete_plan', { plan_name: `${plan.name}` })}
        </Typography>
      ),
      ContentText: (<>
          <Typography variant="body2" align="center">
            {t('this_action_is_permanent_once_the_plan_is_deleted')}
          </Typography>
        </>
      ),
      Actions: (
        <div className={classes.dialogButtonsWrapper}>
          <DialogButtons type='warning'
            confirmLabel={t('yes_delete')}
            cancelLabel={t('no')}
            onConfirm={onDeletePlan}
            onCancel={() => deletePlanDialog({ isOpen: false })}
          />
        </div>
      )
    }
  });

  return options.length > 0 ? <MenuActions options={options} /> : null;
}

ActionsMenu.propTypes = propTypes;
