import jwtDecode from 'jwt-decode';

const getLoggedInUser = () => {
  const token = localStorage.getItem('token');
  return token ? { ...jwtDecode(token) } : { roles: [] };
};

const getRoles = () => {
  const user = getLoggedInUser();
  return user.roles;
};

export const isAdmin = () => getRoles().includes('admin');

export const isAnalyst = () => getRoles().includes('analyst');

export const isOperator = () => getRoles().includes('operator');

export const getLoggedUserId = () => {
  const user = getLoggedInUser();
  return user.identity?.id;
};

export const isGuestUser = () => {
  const user = getLoggedInUser();
  const isGuestUser = user.roles && user.roles.length === 0;
  return isGuestUser;
};
