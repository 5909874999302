import { array, bool, shape, string } from 'prop-types';

export const propTypes = {
  results: array.isRequired,
  isFile: bool.isRequired,
  target: shape({
    name: string,
    size: string,
    mimetype: string,
    owner: shape({
      id: string.isRequired
    })
  }).isRequired
};
