import React from 'react';
import { propTypes } from './PlanAccounts.props';
import Accounts from 'pages/Accounts';

const PlanAccounts = ({ plan }) => {
  return (
    <Accounts plan={plan} />
  );
};

PlanAccounts.propTypes = propTypes;

export default PlanAccounts;
