import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@material-ui/core';
import { LinkExternal } from 'common/Icons';
import { propTypes } from './MitreLink.props';

const URL_MITRE = 'https://attack.mitre.org/techniques/';

const MitreLink = ({ id }) => {
  const [t] = useTranslation();
  if (id) {
    const mainId = id?.split?.('.')?.[0];
    return <Link target='_blank' href={URL_MITRE + mainId}>{id} <LinkExternal size={10} title={t('open_link')}/></Link>;
  } else {
    return null;
  }
};

MitreLink.propTypes = propTypes;

export default MitreLink;
