import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  title: {
    color: 'gray'
  },
  planValue: {
    margin: 0
  },
  boxContainer: {
    border: '1px solid gray',
    borderRadius: '5px',
    padding: '30px',
    marginBottom: '32px'
  },
  textAlignEnd: {
    textAlign: 'end'
  },
  badgeSize: {
    width: '4rem',
    height: '4rem',
    backgroundColor: '#d9ecfa',
    padding: '10px',
    borderRadius: '10px'
  },
  marginVertical: {
    margin: 'auto 0'
  },
  planNameBox: {
    margin: '30px 0'
  },
  planInfoBox: {
    height: '465px'
  }
}));
