import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { getFlatList, filterListToObjectByAttribute } from 'pages/Details/Details.utils';
import GenericTable from '../GenericTable';
import ProbeTabes from '../ProbeTabs';
import { propTypes } from './Process.props';
import { COLUMNS } from './Process.constants';
import makeStyles from '../../styles';

const useStyles = makeStyles();

const Process = ({ data }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const rowsPerProbeType = useMemo(() => {
    return filterListToObjectByAttribute(getFlatList(data), 'probeName');
  }, [data]);

  const probes = useMemo(() => Object.keys(rowsPerProbeType), [rowsPerProbeType]);

  return (
    <Grid container spacing={1} className={classes.noWrap} >
      <ProbeTabes tabs={probes}>
        {activeTabIndex => (
          <GenericTable title={t('process')} columns={COLUMNS} rows={rowsPerProbeType[probes[activeTabIndex]]} />
        )}
      </ProbeTabes>
    </Grid>
  );
};

Process.propTypes = propTypes;

export default Process;
