export const getPlan = response => {
  const plan = response.data ?? {};
  return {
    id: plan.id,
    name: plan.name,
    subscriptionCost: plan.subscription_cost,
    billingPeriod: plan.billing_period,
    scanLimit: plan.scan_limit,
    scanLimitPeriod: plan.scan_limit_period,
    maxFileSize: plan.max_file_size,
    maxAdminAccounts: plan.max_admin_accounts,
    maxAnalystAccounts: plan.max_analyst_accounts,
    maxOperatorAccounts: plan.max_operator_accounts
  };
};
