import { makeStyles } from '@material-ui/core/styles';

const WARNING_LEVEL = 100;
const radius = 100;
const stroke = 12;
const normalizedRadius = radius - stroke * 2;
const circumreference = normalizedRadius * 2 * Math.PI;
const difference = 70;

export default theme => makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '430px',
    width: '100%',
    border: `1px solid ${theme.palette.text.secondary + 50}`,
    padding: '20px',
    minWidth: '250px',
    maxWidth: '300px'
  },
  scanInfo: {
    height: '100px',
    width: '90%',
    paddingTop: '20px'
  },
  resetInfo: {
    height: '60px',
    width: '90%',
    marginTop: '10px',
    borderTop: `1px solid ${theme.palette.text.secondary + 50}`,
    borderBottom: `1px solid ${theme.palette.text.secondary + 50}`,
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  scanCount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '40px',
    width: '100%',

    '& .label::before': {
      content: '""',
      display: 'inline-block',
      height: '10px',
      width: '10px',
      marginRight: '5px'
    },

    '& .labelGray::before': {
      background: theme.palette.text.secondary + 50
    },

    '& .labelBlue::before': {
      background: ({ progress }) =>
        progress === WARNING_LEVEL ? theme.palette.error.main : theme.palette.primary.main
    }
  },
  progressWrapper: {
    height: radius * 2,
    width: radius * 2,
    position: 'relative',

    '& .progressDetails': {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      color: ({ progress }) =>
        progress === WARNING_LEVEL ? theme.palette.error.main : '#000',

      '& .percentage': {
        fontSize: '24px',
        fontWeight: 'bold',
        margin: 0
      },

      '& .description': {
        fontSize: '12PX',
        color: ({ progress }) =>
          progress === WARNING_LEVEL ? theme.palette.error.main : '#777777'
      }
    }
  },
  progress: {
    height: radius * 2 + 'px',
    width: radius * 2 + 'px',

    '& .baseCircle': {
      stroke: '#e1e4e8',
      fill: 'transparent',
      strokeWidth: stroke,
      strokeLinecap: 'round',
      r: normalizedRadius,
      cx: radius,
      cy: radius,
      strokeDasharray: circumreference - difference,
      strokeDashoffset: (circumreference - 1 * circumreference) - difference,
      transform: 'rotate(64deg)',
      transformOrigin: '50% 50%'
    },

    '& .progressCircle': {
      stroke: ({ progress }) =>
        progress === WARNING_LEVEL ? theme.palette.error.main : theme.palette.primary.main,
      fill: 'transparent',
      strokeWidth: stroke,
      strokeLinecap: 'round',
      r: normalizedRadius,
      cx: radius,
      cy: radius,
      strokeDasharray: circumreference,
      strokeDashoffset: ({ progress }) => {
        return (circumreference - progress / 100 * circumreference + (difference * progress / 100));
      },
      transform: 'rotate(117deg)',
      transformOrigin: '50% 50%'
    }
  }
}));
