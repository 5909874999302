import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  userSettingsDescription: {
    color: theme.palette.text.secondary,
    display: 'block',
    marginBottom: '2em'
  },
  backIcon: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2em',
    '& > svg': {
      marginRight: 5
    }
  }
}));
