import React from 'react';
import { useTranslation } from 'react-i18next';
import { propTypes } from './SubmittedBy.props';

const SubmittedBy = ({ owner }) => {
  const [t] = useTranslation();
  const fileOwner = owner?.traits?.username || t('deleted_user');

  return <p>{fileOwner}</p>;
};

SubmittedBy.propTypes = propTypes;

export default SubmittedBy;
