import React, { useEffect, useRef, useState } from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import MaterialTable from 'material-table';
import { Button, IconButton, Snackbar, Grid, Paper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { UserAddOutline } from 'common/Icons';
import { DialogButtons } from 'common/probes/ProbeDialog';
import { TableLocalization } from 'common/tableConfig';
import useDialog from 'hooks/useDialog';
import { fetchSelfUser, fetchUsers } from 'entities/users/users.api';
import AddEditUser from './components/AddEditUser';
import Search from './components/Search';
import getTableProps from './Users.tableProps';
import makeStyles from './Users.styles';

const useStyles = makeStyles();

export const Users = ({ accountId }) => {
  const [t] = useTranslation();
  const localization = TableLocalization();
  const classes = useStyles();
  const [trait, setTrait] = useState('username');
  const [loading, setLoading] = useState(false);
  const [fragment, setFragment] = useState('');
  const [error, setError] = useState('');
  const [data, setData] = useState();
  const [selfUser, setSetSelfUser] = useState('');
  const tableRef = useRef();
  const submitButtonRef = useRef();
  const userRef = useRef({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    id_account: ''
  });

  const fetchData = async (params = {}) => {
    setLoading(true);
    const res = await fetchUsers(params);
    let filteredList = res.data;
    if (accountId) {
      filteredList = res.data.filter(user => user.account === accountId);
    }
    setData(filteredList);
    fetchSelfUser()
      .then(response => {
        setSetSelfUser(response?.data?.account);
      });
    setLoading(false);
  };

  const { columns, options, actions, components } = getTableProps(fetchData, { selfUser });

  const onConfirm = () => {
    submitButtonRef.current.click();
  };

  const onSearch = (trait, fragment) => {
    fetchData({
      trait,
      fragment
    });
  };

  const closeDialog = () => {
    onDisplayDialog({ isOpen: false });
  };

  const content = <AddEditUser
    submitButtonRef={submitButtonRef}
    userRef={userRef}
    onSubmit={closeDialog}
    accountId={accountId}
    editMode={false}
    refreshTable={fetchData}
  />;

  const onDisplayDialog = useDialog('add-user-dialog', {
    onClose: () => {
      onDisplayDialog({ isOpen: false });
    },
    resetOnUpdate: false,
    components: {
      Icon: <UserAddOutline className={classes.dialogIconSize} title={t('add_a_new_user')} />,
      Title: <Typography variant="h5" align="center">{t('add_a_new_user')}</Typography>,
      Content: content,
      Actions: (
        <DialogButtons
          confirmLabel={t('add_user')}
          cancelLabel={t('cancel')}
          onConfirm={onConfirm}
          onCancel={() => onDisplayDialog({ isOpen: false })}
        />
      )
    }
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs={12}>
      <Typography variant="h2" classes={{ h2: classes.subheading }}> {t('users')} </Typography>
      <Paper className={classes.paper} elevation={0}>
        <Grid container className={classes.toolbarContainer}>
          <Grid xs={10}>
            <Search
              stateSearch={{
                fragment,
                trait
              }}
              setTrait={setTrait}
              setFragment={(value) => {
                if (!value) {
                  fetchData();
                }
                setFragment(value);
              }}
              onSearch={() => onSearch(trait, fragment)}
              traits={[
                'email',
                'username'
              ]}
            />
          </Grid>
          <Grid item container xs={2} justify="flex-end">
            <Button
              className={classes.addButton}
              size="medium"
              color="secondary"
              variant="contained"
              onClick={() => onDisplayDialog({ isOpen: true })}>
              {t('add_user')}
            </Button>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={error}
          autoHideDuration={6000}
          onClose={() => setError('')}
          message={error}
          className={classes.errorBar}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setError('')}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
        <MaterialTable
          title=''
          columns={[
            ...columns
          ]}
          tableRef={tableRef}
          data={data}
          isLoading={loading}
          options={options}
          actions={actions}
          components={components}
          localization={localization}
        />
      </Paper>
    </Grid>
  );
};

Users.propTypes = {
  accountId: string
};

export default Users;
