import React from 'react';
import { useTranslation } from 'react-i18next';
import { FillCheckSquare, FillCloseSquare } from 'common/Icons';
import { propTypes } from './BooleanDisplay.props';
import makeStyles from './BooleanDisplay.styles';

const useStyles = makeStyles();

const BooleanDisplay = ({ value }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const iconProps = {
    size: 20,
    color: 'white'
  };

  if (value === true) {
    return (
      <div className={classes.truthy}>
        <FillCheckSquare {...iconProps} title={t('true')}/>
        <p>{t('true')}</p>
      </div>
    );
  } else if (value === false) {
    return (
      <div className={classes.falsy}>
        <FillCloseSquare {...iconProps} title={t('false')}/>
        <p>{t('false')}</p>
      </div>
    );
  }

  return null;
};

BooleanDisplay.propTypes = propTypes;

export default BooleanDisplay;
