import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function DialogTitle ({ title }) {
  return (
    <Typography variant="h5" align="center">{title}</Typography>
  );
}

DialogTitle.propTypes = {
  title: PropTypes.string
};
