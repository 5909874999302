import { stringify } from 'pages/Details/Details.utils';

export const getTabs = (tabs = []) => {
  if (!Array.isArray(tabs)) {
    return [];
  };

  return tabs.map(tab => {
    return {
      ...tab,
      body: stringify({ [tab.key]: tab.data }),
      isEmpty: (tab.type === 'json' && !tab.data) || (tab.type === 'table' && !tab.data?.length)
    };
  });
};
