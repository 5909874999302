import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Divider from '@material-ui/core/Divider';
import { INITIAL_SEARCH_QUERY } from '../../MyFiles.constants';
import BasicSearch from './components/BasicSearch/BasicSearch';
import AdvancedSearch from './components/AdvancedSearch/AdvancedSearch';
import { propTypes } from './SearchContainer.props';
import makeStyles from '../../styles';
const useStyles = makeStyles();

const SearchContainer = ({ onSearch, searchQuery }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [isBasicSearch, setIsBasicSearch] = useState(_.isEqual(searchQuery, INITIAL_SEARCH_QUERY.BASIC));

  const handleSearchTypeChange = () => {
    setIsBasicSearch(!isBasicSearch);
    // Reset search when changing types.
    const search = isBasicSearch ? INITIAL_SEARCH_QUERY.BASIC : INITIAL_SEARCH_QUERY.ADVANCED;
    onSearch(search);
  };

  return (
    <>
      <Grid container justify="flex-start" alignItems='center' className={classes.searchContainer} >
        <Grid item xs={10}>
          {
            isBasicSearch
              ? <BasicSearch onSearch={onSearch} onReset={onSearch} prevQuery={searchQuery}/>
              : <AdvancedSearch onSearch={onSearch} onReset={onSearch} prevQuery={searchQuery}/>
          }
        </Grid>
        <Grid item container xs={2} className={classes.gridToggle}>
        <Divider classes={{ root: classes.divider }} orientation="vertical" flexItem/>
          <ToggleButton
            value="check"
            selected={!isBasicSearch}
            onChange={handleSearchTypeChange}
            className={classes.toggleButton}
          >
            { isBasicSearch ? t('advanced_search') : t('close_advanced_search') }
          </ToggleButton>
        </Grid>
      </Grid>
    </>);
};

SearchContainer.propTypes = propTypes;
export default SearchContainer;
