import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from 'locales';

// const browserLanguage = navigator.language || navigator.userLanguage;
// const chosenLanguage = localStorage.getItem('lang');
// const supportedLanguages = ['en', 'en-US', 'en-UK', 'fr', 'fr-fr'];

// const getDefaultLanguage = () => {
//   if (chosenLanguage) {
//     return chosenLanguage;
//   }
//   if (supportedLanguages.includes(browserLanguage)) {
//     return browserLanguage;
//   }
//   return 'en';
// };

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: getDefaultLanguage(),
    lng: 'en',
    fallbackLng: ['en'],
    defaultNS: 'translations',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
