import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import BaseLayout from 'layouts/BaseLayout';
import PageHeader from 'pages/components/PageHeader';
import Profile from './components/ProfileForm';
import PasswordForm from './components/PasswordForm';
import MfaConfig from './components/MfaConfig/MfaConfig';
import makeStyles from './UserSettings.styles';
import ActiveSessions from './components/ActiveSessions/ActiveSessions';

const useStyles = makeStyles();

export default function UserSettings () {
  const [t] = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <BaseLayout>
      <Grid container justify="center" alignItems="center">
        <Grid item md={8}>
          <div className={classes.backIcon} onClick={() => navigate(-1)}> <ArrowBack /> {t('back')}</div>
          <PageHeader title={t('user_settings')} />
          <Typography className={classes.userSettingsDescription}>{t('below_details_profile')} </Typography>
          <Profile />
          <PasswordForm />
          <MfaConfig/>
          <ActiveSessions/>
        </Grid>
      </Grid>
    </BaseLayout>
  );
}
