export const getBackgroundColor = (category) => {
  if (category === 'metadata') {
    return '#D1E8E7';
  } else if (category === 'tools') {
    return '#F4D3DD';
  } else if (category === 'antivirus') {
    return '#C5D5E8';
  } else if (category === 'database') {
    return '#FAEDCF';
  } else {
    return '#FFFFFF';
  }
};
