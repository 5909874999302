import React from 'react';
import { TreeItem } from '@material-ui/lab';
import { propTypes } from './StyledTreeItem.props';
import makeStyles from './StyledTreeItem.style';

const useStyles = makeStyles();

const StyledTreeItem = ({ nodeId, content, children }) => {
  const classes = useStyles();

  return <TreeItem
    nodeId={nodeId}
    label={content}
    classes={{
      iconContainer: classes.iconContainer,
      group: classes.group,
      label: classes.label,
      content: classes.content,
      selected: classes.selected
    }}
  >{children}</TreeItem>;
};

StyledTreeItem.propTypes = propTypes;

export default StyledTreeItem;
