export const initialState = {
  name: '',
  email: ''
};

export const ADD_ACCOUNT_FORM_ACTIONS = Object.freeze({
  NAME: 'addAccountForm/name',
  UPDATE_EMAIL: 'addAccountForm/email'
});

export const addAccountFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ACCOUNT_FORM_ACTIONS.NAME:
      return { ...state, name: action.payload };
    case ADD_ACCOUNT_FORM_ACTIONS.UPDATE_EMAIL:
      return { ...state, email: action.payload };
    default:
      return initialState;
  }
};
