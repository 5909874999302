import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  bodyText: {
    color: 'grey',
    margin: '0px 20px 30px 20px',
    fontSize: '1em',
    textAlign: 'center'
  },
  title: {
    fontSize: '2em',
    margin: '0px 20px 10px 20px',
    textAlign: 'center'
  },
  delete: {
    color: ' #fa4b54'
  }
}));
