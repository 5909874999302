import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import { Button } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useAuthUser } from 'hooks';
import Bar from '../components/Bar';
import NavigationSidebar from './components/NavigationSidebar';
import { propTypes } from './DashboardLayout.props';
import { getLayoutStyle } from './DashboardLayout.utils';
import makeStyles from './DashboardLayout.styles';

const useStyles = makeStyles();

export default function DashboardLayout () {
  const [t] = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const { auth } = useAuthUser();
  const location = useLocation();
  const layoutStyle = getLayoutStyle(location);

  useEffect(() => {
    const timer = setTimeout(() => resizeWindow(), 50);
    return () => clearTimeout(timer);
  }, [open]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const resizeWindow = () => {
    window.dispatchEvent(new CustomEvent('resize'));
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const isLoggedUser = auth.roles.length > 0; // no roles means is a guest
  const appBarShift = isLoggedUser && (open && classes.appBarShift);
  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, appBarShift)}
        elevation={0}
      >
        <Bar open={open} handlers={{ handleDrawerOpen }} />
      </AppBar>
      { isLoggedUser && <Drawer
        variant="permanent"
        className= {clsx(classes.drawer, !open && classes.drawerClosed)}
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}
        anchor="left"
      > <div className={classes.drawerContainer}>
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <NavigationSidebar collapse={!open} />
          <Button
            onClick={toggleDrawer}
            variant='text'
            startIcon={open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            fullWidth
            className={classes.toggleDrawer}
          >
            {!open ? null : t('collapse_sidebar')}
          </Button>
        </div>
      </Drawer>}
      <div className={classes[layoutStyle]}>
        <Outlet/>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = propTypes;
