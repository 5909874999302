import { bool, shape, string } from 'prop-types';

export const propTypes = {
  account: shape({
    id: string,
    name: string,
    subdomain: string,
    address: shape({
      country: string,
      state: string,
      city: string,
      post_code: string,
      line1: string,
      line2: string,
      phone: string
    }),
    root: bool,
    owner: string,
    finance_contact: string,
    finance_email: string,
    currency: string
  })
};
