import { arrayOf, func, shape, string } from 'prop-types';

export const propTypes = {
  files: arrayOf(shape({
    id: string,
    name: string,
    size: string
  })).isRequired,
  refreshData: func.isRequired,
  onCancelSelection: func.isRequired
};
