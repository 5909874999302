import React from 'react';
import { Link } from 'react-router-dom';
import { FileTextIcon } from 'common/Icons';
import { Tooltip, CircularProgress } from '@material-ui/core';
import { getColor } from '../../ArchivesSidebar.utils';
import { propTypes } from './FileItem.props';
import makeStyles from './FileItem.styles';

export default function FileItem ({ file, handleArchiveClick, parent }) {
  const { name, id, safety } = file;
  const { status } = parent;
  const useStyles = makeStyles();
  const classes = useStyles();

  const color = getColor(safety?.toLowerCase());
  const backgroundColor = classes[safety?.toLowerCase() + 'Background'];

  return (
    status === 'RUNNING'
      ? (
        <div className={classes.itemWrapper}>
          <Tooltip title={name} classes={{ tooltip: classes.tooltip }}>
            <div className={classes.fileNameLoading}><CircularProgress className={classes.loading} size={12} />{name}</div>
          </Tooltip>
        </div>
        )
      : (
      <Link className={classes.link} to={`/details/file/${id}`} onClick={handleArchiveClick}>
        <div className={classes.itemWrapper}>
          <Tooltip title={name} classes={{ tooltip: classes.tooltip + ' ' + backgroundColor }}>
            <div className={classes.fileName}><FileTextIcon className={classes.fileIcon} color={color} />{name}</div>
          </Tooltip>
        </div>
      </Link>
        )
  );
}
FileItem.propTypes = propTypes;
