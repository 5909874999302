import React, { useState, useRef } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MaterialTable from 'material-table';
import { TableLocalization } from 'common/tableConfig';
import { TrashIcon } from 'common/Icons';
import { useDialog } from 'hooks';
import { useSnackSetState } from 'contexts/SnackContext';
import { deleteActiveSessions, fetchActiveSessions, fetchCurrentSession } from 'entities/sessions/sessions.api';
import DialogButtons from 'components/DialogButtons/DialogButtons';
import { getServerError } from 'pages/pages.utils';
import getTableProps from './ActiveSessions.tableprops';
import makeStyles from './ActiveSessions.styles';

const useStyles = makeStyles();

function ActiveSessions () {
  const [t] = useTranslation();
  const classes = useStyles();
  const localization = TableLocalization();
  const setSnack = useSnackSetState();
  const [isLoading, setIsLoading] = useState(true);
  const [hasActiveSessions, setHasActiveSessions] = useState(false);
  const tableRef = useRef(null);
  const refreshTableData = () => tableRef.current.onQueryChange();

  const getTableData = async () => {
    setIsLoading(true);
    try {
      const currentSession = await fetchCurrentSession();
      const activeSessions = await fetchActiveSessions();
      setHasActiveSessions(activeSessions.length > 0);
      setIsLoading(false);
      const data = [currentSession, ...activeSessions];
      return { data };
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const { components, columns, options } = getTableProps(refreshTableData);

  const handleRevokeActiveSessions = async () => {
    try {
      await deleteActiveSessions();
      await refreshTableData();
      setSnack({
        isOpen: true,
        title: t('active_sessions_revoked'),
        severity: 'success'
      });
    } catch (error) {
      setSnack({
        isOpen: true,
        title: t('something_went_wrong'),
        message: getServerError(error.data),
        severity: 'warning'
      });
    } finally {
      revokeSessionsDialog({ isOpen: false });
    }
  };

  const revokeSessionsDialog = useDialog('revoke-sessions-dialog', {
    onClose: () => {
      revokeSessionsDialog({ isOpen: false });
    },
    components: {
      Icon: <TrashIcon className={classes.dialogIconSize} />,
      Title: <Typography variant="h5" align="center">{t('are_you_sure_you_want_to_revoke_all_sessions')}</Typography>,
      ContentText: <Typography variant="body2" align="center">{t('active_sessions_msg_revoke_warning')}</Typography>,
      Actions: (
        <DialogButtons
          confirmLabel={t('yes_revoke_access')}
          cancelLabel={t('no')}
          onConfirm={() => handleRevokeActiveSessions()}
          onCancel={() => revokeSessionsDialog({ isOpen: false })}
          type='warning'
        />
      )
    }
  });

  return (
    <Grid container className={classes.container} fullWidth>
      <Grid item xs={12} xl={10}>
        <Typography variant="h5">
          <strong>{t('active_sessions')}</strong>
        </Typography>
        <MaterialTable
          title=''
          columns={columns}
          data={getTableData}
          options={options}
          components={components}
          localization={localization}
          isLoading={isLoading}
          tableRef={tableRef}
        />
        { hasActiveSessions &&
          <Button
              variant="contained"
              color="primary"
              className={classes.revokeSessionsButton}
              onClick={() => revokeSessionsDialog({ isOpen: true })}>
                {t('revoke_active_sessions')}
            </Button>
        }
      </Grid>
    </Grid>
  );
}

export default ActiveSessions;
