import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ShieldFill } from 'common/Icons';
import { propTypes } from './ResultBlock.props';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'utils/routes.constants';
import makeStyles from './ResultBlock.styles';

const useStyles = makeStyles();

export default function ResultBlock ({ type, total, since }) {
  const classes = useStyles();
  const [t] = useTranslation();
  const navigate = useNavigate();

  const handleClick = (type) => {
    const searchQuery = {
      main: 'name',
      searchText: '',
      status: '',
      sort: 'updated:desc',
      safety: type,
      since: since,
      tags: []
    };
    navigate(ROUTE_PATHS.FILES, { state: searchQuery });
  };

  return <Button className={`${classes.block} ${classes[type]}`} onClick={() => handleClick(type)}>
    <div>
      <ShieldFill className={`${classes.icon} ${classes[type + 'icon']}`} />
    </div>
    <div>
      <div>
        <Typography variant="h5" ><strong>{total}</strong></Typography>
      </div>
      <div className={classes.fileDescription}>{t(`${type}` + '_FILES')}</div>
    </div>
  </Button>;
}

ResultBlock.propTypes = propTypes;
