import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Avatar } from '@material-ui/core';
import _ from 'lodash';
import Loading from 'common/Loading';
import ManifestSection from '../ManifestSection';
import { propTypes } from './ProbeDetail.props';
import makeStyles from './ProbeDetail.styles';
const useStyles = makeStyles();

export default function ProbeDetail ({ probe, loading }) {
  const [t] = useTranslation();
  const classes = useStyles();
  const probeNameFirstLetter = _.capitalize(probe?.display_name?.charAt(0));

  const GridContainerClasses = {
    container: classes.container
  };

  return (
    <> { loading && <Loading align="center" size={75} />}
    {
      probe !== null &&
      (
        <>
          <Grid
            container
            xs={12}
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={2}
            classes={GridContainerClasses}
          >
            <Avatar aria-label="probe" className={classes.avatar}>
              <Typography variant="h5">{probeNameFirstLetter}</Typography>
            </Avatar>
          </Grid>
          <Grid container xs={12} direction="column" spacing={2} classes={GridContainerClasses}>
            <Grid item>
              <Typography variant="h4" gutterBottom>{probe.display_name}</Typography>
              <Typography variant="body1" component="p" gutterBottom>
                {probe.summary}
              </Typography>
            </Grid>
            <ManifestSection probe={probe} />
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Typography variant="h5" gutterBottom> {t('documentation')}</Typography>
              <Typography variant="body1" component="p" gutterBottom>
                {probe.documentation}
              </Typography>
            </Grid>
          </Grid>
        </>
      )
    }</>
  );
}
ProbeDetail.propTypes = propTypes;
