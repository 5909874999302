/**
 * Returns the active plan by comparing plan's start & end dates.
 * @param {Array} plans plans linked to account.
 * @returns {Object} Active plan or empty object.
 */
export const getActivePlan = (plans = []) => {
  return plans.filter(plan => {
    const startDate = new Date(plan.startDate);
    const endDate = new Date(plan.endDate);
    const now = new Date();
    now.setUTCHours(0, 0, 0, 0);
    return startDate <= now && endDate >= now && !plan.isCancelled;
  })[0] ?? {};
};

/**
 * Get the maximum number of users for an account.
 * @param {Object} the account active plan.
 * @returns {Number} total count..
 */
export const getTotalUsersLimit = plan => {
  return plan.maxAdminAccounts + plan.maxAnalystAccounts + plan.maxOperatorAccounts;
};

/**
 * Get the usage of users for an account.
 * @param {Object} the account usage for the active plan.
 * @returns {Number} total count.
 */
export const getTotalUsersUsage = usage => {
  return usage.analystCount + usage.operatorCount + usage.adminCount;
};

/**
 * Format the account address
 * @param {Object} address
 * @returns {Object}
 */
export const transformAddress = address => ({
  ...address,
  post_code: parseInt(address.post_code)
});
