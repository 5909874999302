import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, TableRow, TableCell } from '@material-ui/core';
import { useSnackSetState } from 'contexts/SnackContext';
import { Copy, Copied } from 'common/Icons';
import Safety from 'pages/components/Safety';
import Security from 'pages/components/Security';
import Privacy from 'pages/components/Privacy';
import AnalysisStatus from 'pages/components/AnalysisStatus';
import { useClipboard } from 'hooks';
import { propTypes } from './Item.props';
import { isGuestUser } from 'entities/auth/auth.utils';
import Filename from './components/FileName';
import makeStyles from './Item.styles';

const useStyles = makeStyles();

const Item = ({ data, isApkWorkflow }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const isGuest = isGuestUser();
  const setSnack = useSnackSetState();
  const copy = useClipboard();
  const [copied, setCopied] = useState(false);
  const copyClipboard = () => {
    copy({
      value: `${window.location.origin}/details/file/${data.id}`
    });

    setCopied(true);
    setSnack({
      isOpen: true,
      title: t('url_copied_to_clipboard'),
      severity: 'success'
    });
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <TableRow className={classes.dataRow}>
      <TableCell className={classes.filename}>
        <Filename rowData={data} />
      </TableCell>
      <TableCell className={classes.status}>
        <Safety rowData={data} />
      </TableCell>
      {isApkWorkflow && <>
      <TableCell className={classes.status}>
        <Security rowData={data} />
      </TableCell>
      <TableCell className={classes.status}>
        <Privacy rowData={data} />
      </TableCell></>}
      <TableCell className={classes.status}>
        <AnalysisStatus rowData={data} />
      </TableCell>
      {!isGuest && (
        <TableCell className={classes.actions}>
          <IconButton color="secondary" aria-label="clipboard" onClick={copyClipboard}>
            {!copied && <Copy style={{ height: 25, width: 30 }} title={t('copy_to_clipboard')} />}
            {copied && <Copied style={{ height: 25, width: 30 }} title={t('copy_to_clipboard')} />}
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};

Item.propTypes = propTypes;

export default Item;
