import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { getBackgroundColor } from '../../AdvancedScan.utils';
import makeStyles from './CategoryChip.styles';
const useStyles = makeStyles();

export default function CategoryChip ({ category }) {
  const classes = useStyles();
  const backgroundColor = getBackgroundColor(category);

  const chipStyle = {
    backgroundColor: backgroundColor
  };

  return (
    <Chip
      className={classes.chip}
      label={category}
      style={chipStyle}
    />
  );
}

CategoryChip.propTypes = {
  category: PropTypes.string
};
