import React from 'react';
import PropTypes from 'prop-types';
import AdvancedScanDrawer from 'common/scan/AdvancedScanDrawer';
import { SCAN_ACTIONS } from 'contexts/ScanContext/ScanContext';
import useScanContext from 'contexts/ScanContext/useScanContext';
import SelectFiles from 'common/scan/SelectFiles';

const Upload = ({ disabled = false }) => {
  const [{ isAdvancedScan }, setScanContext] = useScanContext();

  const toggleAdvancedScan = () => {
    setScanContext({ type: SCAN_ACTIONS.TOGGLE_ADVANCED_SCAN });
  };

  return (
    <>
    { !isAdvancedScan && <SelectFiles onHide={() => toggleAdvancedScan()} disabled={disabled}/> }
    { isAdvancedScan && <AdvancedScanDrawer type={'FILE'} onClose={() => toggleAdvancedScan()} /> }
    </>
  );
};

Upload.propTypes = {
  disabled: PropTypes.bool.isRequired
};

export default Upload;
