import React from 'react';
import { useLocation } from 'react-router-dom';
import FileResults from './components/FileResults';
import URLResults from './components/URLResults';

export default function Results () {
  const { search, pathname } = useLocation();
  const isFile = pathname.includes('/file');
  const isURL = !isFile && pathname.includes('/url');
  const params = new URLSearchParams(search);
  const ids = isFile && params.get('ids')?.split(',');
  const urlId = isURL && params.get('id');
  const flow = params.get('flow');

  return isFile ? <FileResults ids={ids} flow={flow} /> : <URLResults urlId={urlId}/>;
}
