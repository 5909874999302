import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { UsersIcon } from 'common/Icons';
import { propTypes } from './AccountSummary.props';
import makeStyles from './AccountSummary.styles';

const useStyles = makeStyles();

function AccountSummary ({ numberOfAccounts }) {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <Grid>
      <Typography variant="h5" component="span" gutterBottom><strong>Accounts</strong></Typography>
      <Grid className={classes.banner}>
        <div className={classes.iconContainer}>
        <UsersIcon color='#FFFFFF' className={classes.icon}/>
        </div>
        <Typography variant="h4" className={classes.number}><strong>{numberOfAccounts}</strong></Typography>
        <Typography>{t('total_accounts')}</Typography>
      </Grid>
    </Grid>
  );
}

export default AccountSummary;
AccountSummary.propTypes = propTypes;
