import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles((theme) => ({
  tableDisplay: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',

    '& *': {
      display: 'flex'
    },

    '& dd': {
      margin: theme.spacing(1, 0)
    },

    '& dt': {
      fontSize: theme.typography.htmlFontSize,
      clear: 'left',
      width: '250px',
      flexDirection: 'column',
      paddingTop: '25px'
    },

    '& .labelChip': {
      fontWeight: 'normal',
      fontSize: 14,
      margin: '0 3px',
      padding: theme.spacing(0.75, 1)
    }
  },
  formField: {
    margin: 0,
    width: '300px'
  }
}));
