import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    marginBottom: '10px',
    minWidth: '550px',

    '&:last-of-type': {
      border: 'none'
    },

    '& .text': {
      fontStyle: 'italic'
    },

    '& .details': {
      marginLeft: '10px',
      fontSize: '14px',

      '& .size': {
        color: theme.palette.grey[500]
      }
    },

    '& .name': {
      width: '220px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  error: {
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',

    '& .action': {
      cursor: 'pointer',
      textDecoration: 'none'
    },

    '& .divider': {
      color: theme.palette.grey[300],
      marginRight: '10px'
    }
  },
  info: {
    display: 'flex',
    alignItems: 'center'
  },
  arrow: {
    color: theme.palette.error.main
  },
  tooltip: {
    backgroundColor: theme.palette.error.main,
    fontSize: '11px',
    padding: '15px'
  }
}));
