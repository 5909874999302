import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { filterListToObjectByAttribute, getFlatList } from 'pages/Details/Details.utils';
import ProbeTabes from '../ProbeTabs';
import GenericPanel from '../GenericPanel';
import Table from './components/Table';
import { propTypes } from './ApkInfo.props';
import makeStyles from '../../styles';

const useStyles = makeStyles();

const ApkInfo = ({ title, data, columns }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const arraysColumns = useMemo(() => columns.filter(col => col.type === 'array'), [columns]);
  const panelColumns = useMemo(() => columns.filter(col => col.type !== 'array'), [columns]);
  const rowsPerProbeType = useMemo(() => {
    return filterListToObjectByAttribute(getFlatList(data), 'probeName');
  }, [data]);
  const probes = useMemo(() => Object.keys(rowsPerProbeType), [rowsPerProbeType]);

  return (
        <ProbeTabes tabs={probes}>
          {activeTabIndex => {
            const probeInfo = rowsPerProbeType[probes[activeTabIndex]][0];
            return (
              <Grid container>
                <Grid item xs={4}>
                  <div className={classes.fileInfoHeader}>
                    <Typography variant="h4" className={classes.panelTitle}>
                      {t(title)}
                    </Typography>
                  </div>
                  <div>
                    <GenericPanel data={probeInfo} keys={panelColumns} columnWidth='130px'/>
                  </div>
                </Grid>
                <Grid item xs={8}>
                  {
                    arraysColumns
                      .filter(({ key }) => probeInfo[key] !== undefined)
                      .map(({ key, label }) => (
                        <Grid container key={key}>
                          <Grid item xs={6}>
                            <Table title={label} rows={probeInfo[key]} />
                          </Grid>
                        </Grid>
                      ))
                  }
                </Grid>
              </Grid>
            );
          }}
      </ProbeTabes>
  );
};

ApkInfo.propTypes = propTypes;

export default ApkInfo;
