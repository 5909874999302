import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  page: {
    flexGrow: 1,
    margin: '100px 50px'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    borderBottom: '1px solid #e0e0e0',
    background: '#fff',
    boxShadow: 'none'
  }
}));
