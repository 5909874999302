import { makeStyles, styled } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';

export default theme => makeStyles(theme => ({
  formLayout: {
    maxWidth: '400px',
    margin: '0px 20px 10px 20px'
  },
  inputLayout: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'end',
    margin: '12px 0px 12px 0px'
  },
  formInput: {
    height: '35px',
    marginBottom: '5px'
  },
  infoIconSize: {
    width: '0.75rem',
    height: '0.75rem'
  },
  lockIcon: {
    width: '1rem',
    height: '1rem'
  },
  bodyText: {
    color: 'grey',
    fontSize: '0.8em',
    marginBottom: '30px'
  },
  label: {
    fontSize: '0.9em'
  },
  infotext: {
    fontSize: '0.8em'
  }
}));

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 30,
  height: 16,
  padding: 0,

  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  }
}));
