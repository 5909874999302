import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles((theme) => ({
  genericPanel: {
    margin: 0,
    padding: 0,
    fontSize: 16,
    display: 'flex',
    flexDirection: 'column',

    '& *': {
      fontSize: theme.typography.htmlFontSize,
      margin: theme.spacing(1, 0),
      float: 'left'
    },

    '& dt': {
      clear: 'left',
      width: props => props.columnWidth ?? 'mint-content',
      color: theme.palette.text.secondary
    },

    '& .labelChip': {
      fontWeight: 'normal',
      fontSize: 14,
      margin: '0 3px',
      padding: theme.spacing(0.75, 1)
    }
  },
  panelTitle: {
    fontSize: 24,
    fontStyle: 'bold',
    letterSpacing: '0.6px'
  },
  fileInfoHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
    margin: '15px 0 15px',
    lineHeight: '40px'
  }
}));
