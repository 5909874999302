import HTTP from 'common/http';

const PROBES = 'probes';

export const fetchAllProbes = () => {
  const url = PROBES;
  return HTTP.get(url);
};

export const fetchProbe = id => {
  const url = `${PROBES}/${id}`;
  return HTTP.get(url);
};

export const updateProbe = (id, data) => {
  const url = `${PROBES}/${id}/configs`;
  return HTTP.put(url, { data });
};
export const disableProbe = (id) => {
  const url = `${PROBES}/${id}/configs`;
  var data = JSON.stringify({
    enabled: false
  });
  return HTTP.put(url, { data });
};
