import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles((theme) => ({
  container: {
    paddingTop: '20px'
  },
  usageContainer: {
    marginTop: '15px'
  },
  hiddenTitle: {
    opacity: 0
  },
  title: {
    fontWeight: 'bold'
  }
}));
