/**
 * Defined is any property that was intentionally assigned, it could be null, empty string...
 * This is useful when testing against an object property.
 * @param {any} value to check.
 * @returns boolean True if param has a value.
 */
export const isDefined = value => typeof value !== 'undefined';

export const isString = value => typeof value === 'string';

export const isArray = value => Array.isArray(value);

export const isObject = value => typeof value === 'object' && !isArray(value);

export const isNumber = value => typeof value === 'number';

export const isBoolean = value => typeof value === 'boolean';

export const isNull = value => value === null;

export const isNotNull = value => value !== null;
