import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

export const ScanContext = React.createContext();

export const SCAN_ACTIONS = {
  SELECT_FILES: 'select_files',
  SELECT_FLOWS: 'select_flows',
  SELECT_PROBES: 'select_probes',
  SELECT_URLS: 'select_urls',
  START_SCAN: 'start_scan',
  START_SCAN_URLS: 'start_scan_urls',
  CLEAN_SCAN: 'clean_scan',
  TOGGLE_ADVANCED_SCAN: 'toggle_advanced_scan',
  TOGGLE_KEEP_FILE: 'toggle_keep_file'
};

const initialState = {
  files: [],
  urls: [],
  probes: [],
  flows: '',
  status: 'idle',
  isAdvancedScan: false,
  keepFile: false
};

function reducer (state, action) {
  switch (action.type) {
    case SCAN_ACTIONS.SELECT_FILES:
      return {
        ...state,
        files: [...action.payload]
      };
    case SCAN_ACTIONS.SELECT_FLOWS:
      return {
        ...state,
        flows: action.payload
      };
    case SCAN_ACTIONS.SELECT_URLS:
      return {
        ...state,
        urls: [...action.payload]
      };
    case SCAN_ACTIONS.SELECT_PROBES:
      return {
        ...state,
        probes: [...action.payload]
      };
    case SCAN_ACTIONS.START_SCAN:
      return {
        ...state,
        ...(action.payload ? { files: [...action.payload] } : {}), // workaround - warning!
        status: 'scanning'
      };
    case SCAN_ACTIONS.START_SCAN_URLS:
      return {
        ...state,
        ...(action.payload ? { urls: [...action.payload] } : {}),
        status: 'scanning'
      };
    case SCAN_ACTIONS.CLEAN_SCAN:
      return {
        files: [],
        urls: [],
        probes: [],
        flows: '',
        status: 'idle'
      };
    case SCAN_ACTIONS.TOGGLE_ADVANCED_SCAN:
      return {
        ...state,
        isAdvancedScan: !state.isAdvancedScan
      };
    case SCAN_ACTIONS.TOGGLE_KEEP_FILE:
      return {
        ...state,
        keepFile: !state.keepFile
      };
    default:
      throw new Error();
  }
}

export default function ScanProvider ({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ScanContext.Provider value={[state, dispatch]}>
      {children}
    </ScanContext.Provider>
  );
}

ScanProvider.propTypes = {
  children: PropTypes.node
};
