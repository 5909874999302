import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  addPlanForm: {
    width: '640px'
  },
  inlineError: {
    color: theme.palette.error.main
  },
  subHeaders: {
    marginBottom: '0px'
  }
}));
