import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  icon: {
    width: '2rem',
    height: '0.8rem'
  },
  failed: {
    color: theme.palette.error.main
  },
  cancelled: {
    color: theme.palette.warning.main
  }
}));
