import { shape, string, func } from 'prop-types';

export const propTypes = {
  file: shape({
    id: string,
    name: string,
    size: string
  }).isRequired,
  onDelete: func.isRequired
};
