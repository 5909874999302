export const SAFETY = Object.freeze({
  SAFE: 'safe', // # File is known to be safe
  UNDETERMINED: 'undetermined', // # File safety hasn't been determined
  CLEAN: 'clean', // # File safety is clean
  SUSPICIOUS: 'suspicious', // # File contains potentially unsafe features
  UNSAFE: 'unsafe' // # File is known to be unsafe
});

export const ANALYSIS_STATUS = Object.freeze({
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  CANCELED: 'CANCELED',
  FAILED: 'FAILED',
  FINISHED: 'FINISHED'
});

export const SAFETY_LABELS = Object.freeze({
  [SAFETY.UNDETERMINED]: 'clean',
  [SAFETY.SAFE]: 'safe',
  [SAFETY.SUSPICIOUS]: 'suspicious',
  [SAFETY.UNSAFE]: 'malicious',
  [SAFETY.CLEAN]: 'clean',
  running: 'scanning',
  failed: 'error',
  unknown: 'unknown',
  notAnalyzed: 'not_analyzed',
  finished: 'unknown'
});

export const SECURITY_LABELS = Object.freeze({
  secure: 'secure', // File is known to be secure
  undetected: 'undetected', // Nothing was detected
  undetermined: 'clean', // File security hasn't been determined
  weak: 'weak', // File uses known weak or outdated libraries
  insecure: 'insecure', // File has significant security flaws and may be dangerous to use
  running: 'scanning',
  failed: 'error',
  unknown: 'unknown',
  notAnalyzed: 'not_analyzed',
  finished: 'unknown'
});

export const PRIVACY_LABELS = Object.freeze({
  respectful: 'respectful', // File makes effort to avoid invading privacy
  undetected: 'undetected', // Nothing invasive was detected
  undetermined: 'clean', // File privacy hasn't been determined
  pervasive: 'pervasive', // File asks for a certain number of permissions
  invasive: 'invasive', // File asks for way too much permissions
  running: 'scanning',
  failed: 'error',
  unknown: 'unknown',
  notAnalyzed: 'not_analyzed',
  finished: 'unknown'
});
