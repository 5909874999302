import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  depositContext: {
    flex: 1
  },
  select: {
    '&.MuiSelect-selectMenu': { padding: 0 },
    '&:before': { border: 'none' },
    '&:after': { border: 'none' },
    '&:hover:not(.Mui-disabled):before': { border: 'none' },
    '&.Mui-focused': { backgroundColor: '#fafafa' }
  },
  mainFlag: {
    width: 30
  },
  flag: {
    width: 30,
    marginRight: 15
  },
  flagText: {
    display: 'none'
  },

  languageSwitcher: {
    display: 'flex'
  },
  comingSoon: {
    marginLeft: 5,
    backgroundColor: '#047fdc',
    color: '#fff',
    padding: 6,
    borderRadius: 4.5,
    fontWeight: 800,
    fontSize: 12
  }
}));
