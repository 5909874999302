import React from 'react';
import { useTranslation } from 'react-i18next';
import { propTypes } from './NoResult.props';

const NoResult = ({ message }) => {
  const [t] = useTranslation();
  const defaultMessage = t('no_result');
  return (<p style={{ fontStyle: 'italic', color: 'gray', margin: 0 }}>{message ?? defaultMessage}</p>);
};

NoResult.propTypes = propTypes;

export default NoResult;
