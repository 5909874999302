import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import { EmptyFiles, URLGlobe } from 'common/Icons';
import makeStyles from './styles';

const useStyles = makeStyles();
export default function ProbeCardHeader ({ title, probeType }) {
  const classes = useStyles();
  const capitalLetterTitle = title.charAt(0);
  const fileIcon = <EmptyFiles size={20} title='File probe'/>;
  const urlIcon = <URLGlobe size={20} title='URL probe'/>;
  const probeTypeIcon = probeType === 'FILE' ? fileIcon : urlIcon;

  return (
    <div className={classes.cardHeader}>
      <Avatar aria-label="probe" className={classes.avatar}>
        {capitalLetterTitle}
      </Avatar>
      { probeTypeIcon }
    </div>
  );
};

ProbeCardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  probeType: PropTypes.string
};
