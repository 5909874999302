import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { DialogTitle, DialogButtons } from 'common/probes/ProbeDialog';
import { Antivirus } from 'common/Icons';
import Loading from 'common/Loading';
import { useDialog } from 'hooks';
import { useSnackSetState } from 'contexts/SnackContext';
import { updateProbe } from 'entities/probes/probes.api';
import ProbeModel from 'common/probes/models/ProbeModel';
import { propTypes } from './UpdateButton.props';
import { getServerError } from 'pages/pages.utils';
import { capitalizeFirstLetter } from 'utils/string';
import makeStyles from './UpdateButton.styles';

const useStyles = makeStyles();

function UpdateButton ({ probeToUpdate, allProbesToUpdate, setProbesToUpdate }) {
  const [isLoading, setLoading] = useState(false);
  const probe = new ProbeModel(probeToUpdate);
  const classes = useStyles();
  const setSnack = useSnackSetState();
  const [t] = useTranslation();

  const Update = async (probe) => {
    setLoading(true);
    const { id } = probe;

    try {
      const [responseNextVersion, responseOldVersion] = await Promise.all([
        updateProbe(probe.latest.id, { enabled: true }),
        updateProbe(probe.id, { enabled: false })
      ]);

      if (responseNextVersion.status === 200 && responseOldVersion.status === 200) {
        const remainingProbesToUpdate = allProbesToUpdate.filter((probe) => probe?.enabled_versions[0]?.id !== id);
        setProbesToUpdate(remainingProbesToUpdate);
        setSnack({
          isOpen: true,
          title: t('update_probe'),
          message: t('probe_was_succesfully_updated_to', {
            name: probe.info.name,
            software_version: probe.latest.version.software
          }),
          severity: 'success'
        });
      }
    } catch (error) {
      const errorMsg = t('could_not_update_probe_to_version', {
        name: probe.info.name,
        software_version: probe.latest?.version?.software
      });
      setSnack({
        isOpen: true,
        title: t('something_went_wrong'),
        message: getServerError(error, errorMsg),
        severity: 'error'
      });
    } finally {
      setLoading(false);
      onDisplayDialog({ isOpen: false });
    }
  };

  const onDisplayDialog = useDialog(`probe-${probe.id}`, {
    onClose: () => {
      onDisplayDialog({ isOpen: false });
    },
    components: {
      Icon: <Antivirus className={classes.icon}></Antivirus>,
      Title: <DialogTitle title={t('do_you_want_to_update_probe_version', { name: probe.info.name, software_version: probe.latest?.version?.software })} />,
      ContentText: <Typography variant="body1" className={classes.description}>{t('probe_will_be_updated', { name: probe.info.name })}</Typography>,
      Actions: <DialogButtons
        confirmLabel={t('ok')} cancelLabel={t('cancel')}
        onConfirm={() => Update(probe)}
        onCancel={() => onDisplayDialog({ isOpen: false })} />
    }
  });

  return (
    <>
      {isLoading
        ? <Loading size={25} />
        : <button className={classes.button} onClick={() => onDisplayDialog({ isOpen: true })}>
          <Typography>{capitalizeFirstLetter(t('update'))}</Typography>
          </button>}
    </>
  );
}

UpdateButton.propTypes = propTypes;

export default UpdateButton;
