import dayjs from 'dayjs';
import _ from 'lodash';
import { fetchFileContent } from 'entities/files/files.api';
import { createPDFBlobToURL } from 'utils/downloadUtil';

const formatDate = date => dayjs(date).format('MMM D, YYYY H:mm A');

export const getLastScan = data => {
  const lastItem = _.last(data.analysis);
  if (lastItem) {
    return formatDate(lastItem.created_at);
  }
  return formatDate(data.timestamp_first_scan);
};

export const getPreview = async id => {
  const response = await fetchFileContent(id, {});
  return createPDFBlobToURL({ stream: response.data });
};
