import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { TextField, Typography, Grid, MenuItem, Button } from '@material-ui/core';
import { useSnackSetState } from 'contexts/SnackContext';
import { updateAccount } from 'entities/accounts/accounts.api';
import FormField from '../FormField';
import { propTypes } from './Details.props';
import makesStyles from '../../AccountInfo.style';

const useStyles = makesStyles();

const Details = ({ account }) => {
  const [t] = useTranslation();
  const [model, setModel] = useState(account);
  const [errorFields, setErrorFields] = useState({});
  const [, setSpinner] = useState(false);
  const setSnack = useSnackSetState();
  const classes = useStyles();

  const cleanupErrors = field => {
    setErrorFields(prevError => {
      const nextErrors = { ...prevError };
      switch (field) {
        case 'country':
        case 'city':
        case 'post_code':
        case 'line1':
          delete nextErrors.address[field]; break;
        default:
          delete nextErrors[field]; break;
      }

      return nextErrors;
    });
  };

  const onChangeForm = (field, value) => {
    setModel((prevState) => ({
      ...prevState,
      [field]: value
    }));
    Object.keys(errorFields).length > 0 && cleanupErrors(field);
  };

  const onChangeAddress = (field, value) => {
    setModel(({ address, ...prevState }) => {
      const anotherAddress = { ...address };
      if (value === '' || value === null) {
        delete anotherAddress[field];
      } else {
        anotherAddress[field] = value;
      }

      return {
        ...prevState,
        address: anotherAddress
      };
    });
    Object.keys(errorFields).length > 0 && cleanupErrors(field);
  };

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    setSpinner(true);
    updateAccount(model.id, model)
      .then((response) => {
        setSnack({
          isOpen: true,
          message: t('info_updated_successfully'),
          title: t('account_details'),
          severity: 'success'
        });
      })
      .catch(error => {
        if (error.status === 400) {
          setErrorFields(error?.data?.errors?.fields);
        } else {
          const errors = _.get(error, 'response.data.errors');
          const errorMsg = _.values(errors);
          setSnack({
            isOpen: true,
            message: errorMsg[0] ? errorMsg[0] : t('if_error_contact_admin'),
            title: t('account_update_failed'),
            severity: 'error'
          });
        }
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  return (
    <>
      {
        model
          ? (
          <>
            <form onSubmit={onSubmit}>
              <Grid container>
                <Typography variant="h2" classes={{ h2: classes.subheading }}> {t('account_information')} </Typography>
                <Grid item xs={12} spacing={2} container>
                  <Grid item xs={6}>
                    <FormField id="account-fullname" label={t('full_name')} variant="outlined" placeholder={t('your_full_name')}
                      value={model.name} onChange={(e) => onChangeForm('name', e.target.value)}
                      error={errorFields.name} />
                  </Grid>
                  <Grid item xs={6}>
                    <FormField id="account-id" label={t('account_id')} variant="outlined" disabled value={model.id} placeholder={t('account')} />
                  </Grid>
                  <Grid item xs={6}>
                    <FormField id="account-subdomain" label={t('sub_domain_name')} variant="outlined" placeholder={t('subdomain')}
                      value={model.subdomain} onChange={(e) => onChangeForm('subdomain', e.target.value)}
                      error={errorFields.subdomain} />
                  </Grid>
                </Grid>
                <Typography variant="h2" classes={{ h2: classes.subheading }}>{t('physical_address')}</Typography>
                <Grid item xs={12} spacing={2} container>
                  <Grid item xs={6}>
                    <FormField
                      id="account-country"
                      label={t('country')} variant="outlined" required
                      value={model.address?.country} onChange={(e) => onChangeAddress('country', e.target.value)}
                      error={errorFields.address?.country} />
                  </Grid>
                  <Grid item xs={6}>
                    <FormField id="account-state" label={t('state')} variant="outlined"
                    value={model.address?.state} onChange={(e) => onChangeAddress('state', e.target.value)}
                    error={errorFields.address?.state} />
                  </Grid>
                  <Grid item xs={6}>
                    <FormField id="account-city" label={t('city')} variant="outlined" required
                    value={model.address?.city || ''} onChange={(e) => onChangeAddress('city', e.target.value)}
                    error={errorFields.address?.city} />
                  </Grid>
                  <Grid item xs={6}>
                    <FormField id="account-post-code" label={t('post_code')} variant="outlined" required
                    value={model.address?.post_code || ''} onChange={(e) => onChangeAddress('post_code', e.target.value)}
                    error={errorFields.address?.post_code} />
                  </Grid>
                  <Grid item xs={6}>
                    <FormField id="account-line1" label={t('address_line_1')} variant="outlined" required
                    value={model.address?.line1 || ''} onChange={(e) => onChangeAddress('line1', e.target.value)}
                    error={errorFields.address?.line1} />
                  </Grid>
                  <Grid item xs={6}>
                    <FormField id="account-line2" label={t('address_line_2')} variant="outlined"
                    value={model.address?.line2 || ''} onChange={(e) => onChangeAddress('line2', e.target.value)}
                    error={errorFields.address?.line2} />
                  </Grid>
                  <Grid item xs={6}>
                    <FormField id="account-phone" label={t('phone_number')} variant="outlined" type="number"
                    value={model.address?.phone} onChange={(e) => onChangeAddress('phone', e.target.value)}
                    error={errorFields.address?.phone} />
                  </Grid>
                </Grid>
                <Typography variant="h2" classes={{ h2: classes.subheading }}>{t('financial_info')}</Typography>
                <Grid item xs={12} spacing={2} container>
                  <Grid item xs={6}>
                    <FormField
                      id="account-finance-name"
                      label={t('name')}
                      variant="outlined"
                      value={model.finance_contact || ''}
                      onChange={(e) => onChangeForm('finance_contact', e.target.value)}
                      error={errorFields[model.finance_contact]} />
                  </Grid>
                  <Grid item xs={6}>
                    <FormField
                      id="account-finance-email"
                      type="email" label={t('email_address')} variant="outlined"
                      value={model.finance_email || ''} onChange={(e) => onChangeForm('finance_email', e.target.value)}
                      error={errorFields[model.finance_email]} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="account-currency"
                      label={t('currency')}
                      variant="outlined"
                      select value={model.currency} onChange={(e) => onChangeForm('currency', e.target.value)}>
                      {[{ value: 'EUR', label: 'EURO' }, { value: 'USD', label: 'DOLLAR' }].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid item xs={12} spacing={4} container>
                  <Grid item xs={6}>
                    <Button type="submit" variant="contained" color="primary">{t('save')}</Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </>)
          : null
      }
    </>
  );
};

Details.propTypes = propTypes;

export default Details;
