import tableConfig from 'common/tableConfig';
import { getColumns } from './AccountTable.columns';

export default ({ plans, plan }) => ({
  columns: getColumns(plans, plan),
  components: {
    ...tableConfig.components
  },
  options: {
    ...tableConfig.options
  }
});
