import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  dialogIconSize: {
    width: '4rem',
    height: '4rem'
  },
  deleteButtonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    margin: '5px auto',
    padding: '0px 20px 10px 20px',
    '& Button': {
      minWidth: '210px'
    },
    '& Button:first-child': {
      backgroundColor: '#fa4b54',
      '&:hover': {
        backgroundColor: '#f90612'
      }
    }
  },
  title: {
    fontSize: '1.10em',
    maxWidth: '410px',
    margin: '0px 20px 0 20px'
  },
  delete: {
    color: ' #fa4b54'
  }
}));
