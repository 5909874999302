import React from 'react';
import { Grid, IconButton, Switch, Tooltip, Typography } from '@material-ui/core';
import { propTypes } from './SwitchProbeCard.props';
import makeStyles from './styles';

SwitchProbeCard.propTypes = propTypes;

const useStyles = makeStyles();
function SwitchProbeCard ({ switchInfo }) {
  const classes = useStyles();

  const enabledSwitch =
    <Grid container alignItems="center" xs="12">
      <Grid item xs="6">
        <Typography variant="body2" align="left">
          {switchInfo.text}
          <Tooltip title={switchInfo.tooltip.text} classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} arrow>
            <IconButton>
              {switchInfo.tooltip.icon}
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>
      <Grid container item xs="6" justify="flex-end">
        <Switch checked={switchInfo.checked} disabled={!switchInfo.enabled} onChange={switchInfo.onClick}></Switch>
      </Grid>
    </Grid>;

  const disabledSwitch =
    <Tooltip title={switchInfo.tooltip.disabledText} arrow>
      <Grid container alignItems="center" xs="12">
        <Grid item xs="6">
            <Typography variant="body2" align="left" className={classes.disabledText}>
              {switchInfo.text}
            </Typography>
        </Grid>
        <Grid container item xs="6" justify="flex-end">
          <Switch checked={switchInfo.checked} disabled={!switchInfo.enabled} onChange={switchInfo.onClick}></Switch>
        </Grid>
      </Grid>
    </Tooltip>;

  return (
    switchInfo.enabled ? enabledSwitch : disabledSwitch
  );
}

export default SwitchProbeCard;
