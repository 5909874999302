import React from 'react';
import { Grid } from '@material-ui/core';
import { capitalizeFirstLetter } from 'utils/string';
import { getPercentage } from 'utils/math';
import { propTypes } from './Usage.props';
import makeStyles from './Usage.styles';

const useStyles = makeStyles();

const Usage = ({ type, count, max }) => {
  const progress = getPercentage(count, max);
  const classes = useStyles({ progress });

  return (
    <Grid container className={classes.container}>
      <div data-test-id='accountLimit' className={classes.accountLimit}>
        <div className={classes.accountType}>{capitalizeFirstLetter(type)}</div>
        <div className={classes.limit}>{max} {type}.</div>
      </div>
      <div data-test-id='accountUsage' className={classes.accountUsage}>
        <div className={classes.addedSoFar}>
          <div className={classes.text}>
            {progress === 100 ? 'Maximum limit reached.' : 'Added so far.'}
          </div>
          <div className={classes.count}>{count}/{max}</div>
        </div>
        <div className={classes.bar}></div>
      </div>
    </Grid>
  );
};

Usage.propTypes = propTypes;

export default Usage;
