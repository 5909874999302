import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  errorItem: {
    backgroundColor: 'red'
  },
  listItemCustom: {
    display: 'flex',
    padding: 10
  },
  scanButton: {
    margin: 12,
    height: 50
  },
  uploadIconSvg: {
    display: 'block',
    margin: '0 auto',
    fill: theme.palette.secondary.main
  },
  dropzoneContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dashed #000',
    borderRadius: 7.5,
    margin: '10px 0',
    height: 240,
    outline: 0,
    '&:hover': {
      backgroundColor: 'rgba(128, 128, 128, 0.0784313725490196)',
      border: '1px solid',
      cursor: 'pointer'
    }
  },
  disabledDropzone: {
    opacity: '.5',
    '& svg': {
      fill: 'gray'
    }
  },
  dropzoneSmallText: {
    color: '#808080c7',
    fontSize: '0.82rem',
    textAlign: 'center',
    padding: '0 20px'

  },
  dropzoneBigText: {
    textAlign: 'center'
  },
  iconSize: {
    width: '2rem',
    height: '2rem'
  },
  actionIconSize: {
    width: '1.5rem',
    height: '1.5rem'
  },
  iconButton: {
    fontSize: '0.75rem'
  },
  dialogIcon: {
    padding: 10,
    display: 'flex',
    justifyContent: 'center'
  },
  assignText: {
    cursor: 'pointer'
  },
  scanHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px'
  },
  workFlowContainer: {
    marginTop: '20px'
  },
  header: {
    fontSize: '1.25rem'
  },
  listBox: {
    marginTop: '20px'
  },
  infoIcon: {
    width: '1rem',
    height: '1rem',
    fill: theme.palette.secondary.main
  },
  arrow: {
    color: theme.palette.secondary.main
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
    fontFamily: theme.typography.caption.fontFamily,
    fontSize: '12px',
    padding: '15px',
    lineHeight: 1.5,
    letterSpacing: 0.3,
    width: '255px'
  },
  actionTooltip: {
    backgroundColor: theme.palette.secondary.main,
    fontFamily: theme.typography.caption.fontFamily,
    fontSize: '15px',
    padding: '12px'
  },
  keepFileComponent: {
    display: 'flex',
    alignItems: 'center'
  },
  saveIcon: {
    marginRight: '29px',
    marginBottom: '3px',
    marginLeft: '2px'
  },
  keepFileLabel: {
    fontSize: '16px'
  },
  keepFileCheckbox: {
    marginRight: '5px'
  },
  explanationIconSize: {
    width: '1rem',
    height: '1rem',
    verticalAlign: 'text-top',
    margin: 2
  },
  passwordExplanation: {
    fontStyle: 'italic',
    fontSize: '14px',
    marginRight: 5,
    inlineSize: '75%'
  },
  passwordExplanationContainer: {
    display: 'flex',
    marginBottom: 30,
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  filenameLabel: {
    maxWidth: 'fit-content',
    paddingRight: '100px',
    wordBreak: 'break-all'
  }
}));
