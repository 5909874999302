import React, { useState, useEffect, useContext } from 'react';
import { NotificationStateContext } from 'contexts/NotificationContext';
import Chip from '@material-ui/core/Chip';
import makeStyles from './styles';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles();

export default function Notification () {
  const notification = useContext(NotificationStateContext);
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const {
    timeout = 0,
    icon = <DoneIcon />,
    message = undefined,
    action = () => undefined,
    status = 100
  } = notification;

  const handleClick = () => {
    setIsOpen(false);
    action();
  };

  useEffect(() => {
    if (message) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    if (timeout) {
      const timeoutToClosePopUp = setTimeout(() => { setIsOpen(false); }, timeout);
      return () => clearTimeout(timeoutToClosePopUp);
    }
  }, [timeout, message]);

  const backgroundChip = {
    background: `linear-gradient(110deg, rebeccapurple ${status}%, pink 0%)`
  };

  const customClasses = {
    root: classes.root
  };

  return (
    isOpen && (
      <Chip
        label={message}
        onClick={action ? handleClick : undefined}
        icon={icon}
        style={backgroundChip}
        classes={customClasses}
      />
    )
  );
}
