import React from 'react';
import { useTranslation } from 'react-i18next';
import QualifierChip from '../QualifierChip';
import { ANALYSIS_STATUS, PRIVACY_LABELS } from 'entities/analysis/analysis.constants';
import { TOOLTIP_STATUS } from './Privacy.constants';
import { propTypes } from './Privacy.props';
import makeStyles from './Privacy.styles';

const useStyles = makeStyles();

const Privacy = ({ rowData }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  let qualifierProps = {
    label: '',
    color: '',
    backgroundColor: '',
    tooltip: ''
  };

  if (!rowData.current_analysis) {
    qualifierProps = {
      label: PRIVACY_LABELS.notAnalyzed,
      color: classes.notAnalyzed,
      backgroundColor: classes.notAnalyzedBackground,
      tooltip: t(TOOLTIP_STATUS.notAnalyzed)
    };
  } else {
    const status = rowData.current_analysis.status || null;
    const privacy = rowData.current_analysis.privacy || null;

    const privacyKey = privacy ? privacy.toLowerCase() : 'unknown';
    let privacyLabel = PRIVACY_LABELS[privacyKey] || PRIVACY_LABELS.unknown;
    let privacyClass = classes[privacyKey] || classes[privacyLabel];
    let statusBackground = classes[privacyKey + 'Background'] || classes[privacyLabel + 'Background'];
    let tooltip = t(TOOLTIP_STATUS[privacyKey]) || t(TOOLTIP_STATUS.unknown);

    if (status === ANALYSIS_STATUS.RUNNING) {
      privacyLabel = PRIVACY_LABELS.running;
      privacyClass = classes.running;
      statusBackground = classes.runningBackground;
      tooltip = t(TOOLTIP_STATUS.running);
    }

    qualifierProps = {
      label: privacyLabel,
      color: privacyClass,
      backgroundColor: statusBackground,
      tooltip
    };
  }

  return <QualifierChip {...qualifierProps} />;
};

Privacy.propTypes = propTypes;

export default Privacy;
