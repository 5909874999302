import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  btnGroup: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '30px'
  },
  revokeBtn: {
    background: theme.palette.text.secondary,
    color: '#FFF',
    width: '250px',
    '&:hover': {
      background: '#515A6B'
    }
  },
  btn: {
    width: '250px'
  }
}));
