import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import ProbeCardContent from './ProbeCardContent';
import ProbeCardHeader from './ProbeCardHeader';
import makeStyles from './styles';

const useStyles = makeStyles();

export default function ProbeCard ({
  onClickCard,
  probe,
  setEnablePossible,
  children
}) {
  const classes = useStyles();

  const cardActionsClasses = {
    root: classes.cardActionsRoot
  };

  return (
    <Card classes={{ root: `${classes.cardRoot} ${probe.isEnabled() ? `${classes.cardRootEnabled}` : ''}` }} variant="outlined">
      <CardActionArea onClick={onClickCard}>
        <ProbeCardHeader title={probe.info.display_name} probeType={probe.info.target_type} />
        <ProbeCardContent
          setEnablePossible={setEnablePossible}
          probe={probe}
        />
      </CardActionArea>
      <CardActions classes={cardActionsClasses}>
        { children }
      </CardActions>
    </Card>
  );
};

ProbeCard.propTypes = {
  onClickCard: PropTypes.func.isRequired,
  probe: PropTypes.object,
  setEnablePossible: PropTypes.func,
  children: PropTypes.node.isRequired
};
