import React, { useMemo } from 'react';
import { TextField, FormHelperText } from '@material-ui/core';
import { propTypes } from './FormField.props';

export default function FormField ({ error, disabled = false, ...TextFieldProps }) {
  const errorIdentifier = useMemo(() => {
    return `${TextFieldProps.id}-error`;
  }, [TextFieldProps.id]);

  return (
    <>
    <TextField
      {...TextFieldProps}
      error={error}
      disabled={disabled}
      aria-describedby={errorIdentifier}
    />
    { error ? <FormHelperText error id={errorIdentifier}>{ error }</FormHelperText> : null }
    </>
  );
};

FormField.propTypes = propTypes;
