import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useDialog from 'hooks/useDialog';
import { DialogTitle, DialogButtons, DialogContent } from 'common/probes/ProbeDialog';
import { FileTextIcon, TrashIcon, WarningIcon, LockedFileIcon, LockOutlinedIcon, UnLockOutlinedIcon } from 'common/Icons';
import { ListElement, ListElementIcon, ListElementText, ListElementActions } from 'components/List';
import SetPasswordForm from './components/SetPasswordForm';
import { bytesToSize } from 'utils';
import makeStyles from './styles';
import getDefaultTheme from 'contexts/ThemeContext/defaultTheme';

const useStyles = makeStyles();
const defaultTheme = getDefaultTheme();

export default function FileListElement ({ file, removeItem, updatePassword }) {
  const [t] = useTranslation();
  const classes = useStyles();
  const { palette } = useTheme();
  const submitButtonRef = useRef();
  const [isPasswordSet, setIsPasswordSet] = useState(file.password.length > 0);

  const handleRemoveItem = useCallback(() => {
    removeItem(file);
  }, [removeItem, file]);

  const handleOnSubmit = async (password) => {
    await updatePassword(file, password);
    setIsPasswordSet(password.length > 0);
  };

  const handleConfirmPassword = () => {
    submitButtonRef.current.click();
    onDisplayPasswordDialog({ isOpen: false });
  };

  const onDisplayDialog = useDialog(`remove-file-${file.temporal_Id}-${file.instance.name}`, {
    onClose: () => {
      onDisplayDialog({ isOpen: false });
    },
    components: {
      Icon: <WarningIcon color={defaultTheme.palette.error.main} size='4rem' />,
      Title: <DialogTitle title={t('do_you_want_to_delete_this_file')} />,
      ContentText: <DialogContent content={t('file_wont_be_scanned', { file: `${file.instance.name}` })} />,
      Actions: <DialogButtons type='warning'
        confirmLabel={t('delete')} cancelLabel={t('cancel')}
        onConfirm={handleRemoveItem}
        onCancel={() => onDisplayDialog({ isOpen: false })} />
    }
  });

  const onDisplayPasswordDialog = useDialog(`set-password-${file.temporal_Id}-${file.instance.name}`, {
    onClose: () => {
      onDisplayDialog({ isOpen: false });
    },
    components: {
      Icon: <LockedFileIcon color={defaultTheme.palette.primary.main} size='4rem' />,
      Title: <DialogTitle title={t('please_type_in_the_password')} />,
      Content: <SetPasswordForm submitButtonRef={submitButtonRef} onSubmit={handleOnSubmit} file={file}/>,
      Actions: <DialogButtons type='info'
        confirmLabel={t('save_password')} cancelLabel={t('cancel')}
        onConfirm={handleConfirmPassword}
        onCancel={() => onDisplayPasswordDialog({ isOpen: false })} />
    }
  });

  return (
    <ListElement className={classes.listItemCustom} divider>
      <ListElementIcon>
        <FileTextIcon className={classes.iconSize} title='File'/>
      </ListElementIcon>
      <ListElementText className={classes.filenameLabel} primary={file.instance.name} secondary={bytesToSize(file.instance.size)} />
      <ListElementActions>
        {isPasswordSet
          ? <Tooltip title={t('edit_password')} classes={{ arrow: classes.arrow, tooltip: classes.actionTooltip }} arrow>
            <IconButton
              color="secondary"
              aria-label={t('edit_password')}
              component="span"
              onClick={() => onDisplayPasswordDialog({ isOpen: true })}
              className={classes.iconButton}
            >
              <UnLockOutlinedIcon className={classes.actionIconSize} color={palette.secondary.main} />
            </IconButton>
          </Tooltip>
          : <Tooltip title={t('add_password')} classes={{ arrow: classes.arrow, tooltip: classes.actionTooltip }} arrow>
            <IconButton
              color="secondary"
              aria-label={t('add_password')}
              component="span"
              onClick={() => onDisplayPasswordDialog({ isOpen: true })}
              className={classes.iconButton}
            >
            <LockOutlinedIcon className={classes.actionIconSize} color={palette.secondary.main} />
          </IconButton>
        </Tooltip>}
        <Tooltip title={t('delete_file')} classes={{ arrow: classes.arrow, tooltip: classes.actionTooltip }}arrow>
        <IconButton
          color="secondary"
          aria-label="remove file"
          component="span"
          onClick={() => onDisplayDialog({ isOpen: true })}
          className={classes.iconButton}
        >
          <TrashIcon className={classes.actionIconSize} color={palette.secondary.main}/>
        </IconButton>
        </Tooltip>
      </ListElementActions>
    </ListElement>
  );
};

FileListElement.propTypes = {
  file: PropTypes.object,
  removeItem: PropTypes.func,
  updatePassword: PropTypes.func
};
