import React from 'react';
import { useTranslation } from 'react-i18next';
import QualifierChip from '../QualifierChip';
import { ANALYSIS_STATUS, SAFETY_LABELS } from 'entities/analysis/analysis.constants';
import { TOOLTIP_STATUS } from './Safety.constants';
import { propTypes } from './Safety.props';
import makeStyles from './Safety.styles';

const useStyles = makeStyles();

const Safety = ({ rowData }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  let qualifierProps = {
    label: '',
    color: '',
    backgroundColor: '',
    tooltip: ''
  };

  if (!rowData.current_analysis) {
    qualifierProps = {
      label: SAFETY_LABELS.notAnalyzed,
      color: classes.notAnalyzed,
      backgroundColor: classes.notAnalyzedBackground,
      tooltip: t(TOOLTIP_STATUS.notAnalyzed)
    };
  } else {
    const status = rowData.current_analysis.status || null;
    const safety = rowData.current_analysis.safety || null;

    const safetyKey = safety ? safety.toLowerCase() : 'unknown';
    let safetyLabel = SAFETY_LABELS[safetyKey] || SAFETY_LABELS.unknown;
    let safetyClass = classes[safetyKey] || classes[safetyLabel];
    let statusBackground = classes[safetyKey + 'Background'] || classes[safetyLabel + 'Background'];
    let tooltip = t(TOOLTIP_STATUS[safetyKey]) || t(TOOLTIP_STATUS.unknown);

    if (status === ANALYSIS_STATUS.RUNNING) {
      safetyLabel = SAFETY_LABELS.running;
      safetyClass = classes.running;
      statusBackground = classes.runningBackground;
      tooltip = t(TOOLTIP_STATUS.running);
    }

    qualifierProps = {
      label: safetyLabel,
      color: safetyClass,
      backgroundColor: statusBackground,
      tooltip
    };
  }

  return <QualifierChip {...qualifierProps} />;
};

Safety.propTypes = propTypes;

export default Safety;
