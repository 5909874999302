import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

export default function Loading ({ align = 'center', style, size }) {
  const stylish = {
    display: 'flex',
    overflow: 'hidden',
    justifyContent: align,
    ...style
  };
  return (
    <div style={stylish}>
      <CircularProgress size={size} />
    </div>
  );
};

Loading.propTypes = {
  size: PropTypes.number,
  align: PropTypes.string,
  style: PropTypes.object
};
