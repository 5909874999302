import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  addPlanForm: {
    display: 'flex',
    margin: '0 auto',
    flexWrap: 'wrap',
    width: 390
  },
  arrow: {
    color: theme.palette.secondary.main
  },
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
    fontFamily: theme.typography.caption.fontFamily,
    padding: 15,
    lineHeight: 1.5,
    letterSpacing: 0.3,
    width: '215px'
  },
  infoIcon: {
    width: '0.8rem',
    height: '0.8rem',
    fill: theme.palette.text.primary
  }
}));
