import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableRow,
  Link
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ArrowUp, ArrowDown } from 'common/Icons';
import { isDefined } from 'utils/types';
import CellFallbackView from '../../../CellFallbackView';
import { propTypes } from './Table.props';
import makeStyles from '../../../../styles';

const useStyles = makeStyles();

const MAX_ROWS_DISPLAY = 2;

const Table = ({ rows, title }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [showFullRows, SetShowFullRows] = useState(rows.length <= MAX_ROWS_DISPLAY);
  const cellProps = {
    className: classes.cell,
    component: 'th',
    scope: 'row'
  };
  const cellStyle = { display: 'flex', alignItems: 'center' };
  const iconStyle = { marginLeft: '10px' };

  const getRowsToDisplay = () => {
    return showFullRows ? rows : Array(MAX_ROWS_DISPLAY).fill(null).map((c, index) => rows[index]);
  };

  const showMoreLink = () => {
    return !showFullRows && rows.length > MAX_ROWS_DISPLAY;
  };

  const showLessLink = () => {
    return showFullRows && rows.length > MAX_ROWS_DISPLAY;
  };

  const toggleShowFullList = (event) => {
    event.preventDefault();
    SetShowFullRows(!showFullRows);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <div className={classes.fileInfoHeader}>
          <Typography variant="h4" className={classes.panelTitle}>
            {title}
          </Typography>
        </div>
        <MuiTable className={classes.table} size='medium' aria-label='Metadata table'>
          <TableBody>
            {getRowsToDisplay().map((d, index) => (
              <TableRow key={index}>
                <CellFallbackView {...cellProps} condition={isDefined(d)}>
                  {d}
                </CellFallbackView>
              </TableRow>
            ))}
            {showLessLink() && <TableRow key='seeLessLink'>
              <TableCell {...cellProps} styles={cellStyle}>
                <Link href='#' onClick={toggleShowFullList}>
                  {t('see_less')}
                  <ArrowUp title={t('see_less')} size={10} style={iconStyle}/>
                </Link>
              </TableCell>
            </TableRow>}
            {showMoreLink() && <TableRow key='seeMoreLink'>
              <TableCell {...cellProps} style={cellStyle}>
                <Link href='#' onClick={toggleShowFullList}>
                  {t('see_more')}
                  <ArrowDown title={t('see_more')} size={10} style={iconStyle}/>
                </Link>
              </TableCell>
            </TableRow>}
          </TableBody>
        </MuiTable>
      </Grid>
    </Grid>
  );
};

Table.propTypes = propTypes;

export default Table;
