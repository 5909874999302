import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Link, Tooltip } from '@material-ui/core';
import getDefaultTheme from 'contexts/ThemeContext/defaultTheme';
import { deleteFile } from 'entities/files/files.api';
import { Details, InfoIcon } from 'common/Icons';
import Loading from 'common/Loading';
import { getServerError } from 'pages/pages.utils';
import bytesToSize from 'utils/BytesToSize';
import { propTypes } from './Row.props';
import makeStyles from './Row.styles';

const NOT_FOUND_STATUS = 404;

const defaultTheme = getDefaultTheme();
const useStyles = makeStyles();

const Row = ({ file, onDelete }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [isPending, setIsPending] = useState(true);
  const [isDeleted, setIsDeleted] = useState(false);
  const [canRetry, setCanRetry] = useState(false);
  const [error, setError] = useState(null);

  const deleteFileAction = useCallback(async () => {
    deleteFile(file.id, { dry: false })
      .then(() => {
        onDelete();
        setCanRetry(false);
        setIsDeleted(true);
        setError(null);
      })
      .catch(error => {
        if (error?.response?.status === NOT_FOUND_STATUS) {
          setError(t('file_not_found'));
          setCanRetry(false);
        } else {
          setError(getServerError(error?.response?.data));
          setCanRetry(true);
        }
      })
      .finally(() => {
        setIsPending(false);
      });
  }, [file.id, onDelete, t]);

  const handleOnRetry = useCallback(() => {
    setError(null);
    setIsPending(true);
    /**
     * A small delay to perceive the retry,
     * Especially if it's a fast error.
     */
    setTimeout(deleteFileAction, 500);
  }, [deleteFileAction]);

  useEffect(() => {
    if (!isDeleted) {
      deleteFileAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.row}>
      <div className={classes.info}>
        <div className="icon">
          <Details size={48} />
        </div>
        <div className="details">
          <div className="name">{file.name}</div>
          <div className="size">{bytesToSize(file.size)}</div>
        </div>
      </div>
      <div className={classes.status}>
        {isPending && (
          <div className="progress text">
            <Loading size={20} align="flex-start" />
          </div>
        )}
        {isDeleted && <div className="success text">{t('file_deleted')}</div>}
        {error && !isDeleted && (
          <div className={classes.error}>
            <p className="text">{t('failed_to_delete')}</p>
            <Tooltip classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} placement={canRetry ? 'top' : 'right'} title={error} arrow>
              <IconButton>
                <InfoIcon size={16} color={defaultTheme.palette.error.main} />
              </IconButton>
            </Tooltip>
            {canRetry && (
              <>
                <div className="divider">|</div>
                <Link onClick={handleOnRetry} className="action">
                  {t('retry')}
                </Link>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Row.propTypes = propTypes;

export default Row;
