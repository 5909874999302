import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  statusIcon: {
    width: 16,
    height: 16,
    fill: theme.palette.text.primary
  },
  noClickTooltip: {
    cursor: 'auto'
  }
}));
