import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from './InputSelect.props';
import makeStyles from './InputSelect.style';

const useStyles = makeStyles();

export default function InputSelect (props) {
  const [t] = useTranslation();
  const [value, setValue] = useState('');
  const classes = useStyles();

  const { id, label, items, filter } = props;

  const handleChange = (event) => {
    setValue(event.target.value);
    filter(id, event.target.value);
  };

  return (
    <Grid item>
      <FormControl
        variant='outlined'
        className={classes.formControl}
      >
        <InputLabel id={`${id + '-selector-label'}`}>{label}</InputLabel>
        <Select
          id={`${id + '-selector'}`}
          labelId={`${id + '-selector-label'}`}
          onChange={handleChange}
          variant='outlined'
          name={label}
          value={value}
          label={t(label)}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}

        >
          <MenuItem key={0} value=''>{t('any')}</MenuItem>
          {items.map(item => {
            return <MenuItem key={item} value={item}>{t(item)}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </Grid>
  );
};

InputSelect.propTypes = propTypes;
