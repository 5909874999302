import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import scanner from 'utils/scanner';
import { ROUTE_PATHS } from 'utils/routes.constants';
import PropTypes from 'prop-types';

export default function NewScanButton (props) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const newScan = () => {
    props?.stopScanning?.(true);
    navigate(ROUTE_PATHS.SCAN);
    window.scrollTo(0, 0);
    scanner.cleanStoreFiles();
  };

  return (
    <Button
      variant="contained"
      fullWidth
      style={{ margin: 12 }}
      onClick={newScan}
      color="primary"
    >
      {t('new_scan')}
    </Button>
  );
}

NewScanButton.propTypes = {
  stopScanning: PropTypes.func
};
