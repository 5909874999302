import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    marginBottom: '20px'
  },
  infoPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '600px'
  },
  info: {
    minWidth: '150px'
  },
  infoTitle: {
    fontSize: '14px',
    color: theme.palette.text.secondary
  }
}));
