export const handleTabsScroll = (tabsPositions, selectedTab, setSelectedTab) => {
  const tabs = document.getElementById('category-tabs'); // Asegúrate de asignar un id a tus Tabs

  if (tabs) {
    tabs.style.top = window.scrollY > 20 ? '65px' : 'initial';
    tabs.style.position = window.scrollY > 50 ? 'fixed' : 'inherit';
  }

  const scrollY = window.scrollY || window.pageYOffset;

  const tabIndex = () => {
    const index = tabsPositions.findLastIndex(function (position, i) {
      return (position.positionY - 200) < scrollY;
    });
    return index;
  };

  if (tabIndex !== selectedTab) {
    setSelectedTab(tabIndex);
  }
};

export const getTabsPositions = () => {
  const tabsElements = document.querySelectorAll('.category-tab');
  const positions = [];

  tabsElements.forEach((elemento) => {
    const rect = elemento.getBoundingClientRect();
    positions.push({
      id: elemento.id,
      positionY: rect.top + window.scrollY
    });
  });
  return positions;
};
