import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Popper, Typography } from '@material-ui/core';
import { fetchActivePlan } from 'entities/accounts/accounts.api';
import { fetchPlan } from 'entities/plans/plans.api';
import { getSubscriptionCost } from 'entities/plans/plans.utils';
import Loading from 'common/Loading';
import { InfoIcon } from 'common/Icons';
import { propTypes } from './LinkedPlan.props';
import makeStyles from './LinkedPlan.style';

const UNLIMITED = 'Unlimited';

const useStyles = makeStyles();

const LinkedPlan = ({ accountId, currency }) => {
  const [loading, setLoading] = useState(true);
  const [planInfo, setPlanInfo] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const activePlan = await fetchActivePlan(accountId);
      if (activePlan?.plan) {
        const planData = await fetchPlan(activePlan.plan);
        setPlanInfo(planData);
      };
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = (event) => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (<>
    { loading
      ? (
        <Grid item xs={6}>
          <Loading size={20} align="flex-start" style={{ padding: 12 }} />
        </Grid>
        )
      : null
    }
    { !loading && planInfo
      ? <span className={classes.planRow}>
            {planInfo.name}
            <IconButton
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <InfoIcon className={classes.infoicon}/>
            </IconButton>
            <Popper
              anchorEl={anchorEl}
              open={open}
              placement="right"
              disablePortal={false}
              modifiers={{
                flip: {
                  enabled: true
                },
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'window'
                },
                arrow: {
                  enabled: true,
                  element: arrowRef
                }
              }}
              className={classes.popper}
            >
              <span className={classes.arrow} ref={setArrowRef} />
              <div className={classes.boxHeader}>
                <span>{planInfo.name}</span>
                <span>{getSubscriptionCost(planInfo.subscriptionCost, planInfo.billingPeriod, currency)}</span>
              </div>
              <div className={classes.boxInfo}>
                <Typography variant="caption">USERS</Typography>
                <Typography variant="caption">
                  <ul className={classes.featureList}>
                    <li>
                      {planInfo.maxOperatorAccounts ?? UNLIMITED} Operators
                    </li>
                    <li>
                      {planInfo.maxAnalystAccounts ?? UNLIMITED} Analysts
                    </li>
                    <li>
                      {planInfo.maxAdminAccounts ?? UNLIMITED} Administrators
                    </li>
                  </ul>
                </Typography>
              </div>
              <div className={classes.boxInfo}>
                <Typography variant="caption">USAGE</Typography>
                <Typography variant="caption">
                  <ul className={classes.featureList}>
                    <li>
                      {planInfo.scanLimit} scan / {planInfo.scanLimitPeriod ?? ''}
                    </li>
                  </ul>
                </Typography>
              </div>
            </Popper>
          </span>
      : null
    }
  </>);
};

LinkedPlan.propTypes = propTypes;
export default LinkedPlan;
