import { makeStyles, styled } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';

export default theme => makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  description: {
    color: theme.palette.text.secondary,
    display: 'block',
    marginTop: '16px'
  },
  backupCodesButton: {
    marginTop: '30px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  disabledTitle: {
    fontSize: '1.10em',
    maxWidth: '400px',
    margin: '0px 20px 0 20px',
    textAlign: 'center'
  },
  delete: {
    color: ' #fa4b54'
  },
  backupCodesEnabledLabel: {
    display: 'flex',
    marginTop: '16px'
  },
  successIcon: {
    fill: theme.palette.success.main,
    marginRight: '10px'
  }
}));

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  marginTop: 7,
  width: 30,
  height: 16,
  padding: 0,
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  }
}));
