import React, { useEffect, useState } from 'react';
import useAppConfigContext from 'contexts/AppConfigContext/useAppConfigContext';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ButtonGroup, Grid, Typography } from '@material-ui/core';
import ButtonFilter from './components/ButtonFilter';
import { propTypes } from './LineChart.props';
import makeStyles from './LineChart.style';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const useStyles = makeStyles();

export default function LineChart ({ files, urls }) {
  const [t] = useTranslation();
  const classes = useStyles();
  const { isUrlAnalysisEnabled } = useAppConfigContext();
  const locale = i18next.language;
  const [filesData, setFilesData] = useState([]);
  const [urlsData, setURLsData] = useState([]);
  const [period, setPeriod] = useState(7);
  const maxPeriod = new Date();

  maxPeriod.setDate(maxPeriod.getDate() - period);

  const getdateFormat = (date) => {
    if (period === 1) {
      const hourlyDate = new Date(date);
      hourlyDate.setMinutes(0);
      hourlyDate.setSeconds(0);
      return hourlyDate.toLocaleString(locale, { hour: 'numeric' });
    } else if (period === 365) {
      return new Date(date).toLocaleString(locale, { month: 'long', year: '2-digit' });
    } else {
      return new Date(date).toLocaleString(locale, { day: 'numeric', month: 'short' });
    }
  };

  const loadData = (days) => {
    const lastFiles = files.filter(scan => {
      const scanDate = new Date(scan.date);
      return scanDate >= maxPeriod;
    }).map((scan) => {
      return {
        id: scan.id,
        date: getdateFormat(scan.date)
      };
    });

    const groupedByDatesFiles = lastFiles.reduce((prev, curr) => {
      const date = curr.date;
      if (!prev[date]) {
        prev[date] = 0;
      }
      prev[date]++;
      return prev;
    }, {});

    const lastURLs = urls.filter(scan => {
      const scanDate = new Date(scan.date);
      return scanDate >= maxPeriod;
    }).map((scan) => {
      return {
        id: scan.id,
        date: getdateFormat(scan.date)
      };
    });

    const groupedByDatesURLs = lastURLs.reduce((prev, curr) => {
      const date = curr.date;
      if (!prev[date]) {
        prev[date] = 0;
      }
      prev[date]++;
      return prev;
    }, {});

    const scannedFiles = [];
    const scannedURLs = [];
    if (days === 1) {
      for (let i = 23; i >= 0; i--) {
        const date = new Date();
        date.setHours(date.getHours() - i);
        const formattedDate = getdateFormat(date);
        scannedFiles[formattedDate] = 0;
        scannedURLs[formattedDate] = 0;
      };
    } else {
      for (let i = days; i >= 0; i--) {
        const date = new Date();
        date.setDate(date.getDate() - i);
        const formattedDate = getdateFormat(date);
        scannedFiles[formattedDate] = 0;
        scannedURLs[formattedDate] = 0;
      };
    };

    for (var i = 0; i < Object.keys(groupedByDatesFiles).length; i++) {
      scannedFiles[Object.keys(groupedByDatesFiles)[i]] = Object.values(groupedByDatesFiles)[i];
    };
    setFilesData(scannedFiles);

    for (var z = 0; z < Object.keys(groupedByDatesURLs).length; z++) {
      scannedURLs[Object.keys(groupedByDatesURLs)[z]] = Object.values(groupedByDatesURLs)[z];
    };
    setURLsData(scannedURLs);
  };

  useEffect(() => {
    if (files.length > 0) {
      loadData(period);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, period]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom'
      }
    },
    scales: {
      y: {
        ticks: {
          precision: 0
        }
      }
    }
  };

  const labels = Object.keys(filesData);
  const fileValues = Object.values(filesData);
  const urlValues = Object.values(urlsData);

  const graphData = {
    labels,
    datasets: [
      {
        label: 'Files',
        data: fileValues,
        borderColor: 'rgb(5, 127, 220)',
        backgroundColor: 'rgba(5, 127, 220)'
      },
      {
        label: 'URLs',
        data: urlValues,
        borderColor: 'rgb(18, 33, 77)',
        backgroundColor: 'rgba(18, 33, 77)'
      }
    ]
  };

  const filterUrlsFromGraphData = (data) => {
    const filteredDatasets = data.datasets.filter(dataset => dataset.label !== 'URLs');
    return { ...data, datasets: filteredDatasets };
  };

  return <>
    <div className={classes.LineChartBlock}>
      <Grid container justify='space-between' className={classes.header}>
        <Grid item>
          <Typography variant="h5" component="span" gutterBottom><strong>{isUrlAnalysisEnabled ? t('files_urls_analyzed') : t('files_analyzed')}</strong></Typography>
        </Grid>
        <Grid item>
          <ButtonGroup size='small' color='secondary'>
            <ButtonFilter id={1} period={period} setPeriod={setPeriod}>{t('last_24h')}</ButtonFilter>
            <ButtonFilter id={7} period={period} setPeriod={setPeriod}>{t('last_week')}</ButtonFilter>
            <ButtonFilter id={31} period={period} setPeriod={setPeriod}>{t('last_month')}</ButtonFilter>
            <ButtonFilter id={365} period={period} setPeriod={setPeriod}>{t('last_year')}</ButtonFilter>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Line options={options} data={isUrlAnalysisEnabled ? graphData : filterUrlsFromGraphData(graphData)} width={100} height={25} />
    </div>
  </>;
};

LineChart.propTypes = propTypes;
