export const getColor = safety => {
  switch (safety) {
    case 'safe':
      return '#167424';
    case 'undetermined':
    case 'clean':
      return '#167424';
    case 'suspicious':
      return '#ffa500';
    case 'unsafe':
      return '#ff0000';
    default:
      return '#777777';
  }
};
