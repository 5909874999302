import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Divider } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import makeStyles from './styles';
import { ITEM_HEIGHT } from './constants';
const useStyles = makeStyles();

export default function MenuActions ({
  options, type
}) {
  const [anchorElem, setAnchorEl] = useState(null);
  const open = Boolean(anchorElem);
  const classes = useStyles({ type });
  const onClose = () => {
    setAnchorEl(null);
  };

  const PaperProps = {
    style: {
      maxHeight: ITEM_HEIGHT * 5,
      width: '20ch'
    }
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onSelect = (option) => {
    setAnchorEl(null);
    option.onClick();
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={openMenu}
        className={classes.menuButton}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorElem}
        keepMounted
        open={open}
        onClose={onClose}
        PaperProps={PaperProps}
      >
        {
         options.map((option) => (
           !option.divider
             ? <MenuItem key={option.key} onClick={() => onSelect(option)} disabled={option.disabled} >
              {option.placeholder}
              </MenuItem>
             : <Divider key={option.key}/>
         ))
        }
      </Menu>
    </>
  );
}

MenuActions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  })).isRequired,
  type: PropTypes.string
};
