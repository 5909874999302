import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertIcon } from 'common/Icons';
import { fetchActivePlan } from 'entities/accounts/accounts.api';
import { fetchPlan } from 'entities/plans/plans.api';
import { propTypes } from './ScansColumn.props';
import makeStles from './ScansColumn.styles';

const useStyles = makeStles();

const ScansColumn = ({ accountId }) => {
  const [t] = useTranslation();
  const [activePlan, setActivePlan] = useState({ usage: {} });
  const [isLoading, setIsLoading] = useState(true);
  const [hasNoActivePlan, setHasNoActivePlan] = useState(false);
  const isLimitReached = activePlan.usage.analysisCount / activePlan.scanLimit === 1;
  const classes = useStyles({ isLimitReached });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const activePlan = await fetchActivePlan(accountId);
      if (activePlan?.plan) {
        const planData = await fetchPlan(activePlan.plan);
        setActivePlan({ ...planData, ...activePlan });
      } else {
        setHasNoActivePlan(true);
      }
      setIsLoading(false);
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    {isLoading && <p>...</p>}
    {!isLoading && hasNoActivePlan && <p className={classes.noScans}>0</p>}
    {!isLoading && !hasNoActivePlan && (
      <p className={classes.scans}>
        {isLimitReached && <span className={classes.warningIcon}> <AlertIcon size={22} title={t('scan_limit_reached')}/> </span>}
        <span>{`${activePlan.usage.analysisCount}/${activePlan.scanLimit}`}</span>
      </p>
    )}
  </>;
};

ScansColumn.propTypes = propTypes;

export default ScansColumn;
