import React, { useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput } from '@material-ui/core';
import { useSnackSetState } from 'contexts/SnackContext';
import { createPlan, editPlan } from 'entities/plans/plans.api';
import { getServerError } from 'pages/pages.utils';
import OutlinedFieldSelect from './components/OutlinedFieldSelect';
import { SCAN_LIMIT_PERIOD, BILLING_PERIOD } from './AddEditPlan.constants';
import propTypes from './AddEditPlan.props';
import makeStyles from './AddEditPlan.styles';

const useStyles = makeStyles();

function reducer (state, action) {
  switch (action.type) {
    case 'updatePlan':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
function AddEditPlan (props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const { refreshTable, editMode = false, submitButtonRef, data, onSubmit } = props;
  const setSnack = useSnackSetState();
  const [plan, dispatch] = useReducer(reducer, {
    billing_period: data?.billingPeriod,
    id: data?.id,
    max_admin_accounts: data?.maxAdminAccounts,
    max_analyst_accounts: data?.maxAnalystAccounts,
    max_operator_accounts: data?.maxOperatorAccounts,
    name: data?.name,
    max_file_size: 0,
    scan_limit: data?.scanLimit,
    scan_limit_period: data?.scanLimitPeriod,
    subscription_cost: data?.subscriptionCost
  });

  const updatePlan = useCallback(e => {
    dispatch({
      type: 'updatePlan',
      payload: {
        [e.target.name]: e.target.value
      }
    });
  }, [dispatch]);

  const updateSubscriptionCost = useCallback(e => {
    dispatch({
      type: 'updatePlan',
      payload: {
        subscription_cost: {
          EUR: e.target.value
        }
      }
    });
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!editMode) {
      createPlan(plan)
        .then(() => {
          onSubmit();
          setSnack({
            isOpen: true,
            title: t('plan_created_successfully'),
            severity: 'success'
          });
          refreshTable();
        })
        .catch(err => {
          setSnack({
            isOpen: true,
            title: getServerError(err?.response?.data),
            severity: 'error'
          });
        });
    } else {
      editPlan(plan)
        .then(() => {
          onSubmit();
          setSnack({
            isOpen: true,
            title: t('plan_updated_successfully'),
            severity: 'success'
          });
          refreshTable();
        })
        .catch(err => {
          setSnack({
            isOpen: true,
            title: getServerError(err?.response?.data),
            severity: 'error'
          });
        });
    }
  };

  return (
    <form className={classes.addPlanForm} onSubmit={handleSubmit}>
      <h3 className={classes.subHeaders}>{t('plan_information')}</h3>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl
            variant="outlined"
          >
            <InputLabel htmlFor="planname">
              {t('plan_name')}
            </InputLabel>

            <OutlinedInput
              required
              name="name"
              type="text"
              id="planname"
              autoComplete="planname"
              value={plan.name}
              onChange={updatePlan}
              label={t('plan_name')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <OutlinedFieldSelect
            label={t('billing')}
            required
            id='billing'
            value= {plan.billing_period}
            onChange={updatePlan}
            name='billing_period'
          >
            {
                Object.values(BILLING_PERIOD).map(value => {
                  return <MenuItem value={value} key={value}>{value}</MenuItem>;
                })
            }
          </OutlinedFieldSelect>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl
            variant="outlined"
          >
            <InputLabel htmlFor="price">
              {t('price')}
            </InputLabel>

            <OutlinedInput
              required
              type='number'
              id="price"
              value={plan.subscription_cost?.EUR}
              onChange={updateSubscriptionCost}
              label={t('price')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <OutlinedFieldSelect
            label={t('currency')}
            id='currency'
            value= 'EUR'
            disabled={true}
          >
            <MenuItem value={'EUR'}>EUR</MenuItem>;
          </OutlinedFieldSelect>
        </Grid>
      </Grid>
      <h3 className={classes.subHeaders}>{t('users')}</h3>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl
            variant="outlined"
          >
            <InputLabel htmlFor="operators">
              {t('operators')}
            </InputLabel>
            <OutlinedInput
              required
              name="max_operator_accounts"
              type='number'
              id="operators"
              value={plan.max_operator_accounts}
              onChange={updatePlan}
              label={t('operators')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            variant="outlined"
          >
            <InputLabel htmlFor="analysts">
              {t('analysts')}
            </InputLabel>
            <OutlinedInput
              required
              name="max_analyst_accounts"
              type='number'
              id="analysts"
              value={plan.max_analyst_accounts}
              onChange={updatePlan}
              label={t('analysts')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            variant="outlined"
          >
            <InputLabel htmlFor="administrators">
              {t('administrators')}
            </InputLabel>
            <OutlinedInput
              required
              name="max_admin_accounts"
              type='number'
              id="administrators"
              value={plan.max_admin_accounts}
              onChange={updatePlan}
              label={t('administrators')}
            />
          </FormControl>
        </Grid>
      </Grid>
      <h3 className={classes.subHeaders}>{t('usage')}</h3>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl
            variant="outlined"
          >
            <InputLabel htmlFor="scan_limit">
              {t('scan_limit')}
            </InputLabel>

            <OutlinedInput
              required
              name="scan_limit"
              type="text"
              id="scan_limit"
              autoComplete="scan_limit"
              value={plan.scan_limit}
              onChange={updatePlan}
              label={t('scan_limit')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <OutlinedFieldSelect
            label={t('period')}
            required
            id='scan_limit_period'
            value= {plan.scan_limit_period}
            onChange={updatePlan}
            name='scan_limit_period'
          >
            {
                Object.values(SCAN_LIMIT_PERIOD).map(value => {
                  return <MenuItem value={value} key={value}>{value}</MenuItem>;
                })
            }
          </OutlinedFieldSelect>
        </Grid>
      </Grid>
      <button ref={submitButtonRef} style={{ display: 'none' }} type='submit'></button>
    </form>
  );
}

AddEditPlan.propTypes = propTypes;

export default AddEditPlan;
