import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { logout } from 'entities/auth/auth.api';
import { useAuthUser } from 'hooks';
import { LogoutIcon } from 'common/Icons';
import { getServerError } from 'pages/pages.utils';
import { scanner } from 'utils';
import { ROUTE_PATHS } from 'utils/routes.constants';
import makeStyles from './LogoutButton.styles';

const useStyles = makeStyles();

export default function LogoutButton () {
  const [t] = useTranslation();
  const classes = useStyles();
  const { removeToken } = useAuthUser();
  const navigate = useNavigate();

  const handleOnLogout = async () => {
    try {
      await logout();
      removeToken();
      scanner.cleanStoreFiles(); // Missing page refresh after logout
      navigate(ROUTE_PATHS.SIGNIN);
    } catch (error) {
      console.error(getServerError(error));
    }
  };

  return (
    <IconButton onClick={handleOnLogout}>
      <LogoutIcon className={classes.logoutIcon} title={t('logout')} />
    </IconButton>
  );
}
