import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, CircularProgress } from '@material-ui/core';
import makeStyles from './styles';

const useStyles = makeStyles();

const statusColor = {
  uploading: 'primary',
  running: 'primary',
  pending: 'primary',
  finished: 'primary',
  failed: 'error',
  canceled: 'error',
  complete: 'textSecondary',
  errorUploading: 'error',
  errorUploadingFileTooLarge: 'error',
  errorUnsupportedFormat: 'error',
  errorScanning: 'error',
  error_url_uploading: 'error',
  error_url_scanning: 'error',
  pulling_url_data_failed: 'error'
};
const statusMessage = {
  uploading: 'uploading',
  running: 'scan_running',
  pending: 'scan_pending',
  finished: 'scan_complete',
  failed: 'scan_failed',
  canceled: 'scan_canceled',
  errorUploading: 'scan_error_upload_file_failed',
  errorUploadingFileTooLarge: 'scan_error_file_too_large',
  errorUnsupportedFormat: 'scan_error_unsupported_file_format',
  errorScanning: 'scan_error_server_error',
  error_url_uploading: 'scan_error_url_upload_failed',
  error_url_scanning: 'scan_error_url_scan_failed',
  pulling_url_data_failed: 'scan_error_failed_to_get_result'
};

const Status = ({ status }) => {
  const [t] = useTranslation();
  const idleStatus = ['uploading', 'running', 'pending'];
  const classes = useStyles();
  const circularProgressStyle = { marginLeft: '0.35em', marginBottom: '0.35em' };
  const circularProgressSize = 18;

  return (
    <>
      <Typography
        variant="body1"
        color={statusColor[status]}
        classes={{
          body1: classes.body1WithItalic
        }}
        gutterBottom>
        {t(statusMessage[status] || status)}
      </Typography>
      { idleStatus.includes(status) &&
      <CircularProgress
        style={circularProgressStyle} size={circularProgressSize} /> }
    </>
  );
};

Status.propTypes = {
  status: PropTypes.string.isRequired
};

export default Status;
