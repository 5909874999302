import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  chipContainer: {
    display: 'flex',
    alignContent: 'center',
    marginBottom: 45
  },
  title: {
    marginTop: 30,
    marginBottom: 30,
    fontSize: '18px'
  },
  chip: {
    backgroundColor: '#D9ECFA',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    textAlign: 'start',
    padding: '15px 0px'
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
    width: '48px',
    height: '48px',
    padding: '10px',
    borderRadius: '10px',
    verticalAlign: 'middle',
    marginRight: '30px',
    marginLeft: '30px',
    fill: '#FFFF'
  },
  number: {
    fontSize: 25
  },
  table: {
    marginTop: -20
  }
}));
