import { number, string, func, bool, oneOfType } from 'prop-types';

export const propTypes = {
  id: string.isRequired,
  label: string.isRequired,
  value: oneOfType([string, bool, number]),
  variant: string,
  error: string,
  onChange: func,
  disabled: bool
};
