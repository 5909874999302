export const PERIOD = {
  DAILY: 'Daily',
  MONTHLY: 'Monthly',
  ANNUALY: 'Annualy'
};

export const SCAN_LIMIT_PERIOD = {
  [PERIOD.DAILY]: 'Daily',
  [PERIOD.MONTHLY]: 'Monthly',
  [PERIOD.ANNUALY]: 'Annualy'
};

export const BILLING_PERIOD = {
  [PERIOD.MONTHLY]: 'Monthly',
  [PERIOD.ANNUALY]: 'Annualy'
};
