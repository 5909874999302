import React, { useState, useEffect } from 'react';
import QualifierPill from '../QualifierPill/QualifierPill';
import { useTheme } from '@material-ui/core';
import { SAFETY_LABELS } from 'entities/analysis/analysis.constants';
import { propTypes } from './SafetyPill.props';

function SafetyPill ({ safety, isScanning }) {
  const safetyKey = safety ? safety.toLowerCase() : 'unknown';
  const [safetyLabel, setSafetyLabel] = useState(SAFETY_LABELS[safetyKey] || SAFETY_LABELS.unknown);
  const [color, setColor] = useState('');
  const theme = useTheme();

  useEffect(() => {
    if (isScanning) {
      setSafetyLabel('scanning');
    } else {
      let newColor = '';
      switch (safetyKey) {
        case 'safe':
          newColor = theme.palette.success.main;
          break;
        case 'unsafe':
        case 'failed':
        case 'cancelled':
          newColor = theme.palette.error.main;
          break;
        case 'suspicious':
        case 'warning':
          newColor = theme.palette.warning.main;
          break;
        case 'undetermined':
        case 'clean':
          newColor = '#167424';
          break;
        case 'unknown':
        case 'notAnalyzed':
          newColor = theme.palette.text.secondary;
          break;
        default:
          newColor = theme.palette.text.secondary;
      }
      setColor(newColor);
    }
  }, [isScanning, safetyKey, theme.palette]);

  return (
    <div>
      <QualifierPill qualifier='Safety' label={safetyLabel} color={color} isScanning={isScanning} />
    </div>
  );
}

SafetyPill.propTypes = propTypes;
export default SafetyPill;
