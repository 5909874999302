import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    flexWrap: 'wrap',
    marginBottom: 20
  },
  selectedChip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    marginBottom: 6,
    marginTop: 4,
    height: 28,
    width: 'auto',
    paddingRight: 5
  },
  label: {
    marginRight: 10,
    marginBottom: 2
  }
}));
