import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  toolbarContainer: {
    marginBottom: theme.spacing(4)
  },
  addButton: {
    height: 51
  },
  dialogIconSize: {
    width: '4rem',
    height: '4rem'
  },
  dialogButtonsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '90%',
    margin: '0 auto'
  }
}));
