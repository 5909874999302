import { SAFETY_LABELS } from './analysis.constants';

/**
 * Return Avs status.
 * @param {*} Safety indicator
 * @param {*} Status indicator
 * @returns String status text.
 */
export const getAnalysisStatus = (safety, status) => {
  return SAFETY_LABELS[safety?.toLowerCase()] ?? SAFETY_LABELS[status?.toLowerCase()] ?? SAFETY_LABELS.unknown;
};
