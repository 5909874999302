import React from 'react';
import { Date } from 'common/Table/Cells';
import tableConfig from 'common/tableConfig';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { ROUTE_PATHS } from 'utils/routes.constants';

const GetDate = () => {
  const [t] = useTranslation();
  return {
    title: t('submitted_on'),
    width: '30%',
    // eslint-disable-next-line react/display-name
    render: rowData => <Date rowData={rowData} />
  };
};

const GetFilename = () => {
  const [t] = useTranslation();

  const handleClick = () => {
    // Scroll to top of the page
    window.scrollTo(0, 0);
  };

  return {
    title: t('filename'),
    width: '60%',
    cellStyle: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      border: 'none'
    },
    // eslint-disable-next-line react/display-name
    render: rowData => <Link to={generatePath(ROUTE_PATHS.DETAILS_FILE, { id: rowData.id })} onClick={handleClick}>{rowData.filename}</Link>
  };
};

const getColumns = () => {
  return [
    GetDate(),
    GetFilename()
  ];
};

export default () => ({
  columns: getColumns(),
  components: {
    ...tableConfig.components
  },
  options: {
    ...tableConfig.options,
    tableLayout: 'fixed',
    paging: false
  }
});
