const LOCATION_COLUMNS = {
  type: 'table',
  key: 'locations',
  label: 'locations',
  columns: [
    { key: 'type' },
    { key: 'package' },
    { key: 'cls' },
    { key: 'method' },
    { key: 'main_package' },
    { key: 'file' },
    { key: 'address' }
  ]
};

export const BEHAVIOR_COLUMNS = [
  { key: 'name' },
  { key: 'description' },
  { ...LOCATION_COLUMNS }
];

export const MITRE_COLUMNS = [
  { key: 'description', label: 'tactics' },
  { key: 'technique_id', label: 'technique_id' }
];
