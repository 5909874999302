import React, { useState } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const token = localStorage.getItem('token');
    return token ? { ...jwtDecode(token) } : { roles: [] };
  });

  const saveToken = (token) => {
    localStorage.setItem('token', token);
    setAuth({ roles: ['admin'], ...jwtDecode(token) });
  };

  const removeToken = () => {
    localStorage.removeItem('token');
    setAuth({ roles: [] });
  };

  const isUserLogged = () => {
    return !!auth.session_token;
  };

  return (
    <AuthContext.Provider value={{ auth, saveToken, removeToken, isUserLogged }}>
      { children }
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any
};

export { AuthProvider, AuthContext };
