import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@material-ui/core';
import { useDialog } from 'hooks';
import tableConfig from 'common/tableConfig';
import DialogButtons from 'components/DialogButtons';
import AddEditPlan from './components/AddEditPlan';
import { FileCopyLine } from 'common/Icons';
import { getColumns } from './Plans.columns';
import makesStyles from './Plans.style';

const useStyles = makesStyles();
export default ({ submitButtonRef, refreshTableData }) => ({
  columns: getColumns(refreshTableData),
  components: {
    ...tableConfig.components,
    Toolbar: function CustomToolbar () {
      const classes = useStyles();
      const [t] = useTranslation();

      const closeDialog = () => {
        onDisplayDialog({ isOpen: false });
      };

      const content = <AddEditPlan
        submitButtonRef={submitButtonRef}
        onSubmit={closeDialog}
        editMode={false}
        refreshTable={refreshTableData}
      />;

      const onDisplayDialog = useDialog('plan-dialog', {
        onClose: () => {
          onDisplayDialog({ isOpen: false });
        },
        components: {
          Icon: <FileCopyLine className={classes.dialogIconSize} title={t('add_a_new_plan')}/>,
          Title: <Typography variant='h5' align='center'>{t('add_a_new_plan')}</Typography>,
          Content: content,
          Actions: (
            <div className={classes.dialogButtonsWrapper}>
              <DialogButtons
                confirmLabel= {t('add_plan')}
                cancelLabel= {t('cancel')}
                onConfirm= {() => submitButtonRef.current.click()}
                onCancel= {() => onDisplayDialog({ isOpen: false })}
              />
            </div>
          )
        }
      });

      return (
        <Grid container className={classes.toolbarContainer}>
          <Grid item container xs={12} justify="space-between">
            <Typography variant='h4'>{t('plans')}</Typography>
            <Button
              className={classes.addButton}
              size="medium"
              color="secondary"
              variant="contained"
              onClick={() => onDisplayDialog({ isOpen: true })}>
            {t('add_plan')}
            </Button>
          </Grid>
        </Grid>
      );
    }
  },
  options: {
    ...tableConfig.options,
    toolbar: true
  }
});
