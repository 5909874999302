import React from 'react';
import PropTypes from 'prop-types';
import * as dayjs from 'dayjs';

const Date = ({ rowData }) => <span>{dayjs(rowData.date).format('MMM D, YYYY h:mm A')}</span>;

Date.propTypes = {
  rowData: PropTypes.object
};

export default Date;
