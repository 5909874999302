import TabsConfig from './Details.tabs.config';

/**
 * Return sorted list of tabs to show in the UI.
 * @param {array} latestData data response
 * @param {array} fileData data response
 * @returns Array of objects.
 */
export const getTabsSorted = (latestData, fileData, workflow) => {
  const tabsList = [...Object.values(TabsConfig)];
  return tabsList
    // Add props.
    .map(tab => ({ ...tab, props: tab.getProps(latestData, fileData, workflow) }))
    // Filter non visible tabs.
    .filter(tab => tab.isVisible(tab.props, fileData.type))
    // Sort by order field.
    .sort((firstTab, secondTab) => firstTab.order - secondTab.order);
};

/**
 * Filters BE probes output by type. Keeps related probe information.
 * E.g filterDataByType(data, 'Behavior')
 * Input: data = [{ data: [{ type: 'Info' }], probe: {...} }, { data: [{ type: 'Behavior' }, { type: 'Behavior' }, { type: 'Another' }], probe: {...} }]
 * Output => [{ data: [{ type: 'Behavior' }, { type: 'Behavior' }], probe: {...} }, ...]
 * @param array data list of all probes output.
 * @returns data list of probes output for same type.
 */
export const filterDataByType = (data = [], type) =>
  (data ?? []).reduce((prev, curr) => {
    if (curr.data) {
      const typeGroup = curr.data.filter(sub => sub.type === type);
      if (typeGroup.length) {
        return [...prev, { ...curr, data: typeGroup }];
      }
      return [...prev];
    }
  }, []);

/**
 * Similar to filterDataByType but keeps the result siblings for each probe.
 * E.g filterDataByTypeKeepSiblings(data, 'Behavior')
 * Input: data = [{ data: [{ type: 'Info' }, { type: 'Behavior' }], probe: {...} }, { data: [{ type: 'Document' }, { type: 'Document' }], probe: {...} }]
 * Output => [{ data: [{ type: 'Info' }, { type: 'Behavior' }], probe: {...} }]
 * @param array data list of all probes output.
 * @returns data list of probes output and their siblings if at least one belong to the type.
 */
export const filterDataByTypeKeepSiblings = (data = [], type) =>
  (data ?? []).reduce((prev, curr) => {
    if (curr.data) {
      const typeGroupExist = curr.data.find(sub => sub.type === type);
      if (typeGroupExist) {
        return [...prev, { ...curr, data: [...curr.data] }];
      }
      return [...prev];
    }
  }, []);

export const filterDataByMultipleTypesKeepSiblings = (data = [], types = []) =>
  (data ?? []).filter((curr) => {
    if (curr.data) {
      const typeGroupExist = curr.data.some((sub) => types.includes(sub.type));
      return typeGroupExist;
    }
    return false;
  });
/** Reduce probes output to a flat list, each output with its related probe name, and category.
 * E.g getFlatList(data)
 * Input: data = [{ data: [{ type: 'Behavior' }], probe: { name: 'lorem' } }, { data: [{ type: 'Behavior' }], probe: { name: 'lorem2'} }]
 * Output => [{ type: 'Behavior', probeName: 'lorem', ... }, { type: 'Behavior', probeName: 'lorem2', ... }]
 * @param array data list of probes output.
 * @returns array flat list.
 */
export const getFlatList = (data = []) => (data ?? []).reduce((prev, curr) => {
  // Add related probeName to outputs.
  curr.data.map(d => {
    d.probeName = curr?.probe?.name;
    d.probeCategory = curr?.probe?.category;
    return d;
  });
  return [...prev, ...curr.data];
}, []);

/**
 * Transform json to string and trim to match UI desired display.
 * @param {*} json The json content.
 * @returns string stringified json to display in UI.
 */
export const stringify = json => {
  return JSON.stringify(json, null, 2)
    .replace(/\n/, '')
    .replace('{', '')
    .replace(/}$/, '');
};

/**
 * Turns a list of:
 *
 * const fruits = [
 *  { fruit: 'Watermelon', season: 'summer' },
 *  { fruit: 'Ananas', season: 'winter' },
 *  { fruit: 'Ananas', season: 'spring' },
 *  { fruit: 'Orange' },
 *  { fruit: 'Banana' }
 * ];
 *
 * filterListToObjectByAttribute(fruits, 'season', 'allSeasons');
 *
 * To:
 * {
 *  summer: [{ fruit: 'Watermelon', season: 'summer' }],
 *  winter: [{ fruit: 'Ananas', season: 'winter' }],
 *  spring: [{ fruit: 'Ananas', season: 'spring' }]
 *  allSeasons: [{ fruit: 'Orange' }, { fruit: 'Banana }]
 * }
 * @param {*} list Array of objects.
 * @param {*} attribute A key to use for filtering.
 * @param {*} defaultAttribute Filter elements without attribute (otherwise underfined key).
 * @returns {Object} An Object of Arrays listed by attribute.
 */
export const filterListToObjectByAttribute = (list, attribute, defaultAttribute) => {
  return list.reduce((prev, curr) => {
    const pick = curr[attribute] ?? defaultAttribute;
    prev[pick] = [...(prev[pick] || []), curr];
    return prev;
  }, {});
};

/**
 * Returns the UI representation from the provided schema.
 * @param {JSON} schema Json object.
 * @param {String} key the key to access schema properties.
 * @returns {Array} List of columns representation ([{key, type}]).
 */
export const getColumnsFromSchema = (schema, key) => {
  const properties = schema?.definitions?.[key]?.properties || {};
  return Object.values(properties)
    .sort((a, b) => a.order - b.order)
    .map(({ title, type, display_name: displayName, items }) => {
      let subSchema;
      let subType = type;
      // Include referenced objects.
      if (items?.$ref) {
        const typesMap = { array: 'table' };
        const schemaName = items?.$ref?.split?.('/')?.[2]; // E.g "$ref": "#/definitions/LocationSchema"
        if (schemaName) {
          subSchema = getColumnsFromSchema(schema, schemaName);
          subType = typesMap[type];
        }
      }
      return { key: title, type: subType, label: displayName, columns: subSchema };
    });
};
