import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { filterListToObjectByAttribute, getFlatList } from 'pages/Details/Details.utils';
import GenericTable from '../GenericTable';
import { FILTER_ATTRIBUTE, ROOT } from './StringOfInterest.constants';
import { propTypes } from './StringOfInterest.props';

const StringOfInterest = ({ title, data, columns }) => {
  const [t] = useTranslation();
  const rowsPerType = useMemo(() => {
    return filterListToObjectByAttribute(getFlatList(data), FILTER_ATTRIBUTE, ROOT);
  }, [data]);

  return (
    <>
      {/* Render results without subtype first as StringOfInterest table. */}
      {rowsPerType[ROOT] && (
        <GenericTable columns={columns} rows={rowsPerType[ROOT]} title={t(title)} isFixed/>
      )}
      {/* Render each subtype group in own table. */}
      {Object.keys(rowsPerType).map(type => {
        if (type !== ROOT) {
          return (
            <>
              <br/>
              <GenericTable columns={columns} rows={rowsPerType[type]} title={type} isFixed/>
            </>
          );
        }
      })}
    </>
  );
};

StringOfInterest.propTypes = propTypes;

export default StringOfInterest;
