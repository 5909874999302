import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Tabs, Tab } from '@material-ui/core';
import TabPanel from './components/TabPanel';
import { propTypes } from './Tabs.props';
import makeStyles from './Tabs.styles';

const useStyles = makeStyles();

export default function AccountTabs ({ tabs, children, centered = false }) {
  const classes = useStyles();
  const [t] = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const handleChange = (event, newValue) => {
    setActiveTabIndex(newValue);
  };

  return (
    <Grid container>
      <Tabs
        variant="fullWidth"
        orientation='horizontal'
        position="static"
        color="primary"
        indicatorColor="primary"
        textColor="primary"
        value={activeTabIndex}
        onChange={handleChange}
        aria-label='Tabs'
        className={classes.tabs}
        centered>
        {
          tabs.map(tab => <Tab key={`tab-${tab}`} label= {t(`${tab}`)} classes={{
            root: classes.tab,
            selected: classes.tabSelected
          }} />)
        }
      </Tabs>
      <Grid container>
        <TabPanel value={tabs[activeTabIndex]} key={`label-${tabs[activeTabIndex]}`} index={tabs[activeTabIndex]}>
          { children(activeTabIndex) }
        </TabPanel>
      </Grid>
    </Grid>
  );
};

AccountTabs.propTypes = propTypes;
