import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  cardRoot: {
    width: 350
  },
  cardRootEnabled: {
    borderColor: theme.palette.primary.main
  },
  cardContentRoot: {
    padding: '15px 30px'
  },
  cardItemsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 15
  },
  cardItems: {
    flexBasis: '30%',
    marginRight: '10px'
  },
  overline: {
    lineHeight: 'normal'
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '30px 30px 0',
    alignItems: 'center'
  },
  title: {
    fontFamily: theme.typography.h3.fontFamily
  },
  summaryHeight: {
    height: 40
  },
  lineClamp2: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden'
  },
  probeCard: {
    float: 'right',
    margin: 10
  },
  typographyWithEllipsis: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 115,
    overflowX: 'hidden'
  },
  cardActionsRoot: {
    padding: 0
  },
  avatar: {
    borderRadius: '5px'
  },
  chip: {
    fontFamily: theme.typography.caption.fontFamily,
    width: '100px',
    textTransform: 'capitalize',
    marginTop: '7px',
    marginBottom: '15px'
  },
  tools: {
    backgroundColor: theme.palette.probeCategory.tools + 40
  },
  metadata: {
    backgroundColor: theme.palette.probeCategory.metadata + 40
  },
  external: {
    backgroundColor: theme.palette.probeCategory.external + 40
  },
  antivirus: {
    backgroundColor: theme.palette.probeCategory.antivirus + 40
  },
  warning: {
    color: '#FFA143'
  },
  error: {
    color: '#fa4b54'
  },
  active: {
    color: '#30B043'
  }
}));
