import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { MoneyIcon, UserLine, UserSearchLine, ShieldUserLine, DonutChartFill } from 'common/Icons';
import { getSubscriptionCost } from 'entities/plans/plans.utils';
import { propTypes } from './PlanDetails.props';
import makeStyles from './PlanDetails.styles';

const useStyles = makeStyles();

const PlanDetails = ({ plan = {} }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <div style={{ flexGrow: 1, display: 'flex' }}>
      <Grid
        container
        direction='row'
        spacing={4}
        justify='space-around'
      >
        <Grid item xs={4}>
          <h3>{t('plan_information')}</h3>
          <div className={`${classes.boxContainer} ${classes.planInfoBox}`}>
            <MoneyIcon className={classes.badgeSize} title={t('plan_information')} />
            <Grid container className={classes.planNameBox}>
              <Grid item>
                <span className={classes.title}>{t('plan_name')}</span>
                <h1 className={classes.planValue}>{plan.name}</h1>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <span className={classes.title}>{t('price')}</span>
                <h1 className={classes.planValue}>{getSubscriptionCost(plan.subscriptionCost, plan.billingPeriod, 'EUR')}</h1>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={4}>
          <h3>Users</h3>
          <div className={classes.boxContainer}>
            <Grid container justify='space-between'>
              <Grid item className={classes.marginVertical}>
                <UserLine className={classes.badgeSize} title={t('operators')} />
              </Grid>
              <Grid item className={classes.textAlignEnd}>
                <span className={classes.title}>{t('operators')}</span>
                <h1 className={classes.planValue}>{plan.maxOperatorAccounts}</h1>
              </Grid>
            </Grid>
          </div>
          <div className={classes.boxContainer}>
            <Grid container justify='space-between'>
              <Grid item className={classes.marginVertical}>
                <UserSearchLine className={classes.badgeSize} title={t('analysts')} />
              </Grid>
              <Grid item className={classes.textAlignEnd}>
                <span className={classes.title}>{t('analysts')}</span>
                <h1 className={classes.planValue}>{plan.maxAnalystAccounts}</h1>
              </Grid>
            </Grid>
          </div>
          <div className={classes.boxContainer}>
            <Grid container justify='space-between'>
              <Grid item className={classes.marginVertical}>
                <ShieldUserLine className={classes.badgeSize} title={t('administrators')} />
              </Grid>
              <Grid item className={classes.textAlignEnd}>
                <span className={classes.title}>{t('administrators')}</span>
                <h1 className={classes.planValue}>{plan.maxAdminAccounts}</h1>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={4}>
          <h3>{t('usage')}</h3>
          <div className={classes.boxContainer}>
            <Grid container justify='space-between'>
              <Grid item className={classes.marginVertical}>
                <DonutChartFill className={classes.badgeSize} title={t('scan_limit')} />
              </Grid>
              <Grid item className={classes.textAlignEnd}>
                <span className={classes.title}>{t('scan_limit')}</span>
                <h1 className={classes.planValue}>{plan.scanLimit} / {plan.scanLimitPeriod}</h1>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

PlanDetails.propTypes = propTypes;

export default PlanDetails;
