import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  backToTopButton: {
    cursor: 'pointer',
    position: 'fixed',
    bottom: '20px',
    right: '100px',
    zIndex: '9999'
  },
  backToTopIcon: {
    width: '2rem',
    height: '2rem'
  },
  backToTopContainer: {
    backgroundColor: 'lightgrey',
    borderRadius: 8,
    padding: '8px 10px'
  }
}));
