import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthUser } from 'hooks';
import { propTypes } from './WithAuth.props';

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { auth } = useAuthUser();

  if (!auth?.roles.length) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/signin" state={{ from: location }} replace/>;
  }

  return children;
};

RequireAuth.propTypes = propTypes;

export default RequireAuth;
