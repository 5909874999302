import { useContext, useEffect } from 'react';
import { DialogSetStateContext } from 'contexts/DialogContext';

const useDialog = (name, { onClose, components }) => {
  const contextSetter = useContext(DialogSetStateContext);

  if (contextSetter === undefined) {
    throw new Error('DialogSetStateContext must be used within a DialogSetStateContext.Provider');
  }

  useEffect(() => {
    contextSetter(prevState => ({
      dialogs: {
        ...prevState.dialogs,
        [name]: { isOpen: false, onClose, components }
      }
    }));

    return () => {
      contextSetter(prevState => {
        const updatedDialogs = { ...prevState.dialogs };
        delete updatedDialogs[name];
        return {
          dialogs: { ...updatedDialogs }
        };
      });
    };
  }, [components, contextSetter, name, onClose]);

  return ({ isOpen }) => {
    const recordedName = name;

    contextSetter(prevState => ({
      dialogs: {
        ...prevState.dialogs,
        [recordedName]: { ...prevState.dialogs[recordedName], isOpen }
      }
    }));
  };
};

export default useDialog;
