import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Tooltip } from '@material-ui/core';
import LabelChip from 'common/LabelChip';
import DialogTitle from 'components/DialogExtensions/components/DialogTitle';
import DialogButtons from 'components/DialogButtons';
import AddTagUncontrolledInput from 'pages/components/AddTag/AddTagUncontrolledInput';
import { addTagToFile, deleteTagFromFile } from 'entities/files/files.api';
import { addTagToUrl, deleteTagFromUrl } from 'entities/urls/urls.api';
import { TagsIcon } from 'common/Icons';
import { useDialog } from 'hooks';
import { propTypes } from './Tags.props';
import makeStyles from './Tags.styles';

const useStyles = makeStyles();

const Tags = ({ tags, fileId, onChange }) => {
  const valueRef = useRef(null);
  const tagIdRef = useRef(null);
  const [t] = useTranslation();
  const classes = useStyles();
  const isUrl = fileId?.includes('url');
  const addTag = isUrl ? addTagToUrl : addTagToFile;
  const deleteTag = isUrl ? deleteTagFromUrl : deleteTagFromFile;

  const handleDialogClose = () => {
    onDisplayTagsDialog({ isOpen: false });
    onDisplayDeleteConfirmationDialog({ isOpen: false });
  };

  const onConfirm = () => {
    if (valueRef.current) {
      const tagsToRemove = tags.filter(tag => !valueRef.current.find(newTag => newTag.id === tag.id));
      const tagsToAdd = valueRef.current.filter(newTag => !tags.find(tag => newTag.id === tag.id));

      const promises = [];

      tagsToAdd.forEach((tag) => {
        promises.push(addTag(fileId, { tag_id: tag.id }));
      });

      tagsToRemove.forEach((tag) => {
        promises.push(deleteTag(fileId, { tag_id: tag.id }));
      });

      Promise.all(promises).then(() => {
        onChange({});
      });
    }
  };

  const onRemoveTag = (fileId, tagId) => {
    const promises = [];
    promises.push(deleteTag(fileId, { tag_id: tagId }));

    Promise.all(promises).then(() => {
      onChange({});
    });
  };

  const tagsContent = <div className={classes.tagInputContainer}><AddTagUncontrolledInput defaultValue={tags} valueRef={valueRef} /></div>;

  const onDisplayTagsDialog = useDialog(`add-tag-to-file-dialog-${fileId}`, {
    onClose: handleDialogClose,
    components: {
      Icon: <TagsIcon className={classes.dialogIconSize} />,
      Title: <Typography variant="h5" align="center">{t('tags')}</Typography>,
      Content: tagsContent,
      Actions: (
        <DialogButtons
          confirmLabel={t('save')}
          cancelLabel={t('cancel')}
          onConfirm={() => onConfirm()}
          onCancel={() => handleDialogClose()}
        />
      )
    }
  });

  const handleDeleteClick = (tag) => {
    tagIdRef.current = tag.id;
    onDisplayDeleteConfirmationDialog({ isOpen: true });
  };

  const onDisplayDeleteConfirmationDialog = useDialog(`delete-tag-from-file-dialog-${fileId}`, {
    onClose: handleDialogClose,
    components: {
      Icon: <TagsIcon className={classes.dialogIconSize} />,
      Title: <DialogTitle title={t('are_you_sure_you_want_to_remove_this_tag')} />,
      Actions: (
        <div className={classes.deleteButtonsWrapper}>
          <DialogButtons
            type='warning'
            confirmLabel={t('remove')}
            cancelLabel={t('cancel')}
            onConfirm={() => onRemoveTag(fileId, tagIdRef.current)}
            onCancel={() => handleDialogClose()}
          />
        </div>
      )
    }
  });

  const groupedTags = [];
  for (let i = 0; i < tags.length; i += 3) {
    groupedTags.push(tags.slice(i, i + 3));
  }

  return (
    <div className={classes.container}>
      {groupedTags.map((tagGroup, groupIndex) => (
        <div key={groupIndex} style={{ display: 'flex' }}>
          {tagGroup.map(tag => (
            <LabelChip
              key={tag.id}
              text={tag.text}
              color={'047FDC'}
              onDelete={() => handleDeleteClick(tag)}
              showDelete={true}
              tooltipText={t('remove_tag')}
            />
          ))}
        </div>
      ))}
      <Tooltip title={t('add_tag')} arrow placement='top'>
        <Button
          variant="outlined"
          aria-label="add tags"
          className={classes.plusIcon}
          classes={{ startIcon: classes.startIcon }}
          startIcon={'+'}
          onClick={() => onDisplayTagsDialog({ isOpen: true })}>
        </Button>
      </Tooltip>
    </div>
  );
};

Tags.propTypes = propTypes;

export default Tags;
