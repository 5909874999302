import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Grid, ButtonGroup } from '@material-ui/core';
import { useSnackSetState } from 'contexts/SnackContext';
import Loading from 'common/Loading';
import { Copied, Copy, DownloadRaw, WarningIcon } from 'common/Icons';
import { getMfaBackupCodes, registerMfaBackupCodes } from 'entities/auth/auth.api';
import { downloadTxtFile } from './MkaBkupCdDialog.utils';
import makeStyles from './MfaBkupCdDialog.styles';

const useStyles = makeStyles();

function MfaBkupCdDialog () {
  const setSnack = useSnackSetState();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [backupCodes, setBackupCodes] = useState({});
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState(false);

  const [t] = useTranslation();

  const copyClipboard = () => {
    navigator.clipboard.writeText(backupCodes.join('\n'));

    setCopied(true);
    setSnack({
      isOpen: true,
      title: t('backup_codes_copied_to_clipboard'),
      severity: 'success'
    });
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const errorDialog = () => {
    return (
      <div className={classes.messageContainer}>
        <WarningIcon className={classes.warningIcon} />
        <Typography variant="h5" align="center"> <strong>{t('something_went_wrong')}</strong> </Typography>
        <Typography variant="body1" className={classes.description} style={{ display: 'inline-block', width: '100%' }}
> {t('backup_codes_failure_message')}</Typography>
        <Typography variant="body1" className={classes.error}>{error}</Typography>
      </div>);
  };

  useEffect(() => {
    const getBkupcodes = async () => {
      setLoading(true);

      try {
        const res = await getMfaBackupCodes();
        setBackupCodes(res?.data[0] || null);
        registerMfaBackupCodes(res?.data[1]);
      } catch (err) {
        if (err?.response?.status === 403) {
          setError(t('session_too_old'));
        } else {
          setError(err?.response?.data?.errors?.description);
        }
      } finally {
        setLoading(false);
      }
    };
    getBkupcodes();
  }, [t]);

  return (
    <>
    { loading && <Loading /> }
    { !loading && error && errorDialog() }
    { !loading && !error &&
      <div className={classes.container}>
        <Typography variant="h5" align="center"><strong>{t('backup_codes')}</strong></Typography>
        <div>
          <Typography variant="body1" align="center" className={classes.description}>{t('backup_codes_explanation')}</Typography>
          <div className={classes.bkupCodesContainer}>
            <div className={classes.bkupCodesBox}>
              <Grid container>
                <Grid item xs>
                  {backupCodes.slice(0, 6).map((code) => {
                    return <Typography variant="body1" key={code} align='center' className={classes.labelCode}>{code}</Typography>;
                  })}
                </Grid>
                <Grid item xs>
                  {backupCodes.slice(6).map((code) => {
                    return <Typography variant="body1" key={code} align='center' className={classes.labelCode}>{code}</Typography>;
                  })}
                </Grid>
              </Grid>
            </div>
            <ButtonGroup color='default' fullWidth className={classes.btnGroup}>
              <Button variant='outlined' onClick={() => downloadTxtFile(backupCodes)} className={classes.btn}>
                <DownloadRaw size={20} color='primary' title={t('download')} className={classes.btnIcon}/>{t('download')}
              </Button>
              <Button variant='outlined' onClick={() => copyClipboard()} className={classes.btn}>
                { !copied && <><Copy color='primary' size={20} title={t('copy_to_clipboard')} className={classes.btnIcon}/>{t('copy')}</>}
                { copied && <><Copied color='primary' size={20} title={t('copy_to_clipboard')} className={classes.btnIcon}/>{t('copied')}</>}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
    }
    </>
  );
};

export default MfaBkupCdDialog;
