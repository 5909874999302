import { makeStyles } from '@material-ui/core/styles';
export default theme => makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  errorLayout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  errorImage: {
    width: '45%',
    display: 'block',
    marginTop: '30px',
    marginBottom: '20px'
  },
  tryRefreshText: {
    color: theme.palette.grey[600]
  },
  refreshBtn: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    marginTop: '20px'
  }
}));
