import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './Dialog';

const DialogBuilder = ({ dialogsProps }) => {
  const dialogList = [];

  Object.entries(dialogsProps).forEach(([name, dialog]) => {
    dialogList.push(<Dialog key={name} keyName={name} {...dialog} />);
  });

  return dialogList;
};

DialogBuilder.propTypes = {
  dialogsProps: PropTypes.object.isRequired
};

export default DialogBuilder;
