import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  loaderContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));
