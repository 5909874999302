import { shape, string } from 'prop-types';

export const propTypes = {
  analysis: shape({
    status: string,
    target: shape({
      url: string.isRequired
    }).isRequired
  }).isRequired,
  statusError: string
};
