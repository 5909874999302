import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { filterListToObjectByAttribute, getFlatList } from 'pages/Details/Details.utils';
import GenericPanel from '../GenericPanel';
import ProbeTabs from '../ProbeTabs/ProbeTabs';
import { propTypes } from './CertInfo.props';

const CertInfo = ({ title, data, columns }) => {
  const [t] = useTranslation();
  const rowsPerProbeType = useMemo(() => {
    return filterListToObjectByAttribute(getFlatList(data), 'probeName');
  }, [data]);
  const probes = useMemo(() => Object.keys(rowsPerProbeType), [rowsPerProbeType]);
  return (
    <Grid container spacing={1}>
      <ProbeTabs tabs={probes}>
        {activeTabIndex => {
          const probeInfo = [...rowsPerProbeType[probes[activeTabIndex]]];
          return probeInfo.map((probe, index) => (
            <GenericPanel key={index} data={probe} keys={columns} columnWidth='250px' title={t(title)}/>
          ));
        }}
      </ProbeTabs>
    </Grid>
  );
};

CertInfo.propTypes = propTypes;

export default CertInfo;
