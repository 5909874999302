import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  primary: {
    color: theme.palette.primary.main
  },
  routeLink: {
    textDecoration: 'none'
  }
}));
