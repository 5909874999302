import { makeStyles } from '@material-ui/core/styles';

export default (theme) => makeStyles((theme) => ({
  dialogIconSize: {
    width: '4rem',
    height: '4rem'
  },
  confirmBtn: {
    background: props => props.type === 'warning' && theme.palette.error.main,

    '&:hover': {
      background: props => props.type === 'warning' && theme.palette.error.dark
    }
  }
}));
