import React from 'react';
import MaterialTable from 'material-table';
import { TableLocalization } from 'common/tableConfig';
import getTableProps from './ProbeUpdateTable.tableprops';
import { propTypes } from './ProbeUpdateTable.props';

function ProbeUpdateTable ({ probesToUpdate, setProbesToUpdate }) {
  const { columns, options, actions, components } = getTableProps(setProbesToUpdate, probesToUpdate);
  const localization = TableLocalization();

  return (
    <MaterialTable
      title=''
      columns={columns}
      data={probesToUpdate}
      options={options}
      actions={actions}
      components={components}
      localization={localization}
    />
  );
};
ProbeUpdateTable.propTypes = propTypes;

export default ProbeUpdateTable;
