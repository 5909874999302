import { makeStyles } from '@material-ui/core/styles';

export default theme => makeStyles(theme => ({
  cancelButton: {
    color: theme.palette.text.secondary,
    fill: theme.palette.text.secondary
  },
  divider: {
    margin: '0 10px'
  }
}));
