export const MENU_KEYS = {
  TAGS: 'tags',
  RESCAN: 'rescan',
  DOWNLOAD: 'download',
  DOWNLOAD_ZIP: 'download zip',
  COPYURL: 'copy',
  DELETE: 'delete'
};
export const MIME_TYPES = Object.freeze({
  APPLICATION: {
    stream: 'application/octet-stream',
    zip: 'application/zip'
  }
});
