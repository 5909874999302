import { transformAddress } from './accounts.utils';

export const getPlans = response => {
  return (response.data ?? []).map((plan = {}) => ({
    id: plan.id,
    plan: plan.plan,
    account: plan.account,
    startDate: plan.start_date,
    endDate: plan.end_date,
    overageAllowed: plan.overage_allowed,
    usage: {
      analysisCount: plan.usage?.analysis_count ?? 0,
      analystCount: plan.usage?.analyst_count ?? 0,
      operatorCount: plan.usage?.operator_count ?? 0,
      adminCount: plan.usage?.admin_count ?? 0
    },
    isCancelled: plan.is_cancelled
  }));
};

export const getPlan = response => {
  const plan = response.data ?? {};
  return {
    id: plan.id,
    plan: plan.plan,
    account: plan.account,
    startDate: plan.start_date,
    endDate: plan.end_date,
    overageAllowed: plan.overage_allowed,
    usage: {
      analysisCount: plan.usage?.analysis_count ?? 0,
      analystCount: plan.usage?.analyst_count ?? 0,
      operatorCount: plan.usage?.operator_count ?? 0,
      adminCount: plan.usage?.admin_count ?? 0
    },
    isCancelled: plan.is_cancelled
  };
};

export const getAccountPayload = (account, owner) => ({
  account: {
    finance_email: null,
    currency: 'EUR',
    subdomain: null,
    address: null,
    enabled: true,
    finance_contact: null,
    reference: null,
    name: account.name,
    email: account.email,
    owner: owner.id
  }
});

export const getRootAccount = (accounts = []) => accounts?.find?.(account => account.root) ?? {};

export const setAccountData = payload => ({
  enabled: true,
  reference: null,
  name: payload.name,
  finance_contact: payload.finance_contact,
  address: Object.values(payload?.address ?? {}).length > 0 ? transformAddress(payload.address) : null,
  subdomain: payload.subdomain === '' ? null : payload.subdomain,
  finance_email: payload.finance_email,
  currency: payload.currency,
  email: payload.email
});
