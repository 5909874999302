import React from 'react';
import { Antivirus } from 'common/Icons';
import makeStyles from './styles';

const useStyles = makeStyles();

export default function DialogIcon () {
  const classes = useStyles();
  return <Antivirus className={classes.dialogIconSize} />;
}
