import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Typography, Button, IconButton } from '@material-ui/core';
import Loading from 'common/Loading';
import { CheckIcon, WarningIcon } from 'common/Icons';
import CloseIcon from '@material-ui/icons/Close';
import { getMfaDetails, registerMfaDevice } from 'entities/auth/auth.api';
import OtpInput from '../OtpInput/OtpInput';
import propTypes from './MfaConfigDialog.props';
import makeStyles from './MfaConfigDialog.styles';

const useStyles = makeStyles();

function MfaConfigDialog ({ closeDialog }) {
  const [imageData, setImageData] = useState(null);
  const [key, setKey] = useState('');
  const [flowId, setFlowId] = useState('');
  const [passcode, setPasscode] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const [t] = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    const getAndSetAuthCode = async () => {
      try {
        const res = await getMfaDetails();
        setImageData(res?.data[0] || null);
        setKey(res?.data[1] || null);
        setFlowId(res?.data[2] || null);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    getAndSetAuthCode();
  }, []);

  const handlePasscodeChange = (value) => {
    setPasscode(value);
  };

  const onConfirm = async () => {
    setLoading(true);

    try {
      await registerMfaDevice(flowId, passcode);
      setSuccess(true);
    } catch (error) {
      setError(error?.response?.data?.errors?.description);
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    closeDialog();
  };

  const MfaSuccess = () => {
    const { t } = useTranslation();
    return (
      <div className={classes.messageContainer}>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onCancel}>
          <CloseIcon />
        </IconButton>
        <CheckIcon className={classes.successIcon} />
        <Typography variant="h5" align="center"> <strong>{t('mfa_success')}</strong> </Typography>
        <Typography variant="body1" className={classes.description}> {t('mfa_success_message')}</Typography>
      </div>);
  };

  const MfaFailure = () => {
    const { t } = useTranslation();
    return (
      <div className={classes.messageContainer}>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onCancel}>
          <CloseIcon />
        </IconButton>
        <WarningIcon className={classes.warningIcon} />
        <Typography variant="h5" align="center"> <strong>{t('something_went_wrong')}</strong> </Typography>
        <Typography variant="body1" className={classes.description} style={{ display: 'inline-block', width: '100%' }}
> {t('mfa_failure_message')}</Typography>
        <Typography variant="body1" className={classes.error}>{error}</Typography>
      </div>);
  };

  const renderContent = () => {
    if (loading) { return <Loading />; }
    if (success === true) { return <MfaSuccess />; }
    if (success === false) { return <MfaFailure />; }
    const isPasscodeValid = passcode.length === 6;

    return (
      <div>
        <Typography variant="h5" align="center"><strong>{t('mfa')}</strong></Typography>
        <div className={classes.container}>
          <Typography variant="body1" className={classes.description}>{t('mfa_explanation')}</Typography>
          {imageData ? <img src={imageData} alt='QR Code' width='150' height='150'/> : <Loading/>}
          <Typography variant="body1" className={classes.description}>
            <Trans i18nKey="mfa_key">
              <span className={classes.key}>{{ key }}</span>
            </Trans>
          </Typography>
          <OtpInput length={6} onChange={handlePasscodeChange} />
        </div>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={onCancel} size="large">
           {t('cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={onConfirm} size="large" disabled={!isPasscodeValid}>
           {t('next')}
          </Button>
        </div>
      </div>
    );
  };
  return <>{renderContent()}</>;
};

MfaConfigDialog.propTypes = propTypes;
export default MfaConfigDialog;
