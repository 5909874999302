import React from 'react';
import Snack from 'common/Snack';
import { Routes, Route } from 'react-router-dom';
import Signin from 'pages/Signin';
import Signup from 'pages/Signup';
import ErrorPage from 'pages/ErrorPage';
import { EmailVerification, PasswordChallenge } from 'pages/Recovery';
import UserSettings from 'pages/UserSettings';
import DashboardLayout from 'layouts/DashboardLayout';
import routes from 'utils/getRoutes';
import SetupInterceptors from 'utils/interceptors';
import WithAuth from '../common/WithAuth';
import BlockedInSaasMode from '../common/BlockedInSaasMode';
import HomePage from '../pages/HomePage/HomePage';
import { ROUTE_PATHS } from 'utils/routes.constants';

export default function MainLayout () {
  SetupInterceptors();

  return (
    <>
      <Routes>
        <Route path='*' element={<ErrorPage />} />
        <Route path={ROUTE_PATHS.HOMEPAGE} element={<HomePage/>}/>
        <Route path={ROUTE_PATHS.SIGNIN} element={<Signin/>}/>
        <Route path={ROUTE_PATHS.SIGNUP} element={<BlockedInSaasMode><Signup/></BlockedInSaasMode>}/>
        <Route path={ROUTE_PATHS.FORBIDDEN} element={<ErrorPage/>}/>
        <Route path={ROUTE_PATHS.ACCOUNT_PASSWORD_VERIFICATION} element={<EmailVerification/>}/>
        <Route path={ROUTE_PATHS.ACCOUNT_PASSWORD_RECOVERY} element={<PasswordChallenge/>}/>
        <Route path={ROUTE_PATHS.USER_SETTINGS} element={<WithAuth><UserSettings/></WithAuth>} />
        <Route element={<DashboardLayout/>}>
          {routes.map(({ path, component: Component }) => {
            return (<Route key={path} path={path} element={<Component/>}/>);
          })
          }
        </Route>
      </Routes>
      <Snack />
    </>
  );
}
