import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { ArrowDown, ArrowRight } from 'common/Icons';
import { TreeView } from 'common/TreeView';
import NoResult from '../../../NoResult';
import makeStyles from '../../Communications.style';

const useStyles = makeStyles();

const UdpProtocol = props => {
  const { data } = props;
  const [t] = useTranslation();
  const networkActivities = data.network_activities;
  const classes = useStyles();
  const noResult = <NoResult message={t('not_defined_abbrev')}/>;

  const gridUdp = itemData => {
    return (
      <Grid container>
        <Grid item xs={3}>
          {t('stream')}
        </Grid>
        <Grid item xs={3}>
          {itemData.sport ?? noResult}
        </Grid>
        <Grid item xs={3}>
          {itemData.dport ?? noResult}
        </Grid>
        <Grid item xs={3}>
          {itemData.upper_protocols ?? noResult}
        </Grid>
      </Grid>
    );
  };

  const udpHeader = () => {
    return <Grid container className={classes.headers}>
      <Grid item xs={3}>
        {t('network_activity')}
      </Grid>
      <Grid item xs={3}>
        {t('sport')}
      </Grid>
      <Grid item xs={3}>
        {t('dport')}
      </Grid>
      <Grid item xs={3}>
        {t('upper_protocols')}
      </Grid>
    </Grid>;
  };

  const udpData = (data) => {
    const mainData = data.map(item => {
      return { content: gridUdp(item) };
    });
    mainData.unshift({ content: udpHeader() });

    return [{
      id: '1',
      content: <div>
        <span className={classes.treeItemLabel}>{t('protocol') + ': '}</span><span>{t('udp')}</span>
      </div>,
      child: mainData
    }];
  };

  return (
    <>
      <div className={classes.headerLabel}>
        <div>
          <span className={classes.treeItemLabel}>{t('source') + ': '}</span><span>{data.source}</span>
        </div>
        <div>
          <span className={classes.treeItemLabel}>{t('first_seen') + ': '}</span><span>{data.first_seen}</span>
        </div>
        <div>
          <span className={classes.treeItemLabel}>{t('last_seen') + ': '}</span><span>{data.last_seen}</span>
        </div>
        <div>
          <span className={classes.treeItemLabel}>{t('destination') + ': '}</span><span>{data.destination}</span>
        </div>
      </div>
      <TreeView
        className={classes.treeView}
        defaultExpanded={['1', '2', '3']}
        defaultCollapseIcon={<ArrowDown size={20}/>}
        defaultExpandIcon={<ArrowRight size={20} />}
        data={udpData(networkActivities)}
      />
    </>
  );
};

UdpProtocol.propTypes = {
  data: PropTypes.object
};

export default UdpProtocol;
