import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@material-ui/core';
import { WarningIcon } from 'common/Icons';
import propTypes from './MfaNewBkupCdDialog.props';
import makeStyles from './MfaNewBkupCdDialog.styles';

const useStyles = makeStyles();

function MfaNewBkupCdDialog ({ closeDialog, generateNewCodes }) {
  const [t] = useTranslation();
  const classes = useStyles();

  const onCancel = () => {
    closeDialog();
  };
  const onConfirm = () => {
    generateNewCodes();
  };

  return (
    <>
      <div className={classes.container}>
        <WarningIcon className={classes.warningIcon} />
        <Typography variant="h4" align='center' className={classes.disabledTitle} >
          {t('you_are_about_to_generate_new_backup_codes')}
        </Typography>
        <Typography variant="body2" className={classes.bodyText}>
          {t('new_backup_codes_message')}
        </Typography>
      </div>
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" onClick={onConfirm} size="large">
          {t('generate_new_backup_codes')}
        </Button>
        <Button variant="outlined" color="secondary" onClick={onCancel} size="large">
          {t('cancel')}
        </Button>
      </div>
    </>
  );
};

MfaNewBkupCdDialog.propTypes = propTypes;
export default MfaNewBkupCdDialog;
