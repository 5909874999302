import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from 'pages/components/PageHeader';
import UrlAnalysisTable from './components/UrlAnalysisTable';

export default function UrlAnalysisList () {
  const [t] = useTranslation();
  return (
    <>
      <PageHeader title={t('urls')} />
      <UrlAnalysisTable />
    </>
  );
}
