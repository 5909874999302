import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from '@material-ui/core';
import { URLGlobe } from 'common/Icons';
import { ROUTE_PATHS } from 'utils/routes.constants';
import { isArray, isBoolean, isNumber, isObject, isString } from 'utils/types';
import CellFallbackView from '../CellFallbackView/CellFallbackView';
import GenericDialog from '../GenericDialog';
import BooleanDisplay from '../BooleanDisplay';
import { propTypes } from './GenericTable.props';
import { isConditionMet } from './GenericTable.utils';
import useAppConfigContext from 'contexts/AppConfigContext/useAppConfigContext';
import makeStyles from '../../styles';

const useStyles = makeStyles();

const GenericTable = ({ title, rows, columns, isFixed }) => {
  const [t] = useTranslation();
  const { isUrlAnalysisEnabled } = useAppConfigContext();
  const classes = useStyles({ tableLayout: isFixed ? 'fixed' : 'auto' });
  const cellProps = {
    className: classes.cell,
    component: 'th',
    scope: 'row'
  };

  const iconProps = {
    style: {
      width: '1rem',
      height: '1rem',
      marginLeft: '5px'
    }
  };

  return (
    <Grid container>
      <Grid item xs>
        {title && (<div className={classes.fileInfoHeader}>
          <Typography variant="h4" className={classes.panelTitle}>
            {title}
          </Typography>
        </div>)}
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size="small" aria-label={t('table_name', { title })}>
            <TableHead>
              <TableRow>
                {columns.map(column => <TableCell key={column.label} className={classes.headerCell} align="left">{t(column.label ?? column.key)}</TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <TableRow key={row.name}>
                  {columns.map((column, colIndex) => {
                    const uniqueKey = column.key + rowIndex + colIndex;
                    if (column.type === 'table') {
                      return (<CellFallbackView key={column.label} {...cellProps} condition={!!row[column.key]}>
                        <GenericDialog
                          id={uniqueKey + column.title}
                          data={{
                            ...column,
                            rows: row[column.key]
                          }}
                        /></CellFallbackView>
                      );
                    } else if (column.type === 'tabs') {
                      return (<CellFallbackView key={column.label} {...cellProps} condition={column.tabs.some(tab => isConditionMet(row[tab.key]))}>
                        <GenericDialog
                          id={uniqueKey}
                          data={{
                            ...column,
                            tabs: column.tabs.map(tab => ({ ...tab, data: row[tab.key] }))
                          }}>
                        </GenericDialog>
                    </CellFallbackView>);
                    } else if (column.key === 'url') {
                      return (<CellFallbackView key={column.label} {...cellProps} condition={isConditionMet(row[column.key])}>
                        {
                          isUrlAnalysisEnabled
                            ? <Link to={{ pathname: ROUTE_PATHS.URL_SCAN + '&URL=' + row[column.key] }} className={classes.urlLink}>{row[column.key]} <URLGlobe {...iconProps} /></Link>
                            : row[column.key]
                        }
                      </CellFallbackView>);
                    }
                    return (
                      <CellFallbackView key={column.label} {...cellProps} condition={isConditionMet(row[column.key])}>
                        {(isString(row[column.key]) || isNumber(row[column.key])) && row[column.key]}
                        {isArray(row[column.key]) && <GenericDialog id={uniqueKey} data={{ [column.key]: row[column.key] }}></GenericDialog>}
                        {isObject(row[column.key]) && <GenericDialog id={uniqueKey} data={row[column.key]}></GenericDialog>}
                        {isBoolean(row[column.key]) && <BooleanDisplay value={row[column.key]}/>}
                      </CellFallbackView>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

GenericTable.propTypes = propTypes;

export default GenericTable;
